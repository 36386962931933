import { useHistory } from 'react-router-dom';

const useQueryParams = (paramString = window.location.search) => {
    const history = useHistory();
    const searchParams = new URLSearchParams(paramString);

    return {
        get: param => {
            return searchParams.get(param);
        },
        set: (param, value) => {
            searchParams.set(param, value);
        },
        delete: param => {
            searchParams?.delete(param);
            history.replace({
                search: searchParams.toString(),
            })
        }
    };
};

export default useQueryParams;
