import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import * as Sentry from '@sentry/react';

import App from 'components/App';

import { SENTRY_DSN, BUILD_ENV } from 'utilities/env';

import reportWebVitals from './reportWebVitals';

// error logging disabled for netlify deploy-preview and branch-deploy builds
// DSN only defined in production apps.  see netlify.toml
if (SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: BUILD_ENV || 'Development',
        release: 'consumer-app@0.1' // hard-coded for now
    });
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <StrictMode>
        <App />
    </StrictMode>
);
// ReactDOM.render(
//     <StrictMode>
//         <App />
//     </StrictMode>,

//     document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
