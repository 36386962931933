export const sortPharmacies = async pharmacies => {
    await pharmacies?.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    await pharmacies?.sort(function (a, b) {
        var textA = a.isPrimary;
        var textB = b.isPrimary;
        return textA > textB ? -1 : textA < textB ? 1 : 0;
    });
    return pharmacies;
};
