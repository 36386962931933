import { useEffect, useState } from 'react';

function getThresholdArray(threshold) {
    const lowerBound = Math.max(threshold - 0.05, 0);
    const upperBound = Math.min(threshold + 0.05, 1);
    return [lowerBound, threshold, upperBound];
}

const useIntersectionObserver = ({ root = null, threshold = 0.5, target }) => {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const [intersectionRatio, setIntersectionRatio] = useState(1);

    useEffect(() => {
        let options = {
            root,
            rootMargin: '0px',
            threshold: getThresholdArray(threshold)
        };

        const callback = entries => {
            setIntersectionRatio(entries[0].intersectionRatio);
            setIsIntersecting(entries[0].isIntersecting);
        };

        let observer = new IntersectionObserver(callback, options);
        if (target && document.querySelector(target)) {
            observer.observe(document.querySelector(target));
        }

        return () => {
            observer.disconnect();
        };
    }, [root, target, threshold]);

    return { isIntersecting, intersectionRatio };
};

export default useIntersectionObserver;
