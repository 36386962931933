const typeAheadTitle = 'Doctor Search';
const singleFoundLabel = 'doctor';
const startPrompt = 'Enter your doctor’s ZIP code before searching.';
const multipleFoundLabel = 'doctors';
const noResultsMessage = 'No doctors found under the current search criteria.';
const placeholder = "Start by typing your doctor's name";
const linkText = 'Add a doctor +';

export {
    linkText,
    multipleFoundLabel,
    noResultsMessage,
    placeholder,
    singleFoundLabel,
    startPrompt,
    typeAheadTitle
};
