import { useState } from 'react';

import Body2 from 'components/Body2';
import Icon from 'components/Icon';

import isFunction from 'utilities/isFunction';

import alertIcon from './icons/alert.svg';
import closeIcon from './icons/close.svg';
import infoIcon from './icons/info.svg';
import successIcon from './icons/success.svg';
import warningIcon from './icons/warning.svg';

import styles from './styles.module.scss';

const FeedbackCard = ({
    className = '',
    severity = 'success',
    text,
    onClose = () => {},
    hideClose = false
}) => {
    // severity = alert || info || success || warning
    const [show, setShow] = useState(true);

    const hanldeClick = () => {
        setShow(false);
        onClose();
    };

    const processBackgroundColor = () => {
        switch (severity) {
            case 'alert':
                return '#fdeded';
            case 'info':
                return '#e5f6fd';
            case 'success':
                return '#edf7ed';
            case 'warning':
                return '#fff4e5';
            default:
                return null;
        }
    };

    const renderIcon = () => {
        switch (severity) {
            case 'alert':
                return alertIcon;
            case 'info':
                return infoIcon;
            case 'success':
                return successIcon;
            case 'warning':
                return warningIcon;
            default:
                return null;
        }
    };

    return (
        show && (
            <div className={`${className} ${styles.container}`}>
                <div
                    className={styles.content}
                    style={{ backgroundColor: processBackgroundColor() }}
                >
                    <Icon
                        alt={`${severity} feedback card icon`}
                        className={styles.icon}
                        image={renderIcon()}
                        id="feedbackCardIcon"
                    />

                    <Body2 text={text} className={styles.text} id="feedback" />

                    {!hideClose && (
                        <Icon
                            className={styles.closeIcon}
                            image={closeIcon}
                            onClick={hanldeClick}
                            id="feedbackCardCloseIcon"
                        />
                    )}
                </div>
            </div>
        )
    );
};

export default FeedbackCard;
