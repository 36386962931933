import { memo, useEffect, useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNohealthConditionPreference } from 'services/clientServices/slice';

import { useAuth0 } from '@auth0/auth0-react';

import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import { setHealthConditionContentModal } from 'components/YourHealthConditionContentModal/slice';
import { setHealthConditionModal } from 'components/YourHealthConditionModal/slice';
import YourHealthIntakeModalContent from 'components/YourHealthIntakeModalContent';
import useFetch from 'components/hooks/useFetch';

import dataLayer from 'utilities/dataLayer';
import { CONSUMER_BASE_URL } from 'utilities/env';
import {
    HEALTH_CONDITION_DETAILS_KEY,
    HEALTH_CONDITION_INDEX_KEY,
    HEALTH_CONDITION_NO_HEALTH_CONDITION,
    HEALTH_CONDITION_OPERATION_KEY,
    HEALTH_CONDITION_TERM_KEY,
    PRESCRIPTION_NO_PRESCRIPTIONS
} from 'utilities/storageKeys';

import image from './image.svg';

import { headerTitle, headerText, saveButtonLabel } from './constants';
import { setHealthIntakeModal, setHealthConditionsList } from './slice';

import styles from './styles.module.scss';

import {
    createHealthCondition,
    updateHealthCondition
} from 'temp/utilities/apiSession/healthConditions';
import { get, set } from 'temp/utilities/storage';

const YourHealthIntakeModal = ({ condition, modalClosed, isModal }) => {
    const contactDetailsPageRef = useRef(null);
    const [dateOfLastTreatmentVal, setDateOfLastTreatmentVal] = useState();
    const [isDisable, setIsDisable] = useState(false);
    const healthIntakeModal = useSelector(
        state => state.healthIntakeDetails?.healthIntakeModal
    );
    const { Post: submitPreference } = useFetch(
        `${CONSUMER_BASE_URL}/Preference/Preference`
    );
    const { consumerId } = useSelector(state => state.userProfile.userProfile);

    useEffect(() => {
        if (healthIntakeModal) {
            dataLayer('modal_appear', headerTitle);
        }
    }, [healthIntakeModal]);
    const dispatch = useDispatch();

    const userProfile = useSelector(state => state.userProfile.userProfile);
    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const onClose = async () => {
        const conditionObj = get(HEALTH_CONDITION_TERM_KEY);
        const conditionObjIndex = get(HEALTH_CONDITION_INDEX_KEY);
        const conditionList = get(HEALTH_CONDITION_DETAILS_KEY) || [];
        const operation = get(HEALTH_CONDITION_OPERATION_KEY);

        if (operation !== 'Edit') {
            conditionList.push(conditionObj);
            if (isAuthenticated) {
                const authToken = await getAccessTokenSilently();
                await createHealthCondition({
                    consumerId: user?.consumerid,
                    authToken,
                    stateCode: userProfile?.stateCode,
                    healthConditionData: conditionList
                });
            }
        } else {
            if (isAuthenticated) {
                const authToken = await getAccessTokenSilently();
                await updateHealthCondition({
                    consumerId: user?.consumerid,
                    authToken,
                    stateCode: userProfile?.stateCode,
                    healthConditionData: conditionObj
                });
            } else {
                conditionList[conditionObjIndex] = conditionObj;
            }
        }
        set(HEALTH_CONDITION_DETAILS_KEY, conditionList);
        !isModal && modalClosed(true);
        dispatch(setHealthConditionsList(conditionList));
        dispatch(setHealthIntakeModal(false));
    };

    const gotoPrevious = () => {
        dispatch(setHealthIntakeModal(false));
        dispatch(setHealthConditionModal(true));
    };

    const gotoFinalExpense = async () => {
        const conditionObj = get(HEALTH_CONDITION_TERM_KEY);
        const conditionObjIndex = get(HEALTH_CONDITION_INDEX_KEY);
        conditionObj.lastTreatmentDate = dateOfLastTreatmentVal;
        let conditionList = get(HEALTH_CONDITION_DETAILS_KEY) || [];
        const operation = get(HEALTH_CONDITION_OPERATION_KEY);

        if (operation !== 'Edit') {
            conditionList.push(conditionObj);
            if (isAuthenticated) {
                const authToken = await getAccessTokenSilently();
                setIsDisable(true);
                const data = await createHealthCondition({
                    consumerId: user?.consumerid,
                    authToken,
                    stateCode: userProfile?.stateCode,
                    healthConditionData: conditionList
                });
                conditionList = data;
                setIsDisable(false);
            }
        } else {
            if (isAuthenticated) {
                const authToken = await getAccessTokenSilently();
                setIsDisable(true);
                await updateHealthCondition({
                    consumerId: user?.consumerid,
                    authToken,
                    agentNPN: user?.agentNPN,
                    stateCode: userProfile?.stateCode,
                    leadId: user?.leadId,
                    healthConditionData: conditionObj
                });
                setIsDisable(false);
                conditionList[conditionObjIndex] = conditionObj;
            } else {
                conditionList[conditionObjIndex] = conditionObj;
            }
        }

        set(HEALTH_CONDITION_DETAILS_KEY, conditionList);
        !isModal && modalClosed(true);
        dispatch(setHealthConditionsList(conditionList));
        if (
            conditionList.length > 0 &&
            get(HEALTH_CONDITION_NO_HEALTH_CONDITION) &&
            isAuthenticated
        ) {
            submitPreference({
                consumerId,
                preferences: {
                    hasNoHealthConditions: false,
                    hasNoPrescriptions: get(PRESCRIPTION_NO_PRESCRIPTIONS)
                }
            });
            dispatch(setNohealthConditionPreference(false));
        }

        set(HEALTH_CONDITION_NO_HEALTH_CONDITION, false);
        dispatch(setHealthIntakeModal(false));
        isModal && dispatch(setHealthConditionContentModal(true));
    };

    return (
        <div className={styles.modalWithHeaderFooter}>
            <Modal className={styles.modal} isOpen={healthIntakeModal}>
                <ModalWindow
                    closeIconClassName={styles.closeIconClassName}
                    contentClassName={styles.contentClassName}
                    footerButtonClassName={styles.footerButtonClassName}
                    footerButtonClicked={() => gotoFinalExpense()}
                    footerButtonDisabled={isDisable}
                    footerClassName={styles.footerClassName}
                    footerLabel={saveButtonLabel}
                    headerClassName={styles.headerClassName}
                    headerIcon={image}
                    headerText={headerText}
                    headerTitle={headerTitle}
                    modalWindow={styles.modalWindow}
                    onClose={() => onClose()}
                    isSkip
                    skipButtonLabel={'Previous'}
                    skipButtonClicked={() => gotoPrevious()}
                    skipButtonClassName={styles.skipButtonClassName}
                >
                    <div className={styles.content}>
                        <YourHealthIntakeModalContent
                            setDateOfLastTreatmentVal={
                                setDateOfLastTreatmentVal
                            }
                            isModal={isModal}
                            ref={contactDetailsPageRef}
                            condition={condition}
                            modalClosed={modalClosed}
                        />
                    </div>
                </ModalWindow>
            </Modal>
        </div>
    );
};

export default memo(YourHealthIntakeModal);
