import Heading3 from '../Heading3';
import PlansByZipForm from '../PlansByZipForm';

import { contentText } from './constants';

import styles from './styles.module.scss';

const YourLocationModalContent = ({ showCounty, id }) => {
    return (
        <div className={styles.yourLocationModalContent}>
            <Heading3
                text={contentText}
                className={styles.heading3}
                id={'yourLocationModalHeading'}
            />
            <div className={styles.plansByZipFormWrapper}>
                <PlansByZipForm
                    className={styles.plansByZipForm}
                    id={id}
                    isOnChangeEnable={false}
                    showCounty={showCounty}
                />
            </div>
        </div>
    );
};

export default YourLocationModalContent;
