import { createSlice } from '@reduxjs/toolkit';

export const createAccount = createSlice({
    name: 'createAccount',

    initialState: {
        createAccountModal: false
    },

    reducers: {
        setCreateAccountModal: (state, action) => {
            state.createAccountModal = action.payload;
        }
    }
});

export const { setCreateAccountModal } = createAccount.actions;

export default createAccount.reducer;
