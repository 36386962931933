import { forwardRef, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button';
import InfoCard from 'components/InfoCard';
import PlanCostLabel from 'components/PlanCostLabel';
import { setLegacyData } from 'components/YourLegacyModal/slice';

import LegacyCard from 'pages/LegacyCard';
import LegacyForm from 'pages/LegacyForm';

import {
    LegacySafeguardWebsite,
    buttonLabel,
    medicareFormDescription
} from './constants';

import styles from './styles.module.scss';

const YourLegacyModalContent = forwardRef(
    ({ showFooter, footerButtonDisabled }, ref) => {
        const dispatch = useDispatch();
        const [showForm, setShowForm] = useState(false);
        const [showCard, setShowCard] = useState(false);
        const [details, setDetails] = useState(null);
        const legacyData = useSelector(
            state => state.legacyModalDetails?.legacyData
        );

        const handleLinkClick = event => {
            event.preventDefault();
            window.open(LegacySafeguardWebsite);
        };

        useEffect(() => {
            if (legacyData?.uniqueId) {
                const data = {
                    firstName: legacyData.firstName,
                    lastName: legacyData.lastName,
                    name: legacyData.name,
                    uniqueId: legacyData.uniqueId
                };
                fillDetails(data);
            } else {
                setShowCard(false);
                setShowForm(true);
            }
        }, []);

        const fillDetails = userProfile => {
            const { firstName, lastName, uniqueId } = userProfile;
            if (userProfile && Object.keys(userProfile).length) {
                setShowCard(Boolean(uniqueId));

                setDetails({
                    firstName: firstName,
                    lastName: lastName,
                    name: firstName + ' ' + lastName,
                    uniqueId: uniqueId
                });

                dispatch(
                    setLegacyData({
                        firstName: firstName,
                        lastName: lastName,
                        name: firstName + ' ' + lastName,
                        uniqueId: uniqueId
                    })
                );
            }
        };

        useEffect(() => {
            showFooter(showForm);
        }, [showForm]);

        const MedicareIDBody = () => {
            if (showForm)
                return (
                    <LegacyForm
                        details={details || {}}
                        onCancel={() => {
                            setShowForm(false);
                            if (details?.uniqueId) setShowCard(true);
                        }}
                        updateDetails={data => {
                            fillDetails(data);
                            setShowForm(false);
                            setShowCard(true);
                            showFooter(false);
                        }}
                        from={'legacy'}
                        noPlaceholder={true}
                        medicareNumberPlaceholderText="***-***-***-***"
                        ref={ref}
                        footerButtonDisabled={footerButtonDisabled}
                        cardHeaderClass={styles.cardHeader}
                        footerBlock={styles.footerBlock}
                    />
                );

            if (showCard && details)
                return (
                    <>
                        <LegacyCard
                            details={details}
                            cardHeaderClass={styles.cardHeader}
                            onCancel={() => {
                                setShowForm(true);
                                setShowCard(false);
                                showFooter(true);
                            }}
                            from={'legacy'}
                            showMedicareCode={true}
                        />
                    </>
                );
            return <></>;
        };

        return (
            <>
                <div
                    className={`${styles.informationCards} medicare-id-container`}
                    id={'medicare-id'}
                >
                    <InfoCard
                        bodyElement={<MedicareIDBody />}
                        containerClassName={styles.insuranceInfoContainer}
                        heading={
                            <PlanCostLabel
                                className={styles.medicareIdInfoCard}
                                description={medicareFormDescription}
                                label="Legacy ID"
                                modalBoxClassName={styles.medicareIdModal}
                                from="legacyModal"
                            />
                        }
                        titleContainerClassName={styles.agentInfoTitle}
                        infoCardDetailsClassName={
                            styles.infoCardDetailsClassName
                        }
                    />
                </div>
                {!showForm && (
                    <div className={styles.buttonBlock}>
                        <Button
                            className={styles.visitLegacyBtn}
                            label={buttonLabel}
                            onClick={handleLinkClick}
                        />
                    </div>
                )}
            </>
        );
    }
);

export default memo(YourLegacyModalContent);
