import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import Heading1 from 'components/Heading1';
import Text from 'components/Text';

import styles from './styles.module.scss';

const Error = ({
    alt = 'icon',
    className = '',
    headingClassName = '',
    errorCode,
    image,
    label = 'Return to Home',
    text = '',
    url
}) => {
    const history = useHistory();

    const handleClick = url => {
        history.push(url);
    };

    return (
        <div className={`${styles.error} ${className} `}>
            <div className={styles.container}>
                {image && (
                    <img alt={alt} className={styles.image} src={image} />
                )}

                <Heading1
                    className={`${styles.heading1} ${headingClassName}`}
                    text={errorCode}
                />

                <Text className={styles.text} text={text} />

                {url && (
                    <Button
                        className={styles.button}
                        label={label}
                        onClick={() => handleClick(url)}
                    />
                )}
            </div>
        </div>
    );
};

export default Error;
