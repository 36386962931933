import { useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InfoCard from 'components/InfoCard';
import InfoCardBodyWithHeader from 'components/InfoCardBodyWithHeader';
import { LifeInsuranceContent } from 'components/InsuranceDetailsInfoCards';
import YourLifePlanModal from 'components/YourLifePlanModal';
import { setLifePlanContentModal } from 'components/YourLifePlanModal/slice';

import icon from './image.svg';

import styles from './styles.module.scss';

const LifeInsuranceDetail = ({
    lifePlans,
    setIsLifeAgentModalOpen,
    setSeletedAgentDetails,
    setIsAgentDetailsModalOpen
}) => {
    const dispatch = useDispatch();
    const finalExpenseAgent = useSelector(
        state => state.planTypeDetail.finalExpenseAgent
    ) || {
        agentFirstName: '',
        agentLastName: ''
    };
    const [insuranceIcon, setInsuranceIcon] = useState();
    const [lifePlanInfo, setLifePlanInfo] = useState();

    const openLifePlanModal = (icon, index) => {
        if (lifePlans[0] && lifePlans[0]?.policyStatus) {
            setInsuranceIcon(icon);
            dispatch(setLifePlanContentModal(true));
            setLifePlanInfo(lifePlans[index]);
        }
    };

    const onOpenLifeInsuranceAgentDetailsModal = event => {
        event.preventDefault();
        setIsLifeAgentModalOpen(true);
        setSeletedAgentDetails(finalExpenseAgent);
        setIsAgentDetailsModalOpen(true);
    };

    return (
        <InfoCard
            bodyElement={
                <div className={styles.bodyContainer}>
                    <InfoCardBodyWithHeader
                        bodyElement={
                            <>
                                <LifeInsuranceContent
                                    openModal={openLifePlanModal}
                                    lifePlans={lifePlans}
                                    agentDetails={finalExpenseAgent}
                                    onClickViewAgent={
                                        onOpenLifeInsuranceAgentDetailsModal
                                    }
                                />

                                {lifePlanInfo && (
                                    <YourLifePlanModal
                                        insuranceImage={insuranceIcon}
                                        plansDetails={lifePlanInfo}
                                        onClose={() => {
                                            setLifePlanInfo(undefined);
                                        }}
                                    />
                                )}
                            </>
                        }
                    />
                </div>
            }
            heading={'Life Insurance Details'}
            containerClassName={styles.container}
            icon={icon}
            iconClassName={styles.headerIcon}
            infoCardDetailsClassName={styles.cardBody}
            titleContainerClassName={styles.titleContainerClassName}
        />
    );
};

export default memo(LifeInsuranceDetail);
