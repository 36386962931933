export const CoverageIcon = props => (
    <svg
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx="15.1416" cy="15" r="15" fill="#A8C5EE" />
        <path
            d="M15.1749 22.975C15.2683 23.0083 15.3683 23.0083 15.4549 22.975C17.7216 22.2017 21.9816 19.4483 21.9816 17.035V9.535C21.9816 9.26833 21.7816 9.04167 21.5149 9.02833C18.1016 8.92167 16.2816 7.68833 15.6149 7.115C15.4416 6.96167 15.1816 6.96167 15.0083 7.115C14.3416 7.68833 12.5216 8.92167 9.10827 9.02833C8.84827 9.04167 8.6416 9.26167 8.6416 9.535V17.035C8.6416 19.4483 12.9016 22.2017 15.1683 22.975H15.1749Z"
            stroke="white"
            stroke-miterlimit="10"
        />
        <path
            d="M19.6416 13L14.4991 17.5714L11.6416 15.0317"
            stroke="white"
            stroke-miterlimit="10"
        />
    </svg>
);
