export const MedicaidRadioOptions = ['Yes', 'No', "I Don't Know"];
export const SubSidyRadioOptions = ['Yes', 'No', "I Don't Know"];

export const bulletList = [
    'Have a monthly income of up to $1,903, or $2,575 for couples',
    'Have resources, such as stocks, bonds, and money in accounts, below $17,220 for individuals, or $34,360 for couples',
    'Be enrolled in Medicaid, Supplemental Security Income (SSI), or a Medicare Savings Program (MSP)',
    'Be entitled to Medicare Part A or Medicare Part B, or both',
    'Be a resident of one of the 50 states or the District of Columbia',
    'Not be incarcerated'
];
