import Icon from 'components/Icon';

import hoverIcon from './icon.svg';
import image from './image.svg';

import styles from './styles.module.scss';

const UserInfoIcon = ({ handleClick, active }) => (
    <Icon
        alt="userIcon"
        className={styles.icon}
        image={active ? hoverIcon : image}
        onClick={handleClick}
        id="userIcon"
    />
);

export default UserInfoIcon;
