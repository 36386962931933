import Heading1 from 'components/Heading1';

import styles from './styles.module.scss';

const BannerWithBackgroundColor = ({
    className = '',
    headingClassName = '',
    title = ''
}) => (
    <div className={`${className} ${styles.bannerWithBackgroundColor}`}>
        <Heading1
            className={`${headingClassName} ${styles.heading1}`}
            text={title}
        />
    </div>
);

export default BannerWithBackgroundColor;
