import { createSlice } from '@reduxjs/toolkit';

import { PHARMACY_NAMES_KEY } from 'utilities/storageKeys';

import { get } from 'temp/utilities/storage';

export const pharmacyDetails = createSlice({
    name: 'pharmacyDetails',

    initialState: {
        pharmacyModal: false,
        pharmacyNames: get(PHARMACY_NAMES_KEY, []),
        isApiFailed: false,
        selectedPharmacyId: [],
        selectedPlan: null
    },

    reducers: {
        setPharmacyModal: (state, action) => {
            state.pharmacyModal = action.payload;
        },

        setPharmacyNames: (state, action) => {
            state.pharmacyNames = action.payload;
        },
        setSelectedPharmacyId: (state, action) => {
            state.selectedPharmacyId = action.payload;
        },
        setIsApiFailed: (state, action) => {
            state.isApiFailed = action.payload;
        },
        setSelectedPlan: (state, action) => {
            state.selectedPlan = action.payload;
        }
    }
});

export const {
    setIsApiFailed,
    setPharmacyModal,
    setPharmacyNames,
    setSelectedPharmacyId,
    setSelectedPlan
} = pharmacyDetails.actions;

export default pharmacyDetails.reducer;
