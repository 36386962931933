import { createSlice } from '@reduxjs/toolkit';

export const reduxExample = createSlice({
    name: 'reduxExample',

    initialState: {
        value: 0
    },

    reducers: {
        decrement: state => {
            state.value -= 1;
        },

        increment: state => {
            state.value += 1;
        },

        incrementByValue: (state, action) => {
            state.value += action.payload;
        }
    }
});

export const { decrement, increment, incrementByValue } = reduxExample.actions;

export default reduxExample.reducer;
