import * as Sentry from '@sentry/react';

import {
    finalExpenseAgentPlanType,
    medicareAgentPlanType,
    medicarePlanType
} from 'components/PlanTypeModal/constants';

import { AVAILABLE_AGENT_URL, AVAILABLE_AGENT_TOKEN } from 'utilities/env';
import getJSON from 'utilities/getJSON';

const getUrl = () => {
    return `${AVAILABLE_AGENT_URL}/getAgentByZip`;
};

const getAvailableAgentsData = async (zipCode, planType = medicarePlanType) => {
    const agentPlanType =
        planType === medicarePlanType
            ? medicareAgentPlanType
            : finalExpenseAgentPlanType;
    const url = `${getUrl()}?zipCode=${zipCode}&planType=${agentPlanType}`;
    const additionalHeaders = {
        Authorization: `Bearer ${AVAILABLE_AGENT_TOKEN}`
    };
    try {
        return await getJSON({ url, additionalHeaders });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

export { getAvailableAgentsData };
