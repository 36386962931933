import WalletContainer from 'components/WalletContainer';

import TodoItems from 'pages/TodoItems';
import UserProfileCard from 'pages/UserProfileCard';

import styles from './styles.module.scss';

const ProfileWalletWithTodos = ({ insuranceIcon, setInsuranceIcon }) => {
    return (
        <div className={styles.profileWalletTodoContainer}>
            <div>
                <UserProfileCard />

                <WalletContainer
                    insuranceIcon={insuranceIcon}
                    setInsuranceIcon={setInsuranceIcon}
                />
            </div>

            <div>
                <TodoItems setInsuranceIcon={setInsuranceIcon} />
            </div>
        </div>
    );
};
export default ProfileWalletWithTodos;
