import { useState } from 'react';

import { Tooltip } from '@mui/material';

import Icon from 'components/Icon';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';

import handleEvent from 'utilities/handleEvent';

import image from './image.svg';

import styles from './styles.module.scss';

const InfoModal = ({
    className = '',
    description,
    iconClassName = '',
    modalBoxClassName = '',
    headerClassName = '',
    hideFooter = false,
    title,
    from
}) => {
    const [open, setOpen] = useState(false);
    const handleClick = onClick => handleEvent(onClick);

    return description ? (
        <div className={className}>
            {from &&
            (from === 'healthInsuranceModal' || from === 'legacyModal') ? (
                <Tooltip
                    title={description}
                    arrow
                    classes={{
                        tooltip: styles.tooltipClass,
                        arrow: styles.tooltipArrowClass
                    }}
                >
                    <div>
                        <Icon
                            className={`${iconClassName} ${styles.infoIcon}`}
                            image={image}
                            id="informationIcon"
                        />
                    </div>
                </Tooltip>
            ) : (
                <>
                    <Icon
                        className={`${iconClassName} ${styles.infoIcon}`}
                        image={image}
                        onClick={handleClick(() => setOpen(true))}
                        id="informationIcon"
                    />

                    <Modal isOpen={open}>
                        <ModalWindow
                            contentClassName={styles.contentClassName}
                            footerClassName={styles.footerClassName}
                            hideFooter={hideFooter}
                            headerClassName={`${styles.headerClassName} ${headerClassName}`}
                            headerTitle={title}
                            footerButtonClassName={styles.footerButtonClassName}
                            modalBoxClassName={modalBoxClassName}
                            footerLabel={'< Back to Filters'}
                            footerButtonClicked={() => {
                                setOpen(false);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                        >
                            {title === 'Medicare ID' ? (
                                description
                            ) : (
                                <span
                                    id="description"
                                    dangerouslySetInnerHTML={{
                                        __html: description
                                    }}
                                ></span>
                            )}
                        </ModalWindow>
                    </Modal>
                </>
            )}
        </div>
    ) : null;
};

export default InfoModal;
