import { useSelector } from 'react-redux';

import Body2 from 'components/Body2';
import Heading2 from 'components/Heading2';
import Icon from 'components/Icon';
import IconWithLink from 'components/IconWithLink';
import LinkWithArrow from 'components/LinkWithArrow';
import { medicarePlanType } from 'components/PlanTypeModal/constants';
import ProfilePicture from 'components/ProfilePicture';
import Text from 'components/Text';

import arrow from './images/arrow.svg';
import online from './images/online.svg';

import styles from './styles.module.scss';

export const AgentAvailableComponent = ({
    labelClassName = '',
    hideText = false,
    text = 'Available Now',
    isNetworkAgent,
    toggleStatus
}) => {
    if (isNetworkAgent && toggleStatus) {
        return (
            <span className={styles.onlineStyle}>
                {' '}
                <Icon className={`${styles.onlineIcon}`} image={online} />
                {!hideText && (
                    <Body2
                        className={`${styles.availableText} ${labelClassName}`}
                        text={text}
                    />
                )}
            </span>
        );
    } else {
        return null;
    }
};

export const AgentAvailableComponentPurl = ({
    labelClassName = '',
    hideText = false,
    text = 'Available Now',
    isPurlToggledOn
}) => {
    if (isPurlToggledOn) {
        return (
            <span className={styles.onlineStyle}>
                {' '}
                <Icon className={`${styles.onlineIcon}`} image={online} />
                {!hideText && (
                    <Body2
                        className={`${styles.availableText} ${labelClassName}`}
                        text={text}
                    />
                )}
            </span>
        );
    } else {
        return null;
    }
};

export const PurlBannerAgentInfo = ({
    purlAgentData,
    handleClose,
    isMobile,
    onClickViewMore
}) => {
    const { isPurlToggledOn, agentProfessionalInfoResponse } =
        purlAgentData || {};
    const { agentOfficeLocation: office, formattedHours = '' } =
        agentProfessionalInfoResponse || {};
    const { city = '', state = '' } = office || {};

    const selectedPlanType = useSelector(
        state => state.planTypeDetail.selectedPlanType
    );
    const selectedAgentType = useSelector(
        state => state.planTypeDetail.selectedAgentType
    );

    const agentName = purlAgentData?.presentationName
        ? purlAgentData?.presentationName
        : `${purlAgentData.agentFirstName} ${purlAgentData.agentLastName}`;

    const npnCAContent = (
        <div className={styles.npnCIAContainer}>
            <Text>NPN: {purlAgentData.agentNpn}</Text>
            {purlAgentData.caLicense && (
                <Text
                    className={purlAgentData.agentNpn ? styles.ciaLicense : ''}
                >
                    California License#: {purlAgentData.caLicense}
                </Text>
            )}
        </div>
    );

    return (
        <div className={styles.agentInfoContainer}>
            {isMobile && (
                <ProfilePicture
                    firstName={purlAgentData.agentFirstName}
                    lastName={purlAgentData.agentLastName}
                    profileUrl={purlAgentData.profileImageUrl}
                    hideCameraOverlay={true}
                />
            )}
            <div className={styles.purlAgentNameContainer}>
                <div className={styles.nameWithStatus}>
                    <div className={styles.nameContainer}>
                        <Heading2
                            className={styles.purlAgentName}
                            text={agentName}
                        />

                        {!!selectedAgentType && (
                            <Text
                                text={
                                    purlAgentData?.isMedicareAgent &&
                                    selectedPlanType === medicarePlanType
                                        ? 'Health Agent '
                                        : `Life Agent`
                                }
                                className={styles.agentType}
                            />
                        )}
                    </div>

                    {isMobile && (
                        <AgentAvailableComponent
                            isPurlToggledOn={isPurlToggledOn}
                        />
                    )}
                    {!isMobile && npnCAContent}
                </div>

                {!isMobile && (
                    <Text className={styles.purlAgentSubText}>
                        {(city && city.length > 0) ||
                        (state && state.length > 0) ? (
                            <>
                                <Text className={styles.cityState}>
                                    <Text className={styles.cityState}>
                                        {`${[city, state]
                                            .filter(Boolean)
                                            .join(', ')}  `}
                                    </Text>
                                </Text>
                            </>
                        ) : (
                            <></>
                        )}

                        <>
                            {formattedHours?.trim() !== '' && (
                                <div
                                    className={`${
                                        (city && city.length > 0) ||
                                        (state && state.length > 0)
                                            ? ''
                                            : styles.noBorder
                                    } ${styles.text}`}
                                >
                                    {formattedHours &&
                                        formattedHours.split(',').map(hours => (
                                            <Body2
                                                className={styles.formattedHour}
                                                text={hours
                                                    .split('-')
                                                    .map((item, i, items) => (
                                                        <Body2
                                                            text={`${item} ${
                                                                i % 2 === 0 &&
                                                                items.length -
                                                                    1 !==
                                                                    i
                                                                    ? ' - '
                                                                    : ''
                                                            } `}
                                                        />
                                                    ))}
                                            />
                                        ))}
                                </div>
                            )}
                        </>
                    </Text>
                )}
                {!isMobile && (
                    <LinkWithArrow
                        text={'View More'}
                        className={styles.viewMore}
                        onClick={onClickViewMore}
                        iconClassName={styles.rightArrowIcon}
                    />
                )}
                {localStorage.getItem('reqAgentData') && (
                    <IconWithLink
                        caption="Return to Map"
                        className={styles.iconWithLink}
                        iconClassName={styles.iconClassName}
                        image={arrow}
                        labelClassName={styles.labelClassName}
                        onClick={() => handleClose(true)}
                    />
                )}
            </div>
        </div>
    );
};
