import { createPortal } from 'react-dom';

import Overlay from 'components/Overlay';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return createPortal(
        <Overlay onClick={onClose}>{children}</Overlay>,
        document.body.querySelector('#portal')
    );
};

export default Modal;
