import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useClientServiceContext } from 'services/clientServiceProvider';

import Button from 'components/Button';
import Icon from 'components/Icon';
import LegacySafeGuardCard from 'components/LegacySafeGuardCard';
import { setLegacyData } from 'components/YourLegacyModal/slice';
import useFetch from 'components/hooks/useFetch';

import medicareCardIcon from './image.svg';

import emptyImage from './saftguard_blank.jpeg';

import styles from './styles.module.scss';

const WalletLegacyCard = ({
    mouseOver,
    walletID,
    mouseOverOut,
    cardClassName = styles.walletLegacyCard,
    openLegacyModal
}) => {
    const dispatch = useDispatch();
    const { name, uniqueId } = useSelector(
        state => state.legacyModalDetails?.legacyData
    );

    const { clientService } = useClientServiceContext();

    const getConsumerId = () => {
        const consumerid = clientService.getConsumerid();
        return consumerid;
    };
    const consumerid = getConsumerId();
    const { Get: getLegacyDetails } = useFetch(
        `${import.meta.env.VITE_APP_PLANNING_TOOL_SERVICE_URL}/${consumerid}`
    );

    const initLegacyDetails = async () => {
        try {
            const legacyDetails = await getLegacyDetails();
            if (legacyDetails?.firstName) {
                const { firstName, lastName, uniqueId } = legacyDetails;
                const data = {
                    firstName: firstName,
                    lastName: lastName,
                    name: firstName + ' ' + lastName,
                    uniqueId: uniqueId
                };
                dispatch(
                    setLegacyData({
                        ...data
                    })
                );
            }
            // eslint-disable-next-line no-empty
        } catch (error) {}
    };

    useEffect(() => {
        if (consumerid) initLegacyDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consumerid]);

    return (
        <div className={cardClassName} id="walletLegacyCard">
            {!name ? (
                <div
                    className={styles.walletCardContainer}
                    onClick={() => mouseOver('walletLegacyCard')}
                >
                    <Icon
                        id={'walletLegacyCardIcon'}
                        image={emptyImage}
                        className={styles.legacyIcon}
                    />
                    <Button
                        className={styles.addMemberIdBtn}
                        onClick={() =>
                            walletID === 'walletLegacyCard' &&
                            openLegacyModal(medicareCardIcon)
                        }
                        label="Add Member ID"
                    />
                </div>
            ) : (
                <div
                    onClick={() =>
                        walletID !== 'walletLegacyCard'
                            ? mouseOver('walletLegacyCard')
                            : openLegacyModal(medicareCardIcon)
                    }
                >
                    <LegacySafeGuardCard
                        cardClassName={styles.insuranceIcon}
                        name={name}
                        id={uniqueId}
                    />
                </div>
            )}
        </div>
    );
};

export default WalletLegacyCard;
