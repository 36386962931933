import { createSlice } from '@reduxjs/toolkit';

import {
    HEALTH_CONDITION_NO_HEALTH_CONDITION,
    PRESCRIPTION_NO_PRESCRIPTIONS
} from 'utilities/storageKeys';

import { set } from 'temp/utilities/storage';

export const userProfile = createSlice({
    name: 'userProfile',

    initialState: {
        userProfile: {
            consumerId: 0,
            userId: '',
            leadIdGuid: '',
            firstName: '',
            lastName: '',
            zip: '',
            county: '',
            email: '',
            phoneNumber: '',
            dateOfBirth: '',
            inactive: 0,
            hasMedicAid: null,
            syncId: '',
            height: 0,
            weight: 0,
            gender: '',
            isTobaccoUser: '',
            preferences: {
                hasNoHealthConditions: false,
                hasNoPrescriptions: false
            }
        }
    },

    reducers: {
        setUserProfile: (state, action) => {
            state.userProfile = action.payload || {};
            state.userProfile.syncId =
                action.payload.leadId || action.payload.leadIdGuid
                    ? action.payload.consumerId
                    : '';
        },
        setNohealthConditionPreference: (state, action) => {
            state.preferences = { ...(state.preferences || {}) };
            state.preferences = {
                hasNoHealthConditions: action.payload,
                hasNoPrescriptions:
                    state.preferences.hasNoPrescriptions || false
            };
            set(HEALTH_CONDITION_NO_HEALTH_CONDITION, action.payload);
        },
        setNoPrescriptionsPreference: (state, action) => {
            state.preferences = { ...(state.preferences || {}) };
            state.preferences = {
                hasNoHealthConditions:
                    state.preferences.hasNoHealthConditions || false,
                hasNoPrescriptions: action.payload
            };
            set(PRESCRIPTION_NO_PRESCRIPTIONS, action.payload);
        }
    }
});

export const {
    setUserProfile,
    setNohealthConditionPreference,
    setNoPrescriptionsPreference
} = userProfile.actions;

export default userProfile.reducer;
