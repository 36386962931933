import {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState
} from 'react';

import { TextField } from '@mui/material';

import ActionText from 'components/ActionText';
import IconWithLink from 'components/IconWithLink';
import Spinner from 'components/Spinner';
import Text from 'components/Text';

import handleEvent from 'utilities/handleEvent';
import isFunction from 'utilities/isFunction';

import arrow from './images/arrow.svg';
import SearchIcon from './images/searchIcon.svg';

import styles from './styles.module.scss';

import useDebounce from 'temp/utilities/useDebounce';

const USER_DISCONNECT = 'The user aborted a request.';

const TypeAhead = forwardRef(
    (
        {
            showSearch,
            debounceDelay = 250,
            defaultValue,
            fetchResults,
            multipleFoundLabel = 'found',
            noResultsMessage = 'No results found under the current search criteria.',
            onChange,
            onClear,
            placeholder = 'Start typing to search',
            prescriptions,
            prescriptionsList,
            resultMap,
            setShowLink,
            singleFoundLabel = 'found',
            startPrompt = 'Start typing to search.',
            title,
            PER_PAGE = 10,
            handleSelectedPrescription
        },
        ref
    ) => {
        const [page, setPage] = useState(1);
        const [results, setResults] = useState([]);

        const [searchResults, setSearchResults] = useState([]);
        const [total, setTotal] = useState(0);
        const [showResults, setShowResults] = useState(false);
        const label =
            results?.length === 1 ? singleFoundLabel : multipleFoundLabel;
        const text = `${results?.length || 0} ${label}`;
        const [searchTerm, setSearchTerm] = useState(defaultValue);
        const debouncedSearchTerm = useDebounce(searchTerm, debounceDelay);
        const showNoResultsMessage =
            debouncedSearchTerm && results?.length === 0;
        const infoMessage = !searchTerm
            ? startPrompt
            : showNoResultsMessage
            ? noResultsMessage
            : '';
        const [error, setError] = useState(false);
        const [loading, setLoading] = useState(false);
        const [isShowAddList, setIsShowAddList] = useState(
            prescriptions.length > 0 && !showSearch
        );
        const searchTermChanged = e => {
            const newSearchTerm = e?.target?.value;
            setSearchTerm(newSearchTerm);
            setShowResults(false);
            isFunction(onChange) && onChange(newSearchTerm);
        };

        const handleClear = handleEvent(onClear, () => {
            setSearchTerm('');
            setShowResults(false);
            setIsShowAddList(!!prescriptions.length && !showSearch);
            setShowLink(true);
            handleSelectedPrescription('');
        });

        const onBlur = e => {
            setTimeout(() => {
                setShowLink(true);
                if (prescriptions.length > 0 && !searchTerm) {
                    setIsShowAddList(true);
                }
            }, 100);
        };

        const onFocus = () => {
            setShowLink(true);
            setIsShowAddList(false);
        };

        useEffect(() => {
            setSearchTerm(defaultValue);
        }, [defaultValue]);

        useEffect(() => {
            if (prescriptions.length === 0) {
                setShowLink(true);
            } else {
                setShowLink(true);
                if (prescriptions.length > 0 && !searchTerm) {
                    setIsShowAddList(true);
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [prescriptions]);

        const clearResults = useCallback(() => {
            setResults([]);
            setShowResults(false);
            setTotal(0);
            setPage(1);
        }, []);

        const getData = useCallback(
            async ({ searchTerm, signal, perPage }) => {
                setLoading(true);
                setShowResults(false);
                setError(false);
                try {
                    const searchResultsList = await fetchResults({
                        searchTerm,
                        signal,
                        page,
                        perPage
                    });

                    setError(false);
                    // setResults(searchResults || []);
                    if (searchResultsList) {
                        setSearchResults(searchResultsList);
                        const results = searchResultsList?.slice(
                            0,
                            page * perPage
                        );
                        setResults(results);
                        setTotal(searchResultsList.length);
                    } else {
                        clearResults();
                    }
                } catch (networkError) {
                    clearResults();
                    if (!networkError === USER_DISCONNECT) {
                        setError(true);
                    }
                }

                setLoading(false);
                setShowResults(true);
            },
            [clearResults, fetchResults]
        );

        useEffect(() => {
            const controller = new AbortController();
            const signal = controller.signal;

            if (debouncedSearchTerm?.length) {
                getData({
                    searchTerm: debouncedSearchTerm,
                    signal,
                    page,
                    perPage: PER_PAGE
                });
            } else {
                clearResults();
            }

            return () => {
                controller.abort();
            };
            // this effect should only be rerun when the debounced Search Term changes
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [debouncedSearchTerm, clearResults]);

        useEffect(() => {
            if (results) {
                const results = searchResults?.slice(0, page * PER_PAGE);
                setResults(results);
                setTotal(searchResults.length);
            } else {
                clearResults();
            }
        }, [page]);

        function showAddList() {
            setShowLink(true);
            setIsShowAddList(true);
            setSearchTerm('');
            document.getElementById('typeAhead')?.blur();
        }

        useImperativeHandle(ref, () => ({
            showAddList
        }));

        return (
            <>
                {showSearch && (
                    <>
                        <Text className={styles.title} text={title} />

                        <TextField
                            autoFocus={
                                !(prescriptions.length > 0 && !searchTerm)
                            }
                            id={
                                title
                                    ? `${title?.replaceAll(' ', '_')}Input`
                                    : 'typeAhead'
                            }
                            className={styles.textField}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onChange={searchTermChanged}
                            defaultValue={defaultValue}
                            error={error}
                            variant="outlined"
                            inputProps={{
                                maxLength: 100,
                                type: 'search'
                            }}
                            InputProps={{
                                endAdornment: (
                                    <img
                                        alt="search"
                                        className={styles.searchIcon}
                                        src={SearchIcon}
                                    />
                                )
                            }}
                            placeholder={placeholder}
                            value={searchTerm}
                        />

                        {searchTerm && (
                            <ActionText
                                className={styles.actionText}
                                onClick={handleClear}
                                id="cancelSearch"
                            >
                                Cancel
                                <span className={styles.hideOnMobile}>
                                    Search
                                </span>
                            </ActionText>
                        )}
                    </>
                )}

                {isShowAddList && prescriptions.length > 0 && !showSearch ? (
                    prescriptionsList
                ) : (
                    <>
                        {results?.length > 0 && (
                            <Text
                                className={styles.foundText}
                                id="foundPrescriptionCount"
                            >
                                <b>{text}</b> found
                            </Text>
                        )}

                        {loading ? <Spinner /> : null}
                        {!loading && infoMessage && (
                            <Text
                                className={styles.infoMessage}
                                text={infoMessage}
                                id="infoMessage"
                            />
                        )}

                        {!loading && showResults && (
                            <ul className={styles.resultsList}>
                                {isFunction(resultMap) &&
                                    results.map(resultMap)}
                            </ul>
                        )}
                        {!loading && total > results?.length && (
                            <>
                                {total > PER_PAGE && (
                                    <>
                                        <div className={styles.divider} />
                                        <IconWithLink
                                            caption={'Show More'}
                                            className={styles.iconWithLink}
                                            iconClassName={styles.iconClassName}
                                            image={arrow}
                                            isImageClickable={true}
                                            labelClassName={
                                                styles.labelClassName
                                            }
                                            onClick={() => {
                                                setPage(page + 1);
                                                document
                                                    .getElementById(
                                                        `${page * PER_PAGE}`
                                                    )
                                                    .scrollIntoView();
                                            }}
                                            isTextDisplayRight={false}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </>
        );
    }
);

export default TypeAhead;
