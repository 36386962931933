export const headerTitle = 'Your Doctors';
export const headerTitle2 = 'Edit Your Doctors';
export const headerTitle3 = 'Add Doctor';
export const headerText = `Want to add or edit your selected doctors? Edit your doctor list and add new physicians to see if they are covered on your plan’s network.`;
export const headerText2 = `Your relationships with your healthcare providers are important. If you’d like, add your doctors to see if they’re in each plan’s network.`;
export const headerIconAlt =
    'An image with a plus sign inside a suite case symbol';
export const buttonLabel = 'Add and continue';
export const plansButtonLabel = 'Continue';
export const addDoctorsButtonLabel = 'Add Doctors';
export const updateLabel = 'Update Doctor';
export const updateLabelMobile = 'Update';
