import { createSlice } from '@reduxjs/toolkit';

export const healthInsuranceModalDetails = createSlice({
    name: 'healthInsuranceModalDetails',

    initialState: {
        healthInsuranceContentModal: false,
        legacyContentModal: false
    },

    reducers: {
        setHealthInsuranceContentModal: (state, action) => {
            state.healthInsuranceContentModal = action.payload;
        },
        setLegacyContentModal: (state, action) => {
            state.legacyContentModal = action.payload;
        }
    }
});

export const { setHealthInsuranceContentModal, setLegacyContentModal } =
    healthInsuranceModalDetails.actions;

export default healthInsuranceModalDetails.reducer;
