import { format } from 'date-fns';
import moment from 'moment';

import * as Sentry from '@sentry/react';

import { API_BASE_URL } from 'utilities/env';
import getJSON from 'utilities/getJSON';
import postJSON from 'utilities/postJSON';

import { getLeadId } from './leadId';

const getUrl = () => {
    const leadId = getLeadId();
    return `${API_BASE_URL}/FinalExpenses/quotes/Lead/${leadId}`;
};

const getFinalExpensePlans = async ({
    usState,
    age,
    conditionsData,
    gender,
    tobacco,
    desiredFaceValue,
    desiredMonthlyRate,
    coverageTypes,
    userHeight,
    userWeight
}) => {
    const url = getUrl();
    const todayDate = format(new Date(), 'yyyy-MM-dd');
    const conditionsArray = [];

    if (conditionsData) {
        for await (const condition of conditionsData) {
            const conditionObj = {
                categoryId: condition?.conditionId,
                lastTreatmentDate: condition?.lastTreatmentDate
                    ? moment(condition?.lastTreatmentDate).format('YYYY-MM-DD')
                    : null
            };

            conditionsArray.push(conditionObj);
        }
    }
    const params = {
        usState,
        age,
        gender,
        tobacco,
        desiredFaceValue,
        desiredMonthlyRate,
        coverageTypes,
        effectiveDate: todayDate,
        underWriting: {
            user: {
                height: userHeight,
                weight: userWeight
            },
            conditions: conditionsArray
        }
    };

    try {
        return await postJSON({
            body: params,
            url
        });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const getHealthConditions = async searchVal => {
    const url = `${API_BASE_URL}/FinalExpenses/uw/search/${searchVal}`;
    try {
        return await getJSON({ url });
    } catch (e) {
        Sentry.captureException(e);
        throw e;
    }
};

export { getFinalExpensePlans, getHealthConditions };
