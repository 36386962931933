import styles from './styles.module.scss';

const Body2 = ({
    className = '',
    text = '',
    onClick = () => {},
    id = 'label'
}) => (
    <span className={`${className} ${styles.body2}`} onClick={onClick} id={id}>
        {text}
    </span>
);

export default Body2;
