import { createSlice } from '@reduxjs/toolkit';
import { set, get } from 'temp/utilities/storage';
import { PURL_AGENT_CODE_NEW } from 'utilities/sessionStorageKeys';

export const preferredAgentDetails = createSlice({
    name: 'preferredAgentDetails',

    initialState: {
        checkPreferredAgentModal: false,
        preferredAgentModal: false,
        isAgentAssociationCompleted: false,
        agentUserProfile: null,
        agentPurlCode: get(PURL_AGENT_CODE_NEW),
        agentPurl: null,
        isApiFailed: false
    },

    reducers: {
        setCheckPreferredAgentModal: (state, action) => {
            state.checkPreferredAgentModal = action.payload;
        },

        setIsAgentAssociationCompleted: (state, action) => {
            state.isAgentAssociationCompleted = action.payload;
        },

        setPreferredAgentModal: (state, action) => {
            state.preferredAgentModal = action.payload;
        },

        setAgentUserProfile: (state, action) => {
            state.agentUserProfile = action.payload;
        },

        setAgentPurl: (state, action) => {
            state.agentPurl = action.payload;
        },

        setAgentPurlCode: (state, action) => {
            set(PURL_AGENT_CODE_NEW, action.payload);
            state.agentPurlCode = action.payload;
        },

        setIsApiFailed: (state, action) => {
            state.isApiFailed = action.payload;
        }
    }
});

export const {
    setCheckPreferredAgentModal,
    setIsAgentAssociationCompleted,
    setPreferredAgentModal,
    setAgentUserProfile,
    setAgentPurl,
    setAgentPurlCode,
    setIsApiFailed
} = preferredAgentDetails.actions;

export default preferredAgentDetails.reducer;
