import React from 'react';
import { Link } from 'react-router-dom';

const LinkImage = ({ alt = '', url = '', src, id = 'linkImage' }) => {
    if (url.startsWith('/')) {
        return (
            <Link to={url}>
                <img src={src} alt={alt} id="link" />
            </Link>
        );
    }

    return (
        <a href={url} target="_blank" rel="noreferrer" id={id}>
            <img src={src} alt={alt} />
        </a>
    );
};

export default LinkImage;
