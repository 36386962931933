const fullAddress = ({
    streetLine1 = '',
    streetLine2 = '',
    city = '',
    state = '',
    zipCode = ''
}) => {
    const formattedAddress = [streetLine1, streetLine2, city, state, zipCode]
        .filter(Boolean)
        .join(', ');

    return formattedAddress;
};

export default fullAddress;
