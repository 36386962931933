import { useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import AddressWithInOrOutNetworkIcon from 'components/AddressWithInOrOutNetworkIcon';
import Label from 'components/Label';

import styles from './styles.module.scss';

export const AddressWithCheckBox = ({
    address,
    handleSelect,
    isChecked = false,
    maxReached = false,
    id,
    isFromPharmacy = false
}) => {
    const [checked, setChecked] = useState(isChecked);

    return (
        <div
            className={`${
                isFromPharmacy ? styles.pharmacyListItem : styles.addressItem
            } ${checked ? styles.active : ''}`}
            id={isFromPharmacy ? id : address.id}
        >
            <FormControlLabel
                control={
                    <Checkbox
                        id={isFromPharmacy ? id : address.id}
                        checked={checked}
                        disabled={!checked && maxReached}
                        onChange={e => {
                            setChecked(e.target.checked);
                            handleSelect(
                                e.target.checked,
                                isFromPharmacy ? e.target.value : address.id
                            );
                        }}
                    />
                }
                label={
                    isFromPharmacy ? (
                        <Label
                            htmlFor={id}
                            className={styles.labelClass}
                            text={address}
                            id={`${id}_label`}
                        />
                    ) : (
                        <AddressWithInOrOutNetworkIcon address={address} />
                    )
                }
            />
        </div>
    );
};

export default AddressWithCheckBox;
