import { useContext, useMemo } from 'react';

import { Circle } from '@mui/icons-material';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import RadioMultipleOptions from 'components/RadioMultipleOptions';
import SubText from 'components/SubText';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip';

import ZipCodeContext from 'contexts/zipCode';

import {
    bulletList,
    MedicaidRadioOptions,
    SubSidyRadioOptions
} from './constants';

import styles from './styles.module.scss';

const YourMedicaidModalContent = ({
    selectedMedicaidOption,
    setMedicaidSelection,
    selectedSubSidyLevelOption,
    setSubSidyLevelSelection
}) => {
    const { state } = useContext(ZipCodeContext);

    const handleMedicaidBenefits = e =>
        setMedicaidSelection(e.currentTarget.value);

    const handleSubSidyLevel = e =>
        setSubSidyLevelSelection(e.currentTarget.value);

    const ListItems = useMemo(() => {
        return bulletList.map((item, index) => (
            <ListItemButton
                className={styles.listItem}
                key={`${item}-${index}`}
            >
                <ListItemIcon className={styles.listIconClass}>
                    <Circle fontSize="8" className={styles.circleIcon} />
                </ListItemIcon>
                <ListItemText className={styles.listTextItem} primary={item} />
            </ListItemButton>
        ));
    }, [bulletList]);

    return (
        <div className={styles.yourMedicaidModalContent}>
            <div>
                <Text
                    className={styles.text}
                    text={`Are you receiving benefits from ${
                        state ?? ''
                    } Medicaid?`}
                    id="Medicaid_Benefits"
                />

                <RadioMultipleOptions
                    radioClassName={styles.radioClassName}
                    id="Medicaid_Benefits"
                    items={MedicaidRadioOptions}
                    name="Medicaid Benefits"
                    onChange={handleMedicaidBenefits}
                    selectedOption={selectedMedicaidOption}
                />
            </div>
            <div>
                <Text
                    className={`${styles.text} ${styles.subSidyText}`}
                    text={
                        <>
                            Do you qualify for Medicare Part D's Low-Income
                            Subsidy (LIS), also known as Extra Help?
                            <Tooltip
                                content={
                                    <>
                                        <SubText
                                            text={`To qualify for this, in 2024, you must
                                        meet the following criteria:`}
                                            className={styles.listItemHeading}
                                        />

                                        {ListItems}
                                    </>
                                }
                                className={styles.subsidyTooltip}
                                tooltipContentClassName={
                                    styles.subSidyLevelTooltipContentClassName
                                }
                                isClickable
                                width={360}
                                position="top"
                                isClose
                            />
                        </>
                    }
                    id="subSidyLevel"
                />

                <RadioMultipleOptions
                    radioClassName={styles.radioClassName}
                    id="subSidyLevel"
                    items={SubSidyRadioOptions}
                    name="SubSidyLevel"
                    onChange={handleSubSidyLevel}
                    selectedOption={selectedSubSidyLevelOption}
                />
            </div>
        </div>
    );
};

export default YourMedicaidModalContent;
