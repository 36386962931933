import moment from 'moment';
import { forwardRef, memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import InfoCard from 'components/InfoCard';
import PlanCostLabel from 'components/PlanCostLabel';

import ManageMedicareCard from 'pages/ManageMedicareCard';
import ManageMedicareForm from 'pages/ManageMedicareForm';

import { formatMCIN } from 'utilities/maskMedicareId';

import { medicareFormDescription } from './constants';

import styles from './styles.module.scss';

const YourMedicareHealthInsuranceModalContent = forwardRef(
    (
        {
            showFooter,
            footerButtonDisabled,
            firstNameVal = e => {},
            lastNameVal = e => {},
            medicareIdNumberVal = e => {},
            partBVal = e => {},
            partAVal = e => {}
        },
        ref
    ) => {
        const [showForm, setShowForm] = useState(false);
        const [showCard, setShowCard] = useState(false);
        const [details, setDetails] = useState(null);
        const userProfile = useSelector(state => state.userProfile.userProfile);

        const fillDetails = async userProfile => {
            const {
                firstName,
                lastName,
                mbiFirstName,
                mbiLastName,
                mbi,
                partAEffectiveDate,
                partBEffectiveDate
            } = userProfile;
            if (userProfile && Object.keys(userProfile).length) {
                setShowCard(Boolean(mbi));

                setDetails({
                    firstName: mbiFirstName || firstName,
                    lastName: mbiLastName || lastName,
                    medicareIdNumber: mbi,
                    medicarePartADate: partAEffectiveDate
                        ? new Date(partAEffectiveDate)
                        : undefined,
                    medicarePartBDate: partBEffectiveDate
                        ? new Date(partBEffectiveDate)
                        : undefined
                });
                firstNameVal(mbiFirstName || firstName);
                lastNameVal(mbiLastName || lastName);
                medicareIdNumberVal(formatMCIN(mbi));
                partBVal(
                    partBEffectiveDate
                        ? moment(partBEffectiveDate).format('MM-DD-YYYY')
                        : ''
                );
                partAVal(
                    partAEffectiveDate
                        ? moment(partAEffectiveDate).format('MM-DD-YYYY')
                        : ''
                );
            }
        };

        useEffect(() => {
            const LoadUserProfile = async () => {
                await fillDetails(userProfile);
                !userProfile.mbi && showFooter(true);
            };
            LoadUserProfile();
        }, [userProfile]);

        const MedicareIDBody = () => {
            if (showForm || !showCard)
                return (
                    <ManageMedicareForm
                        details={details || {}}
                        onCancel={() => {
                            setShowForm(false);
                            if (details?.medicareIdNumber) setShowCard(true);
                        }}
                        updateDetails={data => {
                            fillDetails(data);
                            setShowForm(false);
                            setShowCard(true);
                            showFooter(false);
                        }}
                        from={'healthInsurance'}
                        datePickerLabelClass={styles.datePickerLabelClass}
                        noPlaceholder={true}
                        medicareNumberPlaceholderText="****-***-****"
                        datePlaceHolder="MM-DD-YYYY"
                        ref={ref}
                        footerButtonDisabled={footerButtonDisabled}
                        cardHeaderClass={styles.cardHeader}
                        footerBlock={styles.footerBlock}
                        firstNameVal={firstNameVal}
                        lastNameVal={lastNameVal}
                        medicareIdNumberVal={medicareIdNumberVal}
                        partBVal={partBVal}
                        partAVal={partAVal}
                    />
                );

            return (
                <ManageMedicareCard
                    details={details}
                    cardHeaderClass={styles.cardHeader}
                    onCancel={() => {
                        setShowForm(true);
                        setShowCard(false);
                        showFooter(true);
                    }}
                    from={'healthInsurance'}
                    showMedicareCode={true}
                />
            );
        };

        return (
            <div
                className={`${styles.informationCards} medicare-id-container`}
                id={'medicare-id'}
            >
                <InfoCard
                    bodyElement={<MedicareIDBody />}
                    containerClassName={styles.insuranceInfoContainer}
                    heading={
                        <PlanCostLabel
                            className={styles.medicareIdInfoCard}
                            description={medicareFormDescription}
                            label="Medicare ID"
                            modalBoxClassName={styles.medicareIdModal}
                            from="healthInsuranceModal"
                        />
                    }
                    titleContainerClassName={styles.agentInfoTitle}
                    infoCardDetailsClassName={styles.infoCardDetailsClassName}
                />
            </div>
        );
    }
);

export default memo(YourMedicareHealthInsuranceModalContent);
