import Text from 'components/Text';
import styles from './styles.module.scss';

const InfoCardBodyWithHeader = ({
    title,
    bodyElement,
    containerClassName=''
}) => {
    return (
        <div className={`${styles.infoCardBodyWithHeader} ${containerClassName}`}>
            <Text text={title} className={styles.title} />
            {bodyElement}
        </div>
    );
};

export default InfoCardBodyWithHeader;
