import { useContext, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import PlansByZipForm from 'components/PlansByZipForm';
import TextError from 'components/TextError';

import ZipCodeContext from 'contexts/zipCode';

import dataLayer from 'utilities/dataLayer';
import isFunction from 'utilities/isFunction';

import image from './image.svg';

import { headerTitle, headerText, footerLabel } from './constants';

import styles from './styles.module.scss';

import { startSession } from 'temp/utilities/apiSession';

const errorMessage = 'Please enter a valid ZIP Code.';

const EditZipModal = ({
    footerButtonClicked,
    footerButtonLabel = '',
    isOpen,
    isOnChangeEnable = true,
    onClose,
    type = ''
}) => {
    const { buttonDisabled, error, zipCode, county } =
        useContext(ZipCodeContext);
    const { user, getAccessTokenSilently } = useAuth0();
    const getAuthToken = async () => {
        return user ? await getAccessTokenSilently() : '';
    };

    const onClick = async () => {
        if (type === 'contact_agent') {
            const authToken = await getAuthToken();
            startSession({ fips: county, zip: zipCode, authToken });
        }
        isFunction(footerButtonClicked) && footerButtonClicked();
    };

    useEffect(() => {
        if (isOpen) {
            dataLayer('modal_appear', headerTitle);
        }
    }, [isOpen]);

    return (
        <div className={styles.modalWithHeaderFooter}>
            <Modal className={styles.modal} isOpen={isOpen}>
                <ModalWindow
                    closeIconClassName={styles.closeIconClassName}
                    contentClassName={styles.contentClassName}
                    footerButtonClicked={onClick}
                    footerButtonDisabled={error || buttonDisabled}
                    footerClassName={styles.footerClassName}
                    footerButtonClassName={styles.footerButtonClassName}
                    footerId={'footerButton'}
                    footerLabel={footerButtonLabel || footerLabel}
                    headerIcon={image}
                    headerIconAlt="Multiple location pins over a map"
                    headerText={headerText}
                    headerTitle={headerTitle}
                    headerClassName={styles.headerClassName}
                    modalWindow={styles.modalWindow}
                    onClose={onClose}
                >
                    <div
                        className={styles.content}
                        data-testid="edit-zip-modal-content"
                    >
                        <div style={styles.content1}>
                            <PlansByZipForm
                                shouldAlwaysShowCounty={true}
                                footerButtonClicked={onClick}
                                type="modalWindow"
                                isOnChangeEnable={isOnChangeEnable}
                                showError={false}
                            />
                        </div>

                        {error && (
                            <TextError
                                className={styles.error}
                                text={errorMessage}
                            />
                        )}
                    </div>
                </ModalWindow>
            </Modal>
        </div>
    );
};

export default EditZipModal;
