import jwtDecode from 'jwt-decode';

const getHealthInfoTokenData = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    return queryParameters.get('token')
        ? jwtDecode(queryParameters.get('token'))
        : {};
};

export default getHealthInfoTokenData;
