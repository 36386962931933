import { useState } from 'react';
import styles from './styles.module.scss';

const BypassLink = ({ contentId, headerDisable }) =>{
    const [hideLink, setHideLink]= useState(true);
    return (
        <div className={`${styles.bypassLink} ${hideLink ? '' : styles.bypassLinkHide}` }
            onFocus={()=> setHideLink(false)}
            onBlur={() => setHideLink(true)}
        >
            <a
                href={`#${contentId}`}
                onClick={() => headerDisable()}
                className={styles.button}
            >
                Skip to Main Content
            </a>
        </div>
    )
};
export default BypassLink;
