const typeAheadTitle = 'Pharmacy Search';
const singleFoundLabel = 'pharmacy';
const startPrompt = 'Enter your pharmacy ZIP code before searching.';
const multipleFoundLabel = 'pharmacies';
const noResultsMessage = 'No pharmacy found under the current search criteria.';
const placeholder = 'Start by typing your pharmacy name';
const linkText = 'Add a pharmacy +';

export {
    linkText,
    multipleFoundLabel,
    noResultsMessage,
    placeholder,
    singleFoundLabel,
    startPrompt,
    typeAheadTitle
};
