import { createSlice } from '@reduxjs/toolkit';
export const ToDoDetail = createSlice({
    name: 'ToDoDetail',

    initialState: {
        todoItems:[],
        completedItems:[],
        checkCondition: false
    },

    reducers: {
        setTodoItems: (state, action) => {
            state.todoItems = action.payload;
        },
        setCompletedItems: (state, action) => {
            state.completedItems = action.payload;
        },
        setCheckCondition: (state, action) => {
            state.checkCondition = action.payload;
        },
    }
});

export const {
    setTodoItems,
    setCompletedItems,
    setCheckCondition
} = ToDoDetail.actions;

export default ToDoDetail.reducer;