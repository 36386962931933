import TextFieldWithLabel from 'components/TextFieldWithLabel';

import { placeholder, nameRegex, nameError, invalidPatternError } from './data';

import styles from './styles.module.scss';

const LastNameTextField = ({
    autoFocus,
    className = '',
    defaultValue,
    disablePattern = false,
    error,
    id,
    labelText,
    noPlaceholder = false,
    register,
    required,
    labelClassName = styles.label
}) => (
    <div className={`${className} ${styles.container}`}>
        <TextFieldWithLabel
            autoFocus={autoFocus}
            defaultValue={defaultValue}
            error={error}
            errorMessage={nameError}
            errorMessagePattern={invalidPatternError}
            fieldClassName={styles.textField}
            id={id}
            labelClassName={labelClassName}
            labelText={labelText}
            pattern={disablePattern && nameRegex}
            placeholder={!noPlaceholder && placeholder}
            register={register}
            required={required}
            type="text"
        />
    </div>
);

export default LastNameTextField;
