import { createContext } from 'react';

export default createContext({
    isAuthenticated: () => {
        return false;
    },
    logout: () => ({}),
    signinRedirect: () => ({}),
    signinSilent: () => ({}),
    signoutRedirectCallback: () => ({}),
    signinSilentCallback: () => ({})
});
