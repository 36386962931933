import { useContext } from 'react';

import Phone from 'components/Phone';
import Text from 'components/Text';

import phoneContext from 'contexts/phone';

import styles from './styles.module.scss';

const ContactAgentBanner = () => {
    const { phone } = useContext(phoneContext);
    const { time = '', number = '', text = '' } = phone;
    const [, text1 = ''] = text.split('|');

    return (
        <header className={styles.header} data-gtm="nav">
            <div className={styles.headerContent}>
                <Phone number={number} className={styles.phone} />
                {text1 && <Text className={styles.time}>({text1})</Text>}

                <Text className={styles.time}>|</Text>

                <Text className={styles.time}>{time}</Text>
            </div>
        </header>
    );
};

export default ContactAgentBanner;
