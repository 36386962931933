export const SearchCommonConditionList = [
    'Cancer',
    'Diabetes',
    'Dementia',
    'Irregular Heartbeat',
    'Kidney Failure',
    'HIV'
];

export const singleFoundLabel = 'condition';
export const multipleFoundLabel = 'conditions';
export const noResultsMessage =
    'No health conditions found under the current search criteria.';
export const startMessage = 'Start by typing a health condition';
