import { memo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import {
    completeFinalExpenseCurrentStep,
    hideStepper,
    previousFinalExpenseStep,
    resetStepperState,
    setGuidedProcess,
    skipFinalExpenseCurrentStep
} from 'components/ConsumerGuidedExperienceModal/slice';
import { setCreateAccountModal } from 'components/CreateAccountModal/slice';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import YourHealthConditionContent from 'components/YourHealthConditionContent';
import YourHealthConditionModal from 'components/YourHealthConditionModal';
import { setHealthInfoModal } from 'components/YourHealthInfoModal/slice';

import dataLayer from 'utilities/dataLayer';
import { FINAL_EXPENSE_PLANS_ROUTE } from 'utilities/routes';

import image from './image.svg';

import {
    headerTitle,
    headerText,
    saveButtonLabel,
    continueButtonLabel
} from './constants';
import { setHealthConditionContentModal } from './slice';

import styles from './styles.module.scss';

const YourHealthConditionContentModal = () => {
    const history = useHistory();
    const contactDetailsPageRef = useRef(null);
    const healthConditionModal = useSelector(
        state => state.healthConditionModalDetails?.healthConditionContentModal
    );
    const isGuided = useSelector(state => state.guidedDetails.isGuidedProcess);
    const healthConditionsList = useSelector(
        state => state.healthIntakeDetails.healthConditionsList
    );
    const { isAuthenticated } = useAuth0();

    useEffect(() => {
        if (healthConditionModal) {
            dataLayer('modal_appear', headerTitle);
        }
    }, [healthConditionModal]);
    const dispatch = useDispatch();

    const onClose = async () => {
        dispatch(setHealthConditionContentModal(false));
        dispatch(setGuidedProcess(false));
        dispatch(resetStepperState());
    };

    const handleBackButton = () => {
        dispatch(setHealthInfoModal(true));
        dispatch(setHealthConditionContentModal(false));
        dispatch(previousFinalExpenseStep());
    };

    const skipButtonClicked = () => {
        dispatch(setHealthConditionContentModal(false));
        !isAuthenticated && dispatch(setCreateAccountModal(true));
        healthConditionsList.length > 0
            ? dispatch(completeFinalExpenseCurrentStep())
            : dispatch(skipFinalExpenseCurrentStep());
        dispatch(hideStepper());
        isAuthenticated && history.push(FINAL_EXPENSE_PLANS_ROUTE);
    };

    const footerButtonClicked = () => {
        dispatch(setHealthConditionContentModal(false));
        if (isGuided) {
            !isAuthenticated && dispatch(setCreateAccountModal(true));
            healthConditionsList.length > 0
                ? dispatch(completeFinalExpenseCurrentStep())
                : dispatch(skipFinalExpenseCurrentStep());
            dispatch(hideStepper());
        }

        isAuthenticated && history.push(FINAL_EXPENSE_PLANS_ROUTE);
    };

    return (
        <div className={styles.modalWithHeaderFooter}>
            <Modal className={styles.modal} isOpen={healthConditionModal}>
                <ModalWindow
                    closeIconClassName={styles.closeIconClassName}
                    contentClassName={styles.contentClassName}
                    footerButtonClassName={styles.footerButtonClassName}
                    footerButtonClicked={footerButtonClicked}
                    footerButtonDisabled={false}
                    footerClassName={styles.footerClassName}
                    footerLabel={
                        isGuided ? continueButtonLabel : saveButtonLabel
                    }
                    headerClassName={styles.headerClassName}
                    headerIcon={image}
                    headerText={headerText}
                    headerTitle={headerTitle}
                    modalWindow={styles.modalWindow}
                    onClose={() => onClose()}
                    backButtonClicked={handleBackButton}
                    isBack={isGuided}
                    isSkip={isGuided}
                    skipButtonClicked={skipButtonClicked}
                >
                    <div className={styles.content}>
                        <YourHealthConditionContent
                            isModal={true}
                            isOpen={healthConditionModal}
                            ref={contactDetailsPageRef}
                        />
                    </div>
                </ModalWindow>
            </Modal>
            <YourHealthConditionModal isModal={true} />
        </div>
    );
};

export default memo(YourHealthConditionContentModal);
