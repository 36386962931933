const PURL_AGENT_CODE = 'PurlAgentCode';
const PURL_AGENT_CODE_NEW = 'NEWPurlAgentCode';
const PURL_AGENT_DATA = 'PurlAgentData';
const ENROLL_BANNER_WEB = 'EnrollBannerWeb';
const ENROLL_BANNER_MOBILE = 'EnrollBannerMobile';
const PURL_AGENT_MEDICARE_CODE = 'PurlAgentMedicareCode';
const PURL_AGENT_MEDICARE_DATA = 'PurlAgentMedicareData';
const PURL_AGENT_FINALEXPENSE_CODE = 'PurlAgentFinalExpenseCode';
const PURL_AGENT_FINALEXPENSE_DATA = 'PurlAgentFinalExpenseData';
const PURL_AGENT_DATA_OLD = 'PurlAgentDataOld';

export {
    PURL_AGENT_CODE,
    PURL_AGENT_DATA,
    PURL_AGENT_CODE_NEW,
    ENROLL_BANNER_WEB,
    ENROLL_BANNER_MOBILE,
    PURL_AGENT_MEDICARE_CODE,
    PURL_AGENT_MEDICARE_DATA,
    PURL_AGENT_FINALEXPENSE_CODE,
    PURL_AGENT_FINALEXPENSE_DATA,
    PURL_AGENT_DATA_OLD
};
