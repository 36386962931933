import Heading3 from 'components/Heading3';
import Icon from 'components/Icon';
import Text from 'components/Text';

import lifeCoverageEmpty from './emptyLifeCoverage.svg';
import lifeCoverage from './lifeCoverage.svg';

import styles from './styles.module.scss';

const WalletFinalExpenseCard = ({
    mouseOver,
    walletID,
    mouseOverOut,
    cardClassName = styles.lifeCoverageEmpty,
    openLifePlanModal,
    plansDetails,
    plansDetailsIndex
}) => {
    const PlanDate = ({ label, data, className = styles.heading }) =>
        data ? (
            <div className={styles.data}>
                <Heading3 className={className} text={`${label}:`} />

                <Text className={styles.policyDetails}>{data}</Text>
            </div>
        ) : null;

    return (
        <div
            className={cardClassName}
            id={`lifeCoverageEmpty-${plansDetailsIndex}`}
        >
            {plansDetails ? (
                <div
                    className={styles.infoIcon}
                    onClick={() =>
                        walletID !== `lifeCoverageEmpty-${plansDetailsIndex}`
                            ? mouseOver(
                                  `lifeCoverageEmpty-${plansDetailsIndex}`
                              )
                            : openLifePlanModal(lifeCoverage, plansDetailsIndex)
                    }
                    id={`healthInfoIcon-${plansDetailsIndex}`}
                    style={{
                        backgroundColor: plansDetails?.carrierColor || '#1157EE'
                    }}
                >
                    <Text
                        text={plansDetails?.planName}
                        className={styles.planName}
                    />
                    <div className={styles.policyDetailsContainer}>
                        <div className={styles.detailsContainer}>
                            <PlanDate
                                label="Status"
                                data={`${
                                    plansDetails?.policyStatus
                                        ? 'Active'
                                        : 'N/A'
                                }`}
                            />
                            <PlanDate
                                className={styles.heading3}
                                label="Policy Number"
                                data={plansDetails?.policyNumber}
                            />
                        </div>
                        <Icon
                            className={`${styles.icon}`}
                            image={plansDetails?.carrierIconURL}
                            id="insuranceIcon"
                        />
                    </div>
                </div>
            ) : (
                <Icon
                    id={`lifeCoverageEmpty-${plansDetailsIndex}Icon`}
                    image={lifeCoverageEmpty}
                    onClick={() =>
                        walletID !== `lifeCoverageEmpty-${plansDetailsIndex}`
                            ? mouseOver(
                                  `lifeCoverageEmpty-${plansDetailsIndex}`
                              )
                            : openLifePlanModal(lifeCoverage, 0)
                    }
                    className={styles.lifeIcon}
                />
            )}
        </div>
    );
};

export default WalletFinalExpenseCard;
