import { useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InfoCard from 'components/InfoCard';
import InfoCardBodyWithHeader from 'components/InfoCardBodyWithHeader';
import { HealthInsuranceContent } from 'components/InsuranceDetailsInfoCards';
import YourHealthPlanModal from 'components/YourHealthPlanModal';
import { setHealthPlanContentModal } from 'components/YourHealthPlanModal/slice';

import icon from './image.svg';

import styles from './styles.module.scss';

const HealthInsuranceDetail = ({
    healthPlans,
    setIsLifeAgentModalOpen,
    setSeletedAgentDetails,
    setIsAgentDetailsModalOpen
}) => {
    const dispatch = useDispatch();
    const [insuranceIcon, setInsuranceIcon] = useState();
    const [healthPlanInfo, setHealthPlanInfo] = useState();
    const medicareAgent = useSelector(
        state => state.planTypeDetail.medicareAgent
    ) || {
        agentFirstName: '',
        agentLastName: ''
    };
    const onOpenHealthInsuranceAgentDetailsModal = event => {
        event.preventDefault();
        setIsLifeAgentModalOpen(false);
        setSeletedAgentDetails(medicareAgent);
        setIsAgentDetailsModalOpen(true);
    };

    const openHealthPlanModal = (icon, index) => {
        if (healthPlans[0] && healthPlans[0]?.policyStatus) {
            setInsuranceIcon(icon);
            dispatch(setHealthPlanContentModal(true));
            setHealthPlanInfo(healthPlans[index]);
        }
    };

    return (
        <InfoCard
            bodyElement={
                <div className={styles.bodyContainer}>
                    <InfoCardBodyWithHeader
                        bodyElement={
                            <>
                                <HealthInsuranceContent
                                    openModal={openHealthPlanModal}
                                    healthPlans={healthPlans}
                                    key={healthPlans.length}
                                    agentDetails={medicareAgent}
                                    onClickViewAgent={
                                        onOpenHealthInsuranceAgentDetailsModal
                                    }
                                />

                                {healthPlanInfo && (
                                    <YourHealthPlanModal
                                        insuranceImage={insuranceIcon}
                                        plansDetails={healthPlanInfo}
                                        onClose={() => {
                                            setHealthPlanInfo(undefined);
                                        }}
                                    />
                                )}
                            </>
                        }
                    />
                </div>
            }
            heading={'Health Insurance Details'}
            containerClassName={styles.container}
            icon={icon}
            iconClassName={styles.headerIcon}
            infoCardDetailsClassName={styles.cardBody}
            titleContainerClassName={styles.titleContainerClassName}
        />
    );
};

export default memo(HealthInsuranceDetail);
