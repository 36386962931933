const planTypes = [
    'Final Expense',
    'Original Medicare',
    'Dental',
    'Life Insurance',
    'Medigap',
    'Medicare Advantage',
    'Medicare Supplement',
    'Medicare Part A',
    'Medicare Part B',
    'Medicare Part C',
    'Medicare Part D',
    'Medicaid',
    'Vision'
];

const topics = [
    'Financial',
    'Enrollment',
    'Lifestyle',
    'Doctors',
    'AEP',
    'OEP',
    'SEP',
    'Coverage',
    'Private Insurance',
    'Final Expense Types',
    'Costs of Dying',
    'Funeral Expenses',
    'Financial Planning',
    'Taxes',
    'Insurance Types',
    'To-Do List',
    'Children',
    'Family',
    'Death Benefits',
    'Asset Protection',
    'Inheritance',
    'Eligibility'
];

const allTags = [...planTypes, ...topics];

export { allTags, planTypes, topics };
