import styles from './styles.module.scss';

const Body3 = ({
    className = '',
    text = '',
    id = 'label',
    onClick = () => {}
}) => {
    return (
        <span
            className={`${className} ${styles.body3}`}
            id={id}
            onClick={onClick}
        >
            {text}
        </span>
    );
};

export default Body3;
