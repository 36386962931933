import { createSlice } from '@reduxjs/toolkit';

export const healthConditionDetails = createSlice({
    name: 'healthConditionDetails',

    initialState: {
        healthConditionModal: false
    },

    reducers: {
        setHealthConditionModal: (state, action) => {
            state.healthConditionModal = action.payload;
        }
    }
});

export const { setHealthConditionModal } = healthConditionDetails.actions;

export default healthConditionDetails.reducer;
