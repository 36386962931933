import * as Sentry from '@sentry/react';

import { API_BASE_URL, API_ENROLL_URL, API_BASE_URL_V2 } from 'utilities/env';
import { BRITE_VERIFY_APIKEY, BRITE_VERIFY_URL } from 'utilities/env';
import getJSON from 'utilities/getJSON';
import postJSON from 'utilities/postJSON';

import { getLeadId } from './leadId';

const getUrl = () => {
    const leadId = getLeadId();
    return `${API_BASE_URL_V2}/Lead/${leadId}/Plan`;
};

const getPlanCountUrl = () => {
    const leadId = getLeadId();
    return `${API_BASE_URL}/Lead/${leadId}/Plan/Carrier/Count`;
};

const getPlans = async ({
    effectiveDate,
    ShowPharmacy,
    Fips,
    ReturnAllMedicarePlans,
    ShowFormulary,
    Year,
    Zip,
    hasMedicaid,
    authToken = ''
}) => {
    const url = getUrl();
    const params = {
        Zip,
        Fips,
        Year,
        ReturnAllMedicarePlans,
        effectiveDate,
        hasMedicaid,
        ShowPharmacy,
        ...(ShowFormulary && { ShowFormulary })
    };
    localStorage.setItem('effectiveDate', effectiveDate);
    try {
        return await getJSON({ params, url, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const getPlanCounts = async ({ Fips, Zip, Year, authToken = '' }) => {
    const url = getPlanCountUrl();
    const params = {
        Zip,
        Fips,
        Year
    };
    try {
        return await getJSON({ params, url, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const getPlanDetails = async ({
    EffectiveDate,
    Fips,
    PlanType,
    ReturnAllMedicarePlans,
    Year,
    Zip,
    effectiveDate,
    ShowPharmacy,
    planId,
    authToken = '',
    pharmacyId = ''
}) => {
    const url = `${getUrl()}/${planId}`;
    const params = {
        EffectiveDate,
        ShowPharmacy,
        Fips,
        PlanType,
        ReturnAllMedicarePlans,
        Year,
        Zip,
        effectiveDate
    };
    if (pharmacyId) params.pharmacyId = pharmacyId;
    try {
        return await getJSON({ params, url, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const enroll = async ({
    address1 = '',
    address2 = '',
    certificateUrl = '',
    city = '',
    countyFIPS = '',
    email = null,
    firstName = '',
    lastName = '',
    leadId,
    mbi = '',
    mbiFirstName = '',
    mbiLastName = '',
    middleInitial = '',
    partAEffectiveDate = '',
    partBEffectiveDate = '',
    phoneNumber = '',
    planCardData = {},
    planId = '',
    purlAgentData = {},
    sendToBeneficiary = false,
    state = '',
    zip = ''
}) => {
    const findLeadID = leadId || getLeadId();
    const url = `${API_ENROLL_URL}/Medicare/Lead/${findLeadID}/Enroll/${planId}`;
    if (email) email = decodeURI(email);
    const body = {
        certificateUrl,
        enrollRequest: {
            address1,
            address2,
            city,
            countyFIPS,
            email,
            firstName,
            lastName,
            mbi,
            mbiFirstName,
            mbiLastName,
            middleInitial,
            partAEffectiveDate,
            partBEffectiveDate,
            phoneNumber,
            sendToBeneficiary,
            state,
            zip
        },
        planDetail: planCardData,
        agentPurlDetails: purlAgentData
    };

    try {
        return await postJSON({ body, url });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const validatePhoneEmail = async (emailAddress = '', phone = '') => {
    const url = BRITE_VERIFY_URL;
    const ApiKey = BRITE_VERIFY_APIKEY;

    const body = {
        email: emailAddress,
        phone
    };
    try {
        return await postJSON({ body, url, ApiKey });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const getPlansByConfirmationNumber = async (confirmationNumber = '') => {
    const url = `${API_ENROLL_URL}/Medicare/confirmationNumber/${confirmationNumber}`;

    try {
        return await getJSON({ url });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

export {
    enroll,
    getPlanCounts,
    getPlanDetails,
    getPlans,
    getPlansByConfirmationNumber,
    validatePhoneEmail
};
