import EditLink from 'components/EditLink';
import Heading3 from 'components/Heading3';
import Icon from 'components/Icon';
import Text from 'components/Text';

import styles from './styles.module.scss';

const InfoCard = ({
    bodyElement,
    btnLabel,
    btnText,
    containerClassName = '',
    description,
    displayEditButton = false,
    displayIconOnLeft,
    heading,
    icon,
    iconAltText = '',
    iconClassName = '',
    infoCardDetailsClassName,
    onClick,
    titleContainerClassName = ''
}) => {
    return (
        <div className={`${styles.infoCard} ${containerClassName}`}>
            {(heading || icon) && (
                <div
                    className={`${titleContainerClassName} ${styles.infoCardTitleContainer}`}
                >
                    {icon && (
                        <Icon
                            alt={iconAltText}
                            className={`${styles.icon} ${iconClassName}`}
                            image={icon}
                        />
                    )}

                    <Heading3 className={styles.heading3} text={heading} />
                </div>
            )}

            <div
                className={`${styles.infoCardDetails} ${infoCardDetailsClassName}`}
            >
                {bodyElement}

                {description && (
                    <Text className={styles.text}>{description}</Text>
                )}
                {btnLabel ? (
                    <div className={styles.btnLabelContainer}>
                        <EditLink
                            displayIconOnLeft={displayIconOnLeft}
                            onClick={onClick}
                            text={displayEditButton ? btnLabel : btnText}
                        />
                    </div>
                ) : (
                    btnText && (
                        <EditLink
                            displayIconOnLeft={displayIconOnLeft}
                            onClick={onClick}
                            text={btnText}
                        />
                    )
                )}
            </div>
        </div>
    );
};

export default InfoCard;
