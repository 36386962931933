import Button from 'components/Button';

import styles from './styles.module.scss';

const SkipButton = ({
    className = '',
    disabled,
    footerSubContent,
    id,
    label,
    onClick
}) => {
    return (
        <Button
            className={`${styles.button} ${className} ${
                !footerSubContent && styles.footerSkipBorder
            }`}
            data-click-type="button"
            disabled={disabled}
            id={id}
            label={label}
            onClick={onClick}
        />
    );
};

export default SkipButton;
