import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const ButtonLinkWithIcon = ({
    btnIcon,
    containerClassName = '',
    onClick,
    text = '',
    linkText = styles.linkText
}) => {
    const handleClick = onClick => handleEvent(onClick);

    return (
        <div
            className={`${containerClassName} ${styles.link}`}
            onClick={handleClick(onClick)}
        >
            <span className={linkText}>{text}</span>
            <img alt="button icon" src={btnIcon} />
        </div>
    );
};

export default ButtonLinkWithIcon;
