import React, { useState } from 'react';
import Media from 'react-media';

import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import './index.scss';

import styles, { mobileWidth } from './styles.module.scss';
import dayjs from 'dayjs';

const DatePickerWithLabel = ({
    defaultValue,
    id,
    labelText,
    onChange,
    labelClass = styles.label,
    datePickerClass = '',
    disableOpenPicker = false,
    placeholder = 'Select Date'
}) => {
    const [isMobile, setIsMobile] = useState(false);
    const handleOnChange = newValue => {
        onChange(isNaN(newValue) || !newValue ? '' : new Date(newValue));
    };
    return (
        <div className={styles.container} id={id}>
            <Media
                queries={{
                    mobile: { maxWidth: mobileWidth }
                }}
                onChange={breakpoint => {
                    setIsMobile(breakpoint.mobile);
                }}
            />
            <div className={labelClass} id={`${id}_label`}>
                {labelText}
            </div>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                {id === 'finalExpenseDateOfBirth' ? (
                    <DesktopDatePicker
                        componentsProps={{}}
                        className={`medicare-date-picker finalExpense-date ${datePickerClass}`}
                        label="MM/DD/YYYY"
                        onChange={handleOnChange}
                        renderInput={params => (
                            <TextField
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    placeholder: 'MM/DD/YYYY'
                                }}
                            />
                        )}
                        showToolbar={false}
                        value={
                            defaultValue !== null ? dayjs(defaultValue) : null
                        }
                        disableOpenPicker={true}
                        id={id}
                    />
                ) : (
                    <DatePicker
                        componentsProps={{
                            actionBar: {
                                actions: isMobile ? ['clear', 'accept'] : []
                            }
                        }}
                        className={`${
                            id === 'lastTreatmentDate' &&
                            styles.lastTreatmentDate
                        } ${'medicare-date-picker'} ${'medicare-date'}`}
                        label={placeholder}
                        onChange={handleOnChange}
                        renderInput={params => <TextField {...params} />}
                        showToolbar={false}
                        value={
                            defaultValue !== null ? dayjs(defaultValue) : null
                        }
                        maxDate={
                            id === 'lastTreatmentDate' ? dayjs() : undefined
                        }
                        id={id}
                        disableOpenPicker={disableOpenPicker}
                    />
                )}
            </LocalizationProvider>
        </div>
    );
};

export default DatePickerWithLabel;
