import { createSlice } from '@reduxjs/toolkit';

export const plansPage = createSlice({
    name: 'plansPage',

    initialState: {
        currentPlansPage: 1,
        inversePlansAvailableCount: ' - ',
        plansAvailableCount: ' - ',
        results: [],
        carrierList: [],
        subTypeList: [],
        isModalEdited: true
    },

    reducers: {
        setCurrentPlansPage: (state, action) => {
            state.currentPlansPage = action.payload;
        },

        setInversePlansAvailableCount: (state, action) => {
            state.inversePlansAvailableCount = action.payload;
        },

        setPlansAvailableCount: (state, action) => {
            state.plansAvailableCount = action.payload;
        },

        setResults: (state, action) => {
            state.results = action.payload;
        },

        setCarrierList: (state, action) => {
            state.carrierList = action.payload;
        },

        setSubTypeList: (state, action) => {
            state.subTypeList = action.payload;
        },
        setIsModalEdited: (state, action) => {
            state.isModalEdited = action.payload;
        }
    }
});

export const {
    setIsModalEdited,
    setSubTypeList,
    setCarrierList,
    setResults,
    setPlansAvailableCount,
    setInversePlansAvailableCount,
    setCurrentPlansPage
} = plansPage.actions;

export default plansPage.reducer;
