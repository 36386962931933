import { createSlice } from '@reduxjs/toolkit';

export const lifePlanModalDetails = createSlice({
    name: 'lifePlanModalDetails',

    initialState: {
        lifePlanContentModal: false
    },

    reducers: {
        setLifePlanContentModal: (state, action) => {
            state.lifePlanContentModal = action.payload;
        }
    }
});

export const { setLifePlanContentModal } = lifePlanModalDetails.actions;

export default lifePlanModalDetails.reducer;
