import { TextField } from '@mui/material';

import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const handleChange = onChange => handleEvent(onChange);

const TextInput = ({
    autoFocus = false,
    className,
    error,
    id,
    inputProps = {},
    InputProps = {},
    isPadding = true,
    minPadding = false,
    onChange,
    onBlur,
    onFocus,
    placeholder,
    size = 'medium',
    value,
    pattern
}) => {
    return (
        <TextField
            autoFocus={autoFocus}
            id={id}
            className={`${className} ${styles.textInput}`}
            error={error}
            inputProps={{
                style: {
                    padding: isPadding
                        ? id === 'footerInput' || id === 'zipCodeInput'
                            ? '13.5px 14px'
                            : '14px 14px'
                        : minPadding
                        ? '9px 14px'
                        : undefined
                },
                ...inputProps
            }}
            InputProps={{ ...InputProps }}
            onChange={handleChange(onChange)}
            onBlur={handleChange(onBlur)}
            onFocus={handleChange(onFocus)}
            size={size}
            pattern={pattern}
            value={value}
            variant="outlined"
            placeholder={placeholder}
        />
    );
};

export default TextInput;
