import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Heading3 from 'components/Heading3';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import Text from 'components/Text';
import YourLifePlanModalContent from 'components/YourLifePlanModalContent';

import dataLayer from 'utilities/dataLayer';

import { headerTitle } from './constants';
import { setLifePlanContentModal } from './slice';

import styles from './styles.module.scss';

const YourLifePlanModal = ({ plansDetails, onClose: onCloseParent }) => {
    const lifePlanModal = useSelector(
        state => state.lifePlanModalDetails?.lifePlanContentModal
    );

    useEffect(() => {
        if (lifePlanModal) {
            dataLayer('modal_appear', headerTitle);
        }
        document
            .getElementById('infoModalIcon')
            ?.setAttribute(
                'style',
                `background-color:${plansDetails?.carrierColor}`
            );
    }, [lifePlanModal]);
    const dispatch = useDispatch();

    const onClose = async () => {
        dispatch(setLifePlanContentModal(false));
        onCloseParent?.();
    };
    const PlanDate = ({ label, data, className = styles.heading }) => (
        <div className={styles.data}>
            <div>
                <Heading3 className={className} text={`${label}:`} />
            </div>

            <Text className={styles.policyDetails}>{data}</Text>
        </div>
    );

    return (
        <div className={styles.modalWithHeaderFooter}>
            <Modal className={styles.modal} isOpen={lifePlanModal}>
                <ModalWindow
                    closeIconClassName={styles.closeIconClassName}
                    contentClassName={styles.contentClassName}
                    footerButtonClassName={styles.footerButtonClassName}
                    footerClassName={styles.footerClassName}
                    hideFooter={true}
                    headerClassName={styles.headerClassName}
                    headerTitle={headerTitle}
                    modalWindow={styles.modalWindow}
                    onClose={() => onClose()}
                    headerHeadingClassName={styles.headerHeadingClassName}
                    modalHeaderTitle={styles.modalHeaderTitle}
                >
                    <div className={styles.content}>
                        <div className={styles.insuranceIcon}>
                            <div
                                className={styles.infoIcon}
                                id="infoModalIcon"
                                style={{
                                    backgroundColor: plansDetails?.carrierColor
                                }}
                            >
                                <Text
                                    text={plansDetails?.planName}
                                    className={styles.planName}
                                />
                                <div className={styles.policyDetailsContainer}>
                                    <div className={styles.detailsContainer}>
                                        <PlanDate
                                            label="Status"
                                            data={`${
                                                plansDetails?.policyStatus
                                                    ? 'Active'
                                                    : 'N/A'
                                            }`}
                                        />
                                        <PlanDate
                                            className={styles.heading3}
                                            label="Policy Number"
                                            data={plansDetails?.policyNumber}
                                        />
                                    </div>
                                    <Icon
                                        className={styles.icon}
                                        image={plansDetails?.carrierIconURL}
                                        id="insuranceIcon"
                                    />
                                </div>
                            </div>
                        </div>
                        <Text
                            text={'Policies Info'}
                            className={styles.planDetailsLabel}
                        />
                        <YourLifePlanModalContent
                            className={styles.manageAccountContent}
                            plansDetails={plansDetails}
                        />
                    </div>
                </ModalWindow>
            </Modal>
        </div>
    );
};

export default YourLifePlanModal;
