import React, { useEffect } from 'react';

const TrustedFormScript = () => {
    useEffect(() => {
        const element = document.querySelector('#trusted-form-script');
        if (element) return;
        const tf = document.createElement('script');
        tf.type = 'text/javascript';
        tf.async = true;
        tf.id = 'trusted-form-script';
        tf.src =
            ('https:' === document.location.protocol ? 'https' : 'http') +
            '://api.trustedform.com/trustedform.js?field=xxTrustedFormCertUrl&ping_field=xxTrustedFormPingUrl&l=' +
            new Date().getTime() +
            Math.random();
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(tf, s);

        return () => {
            tf.remove();
        };
    }, []);

    return (
        <img
            alt="trustedform"
            height="0"
            src="https://api.trustedform.com/ns.gif"
            style={{
                display: 'none'
            }}
        />
    );
};

export default TrustedFormScript;
