import { useEffect, useContext } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import {
    completeMedicareCurrentStep,
    hideStepper,
    previousMedicareStep,
    resetStepperState,
    setGuidedProcess,
    skipMedicareCurrentStep
} from 'components/ConsumerGuidedExperienceModal/slice';
import { setCreateAccountModal } from 'components/CreateAccountModal/slice';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import YourMedicaidModalContent from 'components/YourMedicaidModalContent';
import useFetch from 'components/hooks/useFetch';

import prescriptionModalContext from 'contexts/prescriptionModal';

import dataLayer from 'utilities/dataLayer';
import { PLANS_ROUTE } from 'utilities/routes';
import { MEDICAID_KEY, SUBSIDYLEVEL_KEY } from 'utilities/storageKeys';

import image from './image.svg';

import {
    headerTitle,
    headerText,
    plansButtonLabel,
    saveButtonLabel,
    medicaidOptions,
    subSidyLevelOptions,
    notAnswered
} from './constants';
import {
    setMedicaidModal,
    setSelectedMedicaidOption,
    setSelectedSubSidyLevelOption
} from './slice';

import styles from './styles.module.scss';

import { get, set } from 'temp/utilities/storage';

const YourMedicaidModal = ({ isFromProfile = false }) => {
    const history = useHistory();
    const [medicaidSelection, setMedicaidSelection] = useState(
        get(MEDICAID_KEY)
    );
    const [subSidyLevelSelection, setSubSidyLevelSelection] = useState(
        get(SUBSIDYLEVEL_KEY)
    );
    const { Put: updateAccountData } = useFetch(
        `${import.meta.env.VITE_APP_ACCOUNT_SERVICE_URL}/Update`
    );

    const resetSelection = () => {
        setMedicaidSelection(get(MEDICAID_KEY));
        setSubSidyLevelSelection(get(SUBSIDYLEVEL_KEY));
    };
    const medicaidModal = useSelector(
        state => state.medicaidDetails.medicaidModal
    );

    useEffect(() => {
        if (medicaidModal) {
            dataLayer('modal_appear', headerTitle);
        }
    }, [medicaidModal]);

    const { setPrescriptionModal } = useContext(prescriptionModalContext);

    const isGuided = useSelector(state => state.guidedDetails.isGuidedProcess);
    const { selectedSubSidyLevelOption, selectedMedicaidOption } = useSelector(
        state => state.medicaidDetails
    );

    const userProfile = useSelector(state => state.userProfile.userProfile);
    const { isAuthenticated, user } = useAuth0();

    const dispatch = useDispatch();

    useEffect(() => {
        setMedicaidSelection(selectedMedicaidOption);
        setSubSidyLevelSelection(selectedSubSidyLevelOption);
    }, [selectedSubSidyLevelOption, selectedMedicaidOption]);

    const onClose = async isContinue => {
        dispatch(setMedicaidModal(false));
        if (!isContinue) {
            dispatch(setGuidedProcess(false));
            resetSelection();
            dispatch(resetStepperState());
        } else {
            set(MEDICAID_KEY, medicaidSelection);
            set(SUBSIDYLEVEL_KEY, subSidyLevelSelection);
            dispatch(setSelectedMedicaidOption(medicaidSelection));
            dispatch(setSelectedSubSidyLevelOption(subSidyLevelSelection));
            if (isGuided) {
                medicaidSelection
                    ? dispatch(completeMedicareCurrentStep())
                    : dispatch(skipMedicareCurrentStep());
                dispatch(hideStepper());
                !isAuthenticated && dispatch(setCreateAccountModal(true));
            }
            if (isAuthenticated && user.consumerid) {
                await updateAccountData({
                    ...userProfile,
                    hasMedicAid: medicaidSelection
                        ? medicaidOptions[medicaidSelection]
                        : null,
                    subsidyLevel: subSidyLevelSelection
                        ? subSidyLevelOptions[subSidyLevelSelection]
                        : notAnswered
                });
            }
            if (isFromProfile) {
                medicaidSelection
                    ? dispatch(completeMedicareCurrentStep())
                    : dispatch(skipMedicareCurrentStep());
                dispatch(hideStepper());
                history.push(PLANS_ROUTE);
            }
        }
    };

    const handleBackButton = () => {
        resetSelection();
        dispatch(setMedicaidModal(false));
        setPrescriptionModal(true);
        dispatch(previousMedicareStep());
    };

    const skipButtonClicked = async () => {
        if (isAuthenticated && user.consumerid) {
            await updateAccountData({
                ...userProfile,
                hasMedicAid: medicaidSelection
                    ? medicaidOptions[medicaidSelection]
                    : null,
                subsidyLevel: subSidyLevelSelection
                    ? subSidyLevelOptions[subSidyLevelSelection]
                    : notAnswered
            });
        }
        resetSelection();
        dispatch(setMedicaidModal(false));
        !isAuthenticated && dispatch(setCreateAccountModal(true));
        medicaidSelection
            ? dispatch(completeMedicareCurrentStep())
            : dispatch(skipMedicareCurrentStep());
        dispatch(hideStepper());

        if (isFromProfile) {
            history.push(PLANS_ROUTE);
        }
    };

    return (
        <Modal className={styles.modal} isOpen={medicaidModal}>
            <ModalWindow
                backButtonClicked={handleBackButton}
                closeIconClassName={styles.closeIconClassName}
                contentClassName={styles.contentClassName}
                footerClassName={styles.footerClassName}
                footerButtonClassName={styles.footerButtonClassName}
                footerButtonClicked={() => onClose(true)}
                footerButtonDisabled={!medicaidSelection}
                footerLabel={
                    isGuided && !isFromProfile
                        ? plansButtonLabel
                        : saveButtonLabel
                }
                headerClassName={styles.headerClassName}
                headerIcon={image}
                headerText={headerText}
                headerTitle={headerTitle}
                isBack={isGuided}
                isSkip={isGuided}
                onClose={() => onClose()}
                skipButtonClicked={skipButtonClicked}
            >
                <div
                    className={styles.content}
                    data-testid="your-pharmacy-modal"
                >
                    <YourMedicaidModalContent
                        selectedMedicaidOption={medicaidSelection}
                        setMedicaidSelection={setMedicaidSelection}
                        selectedSubSidyLevelOption={subSidyLevelSelection}
                        setSubSidyLevelSelection={setSubSidyLevelSelection}
                    />
                </div>
            </ModalWindow>
        </Modal>
    );
};

export default YourMedicaidModal;
