import Body2 from 'components/Body2';
import TextError from 'components/TextError';
import TextInput from 'components/TextInput';
import { zipCodeShortRegex } from 'components/ZipCodeTextField/data';

import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const handleChange = onChange => handleEvent(onChange);

const errorMessage = 'Please enter a valid ZIP Code.';

const ZipCodeInputWithLabel = ({
    className = '',

    disablePattern = false,
    error,
    id,

    labelClass = styles.text,
    onChange,
    onBlur,
    onFocus,
    zipCode,
    zipCodeLabel = 'Zip Code',
    showError = true
}) => (
    <div className={`${className} ${styles.zipCodeInputWithLabel}`}>
        <Body2 className={labelClass} text={zipCodeLabel} id={'zipCodeLabel'} />
        <TextInput
            name="zipCode"
            autoFocus={document?.activeElement?.id === id}
            id={id}
            className={styles.zipCodeTextField}
            error={error}
            inputProps={{ maxLength: 5 }}
            onChange={handleChange(onChange)}
            onBlur={handleChange(onBlur)}
            onFocus={handleChange(onFocus)}
            value={zipCode}
            pattern={disablePattern && zipCodeShortRegex}
            isPadding={false}
        />
        {error && showError && (
            <TextError className={styles.textError} text={errorMessage} />
        )}
    </div>
);

export default ZipCodeInputWithLabel;
