const supplementFrequencyOptions = [
    { value: 0, text: 'Once Daily' },
    { value: 1, text: 'Multi-daily' },
    { value: 2, text: 'Weekly' },
    { value: 3, text: 'Monthly' }
];

export const frequencyKeyMap = supplementFrequencyOptions.reduce((a, c) => {
    a[c.text] = c.value;
    return a;
}, {});

export default supplementFrequencyOptions;
