export const PrescriptionIcon = props => (
    <svg
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx="15.1289" cy="15" r="15" fill="#A8C5EE" />
        <path
            d="M22.0429 11.3438V19.0126C22.0419 19.1813 22.0071 19.3481 21.9407 19.5032C21.8743 19.6583 21.7775 19.7985 21.6561 19.9157C21.4061 20.1562 21.0727 20.2906 20.7258 20.2907H16.1159C15.769 20.2906 15.4356 20.1562 15.1856 19.9157C15.0641 19.7986 14.9673 19.6584 14.9009 19.5033C14.8344 19.3482 14.7997 19.1814 14.7988 19.0126V11.3438"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M22.7013 11.0245C22.7012 11.0667 22.6927 11.1085 22.6761 11.1474C22.6595 11.1862 22.6352 11.2213 22.6047 11.2505C22.5423 11.3109 22.4589 11.3444 22.3721 11.3441H14.4698C14.383 11.3444 14.2996 11.3109 14.2372 11.2505C14.2067 11.2213 14.1824 11.1862 14.1658 11.1474C14.1492 11.1085 14.1407 11.0667 14.1406 11.0245V10.3857C14.1409 10.259 14.1667 10.1337 14.2164 10.0171C14.2661 9.90063 14.3387 9.7953 14.4299 9.70741C14.6176 9.52683 14.8681 9.42619 15.1285 9.42676H21.7142C21.9747 9.42619 22.2252 9.52683 22.4128 9.70741C22.504 9.7953 22.5767 9.90063 22.6264 10.0171C22.6761 10.1337 22.7018 10.259 22.7021 10.3857L22.7013 11.0245Z"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M22.0435 17.7354H17.7628C17.676 17.7358 17.5925 17.7022 17.5302 17.6419C17.4997 17.6126 17.4755 17.5775 17.4589 17.5387C17.4423 17.4998 17.4337 17.458 17.4336 17.4158V13.5833C17.4337 13.5411 17.4423 13.4993 17.4589 13.4604C17.4755 13.4216 17.4997 13.3865 17.5302 13.3572C17.5925 13.2969 17.676 13.2633 17.7628 13.2637H22.0435V17.7354Z"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M12.824 19.3329C12.8237 19.4595 12.7979 19.5848 12.7482 19.7012C12.6985 19.8177 12.6259 19.9229 12.5347 20.0107C12.3472 20.1916 12.0967 20.2924 11.8361 20.2918H8.54458C8.28404 20.2924 8.03349 20.1916 7.84599 20.0107C7.75452 19.9231 7.68173 19.8179 7.632 19.7014C7.58228 19.5849 7.55664 19.4596 7.55664 19.3329C7.55664 19.2063 7.58228 19.0809 7.632 18.9644C7.68173 18.8479 7.75452 18.7427 7.84599 18.6551C8.03349 18.4742 8.28404 18.3734 8.54458 18.374H11.8361C12.0967 18.3734 12.3472 18.4742 12.5347 18.6551C12.6259 18.7429 12.6985 18.8482 12.7482 18.9646C12.7979 19.0811 12.8237 19.2063 12.824 19.3329Z"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10.1895 18.374V20.2918"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M13.2187 13.8617C13.3102 13.9493 13.383 14.0545 13.4327 14.171C13.4825 14.2875 13.5081 14.4128 13.5081 14.5395C13.5081 14.6662 13.4825 14.7915 13.4327 14.908C13.383 15.0245 13.3102 15.1297 13.2187 15.2173L10.8874 17.4807C10.6985 17.656 10.4497 17.7524 10.1921 17.7502C9.9344 17.7481 9.68731 17.6474 9.50143 17.469C9.41119 17.3824 9.3392 17.2786 9.28965 17.1637C9.24011 17.0488 9.21402 16.9252 9.21293 16.8001C9.21184 16.6751 9.23577 16.551 9.28331 16.4353C9.33084 16.3196 9.40103 16.2146 9.48975 16.1264L11.8185 13.8647C11.911 13.7752 12.02 13.7044 12.1394 13.6564C12.3825 13.5585 12.6539 13.5585 12.8969 13.6564C13.0164 13.7044 13.1254 13.7752 13.2179 13.8647L13.2187 13.8617Z"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10.6543 14.9912L12.0515 16.3468"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default PrescriptionIcon;
