import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const ActionText = ({ children, className = '', onClick, id = 'button' }) => {
    const handleClick = onClick => handleEvent(onClick);

    return (
        <button
            className={`${className} ${styles.actionText}`}
            data-click-type="button"
            type="button"
            onClick={handleClick(onClick)}
            id={id}
        >
            {children}
        </button>
    );
};

export default ActionText;
