export const headerTitle = 'Edit Your Prescriptions';
export const headerTitle1 = 'Your Prescriptions';

export const headerText =
    'Prescription drug coverage and costs can vary widely. If you’d like, add your prescriptions to see coverage and estimated costs for each plan.';

export const headerIconAlt = '';
export const buttonLabel = 'Add Prescription';
export const buttonLabel1 = 'Update Prescription';
export const buttonLabel2 = 'Update';
export const plansButtonLabel = 'Continue';
export const plansButtonLabel1 = 'Add and Continue';
export const skipButtonLabel = 'Skip';
