import FooterNavHeader from '../FooterNavHeader';
import SocialMediaLogo from '../SocialMediaLogo';
import React from 'react';

import FacebookIcon from './icons/Gray-Facebook.svg';
import InstagramIcon from './icons/Gray-Instagram.svg';
import LinkedInIcon from './icons/Gray-LinkedIn.svg';
import YoutubeIcon from './icons/Gray-Youtube.svg';

import styles from './styles.module.scss';

const ConnectWithUs = () => (
    <div className={styles.connectWithUs}>
        <FooterNavHeader text={'Connect with us'} />

        <div className={styles.socialMediaLogoContainer}>
            <SocialMediaLogo
                url={'https://www.facebook.com/PlanEnroll'}
                src={FacebookIcon}
                alt={'Gray Facebook logo'}
                id="facebookLogo"
            />

            <SocialMediaLogo
                url={'https://www.instagram.com/planenroll/'}
                src={InstagramIcon}
                alt={'Gray Instagram logo'}
                id="instagramLogo"
            />

            <SocialMediaLogo
                url={'https://www.linkedin.com/company/planenroll/'}
                src={LinkedInIcon}
                alt={'Gray LinkedIn logo'}
                id="linkedInLogo"
            />

            <SocialMediaLogo
                alt={'Gray YouTube logo'}
                src={YoutubeIcon}
                url={'https://www.youtube.com/@planenroll'}
                id="youTubeLogo"
            />
        </div>
    </div>
);
export default ConnectWithUs;
