const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export function changeDateFormat(time, format = 'YYYY-MM-DD hh:mm:ss') {
    if (!time) return '';

    var datetime = new Date(time);
    var o = {
        'Y+': datetime.getFullYear(),
        'M+': datetime.getMonth() + 1,
        'D+': datetime.getDate(),
        'h+': datetime.getHours(),
        'm+': datetime.getMinutes(),
        's+': datetime.getSeconds(),
        'q+': Math.floor((datetime.getMonth() + 3) / 3),
        S: datetime.getMilliseconds()
    };

    if (/(Y+)/.test(format))
        format = format.replace(
            RegExp.$1,
            (datetime.getFullYear() + '').substr(4 - RegExp.$1.length)
        );

    for (var k in o)
        if (new RegExp('(' + k + ')').test(format)) {
            format = format.replace(
                RegExp.$1,
                RegExp.$1.length === 1
                    ? o[k]
                    : ('00' + o[k]).substr(('' + o[k]).length)
            );
        }

    return format;
}

export function compareDate(dateTime1, dateTime2) {
    let flag = false;
    var formatDate1 = new Date(dateTime1);
    var formatDate2 = new Date(dateTime2);

    if (formatDate1 >= formatDate2) {
        flag = true;
    } else {
        flag = false;
    }

    return flag;
}

export function getAgeFromDOB(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age.toString();
}

export function extractYearMonthDate(dateString = '') {
    const index1 = dateString?.indexOf('-');
    const index2 = dateString?.indexOf('-', index1 + 1);
    const year = parseInt(dateString?.substring(0, index1));
    const month = parseInt(dateString?.substring(index1 + 1, index2));
    const date = parseInt(dateString?.substring(index2 + 1));
    return { year, month, date };
}

const fixZeros = i => (i < 10 ? `0${i}` : i);

export const getDateOptions = () => {
    const currentMonth = new Date().getUTCMonth();
    const currentYear = new Date().getUTCFullYear();
    const currentDate = new Date().getDate();
    const options = [];
    for (let i = currentMonth + 1; i <= 11; i++) {
        options.push({
            value: `${currentYear}-${fixZeros(i + 1)}-01`,
            label: `${months[i]} ${currentYear}`
        });
    }
    if (currentMonth >= 9) {
        options.push({
            value: `${currentYear + 1}-01-01`,
            label: `${months[0]} ${currentYear + 1}`
        });
    }
    if (currentMonth === 11 && currentDate >= 15) {
        for (let i = 1; i <= 11; i++) {
            options.push({
                value: `${currentYear + 1}-${fixZeros(i + 1)}-01`,
                label: `${months[i]} ${currentYear + 1}`
            });
        }
    }
    return options;
};
