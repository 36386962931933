import { useSelector } from 'react-redux';

import Icon from 'components/Icon';
import ModalHeaderTitle from 'components/ModalHeaderTitle';
import { CustomizedSteppers } from 'components/ProgressStepper';

import styles from './styles.module.scss';

const ModalHeader = ({
    headerHeadingClassName,
    headerIconClassName,
    headerTextClassName,
    icon,
    iconAlt = '',
    modalHeaderTitle,
    text,
    title
}) => {
    const showStepper = useSelector(state => state.guidedDetails.showStepper);
    const selectedPlanType = useSelector(
        state => state.planTypeDetail.selectedPlanType
    );
    return (
        <div className={styles.container}>
            <div className={styles.modalHeader}>
                {icon && (
                    <div>
                        <Icon
                            alt={iconAlt}
                            className={`${headerIconClassName} ${styles.icon}`}
                            image={icon}
                            id="headerIcon"
                        />
                    </div>
                )}

                <ModalHeaderTitle
                    headerHeadingClassName={headerHeadingClassName}
                    headerTextClassName={headerTextClassName}
                    modalHeaderTitle={modalHeaderTitle}
                    text={text}
                    title={title}
                />
            </div>
            {showStepper && <CustomizedSteppers selectedPlanType={selectedPlanType} />}
        </div>
    );
};

export default ModalHeader;
