import { getAgentNpn } from './getSessionData';
import { PURL_AGENT_CODE } from './sessionStorageKeys';

import { get } from 'temp/utilities/storage';

const buildQueryString = parameters => {
    const queryString = new URLSearchParams(parameters).toString();

    return queryString ? `?${queryString}` : '';
};

const buildUrlWithQueryString = ({ parameters, url }) => {
    const filteredParameters = removeEmptyEntries(parameters);
    const queryString = buildQueryString(filteredParameters);

    return `${url}${queryString}`;
};

const maybeMakeFetchAbortable = (options, signal) => {
    if (signal) {
        options.signal = signal;
    }

    return options;
};

const removeEmptyEntries = keyValuePairs =>
    Object.fromEntries(
        Object.entries(keyValuePairs).filter(
            keyValuePair => typeof keyValuePair[1] !== 'undefined'
        )
    );

const getResponse = async ({
    params = {},
    signal,
    url,
    additionalHeaders = {},
    authToken = ''
}) => {
    const urlWithParams = buildUrlWithQueryString({ parameters: params, url });
    const agentNpn = url.includes('/Enroll/EnrollmentPdf')
        ? '16274828'
        : getAgentNpn();
    const agentPurl = get(PURL_AGENT_CODE);
    const options = maybeMakeFetchAbortable(
        {
            headers: {
                'Content-Type': 'application/json',
                ...(agentPurl && { isPurl: true }),
                ...(agentNpn && { AgentNPN: agentNpn }),
                ...additionalHeaders,
                ...(authToken && {
                    Authorization: `Bearer ${authToken}`
                })
            },
            method: 'GET'
        },
        signal
    );

    const response = await fetch(urlWithParams, options);

    return response;
};

export default getResponse;
