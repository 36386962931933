import { TextField as TextFieldUI } from '@mui/material';

const TextField = props => {
    const {
        autoFocus = false,
        className = '',
        defaultValue,
        disabled = false,
        error,
        id,
        pattern = true,
        placeholder,
        register,
        required = false,
        type,
        InputProps,
        InputPropsForIcon = {}
    } = props;
    return (
        <TextFieldUI
            {...register(id, { required, pattern })}
            autoFocus={autoFocus}
            className={className}
            defaultValue={defaultValue}
            disabled={disabled}
            error={error}
            fullWidth
            id={id}
            placeholder={placeholder}
            size="small"
            type={type}
            variant="outlined"
            inputProps={{ ...InputProps }}
            InputProps={{ ...InputPropsForIcon }}
        />
    );
};

export default TextField;
