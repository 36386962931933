import { isFunction } from 'formik';
import React, { useState, useEffect, useRef } from 'react';
import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setNohealthConditionPreference } from 'services/clientServices/slice';

import { useAuth0 } from '@auth0/auth0-react';

import Body2 from 'components/Body2';
import Button from 'components/Button';
import EditLink from 'components/EditLink';
import Heading3 from 'components/Heading3';
import Icon from 'components/Icon';
import Text from 'components/Text';
import TextAsLink from 'components/TextAsLink';
import { setHealthConditionContentModal } from 'components/YourHealthConditionContentModal/slice';
import YourHealthConditionModal from 'components/YourHealthConditionModal';
import { setHealthConditionModal } from 'components/YourHealthConditionModal/slice';
import {
    setHealthConditionsList,
    setHealthIntakeModal,
    setNoHealthCondition
} from 'components/YourHealthIntakeModal/slice';
import useFetch from 'components/hooks/useFetch';

import { CONSUMER_BASE_URL } from 'utilities/env';
import {
    FINAL_EXPENSE_HEALTH_CONDITION_ROUTE,
    FINAL_EXPENSE_PLANS_ROUTE
} from 'utilities/routes';
import {
    HEALTH_CONDITION_DETAILS_KEY,
    HEALTH_CONDITION_INDEX_KEY,
    HEALTH_CONDITION_NO_HEALTH_CONDITION,
    HEALTH_CONDITION_OPERATION_KEY,
    HEALTH_CONDITION_SEARCH_TERM_KEY,
    HEALTH_CONDITION_TERM_KEY,
    PRESCRIPTION_NO_PRESCRIPTIONS
} from 'utilities/storageKeys';

import completed from './images/completed.svg';
import incomplete from './images/incomplete.svg';
import plus from './images/plus.svg';

import styles, { mobileWidth } from './styles.module.scss';

import FinalExpenseHeaderNav from 'temp/components/FinalExpenseHeaderNav';
import { getUserHealthConditions } from 'temp/utilities/apiSession/healthConditions';
import { get, set } from 'temp/utilities/storage';

const YourHealthConditionContent = ({ isModal, isOpen }, ref) => {
    const history = useHistory();
    const [conditions, setConditions] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [modalClosed, setModalClosed] = useState(false);
    const noHealthCondition = useSelector(
        state => state.healthIntakeDetails.noHealthCondition
    );
    const healthConditionsList = useSelector(
        state => state.healthIntakeDetails.healthConditionsList
    );

    const [contactDetailsPage, setContactDetailsPage] = useState(false);

    const dispatch = useDispatch();

    const isFirstLoad = useRef(true);
    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const { Post: submitPreference } = useFetch(
        `${CONSUMER_BASE_URL}/Preference/Preference`
    );
    const { consumerId } = useSelector(state => state.userProfile.userProfile);

    useEffect(() => {
        const FirstLoad = async () => {
            if (isFirstLoad.current) {
                window.location.pathname ===
                    FINAL_EXPENSE_HEALTH_CONDITION_ROUTE &&
                    setContactDetailsPage(true);
                if (isAuthenticated) {
                    const authToken = await getAccessTokenSilently();
                    const conditionsData = await getUserHealthConditions({
                        consumerId: user?.consumerid,
                        authToken
                    });
                    if (conditionsData && conditionsData?.length > 0) {
                        set(HEALTH_CONDITION_DETAILS_KEY, conditionsData);
                        dispatch(setHealthConditionsList(conditionsData));
                        setConditions(conditionsData);
                    }
                } else {
                    setConditions(get(HEALTH_CONDITION_DETAILS_KEY));
                }
                isFirstLoad.current = false;
            }
        };
        FirstLoad();
    }, [isFirstLoad]);

    useEffect(() => {
        const LoadHealthConditions = async () => {
            if (modalClosed) {
                if (isAuthenticated) {
                    const authToken = await getAccessTokenSilently();
                    const conditionsData = await getUserHealthConditions({
                        consumerId: user?.consumerid,
                        authToken
                    });

                    set(HEALTH_CONDITION_DETAILS_KEY, conditionsData);
                    dispatch(setHealthConditionsList(conditionsData));
                    setConditions(conditionsData);
                } else {
                    setConditions(get(HEALTH_CONDITION_DETAILS_KEY));
                }
            }
            setModalClosed(false);
        };
        LoadHealthConditions();
    }, [modalClosed]);

    useEffect(() => {
        setConditions(healthConditionsList);
    }, [healthConditionsList]);

    const onSubmitDetails = async () => {
        history.push(FINAL_EXPENSE_PLANS_ROUTE);
    };

    const openModal = () => {
        set(HEALTH_CONDITION_SEARCH_TERM_KEY, '');
        set(HEALTH_CONDITION_TERM_KEY, '');
        set(HEALTH_CONDITION_OPERATION_KEY, 'Add');
        dispatch(setHealthConditionModal(true));
    };

    const openEditModal = (condition, index) => {
        set(HEALTH_CONDITION_TERM_KEY, condition);
        set(HEALTH_CONDITION_INDEX_KEY, index);
        set(HEALTH_CONDITION_OPERATION_KEY, 'Edit');
        isModal && dispatch(setHealthConditionContentModal(false));
        dispatch(setHealthIntakeModal(true));
    };

    const onSelectNoHealthCondition = () => {
        set(HEALTH_CONDITION_NO_HEALTH_CONDITION, true);
        dispatch(setNoHealthCondition(true));
        if (isAuthenticated) {
            submitPreference({
                consumerId,
                preferences: {
                    hasNoHealthConditions: true,
                    hasNoPrescriptions: get(PRESCRIPTION_NO_PRESCRIPTIONS)
                }
            });
            dispatch(setNohealthConditionPreference(true));
        }
    };

    const resultMap = (value, index) => (
        <li className={styles.conditionListItem}>
            <div className={styles.drugBlock}>
                <Text
                    className={styles.conditionName}
                    text={value.conditionName}
                    id={value.conditionName?.replaceAll(' ', '_')}
                />
                {!isMobile && (
                    <div className={styles.status}>
                        <Icon
                            className={styles.icon}
                            image={
                                value.lastTreatmentDate ||
                                !value.hasLookBackPeriod
                                    ? completed
                                    : incomplete
                            }
                            id="statusIcon"
                        />
                        <Text
                            className={`${
                                value.lastTreatmentDate ||
                                !value.hasLookBackPeriod
                                    ? styles.completeStatus
                                    : styles.incompleteStatus
                            }`}
                            text={
                                value.lastTreatmentDate ||
                                !value.hasLookBackPeriod
                                    ? 'Completed'
                                    : 'Incomplete'
                            }
                            id="statusLabel"
                        />
                    </div>
                )}
                <EditLink
                    onClick={() => openEditModal(value, index)}
                    text={'Edit'}
                    className={styles.link}
                    iconClassName={styles.icon}
                />
            </div>
            {isMobile && (
                <div className={styles.status}>
                    <Icon
                        className={styles.icon}
                        image={
                            value.lastTreatmentDate || !value.hasLookBackPeriod
                                ? completed
                                : incomplete
                        }
                        id="statusIcon"
                    />
                    <Text
                        className={`${
                            value.lastTreatmentDate
                                ? styles.completeStatus
                                : styles.incompleteStatus
                        }`}
                        text={
                            value.lastTreatmentDate || !value.hasLookBackPeriod
                                ? 'Completed'
                                : 'Incomplete'
                        }
                        id="statusLabel"
                    />
                </div>
            )}
        </li>
    );

    return (
        <>
            {contactDetailsPage && (
                <div>
                    <div className={styles.contactDetailsHeaderNav}>
                        <FinalExpenseHeaderNav from={'HealthCondition'} />
                    </div>
                </div>
            )}
            <div
                className={`${contactDetailsPage && styles.contactDetailsPage}`}
            >
                <Media
                    queries={{
                        mobile: { maxWidth: mobileWidth }
                    }}
                    onChange={breakpoint => {
                        setIsMobile(breakpoint.mobile);
                    }}
                />
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Heading3
                        text={`Conditions (${conditions?.length || 0})`}
                        id="conditions"
                    />
                    <div className={styles.addNewBtnBlock} id="addNewLink">
                        <Body2
                            className={styles.addNewBtn}
                            text="Add New"
                            onClick={() => openModal(true)}
                        />
                        <Icon image={plus} className={styles.icon} />
                    </div>
                </div>
                {contactDetailsPage && (
                    <>
                        <Text
                            className={styles.contactDetailsText}
                            text={'Health Conditions'}
                            id="healthConditionsLabel"
                        />
                        <Text
                            className={styles.questions}
                            text={
                                'Please add health conditions to determine coverage eligibility.'
                            }
                            id="questions"
                        />
                        <div className={styles.addNew}>
                            <Text
                                className={styles.confirm}
                                text={`Conditions (${conditions?.length || 0})`}
                                id="conditionsLabel"
                            />
                            <EditLink
                                onClick={openModal}
                                text={'Add New'}
                                className={styles.link}
                                iconClassName={styles.icon}
                                icon={plus}
                                id="addNewLink"
                            />
                        </div>
                    </>
                )}

                {conditions?.length || noHealthCondition ? (
                    noHealthCondition ? (
                        <div
                            className={`${
                                contactDetailsPage && styles.contactDetails
                            }`}
                        >
                            <Text
                                className={styles.noHealthConditionInfoText}
                                text={`I currently don't have any health conditions.`}
                                id="conditionText"
                            />
                        </div>
                    ) : (
                        <ul className={styles.resultsList}>
                            {isFunction(resultMap) &&
                                conditions?.map(resultMap)}
                        </ul>
                    )
                ) : (
                    <>
                        <div
                            className={`${
                                contactDetailsPage && styles.contactDetails
                            }`}
                        >
                            <Text
                                className={styles.conditionText}
                                text={
                                    'Add health conditions for more personalized results'
                                }
                                id="conditionText"
                            />
                        </div>
                        <div className={styles.nohealthCondition}>
                            <TextAsLink
                                text="I currently don't have any health conditions."
                                onClick={() => onSelectNoHealthCondition()}
                                className={styles.nohealthConditionText}
                            />
                        </div>
                    </>
                )}

                {contactDetailsPage && (
                    <Button
                        label={'Continue to Quote'}
                        className={styles.continueButton}
                        onClick={() => onSubmitDetails()}
                        id={'continueToQuoteButton'}
                    />
                )}
                <YourHealthConditionModal modalClosed={setModalClosed} />
            </div>
        </>
    );
};

export default YourHealthConditionContent;
