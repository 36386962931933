import { useDispatch, useSelector } from 'react-redux';

import DropdownWithLabel from 'components/DropdownWithLabel';

import { RADIUS_MILES_KEY } from 'utilities/storageKeys';

import { setRadiusMiles } from './slice';

import styles from './styles.module.scss';

import { set } from 'temp/utilities/storage';

const DistanceDropdown = () => {
    const dispatch = useDispatch();

    const radiusMiles = useSelector(
        state => state.distanceDropdownDetails.radiusMiles
    );

    const handleChange = event => {
        const value = event.target.value;

        dispatch(setRadiusMiles(value));
        set(RADIUS_MILES_KEY, value);
    };

    const miles = [
        { value: 5, label: '5 Miles' },
        { value: 10, label: '10 Miles' },
        { value: 25, label: '25 Miles' },
        { value: 50, label: '50 Miles' }
    ];

    return (
        <DropdownWithLabel
            displayValue={radiusMiles}
            list={miles}
            id={'radius'}
            label="Radius"
            labelClassName={styles.labelClassName}
            onChange={handleChange}
            dropdownClassName={styles.dropdownClassName}
        />
    );
};

export default DistanceDropdown;
