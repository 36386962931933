import Error from 'components/Error';
import Metadata from 'components/Metadata';

import { brokenChainAlt } from 'utilities/altText';

import image from './image.svg';

import styles from './styles.module.scss';

const ERROR_MESSAGE =
    'Uh oh, we can’t seem to find the page that you’re looking for. ';

const NotFoundError = () => (
    <>
        <Metadata title="404 Error | PlanEnroll" />
        <Error
            alt={brokenChainAlt}
            className={styles.error}
            errorCode="404"
            image={image}
            text={ERROR_MESSAGE}
            url="/"
        />
    </>
);

export default NotFoundError;
