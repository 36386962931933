import { createSlice } from '@reduxjs/toolkit';

import { FINAL_EXPENSE_STEPS, MEDICARE_STEPS } from 'utilities/storageKeys';

import { finalExpenseSteps, medicareSteps } from './constants';

import { get, set } from 'temp/utilities/storage';

export const guidedDetails = createSlice({
    name: 'guidedDetails',

    initialState: {
        isGuidedModal: false,
        isGuidedProcess: false,
        steps: [],
        currentMedicareStep: -1,
        currentFinalExpenseStep: -1,
        showStepper: false,
        allMedicareStepsEnabled: false,
        allFinalExpenseStepsEnabled: false,
        medicareSteps: get(MEDICARE_STEPS, medicareSteps),
        finalExpenseSteps: get(FINAL_EXPENSE_STEPS, finalExpenseSteps),
        isMedicare: false,
        isFinalExpense: false
    },

    reducers: {
        setGuidedModal: (state, action) => {
            state.isGuidedModal = action.payload;
        },

        setGuidedProcess: (state, action) => {
            state.isGuidedProcess = action.payload;
        },
        setMedicareCurrentStep: (state, action) => {
            state.currentMedicareStep = action.payload;
        },
        setFinalExpenseCurrentStep: (state, action) => {
            state.currentFinalExpenseStep = action.payload;
        },
        skipMedicareCurrentStep: (state, action) => {
            state.medicareSteps = [...state.medicareSteps];
            state.medicareSteps[state.currentMedicareStep].isSkipped = true;
            state.medicareSteps[state.currentMedicareStep].isCompleted = false;
            state.medicareSteps[state.currentMedicareStep].isVisited = true;
            if (state.medicareSteps.length - 1 !== state.currentMedicareStep)
                state.currentMedicareStep = state.currentMedicareStep + 1;
            set(MEDICARE_STEPS, state.medicareSteps);
        },
        skipFinalExpenseCurrentStep: (state, action) => {
            state.finalExpenseSteps = [...state.finalExpenseSteps];
            state.finalExpenseSteps[
                state.currentFinalExpenseStep
            ].isSkipped = true;
            state.finalExpenseSteps[
                state.currentFinalExpenseStep
            ].isCompleted = false;
            state.finalExpenseSteps[
                state.currentFinalExpenseStep
            ].isVisited = true;
            if (
                state.finalExpenseSteps.length - 1 !==
                state.currentFinalExpenseStep
            )
                state.currentFinalExpenseStep =
                    state.currentFinalExpenseStep + 1;
            set(FINAL_EXPENSE_STEPS, state.finalExpenseSteps);
        },
        completeMedicareCurrentStep: (state, action) => {
            state.medicareSteps = [...state.medicareSteps];
            if (state.currentMedicareStep === -1) state.currentMedicareStep = 0;
            state.medicareSteps[state.currentMedicareStep].isSkipped = false;
            state.medicareSteps[state.currentMedicareStep].isCompleted = true;
            state.medicareSteps[state.currentMedicareStep].isVisited = true;
            if (state.medicareSteps.length - 1 !== state.currentMedicareStep)
                state.currentMedicareStep = state.currentMedicareStep + 1;
            set(MEDICARE_STEPS, state.medicareSteps);
        },
        completeFinalExpenseCurrentStep: (state, action) => {
            state.finalExpenseSteps = [...state.finalExpenseSteps];
            if (state.currentFinalExpenseStep === -1)
                state.currentFinalExpenseStep = 0;
            state.finalExpenseSteps[
                state.currentFinalExpenseStep
            ].isSkipped = false;
            state.finalExpenseSteps[
                state.currentFinalExpenseStep
            ].isCompleted = true;
            state.finalExpenseSteps[
                state.currentFinalExpenseStep
            ].isVisited = true;
            if (
                state.finalExpenseSteps.length - 1 !==
                state.currentFinalExpenseStep
            )
                state.currentFinalExpenseStep =
                    state.currentFinalExpenseStep + 1;
            set(FINAL_EXPENSE_STEPS, state.finalExpenseSteps);
        },
        skipMedicareStep: (state, action) => {
            state.medicareSteps = [...state.medicareSteps];
            state.medicareSteps[action.payload].isSkipped = true;
            state.medicareSteps[action.payload].isCompleted = false;
        },
        skipFinalExpenseStep: (state, action) => {
            state.finalExpenseSteps = [...state.finalExpenseSteps];
            state.finalExpenseSteps[action.payload].isSkipped = true;
            state.finalExpenseSteps[action.payload].isCompleted = false;
        },
        completeMedicareStep: (state, action) => {
            state.medicareSteps = [...state.medicareSteps];
            state.medicareSteps[action.payload].isCompleted = true;
            state.medicareSteps[action.payload].isSkipped = false;
        },
        completeFinalExpenseStep: (state, action) => {
            let statefinalExpenseSteps = [...state.finalExpenseSteps];
            statefinalExpenseSteps[action.payload].isCompleted = true;
            statefinalExpenseSteps[action.payload].isSkipped = false;
            state.finalExpenseSteps = [...statefinalExpenseSteps];
        },
        previousMedicareStep: (state, action) => {
            state.currentMedicareStep = state.currentMedicareStep - 1;
        },
        previousFinalExpenseStep: (state, action) => {
            state.currentFinalExpenseStep = state.currentFinalExpenseStep - 1;
        },
        showStepper: (state, action) => {
            state.showStepper = true;
            // state.currentStep = 0;
        },
        hideStepper: (state, action) => {
            state.showStepper = false;
            // state.currentStep = 0;
        },
        resetStepperState: state => {
            // state.steps = [];
            state.currentMedicareStep = -1;
            state.currentFinalExpenseStep = -1;
            state.showStepper = false;
            state.allMedicareStepsEnabled = false;
            state.allFinalExpenseStepsEnabled = false;
        },
        enableAllMedicareSteps: state => {
            const medicareStepsToUpdate = [...state.medicareSteps];
            medicareStepsToUpdate.forEach(s => (s.enabled = true));
            state.allMedicareStepsEnabled = true;
            state.medicareSteps = medicareStepsToUpdate;
        },
        enableAllFinalExpenseSteps: state => {
            const finalExpenseStepsToUpdate = [...state.finalExpenseSteps];
            finalExpenseStepsToUpdate.forEach(s => (s.enabled = true));
            state.allFinalExpenseStepsEnabled = true;
            state.finalExpenseSteps = finalExpenseStepsToUpdate;
        },
        enableMedicareStep: (state, action) => {
            const medicareStepsToUpdate = [...state.medicareSteps];
            medicareStepsToUpdate[action.payload].enabled = true;
            state.medicareSteps = medicareStepsToUpdate;
        },
        enableFinalExpenseStep: (state, action) => {
            const finalExpenseStepsToUpdate = [...state.finalExpenseSteps];
            finalExpenseStepsToUpdate[action.payload].enabled = true;
            state.finalExpenseSteps = finalExpenseStepsToUpdate;
        },
        setMedicareStepper: (state, action) => {
            state.isMedicare = true;
            state.isFinalExpense = false;
            state.currentMedicareStep = 0;
            // state.steps = medicareSteps;
        },
        setFinalExpenseStepper: (state, action) => {
            state.isMedicare = false;
            state.isFinalExpense = true;
            state.currentFinalExpenseStep = 0;

            // state.steps = finalExpenseSteps;
        }
    }
});

export const {
    setGuidedModal,
    setGuidedProcess,
    setMedicareCurrentStep,
    setFinalExpenseCurrentStep,
    skipMedicareCurrentStep,
    skipFinalExpenseCurrentStep,
    completeMedicareCurrentStep,
    completeFinalExpenseCurrentStep,
    previousMedicareStep,
    previousFinalExpenseStep,
    showStepper,
    setMedicareStepper,
    setFinalExpenseStepper,
    hideStepper,
    resetStepperState,
    enableAllFinalExpenseSteps,
    enableAllMedicareSteps,
    enableMedicareStep,
    enableFinalExpenseStep,
    skipMedicareStep,
    skipFinalExpenseStep,
    completeMedicareStep,
    completeFinalExpenseStep
} = guidedDetails.actions;

export default guidedDetails.reducer;
