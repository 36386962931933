import { createSlice } from '@reduxjs/toolkit';

export const profileImage = createSlice({
    name: 'profileImage',

    initialState: {
        profileImage: ''
    },

    reducers: {
        setProfileImage: (state, action) => {
            state.profileImage = action.payload;
        }
    }
});

export const { setProfileImage } = profileImage.actions;

export default profileImage.reducer;
