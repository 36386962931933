import { useContext, useState } from 'react';

import AgentsModal from 'components/AgentsModal';
import Button from 'components/Button';
import Heading3 from 'components/Heading3';
import Icon from 'components/Icon';
import LinkText from 'components/LinkText';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import {
    finalExpensePlanType,
    medicarePlanType
} from 'components/PlanTypeModal/constants';
import ProfilePicture from 'components/ProfilePicture';

import purlAgentContext from 'contexts/purlAgentContext';

import formatPhoneNumber from 'utilities/formatPhoneNumber';

import emailIcon from '../icons/emailModal.svg';
import networkAgentIcon from '../icons/networkAgentModal.svg';
import notAvailableIcon from '../icons/notAvailable.svg';
import phoneIcon from '../icons/phoneModal.svg';
import verifiedIcon from '../icons/verifiedBold.svg';

import styles from './styles.module.scss';

const InsuranceAgentDetailsModal = ({
    isOpen,
    onClose,
    agentDetails,
    isLifeAgent
}) => {
    const [agentModalState, setAgentModalState] = useState({
        isOpen: false,
        selectedType: null
    });

    const { setPurlAgentContext } = useContext(purlAgentContext);

    const onAgentModalClose = () => {
        setAgentModalState({ isOpen: false, selectedType: null });
    };

    const phoneNumber = agentDetails?.virtualNumber?.substring(2);
    const isAvailableNow = agentDetails?.isPurlToggledOn;
    const isNetworkAgent = agentDetails?.isTrainingComplete;
    const isPreferredAgent = agentDetails?.hasCampaign;

    return (
        <div className={styles.modalWithHeaderFooter}>
            <Modal className={styles.modal} isOpen={isOpen}>
                <ModalWindow
                    closeIconClassName={styles.closeIconClassName}
                    contentClassName={styles.contentClassName}
                    footerButtonClassName={styles.footerButtonClassName}
                    footerClassName={styles.footerClassName}
                    hideFooter={true}
                    headerClassName={styles.headerClassName}
                    headerTitle={isLifeAgent ? 'Life Agent' : 'Health Agent'}
                    modalWindow={styles.modalWindow}
                    onClose={() => onClose()}
                    headerHeadingClassName={styles.headerHeadingClassName}
                    modalHeaderTitle={styles.modalHeaderTitle}
                >
                    <div className={styles.content}>
                        <div className={styles.agentDetailsBox}>
                            <div
                                className={styles.agentDetailsHeadingContainer}
                            >
                                <Heading3
                                    className={styles.agentDetailsHeading}
                                    text={
                                        isLifeAgent
                                            ? 'Life Agent'
                                            : 'Health Agent'
                                    }
                                />
                                <span
                                    className={
                                        styles.agentDetailsHeadingBracket
                                    }
                                >
                                    {isLifeAgent
                                        ? '(Final Expense)'
                                        : '(Health Care)'}
                                </span>
                            </div>
                            <div className={styles.agentDetailsBoxBody}>
                                <div
                                    className={
                                        styles.agentDetailsAvatarContainer
                                    }
                                >
                                    <ProfilePicture
                                        className={styles.avatarIcon}
                                        hideCameraOverlay={true}
                                        firstName={agentDetails?.agentFirstName}
                                        lastName={agentDetails?.agentLastName}
                                    />
                                </div>
                                <div
                                    className={
                                        styles.agentDetailsRightContainer
                                    }
                                >
                                    <div
                                        className={
                                            styles.agentDetailsNameContainer
                                        }
                                    >
                                        <Heading3
                                            text={
                                                agentDetails?.agentFirstName +
                                                ' ' +
                                                agentDetails?.agentLastName
                                            }
                                            className={
                                                styles.agentDetailsNameHeading
                                            }
                                        />
                                        <div
                                            className={
                                                styles.agentAvailableHeading
                                            }
                                        >
                                            <Icon
                                                image={
                                                    isAvailableNow
                                                        ? verifiedIcon
                                                        : notAvailableIcon
                                                }
                                                className={`${styles.agentVerifiedIcon}`}
                                            />
                                            <span
                                                className={styles.availableNow}
                                            >
                                                {isAvailableNow
                                                    ? 'Available Now'
                                                    : 'Not Available'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={styles.agentContactBox}>
                                        {isPreferredAgent && (
                                            <span
                                                className={
                                                    styles.preferredAgentBoxMobile
                                                }
                                            >
                                                Preferred Agent
                                            </span>
                                        )}
                                        <div className={styles.phoneNumberRow}>
                                            <div className={styles.phoneBox}>
                                                <Icon
                                                    className={styles.phoneIcon}
                                                    image={phoneIcon}
                                                />
                                                <span
                                                    className={
                                                        styles.phoneNumber
                                                    }
                                                >
                                                    <a
                                                        className={
                                                            styles.phoneLink
                                                        }
                                                        href={`tel:${phoneNumber}`}
                                                        rel="noreferrer"
                                                    >
                                                        {formatPhoneNumber(
                                                            phoneNumber
                                                        )}
                                                    </a>

                                                    {isPreferredAgent && (
                                                        <span
                                                            className={
                                                                styles.preferredAgentBox
                                                            }
                                                        >
                                                            Preferred Agent
                                                        </span>
                                                    )}
                                                </span>
                                            </div>
                                            {isNetworkAgent && (
                                                <div
                                                    className={
                                                        styles.networkAgentBox
                                                    }
                                                >
                                                    <span
                                                        className={
                                                            styles.networkAgentText
                                                        }
                                                    >
                                                        Network Agent
                                                    </span>
                                                    <Icon
                                                        className={
                                                            styles.phoneIcon
                                                        }
                                                        image={networkAgentIcon}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className={styles.emailRow}>
                                            <div className={styles.emailBox}>
                                                <Icon
                                                    className={styles.phoneIcon}
                                                    image={emailIcon}
                                                />
                                                <a
                                                    className={styles.emailLink}
                                                    href={`mailto:${agentDetails?.email}`}
                                                    rel="noreferrer"
                                                >
                                                    {agentDetails?.email}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.viewMoreBox}>
                            <Button
                                className={styles.viewMoreButton}
                                label={'View More'}
                            />
                        </div>
                        <div className={styles.footer}>
                            <Heading3
                                className={styles.footerTitle}
                                text="Not getting the help you need?"
                            />
                            <LinkText
                                url="#"
                                onClick={event => {
                                    event.preventDefault();
                                    setAgentModalState({
                                        isOpen: true,
                                        selectedType: isLifeAgent
                                            ? finalExpensePlanType
                                            : medicarePlanType
                                    });
                                }}
                                linkTextClass={styles.footerLink}
                                text="Find an Agent"
                            />
                        </div>
                    </div>
                </ModalWindow>
            </Modal>
            {agentModalState.isOpen && (
                <AgentsModal
                    agentModalState={agentModalState}
                    onClose={onAgentModalClose}
                    setPurlAgentContext={setPurlAgentContext}
                />
            )}
        </div>
    );
};

export default InsuranceAgentDetailsModal;
