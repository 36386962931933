export const typeAheadTitle = 'Prescription Search';

export const singleFoundLabel = 'prescription';
export const startPrompt = 'Start by typing a prescription name to search';
export const multipleFoundLabel = 'prescriptions';
export const noResultsMessage =
    'No prescriptions found under the current search criteria.';

export const mobilePlaceholder = 'Start by typing prescription';
export const placeholder = `${mobilePlaceholder} name`;

export const linkText = 'Add a prescription +';
