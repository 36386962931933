import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import Body3 from 'components/Body3';
import ButtonLinkWithIcon from 'components/ButtonLinkWithIcon';
import Heading3 from 'components/Heading3';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import Text from 'components/Text';
import { setProfilePharmacyContentModal } from 'components/YourProfilePharmacyModal/slice';

import deleteIcon from './images/delete.svg';
import disablePlus from './images/disablePlus.svg';
import addIcon from './images/icon-Add.svg';

import styles from './styles.module.scss';

const YourProfilePharmacyModalContent = ({
    openPharmacyModal,
    PharmacyDetailList,
    deletePharmacy,
    isAdd
}) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const handleClick = async pharmacy => {
        setLoading(true);
        await deletePharmacy(pharmacy);
        setLoading(false);
    };
    const emptyBlock = useMemo(
        () => (
            <Text
                text="Add your Pharmacy to keep track and manage."
                className={styles.emptyBlock}
            />
        ),
        []
    );

    const [pharmacyList, setPharmacyList] = useState([]);

    useEffect(() => {
        setPharmacyList(PharmacyDetailList);
    }, [PharmacyDetailList]);

    return loading ? (
        <Spinner />
    ) : (
        <div className={styles.pharmacyContainer}>
            <div className={styles.header}>
                <Heading3
                    text={
                        <>
                            Pharmacies{' '}
                            <span className={styles.count}>
                                ({pharmacyList?.length || 0})
                            </span>
                        </>
                    }
                />
                <ButtonLinkWithIcon
                    containerClassName={
                        pharmacyList?.length === 3
                            ? styles.disableIconWithLink
                            : styles.addNewLink
                    }
                    onClick={() => {
                        isAdd(true);
                        openPharmacyModal(true);
                        dispatch(setProfilePharmacyContentModal(false));
                    }}
                    text="Add New"
                    btnIcon={pharmacyList?.length === 3 ? disablePlus : addIcon}
                    linkText={
                        pharmacyList?.length === 3
                            ? styles.disableLabelClassName
                            : styles.labelClassName
                    }
                />
            </div>
            <div className={styles.content}>
                {!pharmacyList?.length && emptyBlock}
                <ul className={styles.list}>
                    {pharmacyList?.map(Pharmacy => {
                        return (
                            <li
                                className={styles.listItem}
                                key={Pharmacy?.dosage?.drugID}
                            >
                                <div className={styles.listContent}>
                                    <Body3
                                        className={styles.PharmacyName}
                                        text={Pharmacy?.name}
                                    />

                                    <Body3
                                        className={styles.dosage}
                                        text={`${Pharmacy?.address1}, ${Pharmacy?.state} ${Pharmacy?.zip}`}
                                    />
                                </div>
                                {Pharmacy.isPrimary && (
                                    <div className={styles.primaryContainer}>
                                        <span className={styles.primaryText}>
                                            Primary
                                        </span>
                                    </div>
                                )}
                                <Icon
                                    className={styles.deleteIcon}
                                    onClick={() => handleClick(Pharmacy)}
                                    image={deleteIcon}
                                />
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default YourProfilePharmacyModalContent;
