import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const Heading6 = ({ className = '', text, onClick, id = 'heading' }) => {
    const handleClick = onClick => handleEvent(onClick);

    return (
        <h6
            className={`${styles.heading6} ${className}`}
            onClick={handleClick(onClick)}
            id={id}
        >
            {text}
        </h6>
    );
};

export default Heading6;
