import Icon from 'components/Icon';
import TextAsLink from 'components/TextAsLink';

import styles from './styles.module.scss';

const IconWithLink = ({
    alt = '',
    caption = '',
    className = '',
    iconClassName,
    image,
    isImageClickable = false,
    labelClassName = '',
    onClick,
    display = '',
    isTextDisplayRight = true
}) => {
    if (!image) {
        throw new Error('No image was specified');
    }

    return (
        <div className={`${className} ${styles.iconWithLink} ${display}`}>
            {!isTextDisplayRight && (
                <TextAsLink
                    className={`${labelClassName} ${styles.textAsLink}`}
                    text={caption}
                    onClick={onClick}
                />
            )}

            {isImageClickable ? (
                <Icon
                    alt={alt}
                    className={`${iconClassName} ${styles.icon}`}
                    image={image}
                    onClick={onClick}
                />
            ) : (
                <Icon
                    alt={alt}
                    className={`${iconClassName} ${styles.icon}`}
                    image={image}
                />
            )}

            {isTextDisplayRight && (
                <TextAsLink
                    className={`${labelClassName} ${styles.textAsLink}`}
                    text={caption}
                    onClick={onClick}
                />
            )}
        </div>
    );
};

export default IconWithLink;
