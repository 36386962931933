import Auth0ProviderWithHistory from 'auth/Auth0ProviderWithHistory';
import { Suspense } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { ClientServiceContextProvider } from 'services/clientServiceProvider';

import Metadata from 'components/Metadata';
import Providers from 'components/Providers';
import Spinner from 'components/Spinner';

import MainLayout from 'layouts/MainLayout';

import meta from './meta';

const loading = <Spinner />;

const App = () => (
    <BrowserRouter>
        <Auth0ProviderWithHistory>
            <ClientServiceContextProvider>
                <Providers>
                    <Metadata
                        alt={meta.alt}
                        description={meta.description}
                        image={meta.image}
                        largeImage={meta.largeImage}
                        title={meta.title}
                    />
                    <Suspense fallback={loading}>
                        <Switch>
                            <Route
                                path="/"
                                name="Welcome"
                                render={props => <MainLayout {...props} />}
                            />
                        </Switch>
                    </Suspense>
                </Providers>
            </ClientServiceContextProvider>
        </Auth0ProviderWithHistory>
    </BrowserRouter>
);

export default App;
