import { createSlice } from '@reduxjs/toolkit';

export const mainLayoutSlice = createSlice({
    name: 'mainLayout',

    initialState: {
        value: '',
        purlAgents: [],
        articleProductType: '',
        purlAgentsLoaded: false
    },

    reducers: {
        updateCmsId: (state, action) => {
            state.value = action.payload;
        },
        updatePurlAgents: (state, action) => {
            state.purlAgents = action.payload;
            state.purlAgentsLoaded = true;
        },
        setArticleProductType: (state, action) => {
            state.articleProductType = action.payload;
        }
    }
});

export const { updateCmsId, updatePurlAgents, setArticleProductType } =
    mainLayoutSlice.actions;

export default mainLayoutSlice.reducer;
