import ActivityStreamList from 'components/ActivityStreamList';
import InfoCard from 'components/InfoCard';

import activityStream from './icons/activityStream.png';

import { activityStreamHeading } from './constant';

import styles from './styles.module.scss';

const ActivityStreamInfoCard = ({
    activityStreamCardContainer,
    onclick,
    openZipModal
}) => (
    <div className={`${activityStreamCardContainer}`}>
        <InfoCard
            bodyElement={
                <ActivityStreamList
                    onclick={onclick}
                    openZipModal={openZipModal}
                />
            }
            containerClassName={styles.activityStream}
            heading={activityStreamHeading}
            icon={activityStream}
            titleContainerClassName={styles.agentInfoTitle}
            infoCardDetailsClassName={styles.activityStreamCardDetails}
        />
    </div>
);

export default ActivityStreamInfoCard;
