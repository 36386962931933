import { useState } from 'react';
import Media from 'react-media';

import AdditionalAddress from 'components/AdditionalAddress';
import EditLink from 'components/EditLink';
import Heading3 from 'components/Heading3';
import Text from 'components/Text';

import styles, { mobileWidth } from './styles.module.scss';

const DoctorListItem = ({
    addresses = [],
    firstName = '',
    handleEditDoctor,
    inNetworkIcon = false,
    lastName = '',
    maxReached = false,
    onClick,
    selectedAddresses = [],
    specialty = '',
    type = '',
    id
}) => {
    const [isMobile, setIsMobile] = useState(false);
    return (
        <>
            <Media
                queries={{
                    mobile: { maxWidth: mobileWidth }
                }}
                onChange={breakpoint => {
                    setIsMobile(breakpoint.mobile);
                }}
            />
            <div className={styles.doctorListItem} id={id}>
                {type === 'added' && (
                    <EditLink
                        className={styles.right}
                        onClick={handleEditDoctor}
                        text={!isMobile ? 'Edit' : ''}
                    />
                )}
                <Text
                    className={styles.specialty}
                    text={specialty}
                    id={`specialty_${specialty}`}
                />

                <Heading3
                    className={styles.fullName}
                    text={`${firstName}  ${lastName}`}
                    id={`${firstName}_${lastName}`}
                />

                <AdditionalAddress
                    addresses={addresses}
                    selectedAddresses={selectedAddresses}
                    onClick={onClick}
                    type={type}
                    inNetworkIcon={inNetworkIcon}
                    maxReached={maxReached}
                />
            </div>
        </>
    );
};

export default DoctorListItem;
