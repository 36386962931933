import { useContext } from 'react';

import DistanceDropdown from 'components/DistanceDropdown';
import PlansByZipForm from 'components/PlansByZipForm';

import ZipCodeContext from 'contexts/zipCode';

import styles from './styles.module.scss';

const ZipRadiusSection = ({ label = '' }) => {
    const { counties } = useContext(ZipCodeContext);
    const hasMultipleCounties = counties ? counties.length > 1 : false;
    return (
        <div
            className={
                hasMultipleCounties
                    ? styles.zipRadiusSectionWithCounty
                    : styles.zipRadiusSection
            }
        >
            <PlansByZipForm
                zipCodeLabel={`${label} Zip Code`}
                showSingleCounty={false}
                only5Valid={true}
            />
            <div className={styles.distanceDropdownContainer}>
                <DistanceDropdown />
            </div>
        </div>
    );
};

export default ZipRadiusSection;
