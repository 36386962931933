import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';

import DoctorListItem from 'components/DoctorListItem';
import Heading5 from 'components/Heading5';
import {
    setIsSearchDoctor,
    setSelectedDoctor
} from 'components/YourDoctorModal/slice';

import styles from './styles.module.scss';

const DoctorAddedListItems = ({ doctorsList, isGuided, showAdd }) => {
    const dispatch = useDispatch();
    const [doctors, setDoctors] = useState(doctorsList);
    const sortedResults = useMemo(
        () => doctors?.sort((a, b) => (a.lastName > b.lastName ? 1 : -1)),
        [doctors]
    );
    const selectedPlan = useSelector(state => state.doctorDetails.selectedPlan);

    const handleEditDoctor = data => {
        let editDoctor = { ...data };
        const { addresses } = editDoctor;
        editDoctor['selectedAddresses'] = addresses.map(address => address.id);
        dispatch(setSelectedDoctor(editDoctor));
    };

    useEffect(() => {
        if (selectedPlan) {
            const { providers = [] } = selectedPlan;
            let networkMap = providers.reduce((a, c) => {
                a[c.addressId] = c.inNetwork;
                return a;
            }, {});
            let doctors = doctorsList.map(doctor => {
                doctor.addresses = doctor.addresses.map(address => {
                    return {
                        ...address,
                        inNetwork: networkMap[address.id] ?? false
                    };
                });
                return doctor;
            });
            setDoctors(doctors);
        } else {
            setDoctors(doctorsList);
        }
    }, [selectedPlan, doctorsList]);

    return (
        sortedResults && (
            <div className={styles.doctorAddedListItems}>
                {sortedResults.map((doctor, index) => {
                    const { addresses, firstName, lastName, specialty, NPI } =
                        doctor;
                    return (
                        <DoctorListItem
                            addresses={addresses}
                            firstName={firstName}
                            key={NPI}
                            id={`doctor-searchItem-${index}`}
                            lastName={lastName}
                            handleEditDoctor={() => handleEditDoctor(doctor)}
                            specialty={specialty}
                            type="added"
                            inNetworkIcon={Boolean(selectedPlan)}
                        />
                    );
                })}
                {(isGuided || showAdd) && (
                    <div className={styles.btnContainer}>
                        <Heading5
                            onClick={() => dispatch(setIsSearchDoctor(true))}
                            className={styles.addDoctor}
                            text={
                                <>
                                    Add Another Doctor <AddIcon />
                                </>
                            }
                            id="addAnotherDoctorLink"
                        />
                    </div>
                )}
            </div>
        )
    );
};

export default DoctorAddedListItems;
