import * as Sentry from '@sentry/react';

import {
    addPharmacy,
    deletePharmacy,
    getPharmacies
} from 'utilities/apiSession/pharmacies';
import { API_BASE_URL as baseURL } from 'utilities/env';
import getResponse from 'utilities/getResponse';
import putJSON from 'utilities/putJSON';

import { getPurlAgentData } from './agentPurl';
import { getLeadId } from './leadId';
import {
    enroll,
    getPlanDetails,
    getPlans,
    getPlansByConfirmationNumber
} from './plans';
import {
    addPrescription,
    deletePrescription,
    getPrescriptions
} from './prescriptions';

const startSession = async ({ effectiveDate, fips, zip, authToken = '' }) => {
    const body = { effectiveDate, fips, zip };
    const leadId = getLeadId();
    const url = `${baseURL}/Lead/${leadId}/Session`;
    try {
        return await putJSON({ body, url, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const viewApplication = async authToken => {
    // const leadId = getLeadId();
    const leadId = 61338;
    const params = {
        confirmationNumber: 'A53337347936118M'
    };
    const url = `${baseURL}/Lead/${leadId}/Enroll/EnrollmentPdf`;

    try {
        const response = await getResponse({ params, url, authToken });

        return response;
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

export {
    addPharmacy,
    addPrescription,
    deletePharmacy,
    deletePrescription,
    enroll,
    getPharmacies,
    getPlanDetails,
    getPlans,
    getPlansByConfirmationNumber,
    getPrescriptions,
    getPurlAgentData,
    startSession,
    viewApplication
};
