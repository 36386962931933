export const headerTitle = 'Your Pharmacies';

export const headerText =
    'Want to change your pharmacy? Enter your new pharmacy info below to ensure it is included in your plan’s formulary.';

export const headerIconAlt = '';
export const buttonLabel = 'Add Pharmacy';
export const plansButtonLabel = 'Continue';
export const addAndContinue = 'Add and Continue';
export const maxReachedTextInSearchForm =
    'You may select up to 3 pharmacies, including physical and online options';
export const maxReachedTextInListForm =
    ' You have reached the maximum limit of 3. Remove a pharmacy to add a new one.';

export const infoText = 'You may select a maximum of 3 pharmacies. Add up to .';
