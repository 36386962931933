import Body2 from 'components/Body2';
import InfoModal from 'components/InfoModal';

import styles from './styles.module.scss';

const PlanCostLabel = ({
    className = '',
    description,
    label,
    modalBoxClassName,
    from
}) => (
    <div className={`${className} ${styles.planCostLabel}`}>
        <Body2
            className="plan-cost-label"
            text={label}
            id={label?.replaceAll(' ', '_')}
        />

        <InfoModal
            className="plan-card-info-modal"
            description={description}
            modalBoxClassName={modalBoxClassName}
            title={label}
            from={from}
        />
    </div>
);

export default PlanCostLabel;
