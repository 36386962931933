import * as Sentry from '@sentry/react';

import { AGENT_API_BASE_URL } from 'utilities/env';
import getJSON from 'utilities/getJSON';
import { PURL_AGENT_CODE } from 'utilities/sessionStorageKeys';

import { get } from 'temp/utilities/storage';

const getUrl = () => {
    return `${AGENT_API_BASE_URL}/Purl`;
};

const getPurlAgentData = async () => {
    const purlAgentCode = get(PURL_AGENT_CODE);
    const url = `${getUrl()}/${purlAgentCode}`;
    try {
        if (purlAgentCode) {
            return await getJSON({ url });
        }
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const getPurlAgentDataByAgentCode = async agentCode => {
    const url = `${getUrl()}/${agentCode}`;
    try {
        return await getJSON({ url });
    } catch (e) {
        Sentry.captureException(e);
        throw e;
    }
};

export { getPurlAgentData, getPurlAgentDataByAgentCode };
