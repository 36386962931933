import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const handleClick = onClick => handleEvent(onClick);

const ButtonLink = ({
    className = '',
    href,
    isActived,
    onClick,
    text,
    datagtm = ''
}) => {
    return href ? (
        <a
            className={`${className} ${styles.buttonLink} ${
                isActived ? styles.activedStyle : ''
            }`}
            href={href}
            data-gtm={datagtm}
            id={typeof text === 'string' ? text?.replaceAll(' ', '_') : ''}
        >
            {text}
        </a>
    ) : (
        <button
            className={`${className} ${styles.buttonLink} ${
                isActived ? styles.activedStyle : ''
            }`}
            data-click-type="button"
            onClick={handleClick(onClick)}
            id={typeof text === 'string' ? text?.replaceAll(' ', '_') : ''}
        >
            {text}
        </button>
    );
};
export default ButtonLink;
