import { useState, useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';

/**
 * Custom hook for fetching data from an API
 *
 * @param {string} url - The API endpoint to fetch data from
 * @param {boolean} [isPublic=false] - Whether or not the API endpoint is public (no authentication required)
 * @param {boolean} [noResponse=false] - Whether or not to return the response from the API endpoint
 * @returns {object} An object containing the fetched data, loading state, and error state, as well as helper functions for making PUT, POST, and DELETE requests
 */

const useFetch = (url, isPublic = false, noResponse = false) => {
    const { getAccessTokenSilently } = useAuth0();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    /**
     * Fetches data from the API endpoint using the specified HTTP method and request body
     */
    const fetchData = useCallback(
        async ({
            method = 'GET',
            body,
            returnHttpResponse = false,
            overrideUrl = ''
        }) => {
            try {
                const options = { method };
                if (!isPublic) {
                    const accessToken = await getAccessTokenSilently();
                    if (accessToken) {
                        options.headers = {
                            Authorization: `Bearer ${accessToken}`
                        };
                    }
                }
                if (body) {
                    options.headers = {
                        ...options.headers,
                        'Content-Type': 'application/json'
                    };
                    options.body = JSON.stringify(body);
                }
                const response = await fetch(overrideUrl || url, options);

                if (returnHttpResponse) {
                    return response;
                }

                let json = null;
                if (!noResponse) {
                    if (response.status === 204) json = response;
                    else json = await response.json();
                }
                setData(json);
                setLoading(false);
                return json;
            } catch (error) {
                Sentry.captureException(error);
                setError(error);
                setLoading(false);
            }
        },
        [url, getAccessTokenSilently, isPublic, noResponse]
    );

    const Get = (body, returnHttpResponse, overrideUrl = '') => {
        return fetchData({
            method: 'GET',
            body,
            returnHttpResponse,
            overrideUrl
        });
    };

    const Post = (body, returnHttpResponse) => {
        return fetchData({ method: 'POST', body, returnHttpResponse });
    };

    const Put = (body, returnHttpResponse) => {
        return fetchData({ method: 'PUT', body, returnHttpResponse });
    };

    const Delete = (body, returnHttpResponse) => {
        return fetchData({ method: 'DELETE', body, returnHttpResponse });
    };

    return { data, loading, error, Put, Post, Delete, Get };
};

export default useFetch;
