import Icon from 'components/Icon';
import Label from 'components/Label';
import Text from 'components/Text';

import { formatMCID } from 'utilities/maskMedicareId';

import editIcon from './images/icon-Edit.svg';

import styles from './styles.module.scss';

const LegacyCard = ({
    details,
    onCancel,
    cardHeaderClass = styles.cardHeader
}) => {
    return (
        <>
            <div className={cardHeaderClass}>Legacy Safeguard</div>
            <div className={styles.infoCardDetails}>
                <Label text="Name" />

                <Text className={styles.labelText} text={details?.name} />

                <div className={styles.divider} />

                <Label text="ID" />

                <div className={styles.showInfo}>
                    <Text
                        className={styles.labelText}
                        text={formatMCID(details?.uniqueId)}
                    />
                </div>
            </div>

            <div className={styles.editInfo} onClick={onCancel}>
                {' '}
                Edit Informaton{' '}
                <Icon className={styles.icon} image={editIcon} />
            </div>
        </>
    );
};
export default LegacyCard;
