import Text from 'components/Text';
import TextInput from 'components/TextInput';

import styles from './styles.module.scss';

const MuiTextField = ({
    className,
    defaultValue,
    error,
    isPadding = true,
    maxLength = 100,
    min = 0,
    onChange,
    textFieldClassName = '',
    minPadding = false,
    title
}) => (
    <div className={className}>
        <Text className={styles.text} id={`${title}_label`}>
            {title}
        </Text>
        <TextInput
            className={`${textFieldClassName} ${styles.textField}`}
            isPadding={isPadding}
            onChange={onChange}
            value={defaultValue}
            error={error}
            variant="outlined"
            inputProps={{ maxLength, min }}
            minPadding={minPadding}
            id={`${title}_input`}
        />
    </div>
);

export default MuiTextField;
