import Body2 from 'components/Body2';
import Body2DMSans from 'components/Body2Bold';
import Dropdown from 'components/Dropdown';

import styles from './styles.module.scss';

const DropdownWithLabel = ({
    className,
    displayValue,
    dropDownValues,
    id,
    label,
    labelClassName,
    onChange,
    list,
    register = () => {},
    required = false,
    dropdownClassName = '',
    renderValue = null
}) => (
    <div className={`${styles.dropdownWithLabel} ${className}`}>
        <Body2DMSans className={labelClassName} text={label} />

        <Dropdown
            displayValue={displayValue}
            onChange={onChange}
            values={dropDownValues}
            list={list}
            id={id}
            register={register}
            required={required}
            className={dropdownClassName}
            renderValue={renderValue}
        />
    </div>
);

export default DropdownWithLabel;
