import { useContext } from 'react';

import ZipCode from 'components/ZipCode';
import ZipCodeWithCounty from 'components/ZipCodeWithCounty';

import ZipCodeContext from 'contexts/zipCode';

import styles from './styles.module.scss';

const PlansByZip = ({
    className,
    countyLabelClass,
    footerButtonClicked,
    id,
    isOnChangeEnable = true,
    only5Valid = false,
    shouldAlwaysShowCounty = false,
    showCounty = true,
    showSingleCounty = true,
    type,
    zipLabelClass,
    showError= true
}) => {
    const { counties } = useContext(ZipCodeContext);
    localStorage.removeItem('countyChange');
    const hasMultipleCounties =
        showCounty &&
        (shouldAlwaysShowCounty ||
            (counties ? counties.length > (showSingleCounty ? 0 : 1) : false));

    return hasMultipleCounties ? (
        <ZipCodeWithCounty
            className={`${className} ${styles.zipCodeWithCounty}`}
            id={id}
            isOnChangeEnable={isOnChangeEnable}
            zipLabelClass={zipLabelClass}
            countyLabelClass={countyLabelClass}
            showError={showError}
        />
    ) : (
        <ZipCode
            className={`${className} ${styles.zipCode}`}
            footerButtonClicked={footerButtonClicked}
            id={id}
            type={type}
            only5Valid={only5Valid}
            isOnChangeEnable={isOnChangeEnable}
            labelClass={zipLabelClass}
        />
    );
};

export default PlansByZip;
