import { useCallback, useEffect, useRef, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { SearchOutlined } from '@mui/icons-material';
import { styled, Tab, Tabs } from '@mui/material';
import { TextField } from '@mui/material';

import ActionText from 'components/ActionText';
import Body2 from 'components/Body2';
import Heading5 from 'components/Heading5';
import Icon from 'components/Icon';
import IconWithLink from 'components/IconWithLink';
import { getDigitalPharmacies } from 'components/PharmacySearch/data';
import Spinner from 'components/Spinner';
import Text from 'components/Text';
import TextAsLink from 'components/TextAsLink';
import ZipRadiusSection from 'components/ZipRadiusSection';

import handleEvent from 'utilities/handleEvent';
import isFunction from 'utilities/isFunction';

import arrow from './image/arrow.svg';
import online from './image/online.svg';
import physical from './image/physical.svg';

import styles from './styles.module.scss';

import useDebounce from 'temp/utilities/useDebounce';

// const PER_PAGE = 10;

const TypeAheadPharmacy = ({
    debounceDelay = 250,
    defaultValue,
    fetchResults,
    handleSelectedPharmacy,
    multipleFoundLabel = '',
    noResultsMessage = 'No results found under the current search criteria.',
    onChange,
    onClear,
    PER_PAGE = 10,
    placeholder = 'Start typing to search',
    radiusMiles,
    zipCode,
    county,
    resultMap,
    singleFoundLabel = '',
    startPrompt = 'Start typing to search.',
    title,
    tab,
    setTab
}) => {
    const { user, getAccessTokenSilently } = useAuth0();
    const [showResults, setShowResults] = useState(false);
    const [total, setTotal] = useState(0);
    const [pharmacyList, setPharmacyList] = useState([]);
    const [searchTerm, setSearchTerm] = useState(defaultValue);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [
        digitalPharmaciesPaginationData,
        setDigitalPharmaciesPaginationData
    ] = useState([]);
    const [digitalPharmacies, setDigitalPharmacies] = useState([]);
    const label =
        (tab === 0
            ? pharmacyList.length
            : digitalPharmaciesPaginationData.length) === 1
            ? singleFoundLabel
            : multipleFoundLabel;
    const text1 = `${
        (tab === 0
            ? pharmacyList.length
            : digitalPharmaciesPaginationData?.length) || 0
    } ${label} `;
    const text = `found`;
    const debouncedSearchTerm = useDebounce(searchTerm, debounceDelay);
    const showNoResultsMessage =
        debouncedSearchTerm && pharmacyList?.length === 0;
    const [digitalPharmaciesTotal, setDigitalPharmaciesTotal] = useState(0);
    const infoMessage = (tab === 0 ? total : digitalPharmaciesTotal)
        ? showNoResultsMessage
        : noResultsMessage;

    const firstLoad = useRef(true);
    const getAuthToken = async () => {
        return user ? await getAccessTokenSilently() : '';
    };
    const searchTermChanged = e => {
        setTotal(0);
        setPage(1);
        const newSearchTerm = e?.target?.value;
        setSearchTerm(newSearchTerm);
        setShowResults(false);
        isFunction(onChange) && onChange(newSearchTerm);
    };

    const handleClear = handleEvent(onClear, () => {
        setSearchTerm('');
        setShowResults(false);
        handleSelectedPharmacy(null);
    });

    const clearResults = useCallback(() => {
        setPharmacyList([]);
        setTotal(0);
        setPage(1);
        setShowResults(false);
        handleSelectedPharmacy(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = useCallback(
        async ({
            searchTerm,
            signal,
            page,
            radiusMiles,
            zipCode,
            county,
            perPage
        }) => {
            if (tab === 0) {
                setLoading(true);
                setShowResults(false);
                setError(false);

                try {
                    const searchResults = await fetchResults({
                        searchTerm,
                        signal,
                        page,
                        zipCode,
                        radiusMiles,
                        county,
                        perPage
                    });

                    setError(false);
                    if (searchResults?.pharmacyList) {
                        const results = searchResults?.pharmacyList
                            ? searchResults?.pharmacyList
                            : [];

                        if (page > 1) {
                            setPharmacyList(previousData => [
                                ...previousData,
                                ...results
                            ]);
                        } else {
                            setPharmacyList(results);
                        }
                        setTotal(searchResults.totalCount);
                    } else {
                        clearResults();
                    }
                } catch (networkError) {
                    clearResults();
                    setError(true);
                }

                setLoading(false);
                setShowResults(true);
            }
        },
        [clearResults, fetchResults]
    );

    useEffect(() => {
        if (!firstLoad.current) {
            if (tab === 0 && showResults && pharmacyList?.length >= PER_PAGE) {
                if (page === 1) {
                    let element = document.getElementById(`showReached`);

                    element?.scrollIntoView() ||
                        document
                            .getElementById(`showMaxReached`)
                            ?.scrollIntoView();
                } else {
                    let element = document.getElementById(
                        `pharmacy-searchItem-${(page - 1) * PER_PAGE}`
                    );
                    element?.scrollIntoView();
                }
            } else if (
                tab === 1 &&
                showResults &&
                digitalPharmaciesPaginationData?.length >= PER_PAGE
            ) {
                if (page === 1) {
                    let element = document.getElementById(`online`);
                    element?.scrollIntoView();
                } else {
                    let element = document.getElementById(
                        `digital-pharmacy-searchItem-${(page - 1) * PER_PAGE}`
                    );
                    element?.scrollIntoView();
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showResults]);

    useEffect(() => {
        setPage(1);
        setShowResults(false);
    }, [radiusMiles, zipCode, county, tab]);

    useEffect(() => {
        const controller = new AbortController();
        if (tab === 0) {
            const signal = controller.signal;

            getData({
                searchTerm: debouncedSearchTerm,
                signal,
                page,
                radiusMiles,
                zipCode,
                county,
                perPage: PER_PAGE
            });
        } else if (digitalPharmacies && tab === 1) {
            setLoading(true);
            const results = digitalPharmacies?.slice(0, page * PER_PAGE);
            setDigitalPharmaciesPaginationData(results);
            setLoading(false);
            setShowResults(true);
        }
        return () => {
            controller.abort();
        };
        // this effect should only be rerun when the debounced Search Term changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm, page, radiusMiles, zipCode, county, tab]);

    const fetchDigitalPharmacies = async () => {
        const authToken = await getAuthToken();
        const results = await getDigitalPharmacies(authToken);

        results && setDigitalPharmacies(results?.pharmacyList);
        setDigitalPharmaciesTotal(results?.totalCount);
    };

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            setTab(0);
            fetchDigitalPharmacies();
        }
    }, []);

    useEffect(() => {
        if (tab === 0) {
            const controller = new AbortController();
            const signal = controller.signal;
            getData({
                county,
                page,
                perPage: PER_PAGE,
                radiusMiles,
                signal,
                zipCode
            });
            return () => {
                controller.abort();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const AntTab = styled(props => <Tab disableRipple {...props} />)(
        ({ theme }) => ({
            textTransform: 'none',
            '&.Mui-selected': {
                color: '#052a63',
                fontWeight: 700
            }
        })
    );

    return (
        <>
            {!loading && showResults && (
                <ul className={styles.resultsList}>
                    <div className={styles.pharmacyTabs}>
                        <Tabs
                            value={tab}
                            onChange={(e, value) => setTab(value)}
                            aria-label="Cost Tabs"
                            className={styles.costTabs}
                            variant="fullWidth"
                        >
                            <AntTab
                                id="physical"
                                icon={
                                    <Icon
                                        className={styles.iconSize}
                                        image={physical}
                                    />
                                }
                                iconPosition="start"
                                label="Physical"
                            />
                            <AntTab
                                id="online"
                                icon={
                                    <Icon
                                        className={styles.iconSize}
                                        image={online}
                                    />
                                }
                                iconPosition="start"
                                label="Online"
                            />
                        </Tabs>
                    </div>
                    {tab === 0 && (
                        <>
                            <ZipRadiusSection />
                            <Body2 className={styles.title} text={title} />

                            <TextField
                                className={styles.textField}
                                onChange={searchTermChanged}
                                defaultValue={defaultValue}
                                error={error}
                                variant="outlined"
                                inputProps={{
                                    maxLength: 100,
                                    type: 'search'
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <SearchOutlined
                                            className={styles.searchIcon}
                                        />
                                    )
                                }}
                                placeholder={placeholder}
                                value={searchTerm}
                                id="pharmacySearchInput"
                            />

                            {searchTerm && (
                                <ActionText
                                    className={styles.actionText}
                                    onClick={handleClear}
                                    id="cancelSearchButton"
                                >
                                    Cancel
                                    <span className={styles.hideOnMobile}>
                                        Search
                                    </span>
                                </ActionText>
                            )}

                            <div
                                className={styles.foundText}
                                id="foundPharmaciesCount"
                            >
                                <Heading5
                                    className={styles.heading5}
                                    text={text1}
                                />

                                <Text
                                    className={`${styles.text} ${styles.textItalic}`}
                                    text={text}
                                />
                            </div>

                            {loading ? <Spinner /> : null}

                            {!loading && infoMessage && (
                                <Text
                                    className={styles.infoMessage}
                                    text={infoMessage}
                                />
                            )}
                        </>
                    )}
                    {isFunction(resultMap) && tab === 0
                        ? pharmacyList?.map(resultMap)
                        : digitalPharmaciesPaginationData?.map(resultMap)}
                </ul>
            )}
            {!loading &&
                (tab === 0
                    ? total > pharmacyList?.length
                    : digitalPharmaciesTotal >
                      digitalPharmaciesPaginationData?.length) && (
                    <>
                        {(tab === 0
                            ? total > PER_PAGE
                            : digitalPharmaciesTotal > PER_PAGE) && (
                            <>
                                <div className={styles.divider} />
                                <IconWithLink
                                    caption={'Show More'}
                                    className={styles.iconWithLink}
                                    iconClassName={styles.iconClassName}
                                    image={arrow}
                                    isImageClickable={true}
                                    labelClassName={styles.labelClassName}
                                    onClick={() => setPage(page + 1)}
                                    isTextDisplayRight={false}
                                />
                            </>
                        )}
                    </>
                )}
        </>
    );
};

export default TypeAheadPharmacy;
