import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useClientServiceContext } from 'services/clientServiceProvider';

import { useAuth0 } from '@auth0/auth0-react';

import Button from 'components/Button';
import FeedbackCard from 'components/FeedbackCard';
import FirstNameTextField from 'components/FirstNameTextField';
import LastNameTextField from 'components/LastNameTextField';
import MedicareNumberTextField from 'components/MedicareNumberTextField';
import PageSpinner from 'components/PageSpinner';
import Text from 'components/Text';
import TrustedFormScript from 'components/TrustedFormScript';
import { setLegacyData } from 'components/YourLegacyModal/slice';
import useFetch from 'components/hooks/useFetch';

import { formatMCID } from 'utilities/maskMedicareId';
import { validateMedicalBeneficiaryId } from 'utilities/validate';

import styles from './styles.module.scss';

const LegacyForm = forwardRef(
    (
        {
            details,
            onCancel,
            updateDetails,
            from = '',
            noPlaceholder = false,
            medicareNumberPlaceholderText,
            footerButtonDisabled,
            cardHeaderClass,
            footerBlock = styles.footerBlock
        },
        ref
    ) => {
        const dispatch = useDispatch();
        const [loading, setLoading] = useState(false);
        const [message, setMessage] = useState(false);
        const [isDisable, setIsDisable] = useState(false);
        const { clientService } = useClientServiceContext();
        const { loginWithRedirect } = useAuth0();

        const getConsumerId = () => {
            const consumerid = clientService.getConsumerid();
            return consumerid;
        };
        const consumerid = getConsumerId();
        const { Post: updateLegacyDetails } = useFetch(
            `${
                import.meta.env.VITE_APP_PLANNING_TOOL_SERVICE_URL
            }/legacySafeguard`
        );

        const { firstName = '', lastName = '', uniqueId = '' } = details;

        const {
            register,
            handleSubmit,
            formState: { errors, isDirty },
            reset,
            setError,
            watch,
            setValue,
            clearErrors
        } = useForm({
            defaultValues: {
                firstName,
                lastName,
                medicareId: uniqueId && formatMCID(uniqueId)
            }
        });

        const watchMCIN = watch('medicareId');
        const watchAll = watch();

        useEffect(() => {
            const mciInput = document.getElementById('medicareId');
            let start = mciInput.selectionStart;
            watchMCIN && setValue('medicareId', formatMCID(watchMCIN));
            if (mciInput && watchMCIN?.length !== start)
                mciInput.setSelectionRange(start, start);
        }, [watchMCIN, setValue]);

        const isValid = () => {
            if (watchAll.firstName?.trim() === '') return false;
            if (watchAll.lastName?.trim() === '') return false;
            if (watchAll.medicareId?.trim() === '') return false;

            return true;
        };

        useEffect(() => {
            const state = isValid();
            setIsDisable(!state);
            footerButtonDisabled && footerButtonDisabled(!state);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [watchAll, validateMedicalBeneficiaryId, setIsDisable]);

        useEffect(() => {
            const defaultValue = {
                firstName,
                lastName,
                uniqueId
            };
            reset(defaultValue);
        }, [firstName, lastName, uniqueId, reset]);

        function formSubmit() {
            document.getElementById('modalSubmitButton').click();
        }

        useImperativeHandle(ref, () => ({
            formSubmit
        }));

        const validateUniqueId = uniqueId => {
            return !isNaN(uniqueId) && uniqueId.length === 12;
        };

        const updateProfile = async data => {
            setLoading(true);
            const state = validateUniqueId(data?.medicareId?.replace(/-/g, ''));
            if (!state) {
                setLoading(false);
                setError('medicareId', { type: 'pattern' });
                return;
            }
            if (isDisable) {
                setLoading(false);
                return false;
            }
            const payload = {
                consumerId: consumerid,
                firstName: data.firstName,
                lastName: data.lastName,
                uniqueId: data?.medicareId.replace(/-/g, ''),
                planningTool: 'Legacy Safeguard'
            };
            const response = await updateLegacyDetails(payload, true);

            if (response.status >= 200 && response.status < 300) {
                setLoading(false);
                setMessage('Your legacy info has been updated.');
                dispatch(
                    setLegacyData({
                        ...payload
                    })
                );
                updateDetails(payload);
            } else {
                setMessage('There is a problem updating your legacy');
                setLoading(false);
                if (response.status === 401) {
                    await loginWithRedirect();
                }
            }
            setLoading(false);
        };

        return (
            <>
                {from && (
                    <div className={cardHeaderClass}>Legacy Safeguard</div>
                )}
                <TrustedFormScript />

                <form
                    onSubmit={handleSubmit(data => updateProfile(data))}
                    className={styles.manageAccountForm}
                >
                    <div className={styles.infoCardDetails}>
                        {loading && <PageSpinner />}

                        {message.length > 0 && !isDirty ? (
                            <FeedbackCard
                                severity="success"
                                text={message}
                                onClose={() => setMessage('')}
                            />
                        ) : null}

                        <div>
                            {!from && (
                                <Text
                                    className={styles.medicareNameLabel}
                                    text="Name (as shown on Medicare ID card)"
                                />
                            )}

                            <div className={styles.namesBlock}>
                                <FirstNameTextField
                                    autoFocus
                                    defaultValue={firstName}
                                    error={errors.firstName}
                                    id="firstName"
                                    register={register}
                                    required
                                    labelText={from && 'First Name'}
                                    noPlaceholder={noPlaceholder}
                                />

                                <LastNameTextField
                                    defaultValue={lastName}
                                    error={errors.lastName}
                                    id="lastName"
                                    register={register}
                                    required
                                    labelText={from && 'Last Name'}
                                    noPlaceholder={noPlaceholder}
                                />
                            </div>
                        </div>

                        <div className={styles.divider} />

                        <MedicareNumberTextField
                            className={styles.mcTextField}
                            defaultValue={formatMCID(uniqueId)}
                            error={errors.medicareId}
                            id="medicareId"
                            labelText={`ID *`}
                            register={register}
                            required
                            placeholderText={medicareNumberPlaceholderText}
                            keyDown={() => clearErrors('medicareId')}
                        />
                    </div>

                    <div className={footerBlock}>
                        <Text
                            text={from ? '* Required fields.' : '* optional'}
                            className={styles.optionalLabel}
                        />
                        {!from ? (
                            <div className={styles.buttonsBlock}>
                                <Button
                                    className={styles.cancelButton}
                                    label="Cancel"
                                    onClick={onCancel}
                                />
                                <Button
                                    className={`${styles.submitButton} ${
                                        isDisable && styles.disable
                                    }`}
                                    label="Submit"
                                    type="submit"
                                />
                            </div>
                        ) : (
                            <Button
                                className={styles.modalSubmitButton}
                                id="modalSubmitButton"
                                label="Submit"
                                type="submit"
                            />
                        )}
                    </div>
                </form>
            </>
        );
    }
);
export default LegacyForm;
