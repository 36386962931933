import { createSlice } from '@reduxjs/toolkit';

export const quickProfileModalDetail = createSlice({
    name: 'quickProfileModalDetail',

    initialState: {
        selectedQuickProfileItem: null,
        targetPage: null
    },

    reducers: {
        setSelectedQuickProfileItem: (state, action) => {
            state.selectedQuickProfileItem = action.payload;
        },
        setTargetPage: (state, action) => {
            state.targetPage = action.payload;
        }
    }
});

export const { setSelectedQuickProfileItem, setTargetPage } =
    quickProfileModalDetail.actions;

export default quickProfileModalDetail.reducer;
