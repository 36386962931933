import { createContext } from 'react';

const zipcodeContext = createContext({
    buttonDisabled: true,
    zipcode: '',
    counties: [],
    county: '',
    state: '',
    continueButtonDisabled: true,
    setButtonDisabled() {},
    setContinueButtonDisabled() {},
    setCounty() {},
    setCounties() {},
    setState() {},
    carrierCount: 0,
    planCount: 0,
    setPlanDate() {}
});

export default zipcodeContext;
