import * as React from 'react';
import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';

import Step from '@mui/material/Step';
import StepConnector, {
    stepConnectorClasses
} from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';

// import { stepIconMap } from 'components/ConsumerGuidedExperienceModal/constants';
import {
    completeFinalExpenseStep,
    completeMedicareStep,
    setFinalExpenseCurrentStep,
    setFinalExpenseStepper,
    setGuidedProcess,
    setMedicareCurrentStep,
    setMedicareStepper,
    showStepper,
    skipFinalExpenseStep,
    skipMedicareStep
} from 'components/ConsumerGuidedExperienceModal/slice';
import {
    finalExpensePlanType,
    medicarePlanType
} from 'components/PlanTypeModal/constants';
import { setSelectedPlanType } from 'components/PlanTypeModal/slice';
import { setDoctorModal } from 'components/YourDoctorModal/slice';
import { setHealthConditionContentModal } from 'components/YourHealthConditionContentModal/slice';
import { setHealthInfoModal } from 'components/YourHealthInfoModal/slice';
import { setLocationModal } from 'components/YourLocationModal/slice';
import { setMedicaidModal } from 'components/YourMedicaidModal/slice';
import { setPharmacyModal } from 'components/YourPharmacyModal/slice';

import prescriptionModalContext from 'contexts/prescriptionModal';

import { PHARMACY_LIST_PAGE, ZIP_CODE_KEY } from 'utilities/storageKeys';

import DoctorIcon from './icons/doctor.js';
import HealthConditionsIcon from './icons/healthConditions.js';
import HealthInfoIcon from './icons/healthInfo.js';
import LocationIcon from './icons/location.js';
import MedicaidIcon from './icons/medicaid.js';
import PharmacyIcon from './icons/pharmacy.js';
import PrescriptionIcon from './icons/prescription.js';

import styles, { mobileWidth } from './styles.module.scss';

import { get } from 'temp/utilities/storage';

const stepIconMap = {
    location: LocationIcon,
    pharmacy: PharmacyIcon,
    doctor: DoctorIcon,
    prescription: PrescriptionIcon,
    medicaid: MedicaidIcon,
    healthInfo: HealthInfoIcon,
    healthConditions: HealthConditionsIcon
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    left: 'calc(-50% + 15px)',
    right: 'calc(50% + 15px)',
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 14 //22
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#1157ee'
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#1157ee'
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: '#a8c5ee',
        borderRadius: 1
    }
}));

const ColorlibStepIcon = ({
    step,
    currentStep,
    index,
    selectedPlanType,
    isProfilePage,
    selectedProfilePlanProgress
}) => {
    const { setPrescriptionModal } = React.useContext(prescriptionModalContext);
    const dispatch = useDispatch();
    const { isSkipped, isCompleted, icon, id, enabled, isVisited } = step;
    const zip = get(ZIP_CODE_KEY);
    const pharmacyNames = useSelector(
        state => state.pharmacyDetails.pharmacyNames
    );
    const doctorNames = useSelector(state => state.doctorDetails.doctorNames);
    const drugNames = useSelector(state => state.prescriptionDetails.drugNames);
    const selectedMedicaidOption = useSelector(
        state => state.medicaidDetails.selectedMedicaidOption
    );
    const selectedPrescriptions = get('selectedPrescriptions');
    const healthConditionsList = useSelector(
        state => state.healthIntakeDetails.healthConditionsList
    );
    const noHealthCondition = useSelector(
        state => state.healthIntakeDetails.noHealthCondition
    );
    const healthInfoData = useSelector(
        state => state.healthInfoDetails?.healthInfoData
    );
    const hasNoPrescriptions = useSelector(
        state => state.prescriptionDetails.hasNoPrescriptions
    );
    const pharmacyListPage = get(PHARMACY_LIST_PAGE, false);
    const Icon = stepIconMap[icon];

    const classes = `${styles.iconInactive} ${
        currentStep === index
            ? styles.iconActive
            : isCompleted
            ? styles.iconCompleted
            : !isVisited
            ? ''
            : isSkipped
            ? styles.iconSkipped
            : ''
    }`;

    const hideAllModals = () => {
        dispatch(setLocationModal(false));
        setPrescriptionModal(false);
        dispatch(setDoctorModal(false));
        dispatch(setPharmacyModal(false));
        dispatch(setMedicaidModal(false));
        dispatch(setHealthInfoModal(false));
        dispatch(setHealthConditionContentModal(false));
    };

    const checkData = () => {
        if (selectedPlanType === medicarePlanType) {
            zip
                ? dispatch(completeMedicareStep(0))
                : dispatch(skipMedicareStep(0));
            pharmacyNames.length > 0 || pharmacyListPage
                ? dispatch(completeMedicareStep(1))
                : dispatch(skipMedicareStep(1));
            doctorNames.length > 0
                ? dispatch(completeMedicareStep(2))
                : dispatch(skipMedicareStep(2));
            drugNames.length > 0 ||
            selectedPrescriptions.length > 0 ||
            hasNoPrescriptions
                ? dispatch(completeMedicareStep(3))
                : dispatch(skipMedicareStep(3));
            selectedMedicaidOption
                ? dispatch(completeMedicareStep(4))
                : dispatch(skipMedicareStep(4));
        }
        if (selectedPlanType === finalExpensePlanType) {
            zip
                ? dispatch(completeFinalExpenseStep(0))
                : dispatch(skipFinalExpenseStep(0));
            if (
                healthInfoData &&
                healthInfoData.gender &&
                healthInfoData.dateOfBirth &&
                healthInfoData.tobaccoUse
            ) {
                dispatch(completeFinalExpenseStep(1));
            } else dispatch(skipFinalExpenseStep(1));
            healthConditionsList.length > 0 || noHealthCondition
                ? dispatch(completeFinalExpenseStep(2))
                : dispatch(skipFinalExpenseStep(2));
        }
    };

    const onClick = () => {
        hideAllModals();
        if (isProfilePage) {
            dispatch(setGuidedProcess(true));
            dispatch(showStepper());
            if (selectedProfilePlanProgress === medicarePlanType) {
                dispatch(setMedicareStepper());
                dispatch(setMedicareCurrentStep(id));
                dispatch(setSelectedPlanType(medicarePlanType));
            } else {
                dispatch(setFinalExpenseStepper());
                dispatch(setFinalExpenseCurrentStep(id));
                dispatch(setSelectedPlanType(finalExpensePlanType));
            }
        } else {
            selectedPlanType === medicarePlanType
                ? dispatch(setMedicareCurrentStep(id))
                : dispatch(setFinalExpenseCurrentStep(id));
        }
        icon === 'prescription' && setPrescriptionModal(true);
        icon === 'location' && dispatch(setLocationModal(true));
        icon === 'pharmacy' && dispatch(setPharmacyModal(true));
        icon === 'doctor' && dispatch(setDoctorModal(true));
        icon === 'medicaid' && dispatch(setMedicaidModal(true));
        icon === 'healthInfo' && dispatch(setHealthInfoModal(true));
        icon === 'healthConditions' &&
            dispatch(setHealthConditionContentModal(true));
        checkData();
    };

    return (
        <div
            onClick={enabled ? onClick : () => {}}
            className={enabled ? styles.iconContainer : ''}
        >
            <Icon className={classes} id={icon} />
        </div>
    );
};

export const CustomizedSteppers = ({
    selectedPlanType,
    className = '',
    isProfilePage = false,
    selectedProfilePlanProgress
}) => {
    const [isMobile, setIsMobile] = React.useState(false);
    const medicareSteps = useSelector(
        state => state.guidedDetails.medicareSteps
    );
    const finalExpenseSteps = useSelector(
        state => state.guidedDetails.finalExpenseSteps
    );
    const currentMedicareStep = useSelector(
        state => state.guidedDetails.currentMedicareStep
    );
    const currentFinalExpenseStep = useSelector(
        state => state.guidedDetails.currentFinalExpenseStep
    );
    const steps =
        selectedPlanType === medicarePlanType
            ? medicareSteps
            : finalExpenseSteps;
    const currentStep =
        selectedPlanType === medicarePlanType
            ? currentMedicareStep
            : currentFinalExpenseStep;

    return (
        <>
            <Media
                queries={{
                    mobile: { maxWidth: mobileWidth }
                }}
                onChange={breakpoint => {
                    setIsMobile(breakpoint.mobile);
                }}
            />
            {!isMobile ? (
                <Stepper
                    activeStep={currentStep}
                    alternativeLabel
                    className={`${styles.progressContainer} ${className}`}
                    connector={<ColorlibConnector />}
                >
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel
                                className={styles.progressStepLabelIconGap}
                                StepIconComponent={() => (
                                    <ColorlibStepIcon
                                        currentStep={currentStep}
                                        index={index}
                                        isProfilePage={isProfilePage}
                                        selectedPlanType={selectedPlanType}
                                        step={step}
                                        selectedProfilePlanProgress={
                                            selectedProfilePlanProgress
                                        }
                                    />
                                )}
                            >
                                <span id={`label_${step.label}`}>
                                    {step.label}
                                </span>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            ) : (
                <div className={styles.stepper}>
                    {steps.map((step, index) => (
                        <>
                            {index !== 0 && (
                                <div
                                    className={`${styles.stepperLine} ${
                                        index <= currentStep
                                            ? styles.stepperLineActive
                                            : ''
                                    }`}
                                ></div>
                            )}
                            <ColorlibStepIcon
                                step={step}
                                index={index}
                                currentStep={currentStep}
                                isProfilePage={isProfilePage}
                                selectedPlanType={selectedPlanType}
                                selectedProfilePlanProgress={
                                    selectedProfilePlanProgress
                                }
                            />
                        </>
                    ))}
                </div>
            )}
        </>
    );
};
