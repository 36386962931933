import IconButton from 'components/IconButton';

import image from './image.svg';
import closeIcon from './whiteCloseIcon.svg';

const CloseIcon = ({
    className = '',
    onClick,
    onKeyDown,
    size,
    tabIndex = '',
    iconColor = null,
    id = 'closeIcon',
    iconClassName
}) => (
    <IconButton
        className={className}
        icon={iconColor ? closeIcon : image}
        onClick={onClick}
        onKeyDown={onKeyDown}
        size={size}
        tabIndex={tabIndex}
        id={id}
        iconClassName={iconClassName}
    />
);

export default CloseIcon;
