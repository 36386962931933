import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    finalExpensePlanType,
    medicarePlanType
} from 'components/PlanTypeModal/constants';

import {
    FINAL_EXPENSE_PLANS_ROUTE,
    PLANS_ROUTE,
    PROFILE_ROUTE
} from 'utilities/routes';
import {
    FINAL_EXPENSE_KEY,
    PHARMACY_LIST_PAGE,
    ZIP_CODE_KEY
} from 'utilities/storageKeys';

import {
    completeFinalExpenseStep,
    completeMedicareStep,
    enableAllFinalExpenseSteps,
    enableAllMedicareSteps,
    enableFinalExpenseStep,
    skipFinalExpenseStep,
    skipMedicareStep
} from './slice';

import { get } from 'temp/utilities/storage';

export const useStepperProgress = () => {
    const showStepper = useSelector(state => state.guidedDetails.showStepper);
    const allMedicareStepsEnabled = useSelector(
        state => state.guidedDetails.allMedicareStepsEnabled
    );
    const allFinalExpenseStepsEnabled = useSelector(
        state => state.guidedDetails.allFinalExpenseStepsEnabled
    );
    const selectedPlanType = useSelector(
        state => state.planTypeDetail.selectedPlanType
    );
    const dispatch = useDispatch();
    const pharmacyNames = useSelector(
        state => state.pharmacyDetails.pharmacyNames
    );
    const doctorNames = useSelector(state => state.doctorDetails.doctorNames);
    const drugNames = useSelector(state => state.prescriptionDetails.drugNames);
    const selectedMedicaidOption = useSelector(
        state => state.medicaidDetails.selectedMedicaidOption
    );
    const healthConditionsList = useSelector(
        state => state.healthIntakeDetails.healthConditionsList
    );
    const noHealthCondition = useSelector(
        state => state.healthIntakeDetails.noHealthCondition
    );
    const hasNoPrescriptions = useSelector(
        state => state.prescriptionDetails.hasNoPrescriptions
    );
    const healthInfoData = useSelector(
        state => state.healthInfoDetails?.healthInfoData
    );
    const zip = get(ZIP_CODE_KEY);
    const selectedPrescriptions = get('selectedPrescriptions');
    const pharmacyListPage = get(PHARMACY_LIST_PAGE, false);
    const isProfilePage = window.location.pathname === PROFILE_ROUTE;
    const isMedicarePlanPage = window.location.pathname === PLANS_ROUTE;
    const isFinalExpensePlanPage =
        window.location.pathname === FINAL_EXPENSE_PLANS_ROUTE;

    useEffect(() => {
        if (isProfilePage || isMedicarePlanPage || isFinalExpensePlanPage) {
            zip && dispatch(enableAllMedicareSteps());
            zip && dispatch(enableFinalExpenseStep(1));
            if (
                healthInfoData &&
                healthInfoData.gender &&
                healthInfoData.dateOfBirth &&
                healthInfoData.tobaccoUse
            ) {
                dispatch(enableAllFinalExpenseSteps());
            }
            zip
                ? dispatch(completeMedicareStep(0))
                : dispatch(skipMedicareStep(0));
            pharmacyNames?.length > 0 || pharmacyListPage
                ? dispatch(completeMedicareStep(1))
                : dispatch(skipMedicareStep(1));
            doctorNames?.length > 0
                ? dispatch(completeMedicareStep(2))
                : dispatch(skipMedicareStep(2));
            drugNames?.length > 0 ||
            selectedPrescriptions?.length > 0 ||
            hasNoPrescriptions
                ? dispatch(completeMedicareStep(3))
                : dispatch(skipMedicareStep(3));
            selectedMedicaidOption
                ? dispatch(completeMedicareStep(4))
                : dispatch(skipMedicareStep(4));
            zip
                ? dispatch(completeFinalExpenseStep(0))
                : dispatch(skipFinalExpenseStep(0));
            if (
                healthInfoData &&
                healthInfoData.gender &&
                healthInfoData.dateOfBirth &&
                healthInfoData.tobaccoUse
            ) {
                dispatch(completeFinalExpenseStep(1));
            } else dispatch(skipFinalExpenseStep(1));
            healthConditionsList?.length > 0 || noHealthCondition
                ? dispatch(completeFinalExpenseStep(2))
                : dispatch(skipFinalExpenseStep(2));
        }
        if (showStepper) {
            if (
                selectedPlanType === medicarePlanType &&
                !allMedicareStepsEnabled
            ) {
                zip && dispatch(enableAllMedicareSteps());
            }
            if (
                selectedPlanType === finalExpensePlanType &&
                !allFinalExpenseStepsEnabled
            ) {
                zip && dispatch(enableFinalExpenseStep(1));
                if (
                    healthInfoData &&
                    healthInfoData.gender &&
                    healthInfoData.dateOfBirth &&
                    healthInfoData.tobaccoUse
                ) {
                    dispatch(enableAllFinalExpenseSteps());
                }
            }

            if (selectedPlanType === medicarePlanType) {
                zip
                    ? dispatch(completeMedicareStep(0))
                    : dispatch(skipMedicareStep(0));
                pharmacyNames?.length > 0 || pharmacyListPage
                    ? dispatch(completeMedicareStep(1))
                    : dispatch(skipMedicareStep(1));
                doctorNames?.length > 0
                    ? dispatch(completeMedicareStep(2))
                    : dispatch(skipMedicareStep(2));
                drugNames?.length > 0 ||
                selectedPrescriptions?.length > 0 ||
                hasNoPrescriptions
                    ? dispatch(completeMedicareStep(3))
                    : dispatch(skipMedicareStep(3));
                selectedMedicaidOption
                    ? dispatch(completeMedicareStep(4))
                    : dispatch(skipMedicareStep(4));
            }
            if (selectedPlanType === finalExpensePlanType) {
                zip
                    ? dispatch(completeFinalExpenseStep(0))
                    : dispatch(skipFinalExpenseStep(0));
                if (
                    healthInfoData &&
                    healthInfoData.gender &&
                    healthInfoData.dateOfBirth &&
                    healthInfoData.tobaccoUse
                ) {
                    dispatch(completeFinalExpenseStep(1));
                } else dispatch(skipFinalExpenseStep(1));
                healthConditionsList?.length > 0 || noHealthCondition
                    ? dispatch(completeFinalExpenseStep(2))
                    : dispatch(skipFinalExpenseStep(2));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        showStepper,
        zip,
        healthConditionsList,
        noHealthCondition,
        pharmacyNames,
        doctorNames,
        drugNames,
        selectedPrescriptions,
        selectedMedicaidOption,
        healthInfoData,
        hasNoPrescriptions
    ]);
};
