import { createSlice } from '@reduxjs/toolkit';

import { FINAL_EXPENSE_KEY, MEDICAID_KEY } from 'utilities/storageKeys';

import { get } from 'temp/utilities/storage';

export const healthInfoDetails = createSlice({
    name: 'healthInfoDetails',

    initialState: {
        healthInfoModal: false,
        healthInfoData: get(FINAL_EXPENSE_KEY),
        selectedMedicaidOption: get(MEDICAID_KEY)
    },

    reducers: {
        setHealthInfoModal: (state, action) => {
            state.healthInfoModal = action.payload;
        },
        setSelectedMedicaidOption: (state, action) => {
            state.selectedMedicaidOption = action.payload;
        },
        setHealthInfoData: (state, action) => {
            state.healthInfoData = action.payload;
        }
    }
});

export const {
    setHealthInfoModal,
    setSelectedMedicaidOption,
    setHealthInfoData
} = healthInfoDetails.actions;

export default healthInfoDetails.reducer;
