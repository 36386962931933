import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Body3 from 'components/Body3';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { setSupplements } from 'components/WalletSupplementCard/slice';
import useFetch from 'components/hooks/useFetch';

import walletSupplementCard from './image.svg';

import styles from './styles.module.scss';

const WalletSupplementCard = ({
    mouseOver,
    walletID,
    mouseOverOut,
    cardClassName = styles.walletSupplementCard,
    openSupplementModal
}) => {
    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const { supplements } = useSelector(state => state.supplementCardDetails);
    const { consumerId } = userProfile;
    const { Get: getSupplements } = useFetch(
        `${
            import.meta.env.VITE_APP_SUPPLEMENTS_SERVICE_URL
        }/GetSupplements/${consumerId}`
    );

    useEffect(() => {
        const init = async () => {
            const response = await getSupplements();
            if (response) dispatch(setSupplements(response));
        };
        if (consumerId) init();
    }, [consumerId]);

    const showData = () => {
        document
            .getElementById('supplementContent')
            ?.setAttribute('style', 'display:flex');
    };

    return (
        <div
            className={cardClassName}
            id="walletSupplementCard"
            onClick={e =>
                supplements?.length &&
                walletID === 'walletSupplementCard' &&
                openSupplementModal()
            }
        >
            <Icon
                id={'walletSupplementCardIcon'}
                image={walletSupplementCard}
                onClick={() => {
                    walletID !== 'walletSupplementCard'
                        ? mouseOver('walletSupplementCard')
                        : supplements?.length && openSupplementModal();
                    walletID !== 'walletSupplementCard' &&
                        setTimeout(function () {
                            showData();
                        }, 50);
                }}
                className={styles.supplementIcon}
            />

            <div className={styles.supplementContent} id="supplementContent">
                {!supplements?.length ? (
                    <Button
                        label="Add Supplement"
                        className={styles.addSupplementBtn}
                        onClick={e =>
                            walletID === 'walletSupplementCard' &&
                            openSupplementModal()
                        }
                    />
                ) : (
                    <div className={styles.supplementList}>
                        {supplements.map(supplement => {
                            return (
                                <div className={styles.supplementListItem}>
                                    <Body3
                                        className={styles.supplementName}
                                        text={supplement?.supplementName}
                                    />
                                    <Body3
                                        className={styles.dosage}
                                        text={`${supplement?.dosage} ${supplement?.frequency}`}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default WalletSupplementCard;
