import { closeIconAlt, hamburgerAlt } from 'utilities/altText';
import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const handleClick = onClick => handleEvent(onClick);

const HamburgerIcon = ({ className = '', isOpen, onClick }) => (
    <div
        alt={isOpen ? closeIconAlt : hamburgerAlt}
        className={`${styles.hamburgerIcon} ${className} ${
            isOpen ? styles.open : ''
        }`}
        onClick={handleClick(onClick)}
        role="img"
    >
        {[...Array(3)].map((item, index) => (
            <span className={styles.line} key={`${item}-${index}`}></span>
        ))}
    </div>
);
export default HamburgerIcon;
