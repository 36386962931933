import EditLink from 'components/EditLink';
import Heading5 from 'components/Heading5';
import Icon from 'components/Icon';
import Text from 'components/Text';

import OutofNetwork_Icon from 'temp/components/PrescriptionsTable/icons/OutofNetwork_Icon.svg';
import greenCheckImage from 'temp/components/PrescriptionsTable/icons/greenCheckImage.svg';

import styles from './styles.module.scss';

import drugFrequencyOptions from 'temp/utilities/drugFrequencyOptions';

const frequencyValue = daysOfSupply =>
    drugFrequencyOptions.find(frequency => frequency?.value === daysOfSupply)
        ?.text;

const frequencyMessage = ({ dosage, dosageDetails }) => {
    const { daysOfSupply, metricQuantity, selectedPackage } = dosage;

    return selectedPackage
        ? `${dosage?.userQuantity} X ${
              selectedPackage?.packageDisplayText
          } ${frequencyValue(daysOfSupply)}`
        : dosageDetails
        ? `${metricQuantity} ${dosageDetails.dosageFormName.toLowerCase()}${
              metricQuantity > 1 ? 's' : ''
          } ${frequencyValue(daysOfSupply)}`
        : '';
};

const PrescriptionListItem = ({
    dosage,
    dosageDetails,
    inNetworkIcon,
    inOutNetwork,
    selectDrug
}) => {
    const CoveredCheck = (
        <Icon className={styles.checkIcon} image={greenCheckImage} />
    );
    const NotCoveredCheck = (
        <Icon className={styles.checkIcon} image={OutofNetwork_Icon} />
    );
    return (
        <div
            className={`${styles.drugListItem} ${
                inNetworkIcon ? styles.networkIcon : ''
            }`}
        >
            {inNetworkIcon && (inOutNetwork ? CoveredCheck : NotCoveredCheck)}
            <div className={styles.addressContainer}>
                <EditLink
                    className={styles.right}
                    onClick={() => selectDrug(dosage)}
                    text="Edit"
                />
                <Text
                    className={styles.text}
                    text={dosage?.drugType}
                    id={dosage?.drugType}
                />
                <Heading5
                    className={styles.drugName}
                    text={dosage?.drugName}
                    id={dosage?.drugName}
                />
                <Text
                    className={styles.text}
                    text={frequencyMessage({ dosage, dosageDetails })}
                    id={`${dosage?.drugName}_dosageDetails`}
                />
            </div>
        </div>
    );
};

export default PrescriptionListItem;
