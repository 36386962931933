import { TextField as TextFieldUI } from '@mui/material';

import Body2 from 'components/Body2';
import Body2DMSans from 'components/Body2DMSans';

import { placeholder, errorMessage, invalidPatternError } from './data';

import styles from './styles.module.scss';

const MedicareNumberTextField = ({
    autoFocus,
    className,
    defaultValue,
    error,
    id,
    labelText,
    register,
    required,
    labelClassName = styles.labelClassName,
    placeholderText = placeholder,
    keyDown = e => {}
}) => {
    return (
        <div className={styles.container}>
            <div className={`${styles.textFieldWithLabel}`}>
                <Body2DMSans
                    className={labelClassName}
                    htmlFor={id}
                    text={labelText}
                />

                <TextFieldUI
                    autoFocus={autoFocus}
                    className={className}
                    defaultValue={defaultValue}
                    error={error}
                    fullWidth
                    id={id}
                    placeholder={placeholderText}
                    size="small"
                    type="text"
                    variant="outlined"
                    {...register(id, { required })}
                    onKeyDown={keyDown}
                    onKeyUp={keyDown}
                />
            </div>

            {error?.type === 'required' && (
                <Body2 text={errorMessage} className={`${styles.errorText}`} />
            )}

            {error?.type === 'pattern' && (
                <Body2
                    className={`${styles.errorText}`}
                    text={invalidPatternError + ' ' + labelText}
                />
            )}
        </div>
    );
};

export default MedicareNumberTextField;
