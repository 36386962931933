import { useState } from 'react';

import Icon from 'components/Icon';
import CloseIcon from 'components/icons/CloseIcon';

import image from './image.svg';

import styles from './styles.module.scss';

const Tooltip = ({
    className,
    tooltipContentClassName,
    title,
    content,
    width = 120,
    isClickable = false,
    position = 'bottom',
    isClose = false
}) => {
    const [toggle, setToggle] = useState(false);
    const props = isClickable
        ? { visibility: isClickable && toggle ? 'visible' : 'hidden' }
        : {};
    return (
        <div
            className={`${styles.tooltip} ${styles[position]} ${className} ${
                !isClickable && styles.hover
            }`}
        >
            {title ? (
                title
            ) : (
                <Icon
                    onMouseEnter={() => !isClickable && setToggle(!toggle)}
                    onClick={() => isClickable && setToggle(!toggle)}
                    className={styles.infoIcon}
                    image={image}
                    id="informationIcon"
                />
            )}

            <div
                style={{
                    width: `${width}px`,
                    marginLeft: `-${width / 2}px`,
                    ...props
                }}
                className={`${styles.tooltiptext} ${tooltipContentClassName}`}
            >
                {isClose && (
                    <CloseIcon
                        iconClassName={styles.iconClassName}
                        iconColor
                        className={styles.closeIcon}
                        onClick={() => setToggle(false)}
                    />
                )}
                {content}
            </div>
        </div>
    );
};

export default Tooltip;
