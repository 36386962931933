import { createClient } from '@sanity/client';
import {
    SANITY_CLIENT_PROJECT_ID,
    SANITY_CLIENT_PROJECT_ENVIRONMENT
} from 'utilities/env';

export const sanityClient = createClient({
    projectId: SANITY_CLIENT_PROJECT_ID,
    dataset: SANITY_CLIENT_PROJECT_ENVIRONMENT,
    useCdn: true,
    apiVersion: '2024-10-22'
});

export default sanityClient;
