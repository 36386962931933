import { FocusTrap } from '@mui/base';

import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const Overlay = ({ children, onClick }) => {
    const handleClick = onClick => handleEvent(onClick);

    return (
        <FocusTrap open={true} disableEnforceFocus>
            <div
                tabIndex={-1}
                className={styles.overlay}
                onClick={handleClick(onClick)}
            >
                {children}
            </div>
        </FocusTrap>
    );
};

export default Overlay;
