import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Icon from 'components/Icon';
import Text from 'components/Text';

import { formatMCIN } from 'utilities/maskMedicareId';

import emptyMedicareCardIcon from './emptyMedicareCard.svg';
import medicareCardIcon from './image.svg';

import styles from './styles.module.scss';

const WalletInsuranceCard = ({
    cardClassName = styles.walletInsuranceCard,
    mouseOver,
    openHealthInsuranceModal,
    walletID
}) => {
    const [partA, setPartA] = useState('');
    const [partB, setPartB] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [medicareIdNumber, setMedicareIdNumber] = useState('');
    const userProfile = useSelector(state => state.userProfile.userProfile);

    const showData = () => {
        document
            .getElementById('mInsuranceName')
            ?.setAttribute('style', 'display:block');
        document
            .getElementById('mInsuranceNumber')
            ?.setAttribute('style', 'display:block');
        document
            .getElementById('mInsurancePartA')
            ?.setAttribute('style', 'display:block');
        document
            .getElementById('mInsurancePartB')
            ?.setAttribute('style', 'display:block');
    };

    useEffect(() => {
        const {
            firstName,
            lastName,
            mbiFirstName,
            mbiLastName,
            mbi,
            partAEffectiveDate,
            partBEffectiveDate
        } = userProfile;
        if (userProfile && Object.keys(userProfile).length) {
            setFirstName(mbiFirstName || firstName);
            setLastName(mbiLastName || lastName);
            setMedicareIdNumber(formatMCIN(mbi));
            setPartB(
                partBEffectiveDate
                    ? moment(partBEffectiveDate).format('MM-DD-YYYY')
                    : ''
            );
            setPartA(
                partAEffectiveDate
                    ? moment(partAEffectiveDate).format('MM-DD-YYYY')
                    : ''
            );
        }
    }, [userProfile]);

    return (
        <div
            className={cardClassName}
            id="medicareCard"
            onClick={() =>
                walletID === 'medicareCard' &&
                openHealthInsuranceModal(medicareCardIcon)
            }
        >
            {medicareIdNumber ? (
                <>
                    <Icon
                        id={'medicareCardIcon'}
                        image={medicareCardIcon}
                        onClick={() => {
                            mouseOver('medicareCard');
                            setTimeout(function () {
                                showData();
                            }, 100);
                        }}
                    />
                    <Text
                        text={`${
                            firstName || lastName
                                ? firstName + ' ' + lastName
                                : 'YOUR NAME'
                        }`}
                        className={styles.name}
                        id="mInsuranceName"
                        mouseOver={() => {
                            mouseOver('medicareCard');
                            setTimeout(function () {
                                showData();
                            }, 150);
                        }}
                    />
                    <Text
                        text={medicareIdNumber || '000-000-000XXX'}
                        className={styles.medicareID}
                        id="mInsuranceNumber"
                        onClick={() => {
                            mouseOver('medicareCard');
                            setTimeout(function () {
                                showData();
                            }, 150);
                        }}
                    />
                    <Text
                        text={partA || 'MM-DD-YYYY'}
                        className={styles.partA}
                        id="mInsurancePartA"
                        onClick={() => {
                            mouseOver('medicareCard');
                            setTimeout(function () {
                                showData();
                            }, 150);
                        }}
                    />
                    <Text
                        text={partB || 'MM-DD-YYYY'}
                        className={styles.partB}
                        id="mInsurancePartB"
                        onClick={() => {
                            mouseOver('medicareCard');
                            setTimeout(function () {
                                showData();
                            }, 150);
                        }}
                    />
                </>
            ) : (
                <Icon
                    id={'medicareCardIcon'}
                    image={emptyMedicareCardIcon}
                    onClick={() => {
                        mouseOver('medicareCard');
                        setTimeout(function () {
                            showData();
                        }, 100);
                    }}
                    className={styles.medicareIcon}
                />
            )}
        </div>
    );
};

export default WalletInsuranceCard;
