import { useState, useMemo, useEffect } from 'react';

import AddressWithCheckBox from 'components/AddressWithCheckBox';
import AddressWithInOrOutNetworkIcon from 'components/AddressWithInOrOutNetworkIcon';
import ArrowDownIcon from 'components/ArrowDownIcon';
import Body2 from 'components/Body2';

import styles from './styles.module.scss';

const AdditionalAddress = ({
    addresses = [],
    inNetworkIcon = false,
    onClick,
    selectedAddresses = [],
    type = '',
    maxReached = false
}) => {
    const [init, setInit] = useState(false);
    const [selected, setSelected] = useState([]);
    const [open, setOpen] = useState(true);
    const addressCount = addresses.length - 1;
    const primaryAddress = addresses.length > 0 && addresses[0];

    const handleSelect = (isChecked, id) => {
        let filterAddressess = [];
        if (isChecked) filterAddressess = [...selected, id];
        else {
            const filterSelected = selected.filter(
                addressId => addressId !== id
            );
            filterAddressess = filterSelected;
        }
        setSelected([...filterAddressess]);
        onClick(filterAddressess);
    };

    useEffect(() => {
        if (!init && selectedAddresses) {
            setSelected(selectedAddresses);
            setInit(true);
        }
    }, [selectedAddresses, init]);

    const addressBlock = (address, isPrimary = false) => {
        const { id } = address;
        return type === 'added' ? (
            <AddressWithInOrOutNetworkIcon
                address={address}
                key={id}
                inline={false}
                className={isPrimary ? styles.primary : styles.addressInline}
                inNetworkIcon={inNetworkIcon}
            />
        ) : (
            <AddressWithCheckBox
                address={address}
                key={id}
                handleSelect={handleSelect}
                isChecked={selectedAddresses.includes(address.id)}
                maxReached={maxReached}
            />
        );
    };

    const additionalAddressText = useMemo(() => {
        return (
            addressCount > 0 && (
                <div
                    className={styles.additionalAddressLabel}
                    onClick={() => setOpen(!open)}
                    id="additionalAddressLabel"
                >
                    <div
                        className={`${styles.rotate} ${
                            !open ? styles.active : ''
                        }`}
                    >
                        <ArrowDownIcon />
                    </div>
                    <Body2
                        className={styles.addtionalAddress}
                        text={`Additional Locations (${addressCount})`}
                    />
                </div>
            )
        );
    }, [addressCount, open]);

    return (
        <>
            {primaryAddress && addressBlock(primaryAddress, true)}
            {additionalAddressText}
            {open && (
                <div className={styles.additionalAddressContainer}>
                    {addresses
                        .filter((_, i) => i)
                        .map(address => addressBlock(address))}
                </div>
            )}
        </>
    );
};

export default AdditionalAddress;
