import FooterListItem from 'components/FooterListItem';
import FooterListTitleItem from 'components/FooterListTitleItem';

import styles from './styles.module.scss';

const FooterList = ({ className, items, onClick, title }) => {
    return (
        <nav className={`${styles.footerList} ${className} `}>
            <ul>
                <FooterListTitleItem
                    className={styles.footerListItem}
                    onClick={onClick}
                    text={title}
                />

                {items.map(
                    (
                        {
                            externalURL = false,
                            lines1Text = '',
                            lines2Text = '',
                            link,
                            text,
                            twoLines = false
                        },
                        index
                    ) => (
                        <FooterListItem
                            className={styles.footerListItem}
                            externalURL={externalURL}
                            key={index}
                            lines1Text={lines1Text}
                            lines2Text={lines2Text}
                            link={link}
                            onClick={onClick}
                            text={text}
                            twoLines={twoLines}
                        />
                    )
                )}
            </ul>
        </nav>
    );
};

export default FooterList;
