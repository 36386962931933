import { createSlice } from '@reduxjs/toolkit';

import { DRUG_NAMES_KEY, PRESCRIPTION_NO_PRESCRIPTIONS } from 'utilities/storageKeys';

import { get, set } from 'temp/utilities/storage';

export const prescriptionDetails = createSlice({
    name: 'prescriptionDetails',

    initialState: {
        drugNames: get(DRUG_NAMES_KEY, []),
        isApiFailed: false,
        selectedDrugId: 0,
        isEdit: 0,
        selectedPlan: null,
        hasNoPrescriptions: get(PRESCRIPTION_NO_PRESCRIPTIONS, false)
    },

    reducers: {
        setDrugNames: (state, action) => {
            state.drugNames = action.payload;
        },
        setSelectedDrugId: (state, action) => {
            state.selectedDrugId = action.payload;
        },
        setIsEdit: (state, action) => {
            state.isEdit = action.payload;
        },
        setSelectedPlan: (state, action) => {
            state.selectedPlan = action.payload;
        },
        setIsApiFailed: (state, action) => {
            state.isApiFailed = action.payload;
        },
        setHasNoPrescriptions: (state, action) => 
        {
            set(PRESCRIPTION_NO_PRESCRIPTIONS, action.payload);
            state.hasNoPrescriptions = action.payload;
        },
    }
});

export const {
    setDrugNames,
    setIsApiFailed,
    setSelectedDrugId,
    setIsEdit,
    setSelectedPlan,
    setHasNoPrescriptions
} = prescriptionDetails.actions;

export default prescriptionDetails.reducer;
