import Heading3 from 'components/Heading3';
import Text from 'components/Text';

import styles from './styles.module.scss';

const PolicyDetails = ({ value, label, className }) => (
    <div className={styles.policyId}>
        <Heading3
            className={`${className} ${styles.label}`}
            text={`${label}:`}
        />
        <Text className={styles.value}>{value}</Text>
    </div>
);
export default PolicyDetails;
