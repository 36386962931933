import styles from './styles.module.scss';

const Radio = ({
    className = '',
    disabled = false,
    isChecked = false,
    id = '',
    name = '',
    onChange,
    value = ''
}) => (
    <input
        className={`${className} ${styles.radio}`}
        defaultChecked={isChecked}
        checked={isChecked}
        id={id}
        name={name}
        disabled={disabled}
        onChange={onChange}
        type="radio"
        value={value}
    />
);

export default Radio;
