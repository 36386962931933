import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';

import {
    finalExpensePlanProductType,
    finalExpensePlanType,
    medicarePlanProductType
} from 'components/PlanTypeModal/constants';

import purlAgentContext from 'contexts/purlAgentContext';

export const getProductTypeByPlanType = planType => {
    if (planType === finalExpensePlanType) return finalExpensePlanProductType;
    return medicarePlanProductType;
};

export const usePurlBanner = () => {
    const { isAuthenticated } = useAuth0();
    const { purlAgentData } = useContext(purlAgentContext);
    const purlAgents = useSelector(state => state.mainDetails.purlAgents);

    const HasMedicareAgentData = () => {
        if (isAuthenticated) {
            const medicarePurlAgent = purlAgents.find(
                pa => pa.productType === medicarePlanProductType
            );
            if (medicarePurlAgent) return true;
        } else if (purlAgentData && purlAgentData?.isMedicareAgent) {
            return true;
        }
        return false;
    };

    const HasFinalExpenseAgentData = () => {
        if (isAuthenticated) {
            const finalExpensePurlAgent = purlAgents.find(
                pa => pa.productType === finalExpensePlanProductType
            );
            if (finalExpensePurlAgent) return true;
        } else if (purlAgentData && purlAgentData?.isFinalExpenseAgent) {
            return true;
        }
        return false;
    };

    const HasBothAgentData = () => {
        if (HasMedicareAgentData() && HasFinalExpenseAgentData()) return true;
        return false;
    };

    const HasNoAgentData = () => {
        if (!HasMedicareAgentData() && !HasFinalExpenseAgentData()) return true;
        return false;
    };

    const HasAnyAgentData = () => {
        if (HasMedicareAgentData() || HasFinalExpenseAgentData()) return true;
        return false;
    };

    return {
        HasAnyAgentData,
        HasBothAgentData,
        HasFinalExpenseAgentData,
        HasMedicareAgentData,
        HasNoAgentData
    };
};
