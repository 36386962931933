import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const Heading5 = ({ className = '', text, onClick, id = 'heading' }) => {
    const handleClick = onClick => handleEvent(onClick);
    return (
        <h5
            className={`${styles.heading5} ${className}`}
            onClick={handleClick(onClick)}
            id={id}
        >
            {text}
        </h5>
    );
};

export default Heading5;
