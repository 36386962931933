import DrugListItem from '../DrugListItem';
import { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import Heading5 from 'components/Heading5';
import Heading6 from 'components/Heading6';
import Spinner from 'components/Spinner';
import Text from 'components/Text';

import styles from './styles.module.scss';

import Select from 'temp/components/MuiSelect';
import TextField from 'temp/components/MuiTextField';
import { drugDetailSearch } from 'temp/utilities/apiSearch';
import drugFrequencyOptions from 'temp/utilities/drugFrequencyOptions';
import prescriptionDTO from 'temp/utilities/prescriptionDTO';

const onClick =
    ({ removePrescription, drug, setLoading }) =>
    async () => {
        setLoading(true);
        await removePrescription({ id: drug?.id });
        setLoading(false);
    };

const DrugDetails = ({
    drug,
    isEdit,
    onClose,
    removePrescription,
    setPrescription,
    validate
}) => {
    const defaultDosage =
        drug?.dosages &&
        (isEdit
            ? drug.dosages.filter(
                  ({ dosageID }) => dosageID === drug.dosageID
              )[0]
            : drug?.dosages[0]);

    const defaultFrequency = isEdit
        ? drug?.daysOfSupply
        : defaultDosage?.commonDaysOfSupply;
    const defaultDrugPackages =
        defaultDosage?.packages && defaultDosage?.packages[0];
    const defaultQuantity =
        parseInt(defaultDosage?.commonUserQuantity, 10) || '';
    const [selectedGenericDrug, setSelectedGenericDrug] = useState(false);

    const [dosage, setDosage] = useState(defaultDosage);
    const [quantity, setQuantity] = useState(defaultQuantity);
    const [frequency, setFrequency] = useState(defaultFrequency);
    const [drugPackage, setDrugPackage] = useState(defaultDrugPackages);

    const [dosageOptions, setDosageOptions] = useState([]);
    const [packageOptions, setPackageOptions] = useState([]);
    const [selectedDrug, setSelectedDrug] = useState(null);
    const [loading, setLoading] = useState(false);
    const { user, getAccessTokenSilently } = useAuth0();
    const getAuthToken = async () => {
        return user ? await getAccessTokenSilently() : '';
    };

    const loadDefaults = drug => {
        const defaultDosage = drug?.dosages && drug?.dosages[0];

        const defaultFrequency = defaultDosage?.commonDaysOfSupply;
        const defaultDrugPackages =
            defaultDosage?.packages && defaultDosage?.packages[0];
        const defaultQuantity =
            parseInt(defaultDosage?.commonUserQuantity, 10) || '';
        setDosageOptions(drug?.dosages);
        setPackageOptions(defaultDosage?.packages);
        setDosage(defaultDosage);
        setQuantity(defaultQuantity);
        setFrequency(defaultFrequency);
        setDrugPackage(defaultDrugPackages);
    };

    useEffect(() => {
        let defaultDrug = selectedGenericDrug ? selectedDrug : drug;
        if (defaultDrug && !selectedGenericDrug) {
            const defaultDosage =
                defaultDrug?.dosages &&
                (isEdit
                    ? defaultDrug.dosages.filter(
                          ({ dosageID }) => dosageID === defaultDrug.dosageID
                      )[0]
                    : defaultDrug?.dosages[0]);
            const defaultFrequency = isEdit
                ? defaultDrug?.daysOfSupply
                : defaultDosage?.commonDaysOfSupply;
            let defaultDrugPackages =
                defaultDosage?.packages?.length &&
                (isEdit && defaultDrug.selectedPackageId
                    ? defaultDrug.packages.filter(
                          ({ packageId }) =>
                              packageId === defaultDrug.selectedPackageId
                      )
                    : defaultDosage?.packages);
            defaultDrugPackages =
                isEdit && defaultDrugPackages?.length
                    ? defaultDrugPackages[0]
                    : defaultDosage?.packages && defaultDosage?.packages[0];

            const defaultQuantity =
                parseInt(
                    isEdit
                        ? defaultDrug?.userQuantity
                        : defaultDosage?.commonUserQuantity,
                    10
                ) || '';

            setDosageOptions(defaultDrug?.dosages);
            setPackageOptions(defaultDosage?.packages);
            setDosage(defaultDosage);
            setQuantity(defaultQuantity);
            setFrequency(defaultFrequency);
            setDrugPackage(defaultDrugPackages);
        } else {
            loadDefaults(defaultDrug);
        }
    }, [selectedGenericDrug, selectedDrug, drug, isEdit]);

    useEffect(() => {
        let defaultDrug = selectedGenericDrug ? selectedDrug : drug;
        const isInvalid = !defaultDrug || quantity < 1;
        validate(isInvalid);

        if (!isInvalid) {
            setPrescription(
                prescriptionDTO({
                    dosage,
                    drug: defaultDrug,
                    drugPackage,
                    frequency,
                    quantity
                })
            );
        }
    }, [
        dosage,
        drug,
        selectedDrug,
        selectedGenericDrug,
        drugPackage,
        frequency,
        quantity,
        setPrescription,
        validate
    ]);

    const onDosageChange = e => {
        const value = e?.target?.value;

        setDosage(value);
        setPackageOptions(value?.packages);
        setDrugPackage(value?.packages && value?.packages[0]);
    };

    const onFrequencyChange = e => {
        const value = e?.target?.value;

        setFrequency(value);
    };

    const onDrugPackageChange = e => {
        const value = e?.target?.value;

        setDrugPackage(value);
    };

    const onQuantityChange = e => {
        const value = parseInt(e?.target?.value, 10) || 0;

        setQuantity(value);
    };

    const selectDrug = async id => {
        const authToken = await getAuthToken();
        const drugDetails = await drugDetailSearch({ id, authToken });
        setSelectedDrug(drugDetails);
    };

    return (
        <>
            <div className={styles.drugDetailsTitle}>
                <DrugListItem
                    checked={!selectedGenericDrug}
                    isRadio
                    key={drug?.drugID}
                    name={drug?.drugName}
                    onClick={(e, value) => {
                        e.preventDefault();
                        selectDrug(value);
                        setSelectedGenericDrug(false);
                    }}
                    type={drug?.drugType}
                    value={drug?.drugID}
                    drugDetailsClass={styles.selectDrugDetails}
                />

                {drug?.drugType === 'Brand' && Boolean(drug?.genericDrugID) && (
                    <div className={styles.genericContainer}>
                        <div className={styles.titleBlock}>
                            <Heading5
                                className={styles.genericTitleText}
                                text="Would you like to use the Generic Version?"
                                id="genericTitleText"
                            />
                            <Text
                                className={styles.genericDescription}
                                text="According to the FDA, this generic drug has the same quality, strength, safety and active ingredient as the brand name drug."
                                id="genericDescription"
                            />
                        </div>

                        <DrugListItem
                            checked={selectedGenericDrug}
                            isRadio
                            key={drug?.genericDrugID}
                            name={drug?.genericDrugName}
                            onClick={(e, value) => {
                                e.preventDefault();
                                selectDrug(value);
                                setSelectedGenericDrug(true);
                            }}
                            type={drug?.genericDrugType}
                            value={drug?.genericDrugID}
                            drugDetailsClass={styles.selectDrugDetails}
                        />
                    </div>
                )}
            </div>
            <div className={styles.drugDetails}>
                <Select
                    className={styles.dosage}
                    data={dosageOptions}
                    onChange={onDosageChange}
                    selectClassName={styles.selectClassName}
                    textKey="labelName"
                    title="Dosage"
                    value={dosage}
                />

                <div className={styles.quantityFrequency}>
                    <TextField
                        className={styles.quantity}
                        textFieldClassName={styles.textFieldClassName}
                        defaultValue={quantity}
                        isPadding={false}
                        maxLength={4}
                        min={0}
                        minPadding={true}
                        onChange={onQuantityChange}
                        title="Quantity"
                    />

                    <Select
                        className={styles.frequency}
                        data={drugFrequencyOptions}
                        onChange={onFrequencyChange}
                        selectClassName={styles.selectClassName}
                        title="Frequency"
                        value={frequency}
                        valueKey="value"
                    />
                </div>

                {packageOptions?.length > 0 && (
                    <Select
                        className={styles.packages}
                        data={packageOptions.map(pack => ({
                            text: `${pack?.packageQuantity} x ${pack?.packageSize}${pack?.packageSizeUnitOfMeasure} ${pack?.packageDescription}`,
                            value: pack
                        }))}
                        onChange={onDrugPackageChange}
                        selectClassName={styles.selectClassName}
                        title="Package"
                        value={drugPackage}
                        valueKey="value"
                    />
                )}
            </div>
            {loading ? (
                <Spinner />
            ) : (
                Boolean(isEdit) && (
                    <div className={styles.btnContainer}>
                        <Heading6
                            className={styles.removePrescription}
                            onClick={onClick({
                                removePrescription,
                                drug,
                                setLoading
                            })}
                            text="Remove Prescription"
                            id="removePrescriptionLink"
                        />
                    </div>
                )
            )}
        </>
    );
};

export default DrugDetails;
