import { createSlice } from '@reduxjs/toolkit';

export const legacyModalDetails = createSlice({
    name: 'legacyModalDetails',

    initialState: {
        legacyModal: false,
        legacyData: {
            firstName: '',
            lastName: '',
            name: '',
            uniqueId: ''
        },
        showForm: false
    },

    reducers: {
        setLegacyModal: (state, action) => {
            state.legacyModal = action.payload;
        },
        setLegacyData: (state, action) => {
            state.legacyData = action.payload;
        },
        setShowForm: (state, action) => {
            state.showForm = action.payload;
        }
    }
});

export const { setLegacyModal, setLegacyData, setShowForm } =
    legacyModalDetails.actions;

export default legacyModalDetails.reducer;
