import * as Sentry from '@sentry/react';

import deleteJSON from 'utilities/deleteJSON';
import { API_BASE_URL, CONSUMER_SERVICE_URL } from 'utilities/env';
import getJSON from 'utilities/getJSON';
import { getAgentNpn } from 'utilities/getSessionData';
import postJSON from 'utilities/postJSON';

import { getLeadId } from 'temp/utilities/apiSession/leadId';

const getUrl = () => {
    const leadId = getLeadId();
    return `${API_BASE_URL}/Lead/${leadId}/Provider`;
};

const getProvidersSearchLookUp = async (authToken = '') => {
    const url = getUrl() + '/ProviderSearchLookup';
    try {
        return await getJSON({ url, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const addProvider = async ({
    addressId,
    isPrimary,
    npi,
    syncId,
    authToken
}) => {
    const body = [{ addressId, isPrimary, npi }];
    let url = getUrl();
    if (syncId) url = `${url}/${syncId}`;
    try {
        return await postJSON({ url, body, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const addCoverages = async ({ coverages, consumerId, email, authToken }) => {
    let url = `${CONSUMER_SERVICE_URL}/lead/coverage`;
    const agentNpn = getAgentNpn();
    const leadId = getLeadId();
    const body = {
        agentNpn: agentNpn,
        leadId: leadId,
        email: email,
        consumerId: consumerId,
        coverageQuestions: coverages
    };
    try {
        return await postJSON({ url, body, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const addProviders = async (body, syncId, authToken) => {
    const url = `${getUrl()}/${syncId || ''}`;
    try {
        return await postJSON({ url, body, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const deleteProvider = async ({ addressId, npi, syncId, authToken }) => {
    let url = `${getUrl()}/${npi}`;
    if (syncId) url = `${url}/${syncId}`;
    url = `${url}?addressId=${addressId}`;
    try {
        return await deleteJSON({ url, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const deleteMultiLocationProvider = async (body, syncId, authToken) => {
    let url = getUrl();
    if (syncId) url = `${url}/${syncId}`;
    try {
        return await deleteJSON({ url, body, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const getProviders = async (authToken = '') => {
    try {
        return await getJSON({ url: getUrl(), authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

export {
    addCoverages,
    addProvider,
    deleteProvider,
    deleteMultiLocationProvider,
    getProviders,
    getProvidersSearchLookUp,
    addProviders
};
