import { createSlice } from '@reduxjs/toolkit';

import { HEALTH_CONDITION_DETAILS_KEY, HEALTH_CONDITION_NO_HEALTH_CONDITION } from 'utilities/storageKeys';

import { get, set } from 'temp/utilities/storage';

export const healthIntakeDetails = createSlice({
    name: 'healthIntakeDetails',

    initialState: {
        healthIntakeModal: false,
        healthConditionsList: get(HEALTH_CONDITION_DETAILS_KEY, []),
        noHealthCondition: get(HEALTH_CONDITION_NO_HEALTH_CONDITION, false)
    },

    reducers: {
        setHealthIntakeModal: (state, action) => {
            state.healthIntakeModal = action.payload;
        },
        setHealthConditionsList: (state, action) => {
            state.healthConditionsList = action.payload;
            if(state.healthConditionsList.length>0){
                state.noHealthCondition = false;
            }
        },
        setNoHealthCondition: (state, action) => {
            state.noHealthCondition = action.payload;            
            set(HEALTH_CONDITION_NO_HEALTH_CONDITION, action.payload);
        }, 
    }
});

export const { setHealthIntakeModal, setHealthConditionsList, setNoHealthCondition } =
    healthIntakeDetails.actions;

export default healthIntakeDetails.reducer;
