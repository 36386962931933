import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const Text = ({
    children = '',
    className = '',
    text = children,
    onClick,
    id = 'text',
    mouseOver = e => {}
}) => {
    const handleClick = onClick => handleEvent(onClick);
    return (
        <p
            className={`${className} ${styles.text}`}
            onClick={handleClick(onClick)}
            id={id}
            onMouseOver={mouseOver}
        >
            {text}
        </p>
    );
};

export default Text;
