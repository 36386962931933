import { createSlice } from '@reduxjs/toolkit';

export const quickProflileRSA = createSlice({
    name: 'quickProflileRSA',

    initialState: {
        isDisabled: true
    },

    reducers: {
        setIsDisabled: (state, action) => {
            state.isDisabled = action.payload;
        }
    }
});

export const { setIsDisabled } = quickProflileRSA.actions;

export default quickProflileRSA.reducer;
