import Icon from 'components/Icon';

import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const handleClick = onClick => handleEvent(onClick);

const IconButton = ({
    className = '',
    disabled = false,
    icon,
    iconClassName,
    onClick,
    size = '',
    id = 'icon'
}) => (
    <button
        className={`${className} ${styles.iconButton}`}
        data-click-type="button"
        disabled={disabled}
        onClick={handleClick(onClick)}
    >
        <Icon
            className={
                iconClassName ? iconClassName : `${styles.icon} ${styles[size]}`
            }
            image={icon}
            id={id}
        />
    </button>
);

export default IconButton;
