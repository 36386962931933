import {
    FINAL_EXPENSE_PLANS_ROUTE,
    PLANS_ROUTE,
    PROFILE_ROUTE
} from 'utilities/routes';

export const buttonLabel = 'Find Coverage';
export const LegacySafeguardWebsite = 'https://legacysafeguard.com/';

export const linkData = {
    title: 'Products',
    navigationLink: PLANS_ROUTE,
    medicareLink: PLANS_ROUTE,
    finalExpenseLink: FINAL_EXPENSE_PLANS_ROUTE,
    from: PROFILE_ROUTE
};
