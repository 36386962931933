// DO NOT USE THIS FILE - This will be deprecated.  We should not be using any central files like this.

export const aetnaAlt =
    'A purple heart with squared sides and the word "Aetna" next to it in purple';
export const ambulanceAlt = 'Ambulance';
export const anthemAlt =
    'Anthem" logo in blue with a blue cross to the right of the text.';
export const bedAlt =
    'Bed with a night stand on both sides and a window behind the head board';
export const briefcaseAlt = 'A briefcase';
export const brokenChainAlt = 'A chain that is broken';
export const calenderAlt = 'One calendar page';
export const certificateWithNurseAlt =
    'Certificate with the head of a nurse and an award ribbon in the bottom right corner..';
export const cignaAlt =
    'A tree with a blue stump and "Cigna" to the right in blue';
export const closeIconAlt =
    'A small x at the top right hand corner to close the menu';
export const coupleOnComputerAlt =
    'Smiling man in grey and smiling woman in yellow looking at a computer';
export const introToFinalExpenseAlt = 'Introductions To Final Expense';
export const coupleShakingHandsAlt =
    'Smiling man and smiling woman facing us and shaking hands with a man who is facing away from us';
export const craneAlt =
    'A construction crane lifting a web page that has a giant x in the middle';
export const emojiHappySadAlt =
    'A face split in half. The face on the left is smiling. The other face is blue and frowning and crying.';
export const expandLessAlt =
    'An arrow shaped like a v pointing up to expand less';
export const expandMoreAlt = 'Down arrow';
export const greenCheckIconAlt = 'A white check mark in a green circle';
export const hamburgerAlt =
    'Three small lines in the top left hand corner that open the menu';
export const handClapManAlt =
    'Man in yellow shirt with his hands clapped together while he looks up and smiles';
export const handsAlt = 'Three hands raised';
export const handsCrossedOutAlt =
    'Three hands raised behind a circle that has a slash through them';
export const heartMonitorAlt =
    'Heart monitor with a squiggly line and a heart on the screen';
export const hospitalAlt = 'Hospital with a helicopter on top';
export const houseAlt = 'A view of a house from the outside';
export const houseWithCrossAlt = 'House with a medical cross on the front';
export const huggingCoupleAlt =
    'Man in gray shirt smiling with arm around smiling woman in a red striped shirt';
export const humanaAlt = 'Logo that says "Humana" in green';
export const unitedHealthCareAlt = 'Blue "UnitedHealthcare" logo';
export const magnifyGlassAlt =
    'White paper with a magnifying glass over the right side';
export const magnifyingGlassAlt =
    'Page with a magnifying glass on the top right corner';
export const medicalBagAlt = `Doctor's briefcase with a medical cross on the front`;
export const medicareEnrollLogoWAlt = ' with the PlanEnroll logo';
export const medicareEnrollLogoBlueBackroundWAlt =
    'PlanEnroll logo with blue background';
export const medicareEnrollLogoWhiteBackroundWAlt =
    'PlanEnroll logo with white background';
export const paperAirplaneAlt =
    'A paper airplane flying with a squiggly line behind it';
export const piggyBankAlt =
    'White piggy bank next to coins with a coin floating above it';
export const plugAlt = 'Two cords with plugs that are unplugged';
export const plusSignHandsAlt =
    'White hands reaching up toward a blue medical cross';
export const prescriptionAlt =
    'Prescription bottle with a medical cross and two pills to the right';
export const webPagesAlt =
    'Two pages, one behind the other and slightly sticking out';
export const wellCareAlt = 'Wellcare Logo';
export const whiteCheckMarkIconAlt = 'Blue circle with a white check mark';
export const whiteExclamationIconAlt =
    'Black circle with a white exclamation point';
export const whiteSquareWithCAlt = 'White square with the letter C in blue';
export const whiteSquareWithDAlt = 'White square with the letter D in blue';

export const mutalOmaha = 'mutualOmaha';
export const wellabeAlt = 'wellabe';
export const cfgAlt = 'cfg';
export const coreBridgeAlt = 'Core Bridge';
export const aflacAlt = 'aflac';
export const americanAmicableAlt = 'American Amicable';
export const cvsHealthAlt = 'cvs Health';
