import ButtonLink from 'components/ButtonLink';

import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const handleClick = onClick => handleEvent(onClick);

const ListItem = ({ className = '', children, isActived, onClick, text }) => (
    <li className={`${className} ${styles.listItem}`}>
        {children ? (
            children
        ) : (
            <ButtonLink
                isActived={isActived}
                onClick={handleClick(onClick)}
                text={text}
            />
        )}
    </li>
);

export default ListItem;
