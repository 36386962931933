import { createSlice } from '@reduxjs/toolkit';

export const healthConditionModalDetails = createSlice({
    name: 'healthConditionModalDetails',

    initialState: {
        healthConditionContentModal: false
    },

    reducers: {
        setHealthConditionContentModal: (state, action) => {
            state.healthConditionContentModal = action.payload;
        }
    }
});

export const { setHealthConditionContentModal } =
    healthConditionModalDetails.actions;

export default healthConditionModalDetails.reducer;
