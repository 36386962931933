import { setLocationModal } from '../YourLocationModal/slice';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import {
    finalExpensePlanType,
    medicarePlanType
} from 'components/PlanTypeModal/constants';

import dataLayer from 'utilities/dataLayer';
import { FINAL_EXPENSE_PLANS_ROUTE, PLANS_ROUTE } from 'utilities/routes';
import {
    SELECTED_PLAN_TYPE,
    PLAN_DATE_KEY,
    FINAL_EXPENSE_KEY
} from 'utilities/storageKeys';

import image from './image.svg';

import {
    buttonLabel,
    guidedExperienceLabel,
    headerText,
    headerText1,
    headerTitle,
    headerTitle1,
    noGuidedExperienceLabel,
    noGuidedExperienceLabel1,
    plansButtonLabel,
    plansButtonLabel1
} from './constants';
import {
    hideStepper,
    setFinalExpenseStepper,
    setGuidedModal,
    setGuidedProcess,
    setMedicareStepper,
    showStepper
} from './slice';

import styles from './styles.module.scss';

import PlanEffectiveDateFilter from 'temp/components/PlanEffectiveDateFilter';
import { set, get } from 'temp/utilities/storage';

const ConsumerGuidedExperienceModal = ({
    isOpen,
    onClose = () => null,
    onChangeDateFilter,
    isFromProfile = false
}) => {
    const dispatch = useDispatch();
    const selectedPlanType = get(SELECTED_PLAN_TYPE);
    const [filterDateType, setFilterDateType] = useState(get(PLAN_DATE_KEY));
    const [finalExpenseDetails] = useState(get(FINAL_EXPENSE_KEY));
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const data = finalExpenseDetails || userProfile;
    const isUser = useMemo(
        () =>
            (selectedPlanType === finalExpensePlanType &&
                Boolean(data?.gender)) ||
            selectedPlanType === medicarePlanType,
        [data, selectedPlanType]
    );

    const handleGetStarted = () => {
        selectedPlanType === medicarePlanType
            ? dispatch(setMedicareStepper())
            : dispatch(setFinalExpenseStepper());
        dispatch(setGuidedProcess(true));

        dispatch(showStepper());
        dispatch(setGuidedModal(false));
        dispatch(setLocationModal(true));
    };
    const history = useHistory();
    const handleOnClose = () => {
        dispatch(setGuidedModal(false));
        dispatch(setGuidedProcess(false));
        dispatch(hideStepper());
    };

    const gotoPlans = () => {
        dispatch(setGuidedModal(false));
        dispatch(setGuidedProcess(false));
        dispatch(hideStepper());
        selectedPlanType === medicarePlanType
            ? history.push(PLANS_ROUTE)
            : history.push(FINAL_EXPENSE_PLANS_ROUTE);
    };

    const reEnableScroll = useCallback(() => {
        document.body.classList.remove(styles.disableScroll);
    }, []);

    useEffect(() => {
        if (isOpen) {
            dataLayer('modal_appear', headerTitle);
            document.body.classList.add(styles.disableScroll);
        } else {
            reEnableScroll();
        }
        return reEnableScroll;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <Modal className={styles.modal} isOpen={isOpen}>
            <ModalWindow
                closeIconClassName={styles.closeIconClassName}
                contentClassName={styles.contentClassName}
                footerClassName={!isUser ? styles.hide : styles.footerClassName}
                footerLabel={
                    selectedPlanType === medicarePlanType
                        ? plansButtonLabel
                        : plansButtonLabel1
                }
                footerSubContent={
                    selectedPlanType === medicarePlanType
                        ? noGuidedExperienceLabel
                        : noGuidedExperienceLabel1
                }
                headerClassName={styles.headerClassName}
                headerIcon={image}
                headerText={
                    selectedPlanType === medicarePlanType
                        ? headerText
                        : headerText1
                }
                headerTitle={
                    selectedPlanType === medicarePlanType
                        ? headerTitle
                        : headerTitle1
                }
                isFooterButtonEnable={false}
                isSkip={true}
                onClose={handleOnClose}
                skipButtonClicked={gotoPlans}
                skipButtonLabel={
                    selectedPlanType === medicarePlanType
                        ? plansButtonLabel
                        : plansButtonLabel1
                }
            >
                <div className={styles.content}>
                    <p
                        className={styles.consumerGuided}
                        data-testid="consumer-guided-modal-content-para"
                        id="consumer-guided-modal-content-para"
                    >
                        {guidedExperienceLabel}
                    </p>
                    {selectedPlanType === medicarePlanType && (
                        <PlanEffectiveDateFilter
                            isModal={true}
                            className={styles.modalDateFilter}
                            changeFilter={e => {
                                const { value, name } = e.target;
                                if (name === 'filterDate') {
                                    set(PLAN_DATE_KEY, value);
                                    setFilterDateType(value);
                                }
                                onChangeDateFilter(e);
                            }}
                            initialValue={filterDateType}
                            isMobile={false}
                            isGuided={true}
                        />
                    )}
                    <Button
                        className={styles.buttonSecondaryLight}
                        onClick={handleGetStarted}
                        dataTestId="consumer-guided-modal-content-button-submit"
                        label={buttonLabel}
                        id="consumer-guided-modal-content-button-submit"
                    />
                </div>
            </ModalWindow>
        </Modal>
    );
};

export default ConsumerGuidedExperienceModal;
