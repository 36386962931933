export const headerTitle = 'Assistance Status';

export const headerText =
    'You may be eligible for certain types of plans, such as a Special Needs Plan or a Low Income Subsidy Plan, depending on your circumstances.';

export const headerIconAlt = '';
export const plansButtonLabel = 'Continue';
export const saveButtonLabel = 'Save';

export const medicaidOptions = {
    Yes: 1,
    No: 0,
    "I Don't Know": 2
};

export const subSidyLevelOptions = {
    Yes: 'Yes',
    No: 'No',
    "I Don't Know": "I Don't Know"
};

export const notAnswered = 'Not Answered';
