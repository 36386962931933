import { setPharmacyModal } from '../YourPharmacyModal/slice';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    setGuidedProcess,
    setGuidedModal,
    skipMedicareCurrentStep,
    completeMedicareCurrentStep,
    resetStepperState,
    skipFinalExpenseCurrentStep,
    completeFinalExpenseCurrentStep
} from 'components/ConsumerGuidedExperienceModal/slice';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import { medicarePlanType } from 'components/PlanTypeModal/constants';
import { setHealthInfoModal } from 'components/YourHealthInfoModal/slice';
import YourLocationModalContent from 'components/YourLocationModalContent';

import ZipCodeContext from 'contexts/zipCode';

import dataLayer from 'utilities/dataLayer';

import image from './image.svg';

import {
    headerTitle,
    headerText,
    plansButtonLabel,
    headerText1
} from './constants';
import { setLocationModal } from './slice';

import styles from './styles.module.scss';

const YourLocationModal = () => {
    const {
        buttonDisabled,
        error,
        getCounties,
        onSetCounties,
        onSetCounty,
        onSetZipCode,
        zipCode
    } = useContext(ZipCodeContext);

    const locationModal = useSelector(
        state => state.locationDetails.locationModal
    );
    const selectedPlanType = useSelector(
        state => state.planTypeDetail.selectedPlanType
    );
    useEffect(() => {
        if (locationModal) {
            dataLayer('modal_appear', headerTitle);
        }
    }, [locationModal]);

    const isGuided = useSelector(state => state.guidedDetails.isGuidedProcess);

    const dispatch = useDispatch();

    const onClose = isContinue => {
        dispatch(setLocationModal(false));
        if (!isContinue) {
            dispatch(setGuidedProcess(false));
            dispatch(resetStepperState());
        } else {
            if (isGuided) {
                if (selectedPlanType === medicarePlanType) {
                    dispatch(setPharmacyModal(true));
                    dispatch(completeMedicareCurrentStep());
                } else {
                    dispatch(setHealthInfoModal(true));
                    dispatch(completeFinalExpenseCurrentStep());
                }
            }
        }
    };

    const handleBackButton = () => {
        dispatch(setGuidedModal(true));
        dispatch(setLocationModal(false));
        dispatch(resetStepperState());
    };

    const skipButtonClicked = () => {
        dispatch(setLocationModal(false));
        if (selectedPlanType === medicarePlanType) {
            dispatch(setPharmacyModal(true));
            dispatch(skipMedicareCurrentStep());
        } else {
            dispatch(setHealthInfoModal(true));
            dispatch(skipFinalExpenseCurrentStep());
        }
    };

    return (
        <Modal className={styles.modal} isOpen={locationModal}>
            <ModalWindow
                closeIconClassName={styles.closeIconClassName}
                contentClassName={styles.contentClassName}
                footerButtonClassName={styles.footerButtonClassName}
                footerButtonDisabled={error || buttonDisabled}
                footerClassName={styles.footerClassName}
                footerId={'footerButton'}
                footerLabel={plansButtonLabel}
                headerClassName={styles.headerClassName}
                headerIcon={image}
                headerText={
                    selectedPlanType === medicarePlanType
                        ? headerText
                        : headerText1
                }
                headerTitle={headerTitle}
                onClose={() => {
                    onSetCounty(localStorage.getItem('countyVal'));
                    localStorage.removeItem('countyVal');
                    onClose();
                }}
                footerButtonClicked={() => {
                    onSetZipCode(localStorage.getItem('updatedZipCode'));
                    onSetCounty(
                        localStorage.getItem('updatedCountyVal') ||
                            localStorage.getItem('countyVal')
                    );

                    localStorage.removeItem('updatedCountyVal');
                    onClose(true);
                }}
                backButtonClicked={() => {
                    onSetCounty(localStorage.getItem('countyVal'));
                    localStorage.removeItem('countyVal');
                    const countiesList = getCounties(zipCode);
                    onSetCounties(countiesList);
                    handleBackButton();
                }}
                skipButtonClicked={skipButtonClicked}
            >
                <div
                    className={styles.content}
                    data-testid="your-pharmacy-modal"
                >
                    <YourLocationModalContent error={error} showCounty />
                </div>
            </ModalWindow>
        </Modal>
    );
};

export default YourLocationModal;
