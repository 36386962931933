import { finalExpensePlanType } from 'components/PlanTypeModal/constants';

import { getAgentNpn } from './getSessionData';
import { PURL_AGENT_CODE } from './sessionStorageKeys';
import {
    FINALEXPENSE_AGENT_NPN,
    MEDICARE_AGENT_NPN,
    SELECTED_PLAN_TYPE
} from './storageKeys';

import { get } from 'temp/utilities/storage';

const putJSON = async ({ body, url, authToken }) => {
    const agentNpn = getAgentNpn();
    const agentPurl = get(PURL_AGENT_CODE);
    const npn =
        get(SELECTED_PLAN_TYPE) === finalExpensePlanType
            ? get(FINALEXPENSE_AGENT_NPN)
            : get(MEDICARE_AGENT_NPN);
    const options = {
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
            ...(agentPurl && { isPurl: true }),
            ...(agentNpn && { AgentNPN: npn || agentNpn }),
            ...(authToken && {
                Authorization: `Bearer ${authToken}`
            })
        },
        method: 'PUT'
    };

    try {
        return (await fetch(url, options)).json();
    } catch (error) {
        console.error(error);

        return {};
    }
};

export default putJSON;
