import TextField from '../TextField';

import Body2 from 'components/Body2';
import Body2DMSans from 'components/Body2DMSans';

import styles from './styles.module.scss';

const TextFieldWithLabel = props => {
    const {
        autoFocus,
        body2ClassName,
        className,
        defaultValue,
        disabled,
        error,
        errorMessage,
        errorMessageDoubleConfirm,
        errorMessagePattern,
        fieldClassName,
        id,
        labelClassName = styles.label,
        labelText,
        pattern,
        placeholder,
        register,
        required,
        type,
        InputProps
    } = props;

    return (
        <div className={`${className} ${styles.textFieldWithLabel}`}>
            <Body2DMSans
                htmlFor={id}
                className={labelClassName}
                text={labelText}
                id={`${id}_label`}
                required={required}
            />

            <TextField
                autoFocus={autoFocus}
                className={fieldClassName}
                defaultValue={defaultValue}
                disabled={disabled}
                error={error}
                id={id}
                pattern={pattern}
                placeholder={placeholder}
                register={register}
                required={required}
                type={type}
                InputProps={{ ...InputProps }}
            />

            {error?.type === 'required' && (
                <Body2
                    text={errorMessage}
                    className={`${body2ClassName} ${styles.errorText}`}
                />
            )}
            {error?.type === 'pattern' && (
                <Body2
                    text={errorMessagePattern}
                    className={`${body2ClassName} ${styles.errorText}`}
                />
            )}
            {error?.type === 'doubleConfirm' && (
                <Body2
                    text={errorMessageDoubleConfirm}
                    className={`${body2ClassName} ${styles.errorText}`}
                />
            )}
        </div>
    );
};

export default TextFieldWithLabel;
