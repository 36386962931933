import { setLocationModal } from '../YourLocationModal/slice';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';

import {
    setGuidedProcess,
    setGuidedModal,
    previousMedicareStep,
    skipMedicareCurrentStep,
    resetStepperState,
    completeMedicareCurrentStep
} from 'components/ConsumerGuidedExperienceModal/slice';
import CreateAccountBanner from 'components/CreateAccountBanner';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import Text from 'components/Text';
import { setDoctorModal } from 'components/YourDoctorModal/slice';
import YourPharmacyModalContent from 'components/YourPharmacyModalContent';

import {
    addPharmacy,
    deletePharmacy,
    getPharmacies,
    updatePrimary
} from 'utilities/apiSession/pharmacies';
import dataLayer from 'utilities/dataLayer';
import { sortPharmacies } from 'utilities/sortPharmacies';
import {
    PHARMACY_DETAILS_KEY,
    PHARMACY_SEARCH_TERM_KEY,
    PHARMACY_NAMES_KEY,
    PHARMACY_LIST_PAGE,
    ZIP_CODE_KEY,
    COUNTY_FIPS_KEY,
    PLAN_DATE_KEY
} from 'utilities/storageKeys';

import image from './image.svg';

import {
    addAndContinue,
    buttonLabel,
    headerText,
    headerTitle,
    maxReachedTextInListForm,
    maxReachedTextInSearchForm,
    plansButtonLabel
} from './constants';
import {
    setPharmacyModal,
    setPharmacyNames,
    setSelectedPharmacyId,
    setSelectedPlan
} from './slice';

import styles from './styles.module.scss';

import { mailOrderData } from 'temp/components/PharmacyFilters/constants';
import {
    setPharmacies,
    setSelectedPharmacy as setPrimaryPharmacy
} from 'temp/components/PharmacyFilters/slice';
import { getPlanDetails } from 'temp/utilities/apiSession';
import { checkModalDataHasChanged } from 'temp/utilities/modalHelper';
import { get, set } from 'temp/utilities/storage';

const YourPharmacyModal = ({
    onChange = () => null,
    isAddPharmacy = false,
    isFromProfile = false,
    openPharmacyModal = {},
    isAdd
}) => {
    const { user, getAccessTokenSilently } = useAuth0();
    const pharmacyModal = useSelector(
        state => state.pharmacyDetails.pharmacyModal
    );
    const initialPharmacyNames = useSelector(
        state => state.pharmacyDetails.pharmacyNames
    );

    const { selectedPharmacy: selectedPharmacyID } = useSelector(
        state => state.pharmacyFilter
    );

    const [footerButtonDisabled, setFooterButtonDisabled] = useState(false);

    useEffect(() => {
        if (pharmacyModal) {
            dataLayer('modal_appear', headerTitle);
        }
    }, [pharmacyModal]);
    const firstLoad = useRef(true);
    const isGuided = useSelector(state => state.guidedDetails.isGuidedProcess);
    const syncId = useSelector(state => state.userProfile.userProfile.syncId);
    const [tab, setTab] = useState(0);
    const dispatch = useDispatch();

    const [pharmacyName, setPharmacyName] = useState(
        get(PHARMACY_SEARCH_TERM_KEY, '')
    );
    const [loading, setLoading] = useState(false);
    const [pharmacyList, setPharmacyList] = useState([]);
    const [pharmacyListPage, setPharmacyListPage] = useState(
        get(PHARMACY_LIST_PAGE, false)
    );
    const [pharmacyDetails, setPharmacyDetails] = useState(
        get(PHARMACY_DETAILS_KEY, null)
    );
    const [selectedPharmacy, setSelectedPharmacy] = useState([]);
    const [selectedPharmacyIds, setSelectedPharmacyIds] = useState([]);

    const getAuthToken = async () => {
        return user ? await getAccessTokenSilently() : '';
    };

    const handleSelectedPharmacy = (data, isChecked) => {
        let filterAddresses = [];
        let filterIds = [];
        if (isChecked) {
            filterIds = [...selectedPharmacyIds, data?.pharmacyID];
            filterAddresses = [...selectedPharmacy, data];
        } else {
            const filterSelected = selectedPharmacy.filter(
                addressId =>
                    (addressId?.pharmacyID || addressId?.pharmacyId) !==
                    data?.pharmacyID
            );
            const filterSelectedIds = selectedPharmacyIds.filter(
                addressId => addressId !== data?.pharmacyID
            );
            filterAddresses = filterSelected;
            filterIds = filterSelectedIds;
        }

        setSelectedPharmacy([...filterAddresses]);
        setSelectedPharmacyIds([...filterIds]);
        dispatch(setSelectedPharmacyId(filterIds));
    };
    const selectedPlan = useSelector(
        state => state.pharmacyDetails.selectedPlan
    );
    const effectiveDate = useSelector(
        ({ planDetails }) => planDetails?.effectiveDate
    );
    const handlePharmacy = async data => {
        setLoading(true);
        setPharmacyListPage(true);
        set(PHARMACY_LIST_PAGE, true);
        setPharmacyModal(false);
        await data?.forEach(dataObj => {
            if (!dataObj?.pharmacyRecordID) dataObj.pharmacyRecordID = 0;
            return dataObj;
        });
        const existingPrimary = data.some(pharmacy => pharmacy.isPrimary);
        const pharmacyData = data.map((pharmacy, index) => {
            const obj = { ...pharmacy };
            if (!existingPrimary && index === 0) {
                obj.isPrimary = true;
                dispatch(setPrimaryPharmacy(obj?.pharmacyID));
            }
            return obj;
        });
        setPharmacyDetails(data);
        set(PHARMACY_DETAILS_KEY, data);
        await addPharmacy({
            data: pharmacyData,
            syncId,
            authToken: await getAuthToken()
        });
        if (!isFromProfile || !isAddPharmacy) {
            await getPharmaciesList();
            const pharmacyNames = pharmacyList?.map(item => item.name) || [];
            if (
                checkModalDataHasChanged(initialPharmacyNames, pharmacyNames) &&
                selectedPlan
            ) {
                let effectiveDateValue = effectiveDate || get(PLAN_DATE_KEY);
                const authToken = await getAuthToken();
                let zip = get(ZIP_CODE_KEY);
                let fips = get(COUNTY_FIPS_KEY);
                const planData = await getPlanDetails({
                    Zip: zip,
                    Fips: fips,
                    planId: selectedPlan.id,
                    effectiveDate: effectiveDateValue,
                    authToken,
                    pharmacyId:
                        selectedPharmacyID !== 'null' ? selectedPharmacyID : ''
                });

                dispatch(setSelectedPlan(planData));
            }
        }
        setLoading(false);
    };

    const searchTermChanged = searchTerm => {
        set(PHARMACY_SEARCH_TERM_KEY, searchTerm);
        setPharmacyName(searchTerm);
    };

    const handleClear = e => {
        searchTermChanged('');
        dispatch(setSelectedPharmacyId([]));
    };

    const getPharmaciesList = useCallback(async () => {
        const authToken = await getAuthToken();
        let pharmacies = await getPharmacies(authToken);
        pharmacies = await sortPharmacies(pharmacies);

        if (pharmacies) {
            setPharmacyList([...pharmacies]);
            dispatch(setPharmacies([...pharmacies]));
            const pharmacyIDs = pharmacies.map(pharmacy => pharmacy.pharmacyId);

            setSelectedPharmacy(pharmacies);
            setSelectedPharmacyIds(pharmacyIDs);
            dispatch(setSelectedPharmacyId(pharmacyIDs));
            setPharmacyListPage(pharmacies.length);
        } else {
            setPharmacyList([]);
            dispatch(setSelectedPharmacyId([]));
            dispatch(setPharmacies(mailOrderData));
        }
    }, []);

    useEffect(() => {
        if (pharmacyModal) {
            getPharmaciesList();
        }
    }, [pharmacyModal, getPharmaciesList]);

    const handleDeletePharmacy = async pharmacy => {
        const filterSelected = pharmacyList.filter(
            addressId => addressId?.pharmacyId !== pharmacy?.pharmacyId
        );
        const filterSelectedIds = selectedPharmacyIds.filter(
            addressId => addressId !== pharmacy?.pharmacyId
        );

        await deletePharmacy({
            id: pharmacy?.pharmacyRecordID,
            syncId,
            authToken: await getAuthToken()
        });

        if (pharmacy.pharmacyId === selectedPharmacyID)
            dispatch(setPrimaryPharmacy('null'));

        await getPharmaciesList();
        setLoading(false);

        if (filterSelected?.length === 0) {
            setPharmacyListPage(false);
            setSelectedPharmacy([]);
            setSelectedPharmacyIds([]);
            set(PHARMACY_LIST_PAGE, false);
            handleClear();
            set(PHARMACY_NAMES_KEY, []);
            setPharmacyNames([]);
        } else {
            setSelectedPharmacy(filterSelected);
            setSelectedPharmacyIds(filterSelectedIds);
            dispatch(setSelectedPharmacyId(filterSelectedIds));
            const existingPrimary = filterSelected.some(
                pharmacy => pharmacy.isPrimary
            );
            !existingPrimary &&
                (await setAsPrimary(filterSelected[0], filterSelected));
        }
    };

    const setAsPrimary = async (data, pharmacies = pharmacyList) => {
        const oldPrimaryPharmacy = pharmacies.filter(
            pharmacy =>
                pharmacy?.pharmacyId !== data?.pharmacyId &&
                pharmacy.isPrimary === true
        );

        if (oldPrimaryPharmacy?.length) {
            const oldPrimary = [...oldPrimaryPharmacy].map(pharmacy => {
                let tpharmacy = { ...pharmacy };

                tpharmacy.isPrimary = false;

                return tpharmacy;
            });

            await updatePrimary({
                data: oldPrimary[0],
                syncId,
                authToken: await getAuthToken()
            });
        }

        let upDatePharmacy = { ...data };

        upDatePharmacy.isPrimary = true;

        await updatePrimary({
            data: upDatePharmacy,
            syncId,
            authToken: await getAuthToken()
        });

        const upDateList = pharmacies.map(pharmacy => {
            let tpharmacy = { ...pharmacy };
            if (tpharmacy?.pharmacyId !== data?.pharmacyId) {
                tpharmacy.isPrimary = false;
            } else {
                tpharmacy.isPrimary = true;
                dispatch(setPrimaryPharmacy(data?.pharmacyId));
            }

            return tpharmacy;
        });
        upDateList?.sort(function (a, b) {
            var textA = a.name.toUpperCase();
            var textB = b.name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        upDateList?.sort(function (a, b) {
            var textA = a.isPrimary;
            var textB = b.isPrimary;
            return textA > textB ? -1 : textA < textB ? 1 : 0;
        });

        setPharmacyList([...upDateList]);
        setSelectedPharmacy(
            pharmacies.map(pharmacy => {
                const obj = { ...pharmacy };

                if (obj.pharmacyId === data.pharmacyId) obj.isPrimary = true;
                else obj.isPrimary = false;
                return obj;
            })
        );
        dispatch(setPharmacies([...upDateList]));
        const pharmacyNames = pharmacyList?.map(item => item.name) || [];
        dispatch(setPharmacyNames(pharmacyNames || []));
    };

    const onClose = isContinue => {
        dispatch(setPharmacyModal(false));
        localStorage.removeItem('healthConditionFromPage');
        if (isFromProfile && isAddPharmacy) {
            isAdd(false);
            setPharmacyListPage(true);
            set(PHARMACY_LIST_PAGE, true);
        }
        if (!isContinue) {
            dispatch(setGuidedProcess(false));
            dispatch(resetStepperState());
        } else {
            isGuided && dispatch(setDoctorModal(true));
            if (isGuided)
                pharmacyList.length > 0
                    ? dispatch(completeMedicareCurrentStep())
                    : dispatch(skipMedicareCurrentStep());
        }
        const pharmacyNames = pharmacyList?.map(item => item.name) || [];
        onChange();

        if (checkModalDataHasChanged(initialPharmacyNames, pharmacyNames)) {
            dispatch(setPharmacyNames(pharmacyNames || []));
        }
        dispatch(setSelectedPlan(null));
        dispatch(setSelectedPharmacyId([]));
        setSelectedPharmacy([]);
        setSelectedPharmacyIds([]);
        set(PHARMACY_NAMES_KEY, pharmacyNames);
    };

    const handleBackButton = () => {
        dispatch(setLocationModal(true));
        dispatch(setPharmacyModal(false));
        dispatch(previousMedicareStep());
    };
    // const [selectedPharmacies, setSelectedPharmacies] = useState([]);

    const handleSkipButton = () => {
        dispatch(setGuidedModal(false));
        dispatch(setPharmacyModal(false));
        dispatch(setDoctorModal(true));
        const pharmacyNames = pharmacyList?.map(item => item.name);
        onChange();
        if (pharmacyNames?.length) {
            dispatch(setPharmacyNames(pharmacyNames));
        } else if (
            initialPharmacyNames?.length !== 0 &&
            pharmacyNames?.length === 0
        ) {
            dispatch(setPharmacyNames([]));
        }
        set(PHARMACY_NAMES_KEY, pharmacyNames);
        pharmacyList.length > 0
            ? dispatch(completeMedicareCurrentStep())
            : dispatch(skipMedicareCurrentStep());
    };

    const getFooterText = useCallback(() => {
        if (isGuided && !pharmacyListPage) return addAndContinue;
        if (isGuided && pharmacyListPage) return plansButtonLabel;
        if (!pharmacyListPage) return buttonLabel;
        return plansButtonLabel;
    }, [pharmacyListPage, isGuided]);

    const handleFooterClick = async () => {
        if (!pharmacyListPage && selectedPharmacy) {
            setFooterButtonDisabled(true);
            await handlePharmacy(selectedPharmacy);
            setFooterButtonDisabled(false);

            if (isFromProfile && isAddPharmacy) {
                onClose(false);
                openPharmacyModal();
            }
        } else onClose(true);
    };

    useEffect(() => {
        if (isGuided) {
            setPharmacyListPage(pharmacyList.length);
        }
    }, [isGuided, pharmacyList]);

    const isBack = useMemo(() => {
        const fromPage = localStorage.getItem('healthConditionFromPage');
        if (fromPage) return isGuided && fromPage ? false : true;
        else return isGuided;
    }, [isGuided, pharmacyModal]);

    const CountText = () => (
        <span className={styles.countText}>
            {3 - selectedPharmacy?.length} additional Pharmacies.
        </span>
    );

    useEffect(() => {
        if (isAddPharmacy) {
            setPharmacyListPage(false);
            set(PHARMACY_LIST_PAGE, false);
        }
    }, [isAddPharmacy, pharmacyList]);

    return (
        <Modal className={styles.modal} isOpen={pharmacyModal}>
            <ModalWindow
                contentClassName={styles.contentClassName}
                footerClassName={styles.footerClassName}
                footerButtonDisabled={
                    footerButtonDisabled || isGuided
                        ? pharmacyListPage
                            ? pharmacyList?.length === 0
                            : !selectedPharmacy?.length
                        : !selectedPharmacy?.length
                }
                footerLabel={getFooterText()}
                headerIcon={image}
                headerText={headerText}
                headerTitle={headerTitle}
                headerClassName={styles.headerClassName}
                closeIconClassName={styles.closeIconClassName}
                footerButtonClassName={styles.footerButtonClassName}
                onClose={() => onClose()}
                footerButtonClicked={handleFooterClick}
                isSkip={isGuided}
                isBack={isBack}
                skipButtonClicked={handleSkipButton}
                backButtonClicked={handleBackButton}
                hideFooter={!isGuided && pharmacyListPage}
            >
                {(selectedPharmacy?.length === 3 ||
                    pharmacyList?.length === 3) && (
                    <Text className={styles.banner} id="showMaxReached">
                        {pharmacyListPage
                            ? maxReachedTextInListForm
                            : maxReachedTextInSearchForm}
                    </Text>
                )}
                {selectedPharmacy?.length >= 1 &&
                    selectedPharmacy?.length < 3 && (
                        <Text className={styles.infoBanner} id="showReached">
                            You may select a maximum of 3 Pharmacies, including
                            physical and online options. Add up to &nbsp;
                            <CountText />
                        </Text>
                    )}
                <div
                    className={styles.content}
                    data-testid="your-pharmacy-modal"
                >
                    <YourPharmacyModalContent
                        defaultValue={pharmacyName}
                        handleDeletePharmacy={handleDeletePharmacy}
                        handleSelectedPharmacy={handleSelectedPharmacy}
                        selectedPharmacies={selectedPharmacyIds}
                        loading={loading}
                        onChange={searchTermChanged}
                        onClear={handleClear}
                        pharmacyDetails={pharmacyDetails}
                        pharmacyList={pharmacyList}
                        pharmacyListPage={pharmacyListPage}
                        tab={tab}
                        setTab={setTab}
                        addPharmacy={() => setPharmacyListPage(false)}
                        setAsPrimary={setAsPrimary}
                    />
                </div>
                {isGuided && false && <CreateAccountBanner />}
            </ModalWindow>
        </Modal>
    );
};

export default YourPharmacyModal;
