import { useEffect, useState } from 'react';

import Body3 from 'components/Body3';
import Button from 'components/Button';
import Icon from 'components/Icon';

import walletPrescription from './image.svg';

import styles from './styles.module.scss';

import drugFrequencyOptions from 'temp/utilities/drugFrequencyOptions';

const WalletPrescriptionCard = ({
    mouseOver,
    walletID,
    mouseOverOut,
    cardClassName = styles.walletPrescription,
    openPrescriptionModal,
    prescriptionsList
}) => {
    const [prescriptionList, setPrescriptionList] = useState([]);
    const frequencyValue = daysOfSupply =>
        drugFrequencyOptions.find(
            frequency => frequency?.value === daysOfSupply
        )?.text;

    const frequencyMessage = ({ dosage, dosageDetails }) => {
        const { daysOfSupply, metricQuantity, selectedPackage } = dosage;

        return selectedPackage
            ? `${dosage?.userQuantity} X ${
                  selectedPackage?.packageDisplayText
              } ${frequencyValue(daysOfSupply)}`
            : dosageDetails
            ? `${metricQuantity} ${dosageDetails.dosageFormName.toLowerCase()}${
                  metricQuantity > 1 ? 's' : ''
              } ${frequencyValue(daysOfSupply)}`
            : '';
    };
    const showData = () => {
        document
            .getElementById('prescriptionContent')
            ?.setAttribute('style', 'display:flex');
    };

    useEffect(() => {
        setPrescriptionList(prescriptionsList);
    }, [prescriptionsList]);

    return (
        <div
            className={cardClassName}
            id="walletPrescription"
            onLoad={
                walletID !== 'walletLegacyCard' &&
                setTimeout(function () {
                    showData();
                }, 50)
            }
        >
            <Icon
                id={'walletPrescriptionIcon'}
                image={walletPrescription}
                onClick={() => {
                    walletID !== 'walletPrescription'
                        ? mouseOver('walletPrescription')
                        : prescriptionList?.length && openPrescriptionModal();
                    walletID !== 'walletPrescription' &&
                        setTimeout(function () {
                            showData();
                        }, 50);
                }}
                className={styles.prescriptionIcon}
            />

            <div
                className={styles.prescriptionContent}
                id="prescriptionContent"
                onClick={e =>
                    prescriptionList?.length &&
                    walletID === 'walletPrescription' &&
                    openPrescriptionModal()
                }
            >
                {!prescriptionList?.length ? (
                    <Button
                        label="Add Prescription"
                        className={styles.addPrescriptionBtn}
                        onClick={e =>
                            walletID === 'walletPrescription' &&
                            openPrescriptionModal()
                        }
                    />
                ) : (
                    <div
                        className={styles.prescriptionList}
                        onClick={e =>
                            prescriptionList?.length &&
                            walletID === 'walletPrescription' &&
                            openPrescriptionModal()
                        }
                    >
                        {prescriptionList.map(supplement => {
                            return (
                                <div className={styles.prescriptionListItem}>
                                    <Body3
                                        className={styles.prescriptionName}
                                        text={supplement?.dosage?.drugName}
                                    />

                                    <Body3
                                        className={styles.dosage}
                                        text={frequencyMessage({
                                            dosage: supplement?.dosage,
                                            dosageDetails:
                                                supplement?.dosageDetails
                                        })}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default WalletPrescriptionCard;
