import * as Sentry from '@sentry/react';

import { API_ENROLL_URL } from 'utilities/env';
import getJSON from 'utilities/getJSON';

import { getLeadId } from 'temp/utilities/apiSession/leadId';

const getUrl = () => {
    const leadId = getLeadId();

    return `${API_ENROLL_URL}/BookOfBusiness/lead/${leadId}`;
};

const getPlans = async () => {
    try {
        return await getJSON({ url: getUrl() });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

export { getPlans };
