import Heading5 from 'components/Heading5';
import Icon from 'components/Icon';

import styles from './styles.module.scss';

const IconWithLabel = ({
    alt = '',
    caption = '',
    className = '',
    image,
    labelClassName = '',
    iconClassName = ''
}) => {
    if (!image) {
        throw new Error('No image was specified');
    }

    return (
        <div className={`${className} ${styles.iconWithLabel}`}>
            <Icon
                alt={alt}
                image={image}
                className={`${iconClassName} ${styles.icon}`}
            />

            <Heading5
                className={`${labelClassName} ${styles.heading5}`}
                text={caption}
            />
        </div>
    );
};

export default IconWithLabel;
