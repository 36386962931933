import { finalExpensePlanType } from 'components/PlanTypeModal/constants';

import {
    FINALEXPENSE_AGENT_DATA,
    MEDICARE_AGENT_DATA,
    SELECTED_PLAN_TYPE
} from './storageKeys';

import { get } from 'temp/utilities/storage';

const getAgentNpn = () => {
    const agentType =
        get(SELECTED_PLAN_TYPE) === finalExpensePlanType
            ? FINALEXPENSE_AGENT_DATA
            : MEDICARE_AGENT_DATA;
    const agentDetails = get(agentType);
    const envAgentNpn = import.meta.env.VITE_APP_AGENT_NPN?.toString()?.trim();
    if (agentDetails) {
        return agentDetails.agentNpn;
    }
    if (envAgentNpn?.length) {
        return envAgentNpn;
    }
    return null;
};

const getAgentPurlCode = () => {
    const agentType =
        get(SELECTED_PLAN_TYPE) === finalExpensePlanType
            ? FINALEXPENSE_AGENT_DATA
            : MEDICARE_AGENT_DATA;
    const agentDetails = get(agentType);
    if (agentDetails) {
        return agentDetails.agentPurlCode;
    }
    return null;
};

export { getAgentNpn, getAgentPurlCode };
