import { CoverageIcon } from './icons/coverage.js';
import DoctorIcon from './icons/doctor.js';
import LocationIcon from './icons/location.js';
import MedicaidIcon from './icons/medicaid.js';
import { MeetWithMeIcon } from './icons/meetWithMe.js';
import PharmacyIcon from './icons/pharmacy.js';
import PrescriptionIcon from './icons/prescription.js';

export const headerTitle = 'Lets get few details';

export const stepIconMap = {
    location: LocationIcon,
    pharmacy: PharmacyIcon,
    doctor: DoctorIcon,
    prescription: PrescriptionIcon,
    medicaid: MedicaidIcon,
    coverage: CoverageIcon,
    meetWithMe: MeetWithMeIcon
};

export const quickProfileSteps = [
    {
        id: 0,
        label: 'Personal Information',
        icon: 'location',
        isCompleted: false,
        isSkipped: false,
        enabled: false,
        isVisited: false
    },
    {
        id: 1,
        label: 'Pharmacy',
        icon: 'pharmacy',
        isCompleted: false,
        isSkipped: false,
        enabled: false,
        isVisited: false
    },
    {
        id: 2,
        label: 'Doctors',
        icon: 'doctor',
        isCompleted: false,
        isSkipped: false,
        enabled: false,
        isVisited: false
    },
    {
        id: 3,
        label: 'Prescriptions',
        icon: 'prescription',
        isCompleted: false,
        isSkipped: false,
        enabled: false,
        isVisited: false
    },
    {
        id: 4,
        label: 'Coverage',
        icon: 'coverage',
        isCompleted: false,
        isSkipped: false,
        enabled: false,
        isVisited: false
    },
    {
        id: 5,
        label: 'Meet with Me',
        icon: 'meetWithMe',
        isCompleted: false,
        isSkipped: false,
        enabled: false,
        isVisited: false
    }
];
