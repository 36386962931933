import { useEffect, useRef } from 'react';

import Heading3 from 'components/Heading3';
import Icon from 'components/Icon';
import Text from 'components/Text';

import healthPlansEmpty from './HealthPlansEmpty.svg';
import healthPlansIcon from './image.svg';

import styles from './styles.module.scss';

const WalletHealthCard = ({
    mouseOver,
    mouseOverOut,
    cardClassName = styles.walletHealthPlans,
    openHealthPlanModal,
    plansDetailsIndex = 0,
    showAllByDefault,
    plansDetails,
    walletID
}) => {
    const ref = useRef();
    const PlanDate = ({ label, data, className = styles.heading }) =>
        data ? (
            <div className={styles.data}>
                <Heading3 className={className} text={`${label}:`} />

                <Text className={styles.policyDetails}>{data}</Text>
            </div>
        ) : null;
    useEffect(() => {
        if (ref.current == null) {
            return;
        }

        document
            .getElementById(`healthInfoIcon-${plansDetailsIndex}`)
            ?.setAttribute(
                'style',
                `background-color:${plansDetails?.carrierColor || '#1157EE'}`
            );
    }, [plansDetails?.carrierColor, plansDetailsIndex, ref]);

    return (
        <div
            className={cardClassName}
            style={{ backgroundColor: plansDetails?.carrierColor || '#1157EE' }}
            id={`healthPlansEmpty-${plansDetailsIndex}`}
        >
            {plansDetails ? (
                <div
                    className={styles.healthInfoIcon}
                    onClick={() =>
                        walletID !== `healthPlansEmpty-${plansDetailsIndex}`
                            ? mouseOver(`healthPlansEmpty-${plansDetailsIndex}`)
                            : openHealthPlanModal(
                                  healthPlansIcon,
                                  plansDetailsIndex
                              )
                    }
                    id={`healthInfoIcon-${plansDetailsIndex}`}
                    ref={ref}
                >
                    {plansDetails?.planName ? (
                        <Text
                            text={plansDetails?.planName}
                            className={styles.planName}
                        />
                    ) : (
                        <div>
                            <Text
                                text={'Health Plan'}
                                className={styles.noPlanName}
                            />
                            <Text text={'Name'} className={styles.nameLabel} />
                        </div>
                    )}
                    <div className={styles.policyDetailsContainer}>
                        <div className={styles.detailsContainer}>
                            <PlanDate
                                label="Status"
                                data={`${
                                    plansDetails?.policyStatus
                                        ? 'Active'
                                        : 'N/A'
                                }`}
                            />
                            <PlanDate
                                className={styles.heading3}
                                label="Policy Number"
                                data={plansDetails?.policyNumber}
                            />
                        </div>
                        {plansDetails?.carrierIconURL && (
                            <Icon
                                className={`${styles.icon}`}
                                image={plansDetails?.carrierIconURL}
                                id="insuranceIcon"
                            />
                        )}
                    </div>
                </div>
            ) : (
                <Icon
                    image={healthPlansEmpty}
                    id={`healthPlansEmpty-${plansDetailsIndex}Icon`}
                    onClick={() =>
                        walletID !== `healthPlansEmpty-${plansDetailsIndex}`
                            ? mouseOver(`healthPlansEmpty-${plansDetailsIndex}`)
                            : openHealthPlanModal(healthPlansEmpty, 0)
                    }
                    className={styles.healthIcon}
                />
            )}
        </div>
    );
};

export default WalletHealthCard;
