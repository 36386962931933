import { skipButtonLabel } from 'components/CreateAccountModal/constants';
import SkipButton from 'components/SkipButton';

import styles from './styles.module.scss';

export const QuickProfileSkipButton = ({ skipButtonClicked }) => {
    return (
        <SkipButton
            className={`${styles.skipButton}`}
            disabled={false}
            label={skipButtonLabel}
            onClick={skipButtonClicked}
        />
    );
};
