import { Link } from 'react-router-dom';

import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const LinkText = ({
    linkTextClass = styles.linkText,
    onClick,
    state = '',
    text = '',
    url = ''
}) => {
    const linkText = text || url;
    const handleClick = onClick => handleEvent(onClick);

    if (url?.startsWith('/')) {
        return (
            <Link
                to={{ pathname: url, state: state }}
                className={linkTextClass}
            >
                {linkText}
            </Link>
        );
    } else if (url?.startsWith('#')) {
        return (
            <Link
                to={url}
                className={linkTextClass}
                onClick={handleClick(onClick)}
            >
                {linkText}
            </Link>
        );
    }

    return (
        <a className={linkTextClass} href={url}>
            {linkText}
        </a>
    );
};

export default LinkText;
