import { createSlice } from '@reduxjs/toolkit';

import { RADIUS_MILES_KEY } from 'utilities/storageKeys';

import { get } from 'temp/utilities/storage';

export const distanceDropdownDetails = createSlice({
    name: 'distanceDropdownDetails',

    initialState: {
        radiusMiles: get(RADIUS_MILES_KEY, 5)
    },

    reducers: {
        setRadiusMiles: (state, action) => {
            state.radiusMiles = action.payload;
        }
    }
});

export const { setRadiusMiles } = distanceDropdownDetails.actions;

export default distanceDropdownDetails.reducer;
