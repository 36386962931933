const formatPhoneNumber = (phone, removeExtension = false) => {
    if (!phone) return '';
    return phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
};

export const formatPhoneNumberWithBrackets = phoneNumberString => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join(
            ''
        );
    }
    return null;
};

export const formatInternationalPhoneNumber = (
    phone,
    removeExtension = false
) => {
    if (!phone) return '';
    if (removeExtension && phone.includes('+1')) {
        phone = phone.slice(2);
    }
    if (phone.includes('+1'))
        return (
            phone.slice(0, 2) +
            '-' +
            phone.slice(2, 5) +
            '-' +
            phone.slice(5, 8) +
            '-' +
            phone.slice(8)
        );
    return (
        '1-' +
        phone.slice(0, 3) +
        '-' +
        phone.slice(3, 6) +
        '-' +
        phone.slice(6)
    );
};

export const agentCardPhoneNumber = (phone, removeExtension = false) => {
    if (!phone) return '';
    if (removeExtension && phone.includes('+1')) {
        phone = phone.slice(2);
    }
    if (phone.includes('+1'))
        return (
            phone.slice(0, 2) +
            '-' +
            phone.slice(2, 5) +
            '-' +
            phone.slice(5, 8) +
            '-' +
            phone.slice(8)
        );
    return phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6);
};

export default formatPhoneNumber;
