import Icon from 'components/Icon';
import SubText from 'components/SubText';

import fullAddress from 'utilities/fullAddress';
import fullAddressTwoLine from 'utilities/fullAddressTwoLine';

import OutofNetwork_Icon from 'temp/components/PrescriptionsTable/icons/OutofNetwork_Icon.svg';
import greenCheckImage from 'temp/components/PrescriptionsTable/icons/greenCheckImage.svg';

import styles from './styles.module.scss';

export const AddressWithInOrOutNetworkIcon = ({
    address,
    className = '',
    inline = false,
    inNetworkIcon = false
}) => {
    const {
        streetLine1 = '',
        streetLine2 = '',
        city = '',
        state = '',
        zipCode = ''
    } = address || {};

    const CoveredCheck = (
        <Icon className={styles.checkIcon} image={greenCheckImage} />
    );

    const NotCoveredCheck = (
        <Icon className={styles.checkIcon} image={OutofNetwork_Icon} />
    );

    const addressText = inline
        ? fullAddress({
              streetLine1,
              streetLine2,
              city,
              state,
              zipCode
          })
        : fullAddressTwoLine(address);

    return (
        <div className={`${className} ${styles.addressWithInOrOutNetworkIcon}`}>
            {inNetworkIcon &&
                (address?.inNetwork ? CoveredCheck : NotCoveredCheck)}

            <SubText className={className} text={addressText} />
        </div>
    );
};

export default AddressWithInOrOutNetworkIcon;
