import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { AUTHENTICATED_ROUTES } from 'utilities/routes';

export default function useAuthentication() {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const location = useLocation();
    const login = useCallback(async () => {
        if (
            !isLoading &&
            !isAuthenticated &&
            AUTHENTICATED_ROUTES.includes(location.pathname)
        ) {
            localStorage.setItem('redirectUri', location.pathname);
            await loginWithRedirect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isAuthenticated, loginWithRedirect]);

    useEffect(() => {
        login();
    }, [login]);
}
