import * as Sentry from '@sentry/react';

import { API_BASE_URL } from 'utilities/env';
import getJSON from 'utilities/getJSON';

const searchUrl = `${API_BASE_URL}/Search`;

const getDoctor = async ({
    NPIs,
    fips = '',
    page = 1,
    perPage = 10,
    radius = 10,
    signal,
    zipCode = '',
    authToken = ''
}) => {
    const params = { page, perPage, radius, fips, NPIs, zipCode };

    const url = `${searchUrl}/providers`;

    try {
        return await getJSON({ params, signal, url, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

export { getDoctor };
