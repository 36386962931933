export const MeetWithMeIcon = props => (
    <svg
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx="15.1411" cy="15" r="15" fill="#A8C5EE" />
        <g clip-path="url(#clip0_18212_88605)">
            <path
                d="M21.4718 9.61341L19.2918 7.48674M21.3073 14.0867V19.0001C21.3073 20.4734 20.0802 21.6667 18.5652 21.6667H10.0853C8.57034 21.6667 7.34326 20.4734 7.34326 19.0001V10.7534C7.34326 9.28008 8.57034 8.08674 10.0853 8.08674H15.1376M16.4538 14.4934L13.4032 15.3401L14.2739 12.3667L20.1625 6.64008C20.5806 6.22674 21.2661 6.22674 21.6912 6.64008L22.3424 7.27341C22.7674 7.68008 22.7674 8.34674 22.3424 8.76008L16.4538 14.4867V14.4934Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_18212_88605">
                <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(7.00049 6)"
                />
            </clipPath>
        </defs>
    </svg>
);
