import { createSlice } from '@reduxjs/toolkit';

import { QUICK_PROFILE_STEPS } from 'utilities/storageKeys';

import { quickProfileSteps } from './constants';

import { get, set } from 'temp/utilities/storage';

export const quickProfileStepper = createSlice({
    name: 'quickProfileStepper',

    initialState: {
        steps: [],
        currentQuickProfileStep: -1,
        showStepper: false,
        allQuickProfileStepsEnabled: false,
        quickProfileSteps: get(QUICK_PROFILE_STEPS, quickProfileSteps)
    },

    reducers: {
        setQuickProfileCurrentStep: (state, action) => {
            state.currentQuickProfileStep = action.payload;
        },
        skipQuickProfileCurrentStep: (state, action) => {
            state.quickProfileSteps = [...state.quickProfileSteps];
            state.quickProfileSteps[
                state.currentQuickProfileStep
            ].isSkipped = true;
            state.quickProfileSteps[
                state.currentQuickProfileStep
            ].isCompleted = false;
            state.quickProfileSteps[
                state.currentQuickProfileStep
            ].isVisited = true;
            if (
                state.quickProfileSteps.length - 1 !==
                state.currentQuickProfileStep
            )
                state.currentQuickProfileStep =
                    state.currentQuickProfileStep + 1;
            set(QUICK_PROFILE_STEPS, state.quickProfileSteps);
        },
        completeQuickCurrentStep: (state, action) => {
            state.quickProfileSteps = [...state.quickProfileSteps];
            if (state.currentQuickProfileStep === -1)
                state.currentQuickProfileStep = 0;
            state.quickProfileSteps[
                state.currentQuickProfileStep
            ].isSkipped = false;
            state.quickProfileSteps[
                state.currentQuickProfileStep
            ].isCompleted = true;
            state.quickProfileSteps[
                state.currentQuickProfileStep
            ].isVisited = true;
            if (
                state.quickProfileSteps.length - 1 !==
                state.currentQuickProfileStep
            )
                state.currentQuickProfileStep =
                    state.currentQuickProfileStep + 1;
            set(QUICK_PROFILE_STEPS, state.quickProfileSteps);
        },
        skipQuickProfileStep: (state, action) => {
            state.quickProfileSteps = [...state.quickProfileSteps];
            state.quickProfileSteps[action.payload].isSkipped = true;
            state.quickProfileSteps[action.payload].isCompleted = false;
        },
        completeQuickProfileStep: (state, action) => {
            state.quickProfileSteps = [...state.quickProfileSteps];
            state.quickProfileSteps[action.payload].isCompleted = true;
            state.quickProfileSteps[action.payload].isSkipped = false;
        },
        previousQuickProfileStep: (state, action) => {
            state.currentQuickProfileStep = state.currentQuickProfileStep - 1;
        },
        showStepper: (state, action) => {
            state.showStepper = true;
            state.currentStep = 0;
        },
        hideStepper: (state, action) => {
            state.showStepper = false;
            state.currentStep = 0;
        },
        resetStepperState: state => {
            state.steps = [];
            state.currentQucikProfileStep = -1;
            state.showStepper = false;
            state.allQuickProfileStepsEnabled = false;
        },
        enableAllQuickProfileSteps: state => {
            const quickProfileStepsToUpdate = [...state.quickProfileSteps];
            quickProfileStepsToUpdate.forEach(s => (s.enabled = true));
            state.allQuickProfileStepsEnabled = true;
            state.quickProfileSteps = quickProfileStepsToUpdate;
        },
        enableQuikProfileStep: (state, action) => {
            const quickProfileStepsToUpdate = [...state.quickProfileSteps];
            quickProfileStepsToUpdate[action.payload].enabled = true;
            state.quickProfileSteps = quickProfileStepsToUpdate;
        },
        setQuickProfileStepper: (state, action) => {
            state.isQuickProfile = true;
            state.currentQuickProfileStep = 0;
        }
    }
});

export const {
    setQuickProfileCurrentStep,
    skipQuickProfileCurrentStep,
    completeQuickProfileCurrentStep,
    previousQuickProfileStep,
    showStepper,
    setQuickProfileStepper,
    hideStepper,
    resetStepperState,
    enableAllQuickProfileSteps,
    enableQuickProfileStep,
    skipQuickProfileStep,
    completeQuickProfileStep
} = quickProfileStepper.actions;

export default quickProfileStepper.reducer;
