import { useMemo, useState } from 'react';

import AddressWithCheckBox from 'components/AddressWithCheckBox';
import Body2 from 'components/Body2';
import Icon from 'components/Icon';
import RadioWithLabel from 'components/RadioWithLabel';
import Text from 'components/Text';

import fullAddressTwoLine from 'utilities/fullAddressTwoLine';

import OutofNetwork_Icon from 'temp/components/PrescriptionsTable/icons/OutofNetwork_Icon.svg';
import greenCheckImage from 'temp/components/PrescriptionsTable/icons/greenCheckImage.svg';

import styles from './styles.module.scss';

const PharmacyListItem = ({
    active,
    address1,
    address2,
    checked,
    city,
    id,
    selectedPharmacies,
    inNetworkIcon = false,
    inOutNetwork,
    isRadio = false,
    key,
    name,
    onClick,
    state,
    value,
    zipCode,
    selectPharmacy,
    tab
}) => {
    const CoveredCheck = (
        <Icon className={styles.checkIcon} image={greenCheckImage} />
    );
    const NotCoveredCheck = (
        <Icon className={styles.checkIcon} image={OutofNetwork_Icon} />
    );

    const htmlContent = useMemo(
        () => (
            <div
                className={`${styles.addressBlock} ${
                    inNetworkIcon ? styles.networkIcon : ''
                }`}
            >
                {inNetworkIcon &&
                    (inOutNetwork ? CoveredCheck : NotCoveredCheck)}
                <div className={styles.addressContainer}>
                    <Text
                        className={
                            isRadio &&
                            !checked &&
                            selectedPharmacies?.length === 3
                                ? styles.disableText
                                : styles.text
                        }
                        text={name}
                    />

                    {(address1 || address2 || city || state || zipCode) && (
                        <Body2
                            text={fullAddressTwoLine({
                                streetLine1: address1,
                                streetLine2: address2,
                                city,
                                state,
                                zipCode
                            })}
                            className={
                                isRadio &&
                                !checked &&
                                selectedPharmacies?.length === 3
                                    ? styles.disableAddress
                                    : styles.address
                            }
                        />
                    )}
                </div>
            </div>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [inNetworkIcon, tab, selectedPharmacies]
    );

    return (
        <li
            className={`${styles.pharmacyListItem}  ${
                checked || active ? styles.active : ''
            }  ${
                (id === 'pharmacy-searchItem-0' ||
                    id === 'digital-pharmacy-searchItem-0') &&
                styles.firstPharmacyListItem
            } ${selectPharmacy}`}
            key={key}
            id={id}
        >
            {isRadio ? (
                <AddressWithCheckBox
                    address={htmlContent}
                    handleSelect={(isChecked, data) => {
                        onClick(data, isChecked);
                    }}
                    id={`${id}`}
                    isChecked={checked}
                    maxReached={selectedPharmacies?.length === 3 ? true : false}
                    isFromPharmacy={true}
                />
            ) : (
                <>{htmlContent}</>
            )}
        </li>
    );
};

export default PharmacyListItem;
