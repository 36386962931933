import { createSlice } from '@reduxjs/toolkit';

export const healthPlanModalDetails = createSlice({
    name: 'healthPlanModalDetails',

    initialState: {
        healthPlanContentModal: false
    },

    reducers: {
        setHealthPlanContentModal: (state, action) => {
            state.healthPlanContentModal = action.payload;
        }
    }
});

export const { setHealthPlanContentModal } = healthPlanModalDetails.actions;

export default healthPlanModalDetails.reducer;
