import isFunction from 'utilities/isFunction';

const get = (key, defaultValue) => {
    let value = JSON.parse(localStorage.getItem(key))?.value;

    if (value === undefined) {
        value = isFunction(defaultValue) ? defaultValue() : defaultValue;
        set(key, value);
    }

    return value;
};

const set = (key, value) =>
    localStorage.setItem(key, JSON.stringify({ value }));

const remove = key => localStorage.removeItem(key);

export { get, remove, set };
