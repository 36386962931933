import Body2 from 'components/Body2';
import Icon from 'components/Icon';

const TextWithIcon = props => {
    const {
        alt = '',
        className = '',
        iconClassName,
        image,
        text = '',
        textClassName
    } = props;

    return (
        <span className={className}>
            <Body2 className={textClassName} text={text} />

            <Icon alt={alt} className={iconClassName} image={image} />
        </span>
    );
};

export default TextWithIcon;
