import { createSlice } from '@reduxjs/toolkit';

export const learningCenterTags = createSlice({
    name: 'learningCenterTags',

    initialState: {
        filterTags: []
    },

    reducers: {
        setFilterTags: (state, action) => {
            state.filterTags = action.payload;
        }
    }
});

export const { setFilterTags } = learningCenterTags.actions;

export default learningCenterTags.reducer;
