import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import Text from 'components/Text';
import YourProfilePharmacyModalContent from 'components/YourProfilePharmacyModalContent';

import dataLayer from 'utilities/dataLayer';

import {
    headerTitle,
    maxReachedTextInListForm,
    saveButtonLabel
} from './constants';
import { setProfilePharmacyContentModal } from './slice';

import styles from './styles.module.scss';

const YourProfilePharmacyModal = ({
    openPharmacyModal,
    PharmacyList,
    deletePharmacy,
    isAdd
}) => {
    const profilePharmacyModal = useSelector(
        state => state.profilePharmacyModalDetails?.profilePharmacyContentModal
    );

    useEffect(() => {
        if (profilePharmacyModal) {
            dataLayer('modal_appear', headerTitle);
        }
    }, [profilePharmacyModal]);

    const dispatch = useDispatch();

    const onClose = async () => {
        isAdd && isAdd(false);
        dispatch(setProfilePharmacyContentModal(false));
    };

    const footerButtonClicked = async e => {
        dispatch(setProfilePharmacyContentModal(false));
    };

    const CountText = () => (
        <span className={styles.countText}>
            {3 - PharmacyList?.length} additional Pharmacies.
        </span>
    );

    return (
        <Modal className={styles.modal} isOpen={profilePharmacyModal}>
            <ModalWindow
                closeIconClassName={styles.closeIconClassName}
                contentClassName={styles.contentClassName}
                footerButtonClassName={styles.footerButtonClassName}
                footerButtonClicked={footerButtonClicked}
                footerClassName={styles.footerClassName}
                footerLabel={saveButtonLabel}
                headerClassName={styles.headerClassName}
                headerTitle={headerTitle}
                modalWindow={styles.modalWindow}
                onClose={() => onClose()}
                headerHeadingClassName={styles.headerHeadingClassName}
                modalHeaderTitle={styles.modalHeaderTitle}
            >
                {PharmacyList?.length === 3 && (
                    <Text className={styles.banner} id="showMaxReached">
                        {maxReachedTextInListForm}
                    </Text>
                )}
                {PharmacyList?.length >= 1 && PharmacyList?.length < 3 && (
                    <Text className={styles.infoBanner} id="showReached">
                        You may select a maximum of 3 Pharmacies, including
                        physical and online options. Add up to &nbsp;
                        <CountText />
                    </Text>
                )}

                <YourProfilePharmacyModalContent
                    openPharmacyModal={openPharmacyModal}
                    deletePharmacy={deletePharmacy}
                    PharmacyDetailList={PharmacyList}
                    isAdd={isAdd}
                />
            </ModalWindow>
        </Modal>
    );
};

export default YourProfilePharmacyModal;
