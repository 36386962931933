import { createSlice } from '@reduxjs/toolkit';

export const userDetails = createSlice({
    name: 'userDetails',

    initialState: {
        userData: null
    },

    reducers: {
        setUserData: (state, action) => {
            state.userData = action.payload;
        }
    }
});

export const { setUserData } = userDetails.actions;

export default userDetails.reducer;
