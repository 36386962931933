import FooterListItem from 'components/FooterListItem';

import styles from './styles.module.scss';

const FooterListTitleItem = ({ className, text, onClick }) => (
    <FooterListItem
        className={`${styles.footerListTitleItem} ${className}`}
        onClick={onClick}
        text={text}
    />
);

export default FooterListTitleItem;
