import { useMemo, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import PlanCostLabel from 'components/PlanCostLabel';

import { getDateOptions } from 'utilities/dateUtils';
import { FROM_EMAIL } from 'utilities/storageKeys';

import './index.scss';

import { get } from 'temp/utilities/storage';

export default function PlanEffectiveDateFilter({
    changeFilter = () => {},
    className = '',
    initialValue,

    isGuided = false,
    isMobile = false,
    isModal = false
}) {
    const [open, setOpen] = useState(false);
    const hidePlanEffectiveFilter = get(FROM_EMAIL);
    const onChange = e => {
        const { value } = e?.target || {};
        changeFilter({ target: { value, name: 'filterDate' } });
    };
    const onOpen = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const filterSelect = useMemo(() => {
        const options = getDateOptions();
        return (
            <Select
                onClose={onClose}
                open={open}
                onOpen={onOpen}
                IconComponent={() => (
                    <KeyboardArrowDownIcon
                        className={`selectIconStyle ${
                            open ? 'selectIconStyleActive' : ''
                        }`}
                    />
                )}
                className="select"
                id="demo-simple-select"
                labelId="demo-simple-select-label"
                onChange={onChange}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 200
                        }
                    }
                }}
                value={
                    options.find(item => item.value === initialValue)
                        ? initialValue
                        : options[0].value
                }
            >
                {options.map((item, index) => (
                    <MenuItem
                        key={index}
                        value={item.value}
                        id={item.label?.replaceAll(' ', '_')}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        );
        // eslint-disable-next-line
    }, [initialValue, open]);

    return hidePlanEffectiveFilter ? (
        <></>
    ) : (
        <div
            className={`${className} plan-date-filter  ${
                isGuided ? 'guidedBox' : 'nonGuidedBox'
            } ${isModal ? 'modalBox' : ''}`}
        >
            {isModal ? (
                <div>
                    <div className="header noBgColor">
                        <PlanCostLabel
                            className="modalHeader"
                            description="This is the date that your Medicare coverage begins. Coverage always begins on the 1st day of a month, depending on when you sign up."
                            label="Effective Date"
                            modalBoxClassName="planFilterModal"
                        />
                    </div>
                    {filterSelect}
                </div>
            ) : (
                <>
                    {isMobile && (
                        <>
                            <div className="header">
                                <PlanCostLabel
                                    className="modalHeader"
                                    description="This is the date that your Medicare coverage begins. Coverage always begins on the 1st day of a month, depending on when you sign up."
                                    label="Effective Date"
                                    modalBoxClassName="planFilterModal"
                                />
                            </div>
                            {filterSelect}
                        </>
                    )}

                    {!isMobile && (
                        <>
                            <div className="header">
                                {' '}
                                <PlanCostLabel
                                    className="modalHeader"
                                    description="This is the date that your Medicare coverage begins. Coverage always begins on the 1st day of a month, depending on when you sign up."
                                    label="Effective Date"
                                    modalBoxClassName="planFilterModal"
                                />
                            </div>
                            {filterSelect}
                        </>
                    )}
                </>
            )}
        </div>
    );
}
