import Button from 'components/Button';

import image from './image.svg';

import styles from './styles.module.scss';

const BackButton = ({
    className = '',
    disabled,
    label,
    onClick,
    id,
    isBack = false,
    type = 'button'
}) => (
    <div className={styles.backButton}>
        <img className={styles.image} alt="back arrow" src={image} />

        <Button
            className={`${
                isBack ? styles.backButton : styles.button
            } ${className}`}
            disabled={disabled}
            id={id}
            label={label}
            onClick={onClick}
            type={type}
        />
    </div>
);

export default BackButton;
