export const HealthInfoIcon = props => (
    <svg
        width="31"
        height="30"
        viewBox="0 0 30 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx="15" cy="15.5" r="15" fill="#1157EE" />
        <g clip-path="url(#clip0_9567_65800)">
            <path
                d="M20.5719 17.0647H18.8685C18.3399 17.0647 18.0182 16.6545 17.7832 16.1846C17.7832 16.1846 17.3939 15.1744 17.3707 15.1744C17.3476 15.1744 15.9022 18.7094 15.9022 18.7094C15.8066 18.8773 15.6074 18.9444 15.4617 18.9444C15.316 18.9444 15.1401 18.883 15.0505 18.68C15.0505 18.68 13.647 13.8531 13.5118 13.8531C13.3766 13.8531 13.0086 15.702 12.8479 16.2131C12.6872 16.7241 12.1431 17.0648 11.5557 17.0648H9.02996C8.35447 16.2718 7.97266 15.2732 7.97266 14.216V14.0985C7.97266 12.072 9.41172 10.3099 11.4382 9.95747C12.6424 9.7225 13.8758 10.0456 14.8156 10.7798C14.9918 10.9266 15.4911 11.4259 15.4911 11.4259C15.4911 11.4259 16.1078 10.8092 16.1666 10.7798C17.1064 10.0456 18.3105 9.7225 19.5147 9.95747C21.5412 10.3099 23.0096 12.072 23.0096 14.0985V14.216C23.0096 15.2732 22.5984 16.2718 21.9229 17.0648L20.5719 17.0647Z"
                stroke="white"
                stroke-width="0.738203"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M20.9546 18.3106L16.1968 22.7453L16.1381 22.804C15.9326 22.9509 15.7269 23.0096 15.492 23.0096C15.1983 23.0096 14.934 22.9215 14.7578 22.7453L10 18.3105"
                stroke="white"
                stroke-width="0.738203"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_9567_65800">
                <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(7.5 8.5)"
                />
            </clipPath>
        </defs>
    </svg>
);

export default HealthInfoIcon;
