import { createSlice } from '@reduxjs/toolkit';

export const profilePrescriptionsModalDetails = createSlice({
    name: 'profilePrescriptionsModalDetails',

    initialState: {
        profilePrescriptionsContentModal: false
    },

    reducers: {
        setProfilePrescriptionsContentModal: (state, action) => {
            state.profilePrescriptionsContentModal = action.payload;
        }
    }
});

export const { setProfilePrescriptionsContentModal } =
    profilePrescriptionsModalDetails.actions;

export default profilePrescriptionsModalDetails.reducer;
