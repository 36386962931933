import React, { useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import YourSupplementModalContent from 'components/YourSupplementModalContent';

import image from './images/image.svg';

import {
    title,
    footerButtonLabel,
    headerText,
    addTitle,
    editTitle,
    editFooterButtonLabel
} from './constants';
import { setView, setsupplementContentModal } from './slice';

import styles from './styles.module.scss';

const YourSupplementModal = ({ modalBoxClassName = '' }) => {
    const supplementPageRef = useRef();
    const dispatch = useDispatch();
    const { supplementContentModal, view, isFooterButtonDisabled } =
        useSelector(state => state.supplementModalDetails);

    const onClose = async () => {
        dispatch(setsupplementContentModal(false));
        dispatch(setView('list'));
    };

    const footerButtonClicked = async e => {
        if (view !== 'list') supplementPageRef.current.formSubmit();
        else onClose();
    };

    const getHeaderTitle = useMemo(() => {
        return view === 'list' ? title : view === 'add' ? addTitle : editTitle;
    }, [view]);

    return (
        <Modal isOpen={supplementContentModal}>
            <ModalWindow
                contentClassName={styles.contentClassName}
                footerButtonClassName={styles.footerButtonClassName}
                footerButtonClicked={footerButtonClicked}
                footerClassName={styles.footerClassName}
                footerId={'footerButton'}
                footerLabel={
                    view === 'edit' ? editFooterButtonLabel : footerButtonLabel
                }
                footerButtonDisabled={isFooterButtonDisabled}
                headerClassName={styles.headerClassName}
                headerIcon={view !== 'list' ? image : null}
                headerText={view !== 'list' ? headerText : ''}
                headerTitle={getHeaderTitle}
                hideFooter={false}
                modalBoxClassName={modalBoxClassName}
                modalHeaderTitle={styles.modalHeaderTitle}
                onClose={onClose}
            >
                <YourSupplementModalContent ref={supplementPageRef} />
            </ModalWindow>
        </Modal>
    );
};

export default YourSupplementModal;
