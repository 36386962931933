import { createSlice } from '@reduxjs/toolkit';

export const planDetails = createSlice({
    name: 'planDetails',

    initialState: {
        annualPlanPremium: '',
        documents: [],
        effectiveDate: '',
        estimatedAnnualDrugCost: '',
        fips: null,
        hasMailDrugBenefits: null,
        hasPreferredMailPharmacyNetwork: null,
        hasPreferredRetailPharmacyNetwork: null,
        plan: null,
        planId: null,
        planType: '',
        zip: null
    },

    reducers: {
        setFips: (state, action) => {
            state.fips = action.payload;
        },

        // TODO: We should NOT store the full plan like this - we should get the separate data points
        setPlan: (state, action) => {
            state.plan = action.payload;
        },

        setPlanId: (state, action) => {
            state.planId = action.payload;
        },

        setZip: (state, action) => {
            state.zip = action.payload;
        },

        setEffectiveDate: (state, action) => {
            state.effectiveDate = action.payload;
        }
    }
});

export const { setFips, setPlan, setPlanId, setZip, setEffectiveDate } =
    planDetails.actions;

export default planDetails.reducer;
