import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

export const checkModalDataHasChanged = (initialData = [], modalData = []) => {
    if (initialData?.length !== modalData?.length) {
        return true;
    } else if (initialData?.length === 0 && modalData?.length === 0) {
        return false;
    } else {
        return !!differenceWith(modalData, initialData, isEqual)?.length;
    }
};
