import React, { useEffect, useState, useMemo, memo } from 'react';

import { CameraAltOutlined } from '@mui/icons-material';
import { Avatar } from '@mui/material';

import styles from './styles.module.scss';

const avatarStyles = {
    bgcolor: 'blue',
    fontSize: '32px',
    height: '100px',
    textTransform: 'uppercase',
    width: '100px'
};

const progressBarAvtarStyles = {
    ...avatarStyles,
    left: '11px',
    position: 'absolute',
    top: '11px'
};

const ProfilePicture = ({
    enableProgress,
    firstName,
    lastName,
    profileUrl,
    error,
    onClick = () => {},
    className,
    hideCameraOverlay = false,
    styleProps = {},
    avatarClassName = ''
}) => {
    const [preview, setPreview] = useState();

    const getName = useMemo(() => {
        return firstName ? firstName?.charAt(0) + lastName?.charAt(0) : '';
    }, [firstName, lastName]);

    useEffect(() => {
        setPreview(profileUrl);
    }, [profileUrl]);

    return (
        <>
            <div
                className={`${styles.imageBlock} ${className} ${
                    error ? styles.error : ''
                }`}
            >
                {enableProgress && (
                    <div className={styles.progressBar}>
                        <progress
                            className={styles.progress}
                            max="100"
                            min="0"
                            value={100}
                        >
                            {100}%
                        </progress>
                    </div>
                )}
                <Avatar
                    alt="RZ"
                    sx={
                        enableProgress
                            ? progressBarAvtarStyles
                            : { ...avatarStyles, ...styleProps }
                    }
                    src={preview}
                    className={avatarClassName}
                >
                    {getName}
                </Avatar>
                {!hideCameraOverlay && (
                    <div className={styles.overlay} onClick={onClick}>
                        <CameraAltOutlined className={styles.icon} />
                    </div>
                )}
            </div>
        </>
    );
};

export default memo(ProfilePicture);
