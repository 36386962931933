import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Icon from 'components/Icon';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import Text from 'components/Text';
import YourMedicareHealthInsuranceModalContent from 'components/YourMedicareHealthInsuranceModalContent';

import dataLayer from 'utilities/dataLayer';

import { headerTitle, saveButtonLabel } from './constants';
import { setHealthInsuranceContentModal } from './slice';

import styles from './styles.module.scss';

const YourMedicareHealthInsuranceModal = ({ insuranceImage }) => {
    const [showFooter, setShowFooter] = useState(false);
    const [partA, setPartA] = useState('MM-DD-YYYY');
    const [partB, setPartB] = useState('MM-DD-YYYY');
    const [firstName, setFirstName] = useState('YOUR');
    const [lastName, setLastName] = useState('NAME');
    const [medicareIdNumber, setMedicareIdNumber] = useState('000-000-000XXX');
    const [footerButtonDisabled, setFooterButtonDisabled] = useState(false);
    const contactDetailsPageRef = useRef();
    const healthInsuranceModal = useSelector(
        state => state.healthInsuranceModalDetails?.healthInsuranceContentModal
    );

    useEffect(() => {
        if (healthInsuranceModal) {
            dataLayer('modal_appear', headerTitle);
        }
    }, [healthInsuranceModal]);
    const dispatch = useDispatch();

    const onClose = async () => {
        setShowFooter(false);
        dispatch(setHealthInsuranceContentModal(false));
    };

    const footerButtonClicked = async e => {
        contactDetailsPageRef.current.formSubmit();
    };

    return (
        <Modal className={styles.modal} isOpen={healthInsuranceModal}>
            <ModalWindow
                closeIconClassName={styles.closeIconClassName}
                contentClassName={styles.contentClassName}
                footerButtonClassName={styles.footerButtonClassName}
                footerButtonClicked={footerButtonClicked}
                footerButtonDisabled={footerButtonDisabled}
                footerClassName={styles.footerClassName}
                footerLabel={saveButtonLabel}
                headerClassName={styles.headerClassName}
                headerTitle={headerTitle}
                modalWindow={styles.modalWindow}
                onClose={() => onClose()}
                hideFooter={!showFooter}
                headerHeadingClassName={styles.headerHeadingClassName}
                modalHeaderTitle={styles.modalHeaderTitle}
            >
                <div className={styles.content}>
                    <div className={styles.insuranceIcon}>
                        <Icon
                            className={`${styles.infoIcon}`}
                            image={insuranceImage}
                            id="insuranceIcon"
                        />
                        <Text
                            text={`${
                                firstName || lastName
                                    ? firstName + ' ' + lastName
                                    : 'YOUR NAME'
                            }`}
                            className={styles.name}
                        />
                        <Text
                            text={medicareIdNumber || '000-000-000XXX'}
                            className={styles.medicareID}
                        />
                        <Text
                            text={partA || 'MM-DD-YYYY'}
                            className={styles.partA}
                        />
                        <Text
                            text={partB || 'MM-DD-YYYY'}
                            className={styles.partB}
                        />
                    </div>

                    <YourMedicareHealthInsuranceModalContent
                        className={styles.manageAccountContent}
                        showFooter={setShowFooter}
                        ref={contactDetailsPageRef}
                        footerButtonDisabled={setFooterButtonDisabled}
                        firstNameVal={setFirstName}
                        lastNameVal={setLastName}
                        medicareIdNumberVal={setMedicareIdNumber}
                        partBVal={setPartB}
                        partAVal={setPartA}
                    />
                </div>
            </ModalWindow>
        </Modal>
    );
};

export default YourMedicareHealthInsuranceModal;
