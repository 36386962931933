import ActionText from 'components/ActionText';

import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const TextAsLink = ({ className = '', text, onClick, id = 'link' }) => {
    const handleClick = onClick => handleEvent(onClick);

    return (
        <ActionText
            className={`${className} ${styles.textAsLink}`}
            onClick={handleClick(onClick)}
            id={id}
        >
            {text}
        </ActionText>
    );
};

export default TextAsLink;
