import LinkText from '../LinkText';

import Button from 'components/Button';
import Heading3 from 'components/Heading3';
import Icon from 'components/Icon';
import LinkToNewTab from 'components/LinkToNewTab';
import LinkWithArrow from 'components/LinkWithArrow';
import Text from 'components/Text';
import TextWithIcon from 'components/TextWithIcon';

import { agentCardPhoneNumber } from 'utilities/formatPhoneNumber';
import {
    FINAL_EXPENSE_AGENT,
    FINAL_EXPENSE_REQUEST_CALL_BACK,
    REQUEST_CALL_BACK
} from 'utilities/routes';

import callBack from './images/callBack.png';
import networkAgent from './images/networkAgent.png';
import phone from './images/phone.png';

import offline from './images/offline.svg';
import online from './images/online.svg';

import styles from './styles.module.scss';

const AgentDetailsCardNoPurl = ({
    agentCompletedTraining = 'False',
    agentEmail,
    agentFirstName,
    agentLastName,
    agentLeadCampaignId,
    agentNPN,
    agentPURL,
    agentPhone,
    caLicense,
    lifePreference,
    healthPreference,
    agentToggleStatus = 'False',
    hasCampaign = 'False',
    isAgentModal = false,
    leadTypeName,
    selectAgentNpnValue,
    setSelectedAgent = '',
    showPhoneNumber,
    selectedType,
    viewAgentDetail = () => {}
}) => {
    const toggleStatus = agentToggleStatus === 'True';
    const name = `${agentFirstName} ${agentLastName}`;
    const scrollContactLater = () => {
        const element = document.getElementById('contactContainer');
        element.scrollIntoView();
        localStorage.setItem(
            'reqAgentData',
            JSON.stringify({
                agentFirstName: agentFirstName,
                agentLastName: agentLastName,
                agentLeadCampaignId: agentLeadCampaignId,
                agentNpn: agentNPN,
                agentPurlCode: agentPURL,
                caLicense: caLicense,
                email: agentEmail,
                leadTypeName: leadTypeName,
                virtualNumber: agentPhone
            })
        );

        window.location.href = `${import.meta.env.VITE_APP_APP_BASE_URL}${
            window.location.pathname === FINAL_EXPENSE_AGENT
                ? FINAL_EXPENSE_REQUEST_CALL_BACK
                : REQUEST_CALL_BACK
        }?purl=${agentPURL}`;
    };
    const selectAgent = () => {
        setSelectedAgent(agentPURL);
    };
    const isNetworkAgent = agentCompletedTraining === 'True';

    const callBackIcon = () => {
        return (
            <span className={styles.requestText}>
                <Icon className={styles.callBackIcon} image={callBack} />

                <LinkText
                    onClick={() => {
                        scrollContactLater();
                    }}
                    text={'Request a Callback'}
                    url={'#'}
                    linkTextClass={styles.linkText}
                />
            </span>
        );
    };

    const onClickViewAgentDetails = () => {
        viewAgentDetail(agentNPN);
    };

    return (
        <div className={styles.cardContainer}>
            <div className={styles.btnContainer}>
                {hasCampaign === 'True' && (
                    <Text
                        className={styles.preferredAgentText}
                        text="Preferred Agent"
                    />
                )}

                {agentCompletedTraining === 'True' && (
                    <TextWithIcon
                        className={styles.networkAgent}
                        iconClassName={styles.icon}
                        image={networkAgent}
                        text="Network Agent"
                        textClassName={styles.networkAgentText}
                    />
                )}
            </div>

            <div
                className={
                    hasCampaign === 'True'
                        ? styles.preferredAgentDetailsCard
                        : styles.agentDetailsCard
                }
            >
                <div className={styles.heading}>
                    <div className={styles.headingContainer}>
                        <div className={styles.headingWithAvailability}>
                            <Heading3
                                className={styles.plan}
                                text={`${
                                    healthPreference && lifePreference
                                        ? window.location.pathname ===
                                              FINAL_EXPENSE_AGENT ||
                                          selectedType === 'FinalExpense'
                                            ? 'Final Expense + Medicare'
                                            : 'Medicare + Final Expense'
                                        : window.location.pathname ===
                                              FINAL_EXPENSE_AGENT ||
                                          selectedType === 'FinalExpense'
                                        ? 'Final Expense'
                                        : 'Medicare'
                                }`}
                            />
                            {isNetworkAgent &&
                                (toggleStatus ? (
                                    <TextWithIcon
                                        className={styles.status}
                                        iconClassName={styles.icon}
                                        image={online}
                                        text="Available"
                                        textClassName={styles.online}
                                    />
                                ) : (
                                    <TextWithIcon
                                        className={styles.status}
                                        iconClassName={styles.icon}
                                        image={offline}
                                        text="Offline"
                                        textClassName={styles.offline}
                                    />
                                ))}
                        </div>
                        <Heading3 className={styles.heading3} text={name} />

                        <Text
                            className={styles.agentNpn}
                            text={`NPN: ${agentNPN}`}
                        />
                    </div>
                </div>
                <div className={styles.callContainer}>
                    {!isAgentModal ? (
                        <div className={styles.detailsContainer}>
                            {callBackIcon()}
                        </div>
                    ) : (
                        <></>
                    )}

                    <div
                        className={`${styles.detailsContainer} ${styles.callAgent} `}
                    >
                        {isAgentModal ? (
                            <div>
                                <Button
                                    className={`${styles.button}`}
                                    label="Select Agent"
                                    onClick={selectAgent}
                                />
                            </div>
                        ) : (
                            <div>
                                <Icon
                                    image={phone}
                                    className={styles.phoneIcon}
                                />
                                {agentCardPhoneNumber(agentPhone, true)}
                            </div>
                        )}
                    </div>
                </div>
                <LinkWithArrow
                    text={'View More'}
                    iconClassName={styles.viewMore}
                    onClick={onClickViewAgentDetails}
                />
            </div>
        </div>
    );
};

export default AgentDetailsCardNoPurl;
