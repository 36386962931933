import fullAddress from './fullAddress';

const fullAddressTwoLine = ({
    streetLine1 = '',
    streetLine2 = '',
    city = '',
    state = '',
    zipCode = ''
}) => {
    return (
        <>
            {fullAddress({ streetLine1, streetLine2 })}
            <br />
            {fullAddress({ city, state, zipCode })}
        </>
    );
};

export default fullAddressTwoLine;
