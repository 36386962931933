import * as Sentry from '@sentry/react';

import deleteJSON from 'utilities/deleteJSON';
import { API_BASE_URL } from 'utilities/env';
import getJSON from 'utilities/getJSON';
import postJSON from 'utilities/postJSON';

import { getLeadId } from './leadId';

const getUrl = () => {
    const leadId = getLeadId();
    return `${API_BASE_URL}/Lead/${leadId}/Prescriptions`;
};

const addPrescription = async ({
    dosageID,
    ndc,
    metricQuantity,
    daysOfSupply,
    selectedPackage,
    syncId,
    authToken
}) => {
    const quantity = selectedPackage
        ? selectedPackage?.metricQuantity * metricQuantity
        : metricQuantity;

    const body = {
        dosageID,
        ndc,
        metricQuantity: quantity,
        daysOfSupply,
        selectedPackage
    };
    const url = `${getUrl()}/syncid/${syncId}`;
    let response;
    try {
        response = await postJSON({ url, body, authToken });
    } catch (e) {
        Sentry.captureException(e);
    }
    return response;
};

const updatePrescription = async ({
    dosageID,
    ndc,
    metricQuantity,
    daysOfSupply,
    selectedPackage,
    dosageRecordID,
    authToken
}) => {
    const quantity = selectedPackage
        ? selectedPackage?.metricQuantity * metricQuantity
        : metricQuantity;

    const body = {
        dosageID,
        ndc,
        metricQuantity: quantity,
        daysOfSupply,
        selectedPackage
    };
    const url = `${getUrl()}/${dosageRecordID}`;
    let response;
    try {
        response = await postJSON({ url, body, authToken });
    } catch (e) {
        Sentry.captureException(e);
    }
    return response;
};

const deletePrescription = async ({ id, syncId, authToken }) => {
    let url = `${getUrl()}/${id}`;
    if (syncId) url = `${url}/${syncId}`;
    let response;
    try {
        response = await deleteJSON({ url, authToken });
    } catch (e) {
        Sentry.captureException(e);
    }
    return response;
};

const getPrescriptions = async (authToken = '') => {
    let response = null;
    try {
        response = getJSON({ url: getUrl(), authToken });
    } catch (e) {
        Sentry.captureException(e);
    }
    return response;
};

export {
    addPrescription,
    deletePrescription,
    getPrescriptions,
    updatePrescription
};
