import LinkImage from '../LinkImage';
import React from 'react';

import styles from './styles.module.scss';

const SocialMediaLogo = logoProps => (
    <div className={styles.logo}>
        <LinkImage
            url={logoProps?.url}
            alt={logoProps?.alt}
            src={logoProps?.src}
            id={logoProps?.id}
        />
    </div>
);

export default SocialMediaLogo;
