export const LocationIcon = props => (
    <svg
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx="15.5" cy="15" r="15" fill="#1157EE" />
        <path
            d="M15.5007 8C16.7383 8.00012 17.9252 8.49011 18.8003 9.36219C19.6755 10.2343 20.1672 11.417 20.1673 12.6503C20.1673 14.8493 16.7449 20.3266 15.7624 21.8572C15.7344 21.9011 15.6958 21.9372 15.65 21.9622C15.6042 21.987 15.5528 22 15.5007 22C15.4485 22 15.3971 21.987 15.3513 21.9622C15.3056 21.9372 15.2669 21.9011 15.2389 21.8572C14.2564 20.327 10.834 14.8493 10.834 12.6503C10.8341 11.417 11.3258 10.2343 12.201 9.36219C13.0761 8.49011 14.263 8.00012 15.5007 8Z"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M13.5 12.666C13.4998 13.1288 13.66 13.5773 13.9535 13.9351C14.2469 14.293 14.6553 14.538 15.1091 14.6284C15.563 14.7189 16.0341 14.6491 16.4423 14.4311C16.8505 14.2131 17.1704 13.8603 17.3476 13.4328C17.5248 13.0053 17.5482 12.5296 17.414 12.0867C17.2797 11.6439 16.996 11.2613 16.6113 11.0042C16.2266 10.747 15.7646 10.6313 15.304 10.6766C14.8435 10.722 14.413 10.9256 14.0858 11.2528C13.9001 11.4383 13.7527 11.6586 13.6522 11.9011C13.5517 12.1436 13.5 12.4035 13.5 12.666Z"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default LocationIcon;
