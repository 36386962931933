import { createSlice } from '@reduxjs/toolkit';

export const profilePharmacyModalDetails = createSlice({
    name: 'profilePharmacyModalDetails',

    initialState: {
        profilePharmacyContentModal: false
    },

    reducers: {
        setProfilePharmacyContentModal: (state, action) => {
            state.profilePharmacyContentModal = action.payload;
        }
    }
});

export const { setProfilePharmacyContentModal } =
    profilePharmacyModalDetails.actions;

export default profilePharmacyModalDetails.reducer;
