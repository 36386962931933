const maskMedicareId = medicareId => '****-***-' + medicareId.slice(-4);

export const formatMCIN = mcin => {
    if (mcin && mcin?.trim() !== '') {
        const text = mcin.replace(/-/g, '').toUpperCase().slice(0, 11);
        const value = text.split('').reduce((a, c, i) => {
            if ([4, 7].includes(i)) a += '-';
            a += c;
            return a;
        }, '');
        return value;
    }
    return '';
};

export const formatMCID = mcin => {
    if (mcin && mcin?.trim() !== '') {
        const text = mcin.replace(/-/g, '').toUpperCase().slice(0, 12);
        const value = text.split('').reduce((a, c, i) => {
            if ([3, 6, 9].includes(i)) a += '-';
            a += c;
            return a;
        }, '');
        return value;
    }
    return '';
};

export default maskMedicareId;
