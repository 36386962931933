import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const Heading4 = ({ className = '', text, onClick, id = 'heading' }) => {
    const handleClick = onClick => handleEvent(onClick);

    return (
        <h4
            className={`${className} ${styles.heading4}`}
            onClick={handleClick(onClick)}
            id={id}
        >
            {text}
        </h4>
    );
};

export default Heading4;
