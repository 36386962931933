import { createSlice } from '@reduxjs/toolkit';

import { ENROLL_MODAL_INPUT_DATA } from 'utilities/storageKeys';

import { get, set } from 'temp/utilities/storage';

export const enrollDetails = createSlice({
    name: 'enrollDetails',

    initialState: {
        dsnpPlan: false,
        enrollModalInputData: get(ENROLL_MODAL_INPUT_DATA, {}),
        isEditZipEnrollModalOpen: false,
        isEnrollModalOpen: false,
        planId: ''
    },

    reducers: {
        setIsEnrollModalOpen: (state, action) => {
            state.isEnrollModalOpen = action.payload;
        },

        setIsEditZipEnrollModalOpen: (state, action) => {
            state.isEditZipEnrollModalOpen = action.payload;
        },
        setPlanId: (state, action) => {
            state.planId = action.payload;
        },
        setEnrollModalInputData: (state, action) => {
            state.enrollModalInputData = action.payload;
            set(ENROLL_MODAL_INPUT_DATA, action.payload);
        },
        setDspnPlan: (state, action) => {
            state.dsnpPlan = action.payload;
        }
    }
});

export const {
    setDspnPlan,
    setEnrollModalInputData,
    setIsEditZipEnrollModalOpen,
    setIsEnrollModalOpen,
    setPlanId
} = enrollDetails.actions;

export default enrollDetails.reducer;
