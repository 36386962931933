import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';

import Body3 from 'components/Body3';
import ButtonLinkWithIcon from 'components/ButtonLinkWithIcon';
import Heading3 from 'components/Heading3';
import Text from 'components/Text';
import { setProfilePrescriptionsContentModal } from 'components/YourProfilePrescriptionsModal/slice';

import addIcon from './images/icon-Add.svg';
import editIcon from './images/icon-Edit.svg';

import styles from './styles.module.scss';

import { drugDetailSearch } from 'temp/utilities/apiSearch';
import drugFrequencyOptions from 'temp/utilities/drugFrequencyOptions';

const YourProfilePrescriptionsModalContent = ({
    openPrescriptionModal,
    editDrug,
    prescriptionsList,
    editFromProfile,
    isAdd
}) => {
    const dispatch = useDispatch();

    const emptyBlock = useMemo(
        () => (
            <Text
                text="Add your prescription to keep track and manage."
                className={styles.emptyBlock}
            />
        ),
        []
    );
    const frequencyValue = daysOfSupply =>
        drugFrequencyOptions.find(
            frequency => frequency?.value === daysOfSupply
        )?.text;
    const frequencyMessage = ({ dosage, dosageDetails }) => {
        const { daysOfSupply, metricQuantity, selectedPackage } = dosage;

        return selectedPackage
            ? `${dosage?.userQuantity} X ${
                  selectedPackage?.packageDisplayText
              } ${frequencyValue(daysOfSupply)}`
            : dosageDetails
            ? `${metricQuantity} ${dosageDetails.dosageFormName.toLowerCase()}${
                  metricQuantity > 1 ? 's' : ''
              } ${frequencyValue(daysOfSupply)}`
            : '';
    };
    const { user, getAccessTokenSilently } = useAuth0();
    const getAuthToken = async () => {
        return user ? await getAccessTokenSilently() : '';
    };
    const [prescriptionList, setPrescriptionList] = useState([]);

    useEffect(() => {
        setPrescriptionList(prescriptionsList);
    }, [prescriptionsList]);

    const selectDrug = async (id, dosageRecordID = undefined, dosage) => {
        const authToken = await getAuthToken();
        let drugDetails = await drugDetailSearch({ id, authToken });
        drugDetails = { ...drugDetails, ...dosage };
        editDrug({ ...drugDetails, id: dosageRecordID });
    };

    return (
        <div className={styles.supplementContainer}>
            <div className={styles.header}>
                <Heading3
                    text={
                        <>
                            Prescription{' '}
                            <span className={styles.count}>
                                ({prescriptionList?.length})
                            </span>
                        </>
                    }
                />
                <ButtonLinkWithIcon
                    containerClassName={styles.addNewLink}
                    onClick={() => {
                        isAdd(true);
                        openPrescriptionModal(true);
                        dispatch(setProfilePrescriptionsContentModal(false));
                    }}
                    text="Add New"
                    btnIcon={addIcon}
                />
            </div>
            <div className={styles.content}>
                {!prescriptionList?.length && emptyBlock}
                <ul className={styles.list}>
                    {prescriptionList?.map(prescription => {
                        return (
                            <li
                                className={styles.listItem}
                                key={prescription?.dosage?.drugID}
                            >
                                <div className={styles.listContent}>
                                    <Body3
                                        className={styles.prescriptionName}
                                        text={prescription?.dosage?.drugName}
                                    />

                                    <Body3
                                        className={styles.dosage}
                                        text={frequencyMessage({
                                            dosage: prescription?.dosage,
                                            dosageDetails:
                                                prescription?.dosageDetails
                                        })}
                                    />
                                </div>
                                <ButtonLinkWithIcon
                                    containerClassName={styles.editLink}
                                    onClick={() => {
                                        /*editDrug({
                                            ...prescription?.dosage,
                                            id: prescription?.dosage
                                                ?.dosageRecordID,
                                            dosages: [
                                                prescription?.dosageDetails
                                            ]
                                        });*/
                                        selectDrug(
                                            prescription?.dosage?.drugID,
                                            prescription?.dosage
                                                ?.dosageRecordID,
                                            prescription?.dosage
                                        );
                                        editFromProfile(true);
                                        openPrescriptionModal(true);
                                        dispatch(
                                            setProfilePrescriptionsContentModal(
                                                false
                                            )
                                        );
                                    }}
                                    text="Edit"
                                    btnIcon={editIcon}
                                />
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default YourProfilePrescriptionsModalContent;
