export const HealthConditionIcon = props => (
    <svg
        width="31"
        height="30"
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx="15.4355" cy="15.5" r="15" fill="#052A63" />
        <path
            d="M15.5311 21.9073H9.88196C9.75102 21.9073 9.62136 21.8816 9.50038 21.8315C9.3794 21.7815 9.26947 21.708 9.17686 21.6155C9.08426 21.5229 9.0108 21.413 8.96068 21.2921C8.91056 21.1711 8.88477 21.0414 8.88477 20.9105V8.94739C8.88471 8.81642 8.91046 8.68672 8.96055 8.56571C9.01064 8.4447 9.08409 8.33474 9.1767 8.24213C9.26931 8.14952 9.37927 8.07607 9.50028 8.02598C9.62129 7.97589 9.75099 7.95014 9.88196 7.9502H15.9208C16.0971 7.95022 16.2661 8.02025 16.3907 8.14487L19.3251 11.0793C19.4492 11.2034 19.5192 11.3715 19.5198 11.5469V13.9297"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M13.7387 14.2539L11.7448 16.2478L10.748 15.2506"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M13.7387 12.0234L11.7448 14.0174L10.748 13.0202"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M19.7593 23.0509C20.5481 23.0509 21.3191 22.817 21.9749 22.3788C22.6307 21.9407 23.1418 21.3178 23.4436 20.5891C23.7455 19.8605 23.8245 19.0586 23.6706 18.2851C23.5167 17.5115 23.1369 16.8009 22.5792 16.2432C22.0215 15.6855 21.3109 15.3057 20.5373 15.1518C19.7638 14.998 18.9619 15.0769 18.2333 15.3788C17.5046 15.6806 16.8818 16.1917 16.4436 16.8475C16.0054 17.5033 15.7715 18.2743 15.7715 19.0631C15.7715 20.1207 16.1916 21.135 16.9395 21.8829C17.6874 22.6308 18.7017 23.0509 19.7593 23.0509Z"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M22.1744 18.7309C22.1744 18.6428 22.1394 18.5583 22.0771 18.496C22.0148 18.4337 21.9303 18.3987 21.8422 18.3987H20.5141V17.0715C20.5141 16.9834 20.4791 16.8989 20.4168 16.8366C20.3545 16.7743 20.27 16.7393 20.1819 16.7393H19.5174C19.4293 16.7393 19.3447 16.7743 19.2824 16.8366C19.2201 16.8989 19.1851 16.9834 19.1851 17.0715V18.3987H17.8557C17.7676 18.3987 17.6831 18.4337 17.6208 18.496C17.5584 18.5583 17.5234 18.6428 17.5234 18.7309V19.3959C17.5234 19.484 17.5584 19.5685 17.6208 19.6308C17.6831 19.6931 17.7676 19.7281 17.8557 19.7281H19.1851V21.0576C19.1851 21.1012 19.1937 21.1444 19.2104 21.1847C19.2271 21.225 19.2516 21.2616 19.2824 21.2925C19.3133 21.3234 19.3499 21.3478 19.3902 21.3645C19.4305 21.3812 19.4737 21.3898 19.5174 21.3898H20.1819C20.2255 21.3898 20.2687 21.3812 20.309 21.3645C20.3493 21.3478 20.3859 21.3234 20.4168 21.2925C20.4476 21.2616 20.4721 21.225 20.4888 21.1847C20.5055 21.1444 20.5141 21.1012 20.5141 21.0576V19.7281H21.8435C21.9314 19.7278 22.0156 19.6926 22.0776 19.6303C22.1396 19.5681 22.1744 19.4838 22.1744 19.3959V18.7309Z"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M15.7227 8.57031V11.5012C15.7227 11.5449 15.7313 11.5881 15.748 11.6284C15.7647 11.6688 15.7892 11.7054 15.8201 11.7363C15.851 11.7671 15.8877 11.7916 15.9281 11.8083C15.9684 11.8249 16.0117 11.8335 16.0554 11.8334H18.9867"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default HealthConditionIcon;
