import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const Icon = ({
    alt = '',
    className = '',
    id = 'icon',
    image = '',
    mouseOver = e => {},
    mouseOverOut = e => {},
    onClick,
    onKeyDown,
    onMouseEnter = e => {},
    tabIndex = ''
}) => {
    const handleClick = onClick => handleEvent(onClick);
    const handleKeyDown = onKeyDown => handleEvent(onKeyDown);
    return (
        <img
            alt={alt}
            className={`${className} ${styles.icon}`}
            id={id}
            onClick={handleClick(onClick)}
            onKeyDown={handleKeyDown(onKeyDown)}
            onMouseEnter={onMouseEnter}
            onMouseOut={mouseOverOut}
            onMouseOver={mouseOver}
            src={image}
            tabIndex={tabIndex}
        />
    );
};

export default Icon;
