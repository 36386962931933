import Label from 'components/Label';
import Text from 'components/Text';

import styles from './styles.module.scss';

const TextWithLabel = ({ label = '', text = '' }) => (
    <div>
        <Label text={label} />

        <Text className={styles.labelText} text={text} />
    </div>
);

export default TextWithLabel;
