export const MedicaidIcon = props => (
    <svg
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx="15.6289" cy="15" r="15" fill="#A8C5EE" />
        <path
            d="M9.78745 19.7944C8.67823 18.443 8.07192 16.7488 8.07183 15.0004C8.07178 14.008 8.2672 13.0253 8.64695 12.1084C9.02669 11.1915 9.58332 10.3583 10.285 9.65655C11.5213 8.42022 13.1479 7.65079 14.8879 7.47935C16.6278 7.30791 18.3734 7.74508 19.8271 8.71636C21.2809 9.68763 22.3529 11.1329 22.8605 12.806C23.3681 14.479 23.2799 16.2763 22.6109 17.8916C21.9419 19.507 20.7335 20.8403 19.1916 21.6646C17.6497 22.4888 15.8697 22.7529 14.155 22.4119C12.4402 22.0709 10.8967 21.1459 9.78745 19.7944Z"
            stroke="#FAF8F6"
            stroke-width="0.885844"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M16.9306 12.3916H15.5356C15.2634 12.3918 14.9993 12.4839 14.7859 12.6529C14.5725 12.8219 14.4224 13.0579 14.3599 13.3228C14.2974 13.5878 14.3261 13.866 14.4414 14.1126C14.5567 14.3592 14.7518 14.5596 14.9952 14.6815L16.2666 15.318C16.5103 15.4398 16.7057 15.6402 16.8211 15.8869C16.9366 16.1336 16.9654 16.412 16.9029 16.6771C16.8403 16.9422 16.6901 17.1784 16.4765 17.3475C16.2629 17.5165 15.9985 17.6085 15.7261 17.6084H14.3262"
            stroke="#FAF8F6"
            stroke-width="0.885844"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M15.6289 11.4209V12.3989"
            stroke="#FAF8F6"
            stroke-width="0.885844"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M15.6289 17.7041V18.6826"
            stroke="#FAF8F6"
            stroke-width="0.885844"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default MedicaidIcon;
