import { useHistory } from 'react-router-dom';

import Icon from 'components/Icon';
import IconWithLink from 'components/IconWithLink';

import {
    FINAL_EXPENSE_CONTACT_DETAILS_ROUTE,
    FINAL_EXPENSE_PLANS_ROUTE
} from 'utilities/routes';
import scrollToTop from 'utilities/scrollToTop';

import manageArrow from './images/arrow.png';

import image from './images/Middle_Graphic.svg';

import styles from './styles.module.scss';

const FinalExpenseHeaderNav = ({ from }) => {
    const history = useHistory();

    if (window.location.href.indexOf('#') !== -1) {
        const currentPage = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        const element = document.getElementById(currentPage);
        element?.scrollIntoView();
    }

    const fromPage = localStorage.getItem('healthConditionFromPage');
    const gotoHomePage = () => {
        from === 'ContactDetails'
            ? history.push('/')
            : history.push(
                  fromPage
                      ? FINAL_EXPENSE_PLANS_ROUTE
                      : FINAL_EXPENSE_CONTACT_DETAILS_ROUTE
              );
        scrollToTop();
    };

    return (
        <div className={styles.manageHeader}>
            <IconWithLink
                caption={
                    from === 'ContactDetails'
                        ? 'Back to Choose Plan Type'
                        : `Back to ${fromPage ? 'Plans' : 'Contact'} Details`
                }
                className={styles.iconWithLink}
                iconClassName={styles.iconClassName}
                image={manageArrow}
                isImageClickable={true}
                labelClassName={styles.manageLabelClassName}
                onClick={() => gotoHomePage()}
            />

            <Icon className={styles.middleImage} image={image} />
        </div>
    );
};

export default FinalExpenseHeaderNav;
