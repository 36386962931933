import * as Sentry from '@sentry/react';

import deleteJSON from 'utilities/deleteJSON';
import { HEALTH_CONDITION__URL } from 'utilities/env';
import getJSON from 'utilities/getJSON';
import { getAgentNpn } from 'utilities/getSessionData';
import postJSON from 'utilities/postJSON';
import putJSON from 'utilities/putJSON';

import { getLeadId } from './leadId';

const getUrl = consumerId => {
    return `${HEALTH_CONDITION__URL}/${consumerId}`;
};

const getUserHealthConditions = async ({ consumerId, authToken = '' }) => {
    const url = getUrl(consumerId);

    try {
        return await getJSON({ url, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const deleteUserHealthConditions = async ({
    consumerId,
    authToken = '',
    conditionId
}) => {
    const url = `${getUrl(consumerId)}/id/${conditionId}`;

    try {
        return await deleteJSON({ url, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const createHealthCondition = async ({
    consumerId = '',
    stateCode = 'GA',
    healthConditionData = [],
    authToken = '',
    leadId = ''
}) => {
    const findLeadID = leadId || getLeadId();
    const agentNPN = getAgentNpn();
    const url = getUrl(consumerId);
    const body =
        healthConditionData?.length &&
        healthConditionData.map(condition => ({
            conditionId: condition.conditionId + '',
            leadId: findLeadID + '',
            agentNPN,
            conditionName: condition.conditionName,
            conditionDescription: condition.conditionDescription,
            stateCode,
            hasLookBackPeriod: condition.hasLookBackPeriod,
            lastTreatmentDate: condition.lastTreatmentDate,
            modifyDate: new Date(),
            consumerId
        }));
    try {
        return await postJSON({ body, url, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const updateHealthCondition = async ({
    consumerId = '',
    stateCode = 'GA',
    healthConditionData = {},
    authToken = ''
}) => {
    const findLeadID = getLeadId();
    const url = getUrl(consumerId);
    const agentNPN = getAgentNpn();
    let isComplete = false;
    if (healthConditionData.lastTreatmentDate) {
        const lastTreatmentDate = new Date(
            healthConditionData.lastTreatmentDate
        );
        isComplete = lastTreatmentDate.getTime() < new Date().getTime();
    }

    const body = {
        id: healthConditionData.id,
        conditionId: healthConditionData.conditionId + '',
        leadId: findLeadID + '',
        agentNPN,
        conditionName: healthConditionData.conditionName,
        conditionDescription: healthConditionData.conditionDescription,
        stateCode,
        hasLookBackPeriod: healthConditionData.hasLookBackPeriod,
        lastTreatmentDate: healthConditionData.lastTreatmentDate,
        modifyDate: new Date(),
        consumerId,
        isComplete: isComplete
    };

    try {
        return await putJSON({ body, url, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

export {
    createHealthCondition,
    deleteUserHealthConditions,
    updateHealthCondition,
    getUserHealthConditions
};
