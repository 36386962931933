import * as Sentry from '@sentry/react';

import { API_BASE_URL as baseURL } from 'utilities/env';
import getJSON from 'utilities/getJSON';
import postJSON from 'utilities/postJSON';

const searchUrl = `${baseURL}/Search`;

const countySearch = async ({ zipCode, authToken = '' }) => {
    const params = { zipCode };
    const url = `${searchUrl}/GetCounties`;
    try {
        return await getJSON({ params, url, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const drugDetailSearch = async ({ id, authToken = '' }) => {
    const params = { id };
    const url = `${searchUrl}/DrugDetail`;
    try {
        return await getJSON({ params, url, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const pharmacySearch = ({
    take,
    skip,
    fields,
    radius,
    zip,
    pharmacyName,
    planPharmacyType,
    latLng,
    pharmacyIDType,
    authToken = ''
}) => {
    const body = {
        fields,
        latLng,
        pharmacyIDType,
        pharmacyName,
        planPharmacyType,
        radius,
        skip,
        take,
        zip
    };
    const url = `${searchUrl}/Pharmacies`;

    postJSON({ body, url, authToken });
};

const providerSearch = async ({
    NPIs,
    page = 1,
    perPage = 10,
    radius = 10,
    searchTerm,
    zipCode,
    authToken = ''
}) => {
    const params = {
        NPIs,
        page,
        perPage,
        radius,
        searchTerm,
        zipCode
    };

    const url = `${searchUrl}/Providers`;
    try {
        return await getJSON({ params, url, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

export { countySearch, drugDetailSearch, pharmacySearch, providerSearch };
