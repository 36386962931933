import { createSlice } from '@reduxjs/toolkit';

import { DOCTOR_NAMES_KEY } from 'utilities/storageKeys';

import { get } from 'temp/utilities/storage';

export const doctorDetails = createSlice({
    name: 'doctorDetails',

    initialState: {
        doctorModal: false,
        isApiFailed: false,
        doctorNames: get(DOCTOR_NAMES_KEY, []),
        selectedDoctor: null,
        isSearchDoctor: true,
        editDoctorLoader: false,
        selectedPlan: null
    },

    reducers: {
        setDoctorModal: (state, action) => {
            state.doctorModal = action.payload;
        },
        setDoctorNames: (state, action) => {
            state.doctorNames = action.payload;
        },
        setIsApiFailed: (state, action) => {
            state.isApiFailed = action.payload;
        },
        setSelectedDoctor: (state, action) => {
            state.selectedDoctor = action.payload;
        },
        setIsSearchDoctor: (state, action) => {
            state.isSearchDoctor = action.payload;
        },
        setEditDoctorLoader: (state, action) => {
            state.editDoctorLoader = action.payload;
        },
        setSelectedPlan: (state, action) => {
            state.selectedPlan = action.payload;
        }
    }
});

export const {
    setDoctorModal,
    setDoctorNames,
    setIsApiFailed,
    setSelectedDoctor,
    setIsSearchDoctor,
    setEditDoctorLoader,
    setSelectedPlan
} = doctorDetails.actions;

export default doctorDetails.reducer;
