import { createSlice } from '@reduxjs/toolkit';

// import { RADIUS_MILES_KEY } from 'utilities/storageKeys';

// import { get } from 'temp/utilities/storage';

export const stateDropdownDetails = createSlice({
    name: 'stateDropdownDetails',

    initialState: {
        state: ''
        // get(RADIUS_MILES_KEY, 10)
    },

    reducers: {
        setSelectedState: (state, action) => {
            state.state = action.payload;
        }
    }
});

export const { setSelectedState } = stateDropdownDetails.actions;

export default stateDropdownDetails.reducer;
