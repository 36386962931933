import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useClientServiceContext } from 'services/clientServiceProvider';

import AgentDetailsCardNoPurl from 'components/AgentDetailsCardNoPurl';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import { getProductTypeByPlanType } from 'components/PurlBanner/usePurlBanner';
import Spinner from 'components/Spinner';
import Text from 'components/Text';
import NextIcon from 'components/icons/NextIcon';
import PreviousIcon from 'components/icons/PreviousIcon';

import { updatePurlAgents } from 'layouts/MainLayout/slice';

import { getAgentNpn } from 'utilities/getSessionData';
import { isPromise } from 'utilities/isPromise';
import { PURL_AGENT_CODE, PURL_AGENT_DATA } from 'utilities/sessionStorageKeys';
import {
    FINALEXPENSE_AGENT_NPN,
    MEDICARE_AGENT_NPN,
    ZIP_CODE_KEY
} from 'utilities/storageKeys';

import image from './image.svg';

import { headerTitle, headerText } from './constants';

import styles from './styles.module.scss';

import { getPurlAgentData } from 'temp/utilities/apiSession';
import { getPurlAgentDataByAgentCode } from 'temp/utilities/apiSession/agentPurl';
import { getAvailableAgentsData } from 'temp/utilities/apiSession/availableAgents';
import { get, set, remove } from 'temp/utilities/storage';

const AgentsModal = ({ agentModalState, onClose, setPurlAgentContext }) => {
    const { isOpen, selectedType } = agentModalState;
    const pageSize = 3;
    const [agentsData, setAgentsData] = useState(null);
    const [pagedResults, setPagedResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const { clientService } = useClientServiceContext();
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const dispatch = useDispatch();

    const getAgentsData = async () => {
        const response = await getAvailableAgentsData(
            get(ZIP_CODE_KEY),
            selectedType
        );
        if (response?.length > 0) {
            setAgentsData(response);
            const slicedResults = [...response]?.slice(0, pageSize);
            setPagedResults(slicedResults);
            setTotalPages(Math.ceil(response?.length / pageSize));
            setIsLoading(false);
        } else {
            setAgentsData([]);
            setIsLoading(false);
        }
    };

    const getPageAgents = page => {
        if (currentPage !== page) {
            const currentAgentsPage =
                page === 'next'
                    ? currentPage + 1
                    : page === 'previous'
                    ? currentPage - 1
                    : page;
            const pagedStart = (currentAgentsPage - 1) * pageSize;
            const pageLimit = pageSize * currentAgentsPage;
            const slicedResults = [...agentsData]?.slice(pagedStart, pageLimit);
            setPagedResults(slicedResults);
            setCurrentPage(currentAgentsPage);
            const element = document.getElementById('agent-modal');
            element.scrollIntoView();
        }
    };

    useEffect(() => {
        if (isOpen) {
            getAgentsData();
        }
    }, [isOpen]);

    const defaultVisiblePages = 3;

    const getVisibleRange = (
        total,
        current,
        maxVisible = defaultVisiblePages
    ) => {
        const numVisible = Math.min(total, maxVisible);

        return new Array(numVisible).fill(null).map((_, idx) => {
            if (
                numVisible < maxVisible ||
                current <= Math.ceil(maxVisible / 2)
            ) {
                return idx + 1;
            }
            if (current > total - Math.floor(maxVisible / 2)) {
                return idx + (total - maxVisible + 1);
            }
            return idx + current - Math.floor(maxVisible / 2);
        });
    };
    const updatePurlAgentsData = async response => {
        const agentAssociations = response.consumerAgentAssociations || [];
        if (agentAssociations.length > 0) {
            const purlAgents = await agentAssociations.reduce(
                async (acc, agentAssociation) => {
                    const purlResponse = await getPurlAgentDataByAgentCode(
                        agentAssociation.agentPurlSwitchedTo
                    );
                    let res = [];
                    if (isPromise(acc)) res = await acc;
                    return [
                        ...res,
                        {
                            ...purlResponse,
                            productType: agentAssociation.productType,
                            associationData: agentAssociation
                        }
                    ];
                },
                []
            );
            dispatch(updatePurlAgents(purlAgents));
        }
    };

    const updateUserProfile = async () => {
        const response = await clientService.getConsumerByConsumerId(
            userProfile?.consumerId
        );

        if (response?.consumerAgentAssociations) {
            for (const consumer of response?.consumerAgentAssociations || []) {
                consumer?.productType === 'Final Expense' &&
                    set(FINALEXPENSE_AGENT_NPN, consumer?.agentNpnSwitchedTo);

                consumer?.productType === 'Medicare' &&
                    set(MEDICARE_AGENT_NPN, consumer?.agentNpnSwitchedTo);
            }
        }
        await updatePurlAgentsData(response);
    };

    const setSelectedAgent = async purl => {
        const newAgent = agentsData.find(item => item.agentPURL === purl);
        const agentNpn = getAgentNpn();
        const payload = {
            agentId: 0,
            agentNpnSwitchedFrom: agentNpn?.toString() || '',
            agentNpnSwitchedTo: newAgent.agentNPN,
            agentPurlSwitchedTo: newAgent.agentPURL,
            consumerId: userProfile?.consumerId,
            isActive: true,
            leadId: null,
            productType: getProductTypeByPlanType(selectedType)
        };
        await clientService.agentAssociation(payload);
        set(PURL_AGENT_CODE, purl);
        await fetchPurlAgentDetails(purl);
        await updateUserProfile();
    };

    const fetchPurlAgentDetails = async purl => {
        const response = await getPurlAgentData();
        if (response) {
            set(PURL_AGENT_DATA, response);
            setPurlAgentContext(purl, response);
            onClose();
        } else {
            remove(PURL_AGENT_DATA);
        }
        return response;
    };

    return (
        <div className={styles.modalWithHeaderFooter}>
            <Modal className={styles.modal} isOpen={true}>
                <ModalWindow
                    contentClassName={styles.contentClassName}
                    headerIcon={image}
                    headerText={headerText}
                    headerTitle={headerTitle}
                    headerClassName={styles.headerClassName}
                    closeIconClassName={styles.closeIconClassName}
                    onClose={onClose}
                    hideFooter={true}
                >
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <div
                            className={styles.content}
                            data-testid="agent-modal"
                            id="agent-modal"
                        >
                            <div className={styles.agentsCardsContainer}>
                                {!!agentsData?.length && (
                                    <div className={styles.mapAndCardsBox}>
                                        <div className={styles.cardBox}>
                                            <div
                                                className={
                                                    styles.agentListContainer
                                                }
                                            >
                                                {pagedResults.map(
                                                    (item, index) => (
                                                        <AgentDetailsCardNoPurl
                                                            {...item}
                                                            key={index}
                                                            isAgentModal={true}
                                                            setSelectedAgent={
                                                                setSelectedAgent
                                                            }
                                                            selectedType={
                                                                selectedType
                                                            }
                                                        />
                                                    )
                                                )}
                                            </div>
                                            <div
                                                className={styles.paginationDev}
                                            >
                                                <span
                                                    className={
                                                        styles.paginationText
                                                    }
                                                >
                                                    {agentsData &&
                                                        `Showing ${
                                                            (currentPage - 1) *
                                                                pageSize +
                                                            1
                                                        } - ${
                                                            currentPage *
                                                                pageSize >
                                                            agentsData?.length
                                                                ? agentsData?.length
                                                                : currentPage *
                                                                  pageSize
                                                        } of ${
                                                            agentsData?.length
                                                        }`}
                                                </span>
                                                <span
                                                    className={
                                                        styles.pagination
                                                    }
                                                >
                                                    {agentsData && (
                                                        <nav>
                                                            <ul>
                                                                <div
                                                                    className={
                                                                        styles.paginationMiddle
                                                                    }
                                                                >
                                                                    <li
                                                                        className={
                                                                            styles.pagination__button
                                                                        }
                                                                    >
                                                                        <button
                                                                            type="button"
                                                                            onClick={() =>
                                                                                getPageAgents(
                                                                                    'previous'
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                currentPage ===
                                                                                    1 &&
                                                                                true
                                                                            }
                                                                        >
                                                                            <span
                                                                                className={
                                                                                    styles.navIcon
                                                                                }
                                                                            >
                                                                                <PreviousIcon />
                                                                            </span>
                                                                        </button>
                                                                    </li>
                                                                    {getVisibleRange(
                                                                        totalPages,
                                                                        currentPage
                                                                    ).map(
                                                                        (
                                                                            page,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <li
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    className={`${
                                                                                        page ===
                                                                                        currentPage
                                                                                            ? styles.pagination__button_current
                                                                                            : styles.pagination__button_active
                                                                                    }`}
                                                                                >
                                                                                    <button
                                                                                        type="button"
                                                                                        style={{
                                                                                            fontWeight:
                                                                                                page ===
                                                                                                currentPage
                                                                                                    ? 'bold'
                                                                                                    : undefined
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            getPageAgents(
                                                                                                page
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            page
                                                                                        }
                                                                                    </button>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                                    <li
                                                                        className={
                                                                            styles.pagination__button
                                                                        }
                                                                    >
                                                                        <button
                                                                            type="button"
                                                                            onClick={() =>
                                                                                getPageAgents(
                                                                                    'next'
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                currentPage ===
                                                                                    totalPages &&
                                                                                true
                                                                            }
                                                                        >
                                                                            <span
                                                                                className={
                                                                                    styles.navIcon
                                                                                }
                                                                            >
                                                                                <NextIcon />
                                                                            </span>
                                                                        </button>
                                                                    </li>
                                                                </div>
                                                            </ul>
                                                        </nav>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {agentsData?.length === 0 && (
                                    <div>
                                        <Text text="Sorry, but we do not currently have any field agents in this area." />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </ModalWindow>
            </Modal>
        </div>
    );
};

export default AgentsModal;
