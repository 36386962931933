const prescriptionDTO = ({
    dosage,
    drug,
    drugPackage,
    quantity,
    frequency
}) => ({
    dosageID: dosage.dosageID,
    ndc: drugPackage?.referenceNDC || dosage?.referenceNDC,
    metricQuantity: quantity,
    daysOfSupply: frequency,
    selectedPackage: drugPackage && {
        userQuantity: drugPackage.commonUserQuantity,
        metricQuantity: drugPackage.commonMetricQuantity,
        ndc: drugPackage.referenceNDC
    }
});

export default prescriptionDTO;
