export const PharmacyIcon = props => (
    <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx="15" cy="15" r="15" fill="#CCCCCC" />
        <path
            d="M7.01563 11.7487C7.01604 12.7278 7.35593 13.6765 7.97739 14.4332C8.59885 15.1898 9.46344 15.7075 10.4238 15.8982C11.3842 16.0888 12.381 15.9406 13.2444 15.4787C14.1077 15.0168 14.7842 14.2699 15.1586 13.3651C15.5329 12.4604 15.582 11.4538 15.2975 10.517C15.0129 9.58009 14.4123 8.77086 13.598 8.22715C12.7837 7.68344 11.8061 7.43889 10.8317 7.53516C9.85734 7.63144 8.94648 8.06259 8.25434 8.75514C7.86125 9.14822 7.54952 9.61494 7.33697 10.1286C7.12442 10.6423 7.01522 11.1928 7.01563 11.7487Z"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M17.9061 21.6265C17.635 21.9019 17.312 22.121 16.9558 22.271C16.5995 22.421 16.2172 22.499 15.8306 22.5005C15.4441 22.5021 15.0611 22.4271 14.7038 22.2798C14.3464 22.1326 14.0217 21.9161 13.7484 21.6428C13.4751 21.3695 13.2586 21.0448 13.1113 20.6874C12.9641 20.33 12.8891 19.9471 12.8906 19.5605C12.8922 19.174 12.9702 18.7916 13.1202 18.4354C13.2702 18.0792 13.4893 17.7562 13.7647 17.4851L16.9859 14.2634C17.5368 13.7216 18.2795 13.4195 19.0522 13.4229C19.8249 13.4263 20.565 13.735 21.1111 14.2816C21.6572 14.8283 21.9651 15.5686 21.9678 16.3413C21.9704 17.1141 21.6676 17.8565 21.1252 18.4069L17.9061 21.6265Z"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M15.373 15.874L19.5145 20.0154"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M17.9062 15.1827C18.1338 14.955 18.4238 14.8 18.7395 14.7373C19.0551 14.6745 19.3824 14.7068 19.6797 14.8302"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M15.3627 10.7695H7.12891"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M7.12891 12.7217H15.3627"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default PharmacyIcon;
