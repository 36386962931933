import moment from 'moment';
import { useState } from 'react';

import Icon from 'components/Icon';
import Label from 'components/Label';
import Text from 'components/Text';
import TextWithLabel from 'components/TextWithLabel';

import maskMedicareId, { formatMCIN } from 'utilities/maskMedicareId';

import hideIcon from './images/hide.svg';
import editIcon from './images/icon-Edit.svg';
import showIcon from './images/show.svg';

import styles from './styles.module.scss';

const ManageMedicareCard = ({
    details,
    onCancel,
    cardHeaderClass = styles.cardHeader,
    from,
    showMedicareCode = false
}) => {
    const [showCode, setShowCode] = useState(showMedicareCode);
    const showMedicareId = showCode =>
        !showCode && details?.medicareIdNumber
            ? maskMedicareId(details?.medicareIdNumber)
            : formatMCIN(details?.medicareIdNumber) || '';

    const formattedDate = date => {
        let tdate = date ? moment(date).format('MM-DD-YYYY') : 'N/A';
        return tdate;
    };

    return (
        <>
            <div className={cardHeaderClass}>Medicare Health Insurance</div>
            <div className={styles.infoCardDetails}>
                <Label text="Name" />

                <Text
                    className={styles.labelText}
                    text={details?.firstName + ' ' + details?.lastName}
                />

                <div className={styles.divider} />

                <Label text="Medicare Number" />

                <div className={styles.showInfo}>
                    <Text
                        className={styles.labelText}
                        text={showMedicareId(showCode)}
                    />
                    {!from && (
                        <div className={styles.showBlock}>
                            <Icon
                                className={styles.icon}
                                image={!showCode ? showIcon : hideIcon}
                            />{' '}
                            {!showCode ? (
                                <div
                                    className={styles.showText}
                                    onClick={() => setShowCode(!showCode)}
                                >
                                    Show ID
                                </div>
                            ) : (
                                <div
                                    className={styles.showText}
                                    onClick={() => setShowCode(!showCode)}
                                >
                                    Hide ID
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className={styles.divider} />

                <div className={styles.dateBlock}>
                    <TextWithLabel
                        label="Plan A Coverage Starts"
                        text={formattedDate(details?.medicarePartADate)}
                    />
                    <TextWithLabel
                        label="Plan B Coverage Starts"
                        text={formattedDate(details?.medicarePartBDate)}
                    />
                </div>
            </div>

            <div className={styles.editInfo} onClick={onCancel}>
                {' '}
                Edit Informaton{' '}
                <Icon className={styles.icon} image={editIcon} />
            </div>
        </>
    );
};
export default ManageMedicareCard;
