import Icon from 'components/Icon';
import Text from 'components/Text';

import { formatMCID } from 'utilities/maskMedicareId';

import SafeGuardIcon from './images/safeguard.svg';

import styles from './styles.module.scss';

const LegacySafeGuardCard = ({ name, id, cardClassName }) => (
    <div className={cardClassName}>
        <Icon image={SafeGuardIcon} />
        <Text text={name} className={styles.name} />
        <Text
            text={formatMCID(id) || '000-000-000-XXX'}
            className={styles.medicareID}
        />
    </div>
);

export default LegacySafeGuardCard;
