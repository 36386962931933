const greet = () => {
    const date = new Date();
    const hours = date.getHours();
    return hours < 12
        ? 'Good Morning,'
        : hours >= 12 && hours <= 16
        ? 'Good Afternoon,'
        : 'Good Evening,';
};

export default greet;
