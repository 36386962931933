import { useContext } from 'react';

import Body2 from 'components/Body2';
import Heading3 from 'components/Heading3';
import Icon from 'components/Icon';

import phoneContext from 'contexts/phone';

import phone from './images/phone.svg';
import rightArrow from './images/rightArrow.svg';

import styles from './styles.module.scss';

export const PurlBannerGenericInfo = ({ navigateToFindAgent }) => {
    const { phone: phoneCxt } = useContext(phoneContext);

    const { number = '' } = phoneCxt;
    return (
        <>
            <div className={styles.purlAgentFindContainer}>
                <a
                    className={styles.findAgentPhone}
                    href={`tel:${number}`}
                    rel="noreferrer"
                >
                    {number}
                    <Icon className={`${styles.phoneIcon}`} image={phone} />
                </a>
            </div>
            <div
                className={styles.findAgentHeader}
                onClick={navigateToFindAgent}
            >
                <Body2 text="Find an Agent" className={styles.heading3} />
                <Icon
                    className={`${styles.rightArrowIcon}`}
                    image={rightArrow}
                />
            </div>
        </>
    );
};
