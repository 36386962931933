import moment from 'moment';

import Heading3 from 'components/Heading3';
import Text from 'components/Text';

import PolicyDetails from 'pages/PolicyDetails';

import styles from './styles.module.scss';

const YourLifePlanModalContent = ({ plansDetails }) => {
    const PlanDate = ({ label, data, className = styles.heading }) =>
        data?.trim() ? (
            <div className={styles.data}>
                <Heading3 className={className} text={`${label}:`} />

                <Text className={styles.label}>{data}</Text>
            </div>
        ) : null;

    return (
        <div
            className={`${styles.informationCards} medicare-id-container`}
            id={'medicare-id'}
        >
            <div className={styles.planDetails}>
                <div className={styles.detailAndDateContainer}>
                    <div className={styles.planContent}>
                        <PlanDate
                            className={styles.carrierHeading}
                            label="Carrier Name"
                            data={plansDetails?.carrier}
                        />
                    </div>
                    <div className={styles.planDate}>
                        <PlanDate
                            label="Submitted"
                            data={
                                plansDetails?.submitDate
                                    ? moment(plansDetails?.submitDate).format(
                                          'MM/DD/YY'
                                      )
                                    : ''
                            }
                        />
                        <PlanDate
                            label="Effective"
                            data={
                                plansDetails?.policyEffectiveDate
                                    ? moment(
                                          plansDetails?.policyEffectiveDate
                                      ).format('MM/DD/YY')
                                    : ''
                            }
                        />
                    </div>
                </div>
                <div className={styles.detailAndDateContainer}>
                    <div className={styles.planContent}>
                        <PlanDate
                            label="Product Type"
                            data={plansDetails?.productCategory}
                            className={styles.heading3}
                        />
                    </div>

                    <PlanDate
                        data={`${plansDetails?.agentFirstName ?? ''} ${
                            plansDetails?.agentLastName ?? ''
                        }`}
                        label={'Writing Agent'}
                        className={styles.heading3}
                    />
                </div>
            </div>
            <div className={styles.policyDetails}>
                <PolicyDetails
                    value={`${plansDetails?.consumerFirstName} ${plansDetails?.consumerLastName}`}
                    label={'Policy Holder'}
                    className={styles.policyLabel}
                />
                <PolicyDetails
                    value={plansDetails?.policyNumber}
                    label={'Policy ID'}
                    className={styles.policyLabel}
                />
            </div>
        </div>
    );
};

export default YourLifePlanModalContent;
