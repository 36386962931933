import { useDispatch, useSelector } from 'react-redux';

import useFetch from 'components/hooks/useFetch';

import { CONSUMER_BASE_URL } from 'utilities/env';

import { setCompletedItems, setTodoItems } from './slice';

export const useToDo = () => {
    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const { consumerId } = userProfile;
    const { Get: getToDos } = useFetch(
        `${CONSUMER_BASE_URL}/ToDo/${consumerId}`
    );
    const { Post: createTodo } = useFetch(`${CONSUMER_BASE_URL}/ToDo`);

    const { Put: updateToDo } = useFetch(`${CONSUMER_BASE_URL}/ToDo/Complete`);

    const getData = async (overrideUrl = '') => {
        let toDoData = [];
        if (!overrideUrl) toDoData = await getToDos();
        else toDoData = await getToDos(null, false, overrideUrl);
        const toDoItems = toDoData
            .filter(d => !d.isComplete)
            .sort((a, b) => (a.toDoId > b.toDoId ? 1 : -1));
        dispatch(setTodoItems(toDoItems));
        const completedItems = toDoData
            .filter(d => d.isComplete)
            .sort((a, b) => (a.toDoId > b.toDoId ? 1 : -1));
        dispatch(setCompletedItems(completedItems));
        return { toDoData };
    };

    const toDoUpdate = async (item, toDoTypeId) => {
        await updateToDo({
            consumerId: consumerId,
            toDoTypeId: toDoTypeId || item.toDoType.toDoTypeId
        });

        await getData();
    };

    const toDoCreate = async (consId, toDoTypeId) => {
        await createTodo({
            consumerId: consId,
            toDoTypeId: toDoTypeId
        });
    };

    return { getData, toDoUpdate, toDoCreate };
};
