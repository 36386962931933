// import DoctorIcon from './icons/doctor.svg';
// import HealthConditionsIcon from './icons/healthConditions.svg';
// import HealthInfoIcon from './icons/healthInfo.svg';
// import LocationIcon from './icons/location.svg';
// import MedicaidIcon from './icons/medicaid.svg';
// import PharmacyIcon from './icons/pharmacy.svg';
// import PrescriptionIcon from './icons/prescription.svg';

export const headerTitle = 'Compare Plans and Enroll';
export const headerTitle1 = 'Compare Coverage';

export const headerText =
    'Help us identify plans that may meet your specific needs by adding a few more details about your situation.';
export const headerText1 =
    'Help us find coverage that fits your specific needs by adding a few more details about your situation.';

export const headerIconAlt = '';
export const guidedExperienceLabel =
    'Help us get more accurate results by answering a few questions.';
export const noGuidedExperienceLabel = 'No thanks, just take me to plans.';
export const noGuidedExperienceLabel1 =
    'No thanks, just take me to view coverage options.';
export const buttonLabel = 'Get Started';
export const plansButtonLabel = 'View Plans';
export const plansButtonLabel1 = 'View Coverage';

// export const stepIconMap = {
//     location: LocationIcon,
//     pharmacy: PharmacyIcon,
//     doctor: DoctorIcon,
//     prescription: PrescriptionIcon,
//     medicaid: MedicaidIcon,
//     healthInfo: HealthInfoIcon,
//     healthConditions: HealthConditionsIcon
// };

export const medicareSteps = [
    {
        id: 0,
        label: 'Location',
        icon: 'location',
        isCompleted: false,
        isSkipped: false,
        enabled: true,
        isVisited: false
    },
    {
        id: 1,
        label: 'Pharmacy',
        icon: 'pharmacy',
        isCompleted: false,
        isSkipped: false,
        enabled: false,
        isVisited: false
    },
    {
        id: 2,
        label: 'Doctors',
        icon: 'doctor',
        isCompleted: false,
        isSkipped: false,
        enabled: false,
        isVisited: false
    },
    {
        id: 3,
        label: 'Prescriptions',
        icon: 'prescription',
        isCompleted: false,
        isSkipped: false,
        enabled: false,
        isVisited: false
    },
    {
        id: 4,
        label: 'Assistance',
        icon: 'medicaid',
        isCompleted: false,
        isSkipped: false,
        enabled: false,
        isVisited: false
    }
];

export const finalExpenseSteps = [
    {
        id: 0,
        label: 'Location',
        icon: 'location',
        isCompleted: false,
        isSkipped: false,
        enabled: true,
        isVisited: false
    },
    {
        id: 1,
        label: 'Health Information',
        icon: 'healthInfo',
        isCompleted: false,
        isSkipped: false,
        enabled: false,
        isVisited: false
    },
    {
        id: 2,
        label: 'Health Conditions',
        icon: 'healthConditions',
        isCompleted: false,
        isSkipped: false,
        enabled: false,
        isVisited: false
    }
];
