import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';

import store from 'app/store';

const Providers = ({ children }) => (
    <HelmetProvider>
        <ReduxProvider store={store}>{children}</ReduxProvider>
    </HelmetProvider>
);

export default Providers;
