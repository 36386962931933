import { useMemo, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import handleEvent from 'utilities/handleEvent';

import './index.scss';

import styles from './styles.module.scss';

const Dropdown = ({
    className,
    displayValue,
    id,
    list,
    menuProps,
    onChange,
    onClose = () => {},
    onOpen = () => {},
    values,
    register = () => {},
    required = false,
    renderValue = null
}) => {
    const handleChange = useMemo(() => handleEvent(onChange), [onChange]);

    const [open, setOpen] = useState(false);

    const handleOpen = e => {
        setOpen(true);
        onOpen(e);
    };
    const handleClose = e => {
        setOpen(false);
        onClose(e);
    };

    return (
        <Select
            IconComponent={() => (
                <KeyboardArrowDownIcon
                    className={`${styles.selectIconStyle} ${
                        open && styles.selectIconStyleActive
                    }`}
                />
            )}
            id={id}
            MenuProps={menuProps}
            className={`${className} ${styles.dropdown}`}
            displayEmpty
            onChange={handleChange}
            onClose={handleClose}
            onOpen={handleOpen}
            value={displayValue}
            {...register(id, required ? { required: true } : {})}
            {...(renderValue ? { renderValue } : {})}
        >
            {values?.map(item => (
                <MenuItem key={item} value={item}>
                    {item}
                </MenuItem>
            ))}

            {list?.map(item => (
                <MenuItem key={item.value} value={item.value}>
                    {item.label}
                </MenuItem>
            ))}
        </Select>
    );
};

export default Dropdown;
