import { API_BASE_URL } from 'utilities/env';
import getJSON from 'utilities/getJSON';

const searchUrl = `${API_BASE_URL}/Search`;

const drugSearch = async ({ drugName, signal, authToken = '' }) => {
    const params = { drugName };
    const url = `${searchUrl}/Drugs`;
    try {
        return await getJSON({ params, signal, url, authToken });
    } catch (e) {
        return null;
    }
};

export { drugSearch };
