import { createContext } from 'react';

const purlAgentContext = createContext({
    purlAgentCode: null,
    purlAgentData: null,
    setPurlAgentContext: () => {},
    fetchPurlAgentDetails: () => {}
});

export default purlAgentContext;
