import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LegacySafeGuardCard from 'components/LegacySafeGuardCard';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import YourLegacyModalContent from 'components/YourLegacyModalContent';

import dataLayer from 'utilities/dataLayer';

import { headerTitle, saveButtonLabel } from './constants';
import { setLegacyModal } from './slice';

import styles from './styles.module.scss';

const YourLegacyModal = () => {
    const [showFooter, setShowFooter] = useState(false);
    const [footerButtonDisabled, setFooterButtonDisabled] = useState(false);
    const contactDetailsPageRef = useRef();
    const legacyModal = useSelector(
        state => state.legacyModalDetails?.legacyModal
    );
    const { name, uniqueId } = useSelector(
        state => state.legacyModalDetails?.legacyData
    );

    useEffect(() => {
        if (legacyModal) {
            dataLayer('modal_appear', headerTitle);
        }
    }, [legacyModal]);
    const dispatch = useDispatch();

    const onClose = async () => {
        setShowFooter(false);
        dispatch(setLegacyModal(false));
    };

    const footerButtonClicked = async e => {
        contactDetailsPageRef.current.formSubmit();
    };

    return (
        <Modal className={styles.modal} isOpen={legacyModal}>
            <ModalWindow
                closeIconClassName={styles.closeIconClassName}
                contentClassName={styles.contentClassName}
                footerButtonClassName={styles.footerButtonClassName}
                footerButtonClicked={footerButtonClicked}
                footerButtonDisabled={footerButtonDisabled}
                footerClassName={styles.footerClassName}
                footerLabel={saveButtonLabel}
                headerClassName={styles.headerClassName}
                headerTitle={headerTitle}
                modalWindow={styles.modalWindow}
                onClose={() => onClose()}
                hideFooter={!showFooter}
                headerHeadingClassName={styles.headerHeadingClassName}
                modalHeaderTitle={styles.modalHeaderTitle}
            >
                <div className={styles.content}>
                    <LegacySafeGuardCard
                        cardClassName={styles.insuranceIcon}
                        name={name}
                        id={uniqueId}
                    />

                    <YourLegacyModalContent
                        className={styles.manageAccountContent}
                        showFooter={setShowFooter}
                        ref={contactDetailsPageRef}
                        footerButtonDisabled={setFooterButtonDisabled}
                    />
                </div>
            </ModalWindow>
        </Modal>
    );
};

export default YourLegacyModal;
