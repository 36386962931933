import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Body2 from 'components/Body2';
import ButtonLinkWithIcon from 'components/ButtonLinkWithIcon';
import Heading3 from 'components/Heading3';
import Heading4 from 'components/Heading4';
import Text from 'components/Text';
import { setSupplements } from 'components/WalletSupplementCard/slice';
import YourSupplementForm from 'components/YourSupplementForm';
import { setView } from 'components/YourSupplementModal/slice';
import useFetch from 'components/hooks/useFetch';

import addIcon from './images/icon-Add.svg';
import editIcon from './images/icon-Edit.svg';

import styles from './styles.module.scss';

const YourSupplementModalContent = forwardRef((_, ref) => {
    const dispatch = useDispatch();
    const { supplements } = useSelector(state => state.supplementCardDetails);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const { view } = useSelector(state => state.supplementModalDetails);
    const [supplement, setSupplement] = useState();
    const { consumerId } = userProfile;

    const { Get: getSupplements } = useFetch(
        `${
            import.meta.env.VITE_APP_SUPPLEMENTS_SERVICE_URL
        }/GetSupplements/${consumerId}`
    );

    useEffect(() => {
        const init = async () => {
            const response = await getSupplements();
            if (response) dispatch(setSupplements(response));
        };
        if (userProfile?.consumerId) init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view]);

    const emptyBlock = useMemo(
        () => (
            <Text
                text="Add your supplements to keep track and manage."
                className={styles.emptyBlock}
            />
        ),
        []
    );

    return (
        <div className={styles.supplementContainer}>
            {view === 'list' ? (
                <>
                    <div className={styles.header}>
                        <Heading3
                            text={
                                <>
                                    Supplements{' '}
                                    <span className={styles.count}>
                                        ({supplements?.length})
                                    </span>
                                </>
                            }
                        />
                        <ButtonLinkWithIcon
                            containerClassName={styles.addNewLink}
                            onClick={() => {
                                dispatch(setView('add'));
                                setSupplement();
                            }}
                            text="Add New"
                            btnIcon={addIcon}
                        />
                    </div>
                    <div className={styles.content}>
                        {!supplements?.length ? (
                            emptyBlock
                        ) : (
                            <ul className={styles.list}>
                                {supplements.map(supplement => {
                                    return (
                                        <li
                                            className={styles.listItem}
                                            key={supplement?.supplementId}
                                        >
                                            <div>
                                                <Heading4
                                                    text={
                                                        supplement?.supplementName
                                                    }
                                                />
                                                <Body2
                                                    text={`${supplement?.dosage} ${supplement?.frequency}`}
                                                />
                                            </div>
                                            <ButtonLinkWithIcon
                                                containerClassName={
                                                    styles.editLink
                                                }
                                                onClick={() => {
                                                    dispatch(setView('edit'));
                                                    setSupplement(supplement);
                                                }}
                                                text="Edit"
                                                btnIcon={editIcon}
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                </>
            ) : (
                <YourSupplementForm ref={ref} supplement={supplement || {}} />
            )}
        </div>
    );
});

export default YourSupplementModalContent;
