import { finalExpensePlanType } from 'components/PlanTypeModal/constants';

import { FROM_EMAIL } from 'utilities/storageKeys';

import { getAgentNpn, getAgentPurlCode } from './getSessionData';
import {
    FINALEXPENSE_AGENT_NPN,
    MEDICARE_AGENT_NPN,
    SELECTED_PLAN_TYPE
} from './storageKeys';

import { get } from 'temp/utilities/storage';

const buildQueryString = parameters => {
    const queryString = new URLSearchParams(parameters).toString();

    return queryString ? `?${queryString}` : '';
};

const buildUrlWithQueryString = ({ parameters, url }) => {
    const filteredParameters = removeEmptyEntries(parameters);
    const queryString = buildQueryString(filteredParameters);

    return `${url}${queryString}`;
};

const maybeMakeFetchAbortable = (options, signal) => {
    if (signal) {
        options.signal = signal;
    }

    return options;
};

const removeEmptyEntries = keyValuePairs =>
    Object.fromEntries(
        Object.entries(keyValuePairs).filter(
            keyValuePair => typeof keyValuePair[1] !== 'undefined'
        )
    );

const getJSON = async ({
    params = {},
    signal,
    url,
    additionalHeaders = {},
    authToken = ''
}) => {
    const urlWithParams = buildUrlWithQueryString({ parameters: params, url });
    const agentNpn = getAgentNpn();
    const agentPurl = getAgentPurlCode();
    const fromEmail = get(FROM_EMAIL);
    const isPurl = fromEmail ? false : true;
    const npn =
        get(SELECTED_PLAN_TYPE) === finalExpensePlanType
            ? get(FINALEXPENSE_AGENT_NPN)
            : get(MEDICARE_AGENT_NPN);
    const options = maybeMakeFetchAbortable(
        {
            headers: {
                'Content-Type': 'application/json',
                ...(agentPurl && { isPurl }),
                ...(agentNpn && {
                    AgentNPN: npn || agentNpn
                }),
                ...additionalHeaders,
                ...(authToken && {
                    Authorization: `Bearer ${authToken}`
                })
            },
            method: 'GET'
        },
        signal
    );

    const response = await fetch(urlWithParams, options);

    const responseBody = await response.text();

    if (responseBody === '') {
        return {};
    } else {
        return JSON.parse(responseBody);
    }
};

export default getJSON;
