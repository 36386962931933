import { useState, useCallback, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';

import DoctorListItem from 'components/DoctorListItem';
import Heading6 from 'components/Heading6';
import Spinner from 'components/Spinner';
import { setSelectedDoctor } from 'components/YourDoctorModal/slice';

import ZipCodeContext from 'contexts/zipCode';

import { getDoctor } from './data';

import styles from './styles.module.scss';

const EditDoctor = ({ handleDeleteDoctor, maxReached }) => {
    const dispatch = useDispatch();
    const data = useSelector(state => state.doctorDetails.selectedDoctor);
    const editDoctorLoader = useSelector(
        state => state.doctorDetails.editDoctorLoader
    );
    const { zipCode, county } = useContext(ZipCodeContext);

    const [loading, setLoading] = useState(false);
    const [doctor, setDoctor] = useState(null);
    const [addresses, setAddresses] = useState([]);
    const { firstName, lastName, specialty, NPI } = data;
    const [init, setInit] = useState(false);
    const { user, getAccessTokenSilently } = useAuth0();
    const getAuthToken = async () => {
        return user ? await getAccessTokenSilently() : '';
    };

    const handleEdit = (data, address) => {
        const { addresses } = doctor;
        let tdata = { ...data };
        tdata['selectedAddresses'] = addresses
            .filter(addres => address.includes(addres.id))
            .map(item => item.id);
        dispatch(setSelectedDoctor({ ...tdata }));
    };

    const getData = useCallback(
        async ({ signal, NPIs }) => {
            setLoading(true);
            try {
                const authToken = await getAuthToken();
                const searchResults = await getDoctor({
                    signal,
                    NPIs,
                    fips: county,
                    zipCode,
                    authToken
                });
                if (searchResults?.providers) {
                    const results = searchResults?.providers
                        ? searchResults?.providers
                        : [];
                    setDoctor(results?.length ? results[0] : null);
                } else {
                    setDoctor(null);
                }
                // eslint-disable-next-line no-empty
            } catch (networkError) {
            } finally {
                setInit(true);
                setLoading(false);
            }
        },
        [county, zipCode]
    );

    useEffect(() => {
        if (!init) {
            const controller = new AbortController();
            const signal = controller.signal;

            getData({
                signal,
                NPIs: data?.NPI
            });
            return () => {
                controller.abort();
            };
        }
    }, [data, init, getData]);

    useEffect(() => {
        if (doctor) {
            const { addresses } = doctor;
            setAddresses(addresses || []);
        }
    }, [doctor]);

    return (
        <>
            {editDoctorLoader || loading ? (
                <Spinner />
            ) : (
                <>
                    <DoctorListItem
                        addresses={addresses}
                        selectedAddresses={data?.selectedAddresses || []}
                        firstName={firstName}
                        key={NPI}
                        lastName={lastName}
                        onClick={address => handleEdit(data, address)}
                        specialty={specialty}
                        maxReached={maxReached}
                    />
                    <div className={styles.btnContainer}>
                        <Heading6
                            onClick={handleDeleteDoctor}
                            className={styles.addDoctor}
                            text="Remove Doctor"
                            id="removeDoctor"
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default EditDoctor;
