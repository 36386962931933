import {
    DO_NOT_SELL_INFO,
    FINAL_EXPENSE_BASICS_ROUTE,
    MEDICARE_BASICS_ROUTE,
    MOBILE_TERMS,
    OUR_COMMITMENT_ROUTE,
    PRIVACY_NOTICE_ROUTE,
    REQUEST_CALL_BACK,
    TERMS_ROUTE
} from 'utilities/routes';

export const companyLinks = [
    {
        link: OUR_COMMITMENT_ROUTE,
        text: 'Our Commitment'
    },
    {
        link: PRIVACY_NOTICE_ROUTE,
        text: 'Privacy Notice'
    },
    {
        link: TERMS_ROUTE,
        text: 'Terms of Service'
    },
    {
        externalURL: true,
        link: 'https://submit-irm.trustarc.com/services/validation/d14968e7-edec-4831-b5f2-c494567844f6',
        text: 'Do Not Sell My Information'
    },
    {
        link: MOBILE_TERMS,
        text: 'SMS/MMS Terms'
    },
    //{
    //    externalURL: true,
    //    lines1Text: 'Do Not Sell',
    //    lines2Text: 'My Information',
    //    link: DO_NOT_SELL_INFO,
    //    twoLines: true
    //},

    {
        link: REQUEST_CALL_BACK,
        text: 'Contact a Licensed Insurance Agent'
    }
];

export const companyLinks1 = [
    {
        link: OUR_COMMITMENT_ROUTE,
        text: 'Our Commitment'
    },
    {
        link: PRIVACY_NOTICE_ROUTE,
        text: 'Privacy Notice'
    },
    {
        link: TERMS_ROUTE,
        text: 'Terms of Service'
    },
    {
        externalURL: true,
        link: 'https://submit-irm.trustarc.com/services/validation/d14968e7-edec-4831-b5f2-c494567844f6',
        text: 'Do Not Sell My Information'
    },
    {
        link: MOBILE_TERMS,
        text: 'SMS/MMS Terms'
    },
    {
        externalURL: true,
        lines1Text: 'Do Not Sell',
        lines2Text: 'My Information',
        link: DO_NOT_SELL_INFO,
        twoLines: true
    }
];

export const disclaimerText =
    'PlanEnroll represents Medicare Advantage HMO, PPO, PFFS, and Prescription Drug Plan organizations that have a Medicare contract and/or a Medicare-approved Part D sponsor. Enrollment depends on the plan’s contract renewal. Enrollment in a plan may be limited to certain times of the year unless you qualify for a Special Enrollment Period or you are in your Medicare Initial Enrollment Period. Not all plans offer all of these benefits. Benefits may vary by carrier and location. Limitations and exclusions may apply. Every year, Medicare evaluates plans based on a 5-star rating system.';

/* eslint-disable no-template-curly-in-string */
export const disclaimerText1 =
    'PlanEnroll is a brand operated by Integrity Marketing Group, LLC and is used by its affiliated licensed insurance agencies that are certified to sell Medicare products. PlanEnroll, <a style="color: #ffffff" href="https://www.planenroll.com/">PlanEnroll.com</a> is a non-government website and is not endorsed by the Centers for Medicare and Medicaid Services (CMS), the Department of Health and Human Services (DHHS) or any other government agency.';

export const disclaimerText2 =
    'We do not offer every plan available in your area. Currently we represent 0-${carrierCount} organizations which offer 0-${wrapPlanCount} products in your area. Please contact <a style="color: #ffffff" href="https://www.medicare.gov/">Medicare.gov</a>, 1-800-MEDICARE, or your local State Health Insurance Program (SHIP) to get information on all of your options.';

export const disclaimerText2WhenZipAvailable =
    'We do not offer every plan available in your area. Currently we represent ${carrierCount} organizations which offer ${wrapPlanCount} products in your area. Please contact <a style="color: #ffffff" href="https://www.medicare.gov/">Medicare.gov</a>, 1-800-MEDICARE, or your local State Health Insurance Program (SHIP) to get information on all of your options.';

export const disclaimerText3 =
    'The exact carrier and plan counts are determined by your zip code and county.';

export const disclaimerText4 =
    'To send a complaint to Medicare, call 1-800-MEDICARE (TTY users should call 1- 877-486-2048), 24 hours a day/7 days a week). If your complaint involves a broker or agent, be sure to include the name of the person when filing your grievance. If you are already a member, please contact your health plan to file a complaint.';

export const disclaimerText5 =
    'Final expense life insurance may not cover the entire cost of your funeral and may be used by the designated beneficiary for any purpose rather than being limited to specific funeral services and providers. Final expense life policies will have a lower face value than most traditional term or whole life policies as they are intended for a specific purpose of covering those final costs rather than providing comprehensive support for surviving family members. This type of policy generally doesn’t require a medical exam, but premiums will be higher the older you are, and some benefit payouts may be limited during the first few years of coverage for those with significant health issues. Reducing or skipping premium payments will impact the amount of interest paid and may impact how long the policy lasts. Accessing the cash value of a policy will reduce the available cash surrender value and the death benefit. A policy owner does not have the ability to make unlimited payments into the policy. If too much is paid into the policy, it will become a Modified Endowment Contract (MEC) and withdrawals and loans will be taxable. Coverage may not be available in all states and may vary by state. Policy guarantees are based upon the claims-paying ability of the issuing life insurance company.';

export const learningCenterLinks = [
    {
        link: FINAL_EXPENSE_BASICS_ROUTE,
        text: 'Final Expense Basics'
    },
    {
        link: MEDICARE_BASICS_ROUTE,
        text: 'Medicare Basics'
    }
];
