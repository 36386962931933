import Icon from 'components/Icon';

import image from './image.svg';

import styles from './styles.module.scss';

const LineIcon = () => {
    const icon = <Icon alt="lineIcon" className={styles.icon} image={image} />;
    return (
        <div className={styles.lineIcon}>
            {icon}
            {icon}
            {icon}
        </div>
    );
};

export default LineIcon;
