import Heading3 from 'components/Heading3';
import Text from 'components/Text';

import styles from './styles.module.scss';

const ModalHeaderTitle = ({
    headerHeadingClassName,
    headerTextClassName,
    modalHeaderTitle = styles.modalHeaderTitle,
    text,
    title
}) => (
    <div className={modalHeaderTitle}>
        <Heading3
            className={`${headerHeadingClassName} ${styles.heading3}`}
            text={title}
            id={`headerTitle_${title?.replaceAll(' ', '_')}`}
        />

        <Text
            className={`${headerTextClassName} ${styles.text}`}
            text={text}
            id={`headerText_${title?.replaceAll(' ', '_')}`}
        />
    </div>
);

export default ModalHeaderTitle;
