import { useSelector } from 'react-redux';

import Heading3 from 'components/Heading3';
import IconWithLink from 'components/IconWithLink';
import PharmacyAddedListItem from 'components/PharmacyAddedListItem';
import Spinner from 'components/Spinner';

import disablePlus from './image/disablePlus.svg';
import plus from './image/plus.svg';

import styles from './styles.module.scss';

const PharmacyAddedListItems = ({
    pharmacyList,
    handleDeletePharmacy,
    isFromQuickProfile,
    addPharmacy,
    setAsPrimary
}) => {
    const selectedPlan = useSelector(
        state => state.pharmacyDetails.selectedPlan
    );
    return (
        pharmacyList && (
            <div className={styles.pharmacyAddedListItems}>
                {selectedPlan && (
                    <Heading3
                        className={styles.heading3}
                        text={selectedPlan.planName}
                    />
                )}
                {pharmacyList?.length ? (
                    <>
                        {pharmacyList.map((pharmacy, index) => (
                            <PharmacyAddedListItem
                                deletePharmacy={handleDeletePharmacy}
                                key={index}
                                pharmacyDetails={pharmacy}
                                setAsPrimary={setAsPrimary}
                            />
                        ))}
                        <div className={styles.btnContainer}>
                            <IconWithLink
                                caption={'Add Another Pharmacy'}
                                className={`${
                                    pharmacyList?.length === 3
                                        ? styles.disableIconWithLink
                                        : styles.iconWithLink
                                }`}
                                iconClassName={styles.iconClassName}
                                image={
                                    pharmacyList?.length === 3
                                        ? disablePlus
                                        : plus
                                }
                                isImageClickable={true}
                                labelClassName={
                                    pharmacyList?.length === 3
                                        ? styles.disableLabelClassName
                                        : styles.labelClassName
                                }
                                onClick={addPharmacy}
                                isTextDisplayRight={false}
                            />
                        </div>
                    </>
                ) : (
                    !isFromQuickProfile && <Spinner />
                )}
            </div>
        )
    );
};

export default PharmacyAddedListItems;
