import { useSelector } from 'react-redux';

import DoctorAddedListItems from 'components/DoctorAddedListItems';
import EditDoctor from 'components/DoctorAddedListItems/EditDoctor';
import DoctorSearch from 'components/DoctorSearch';
import Spinner from 'components/Spinner';

import styles from './styles.module.scss';

const YourDoctorModalContent = ({
    defaultValue,
    doctorsList,
    handleDeleteDoctor,
    handleDoctor,
    onChange,
    onClear,
    maxReached,
    isGuided,
    showAdd = false,
    PER_PAGE,
    isFromQuickProfile = false
}) => {
    const details = useSelector(state => state.doctorDetails);
    const { isSearchDoctor, selectedDoctor, editDoctorLoader } = details;
    return (
        <div className={styles.yourDoctorModalContent}>
            {isSearchDoctor ? (
                <DoctorSearch
                    defaultValue={defaultValue}
                    handleDoctor={handleDoctor}
                    onChange={onChange}
                    onClear={onClear}
                    addDoctorLoader={editDoctorLoader}
                    maxReached={maxReached}
                    PER_PAGE={PER_PAGE}
                    isFromQuickProfile={isFromQuickProfile}
                />
            ) : selectedDoctor ? (
                <EditDoctor
                    handleDeleteDoctor={handleDeleteDoctor}
                    maxReached={maxReached}
                    isFromQuickProfile={isFromQuickProfile}
                />
            ) : doctorsList.length === 0 ? (
                <Spinner />
            ) : (
                <DoctorAddedListItems
                    doctorsList={doctorsList}
                    isGuided={isGuided}
                    showAdd={showAdd}
                />
            )}
        </div>
    );
};

export default YourDoctorModalContent;
