import { useContext, useEffect, useRef, useState } from 'react';

import CountyDropdown from 'components/CountyDropdown';
import ZipCodeInputWithLabel from 'components/ZipCodeInputWithLabel';

import ZipCodeContext from 'contexts/zipCode';

import { validateZipCode } from 'utilities/validate';

import styles from './styles.module.scss';

const ZipCodeWithCounty = ({
    className = '',
    id = 'zipCodeInput',
    isOnChangeEnable,
    zipLabelClass,
    countyLabelClass,
    showError = true
}) => {
    const {
        county,
        error,
        getCounties,
        onCountyChange,
        onSetCounties,
        onSetCounty,
        onZipCodeChange,
        setButtonDisabled,
        setCounty,
        setError,
        setZipCode,
        zipCode
    } = useContext(ZipCodeContext);

    const [zip, setZip] = useState(isOnChangeEnable ? zipCode : '');
    const isFirstLoad = useRef(true);

    const countySelected = e => {
        const fips = e.target.value;
        isOnChangeEnable && setCounty(fips);
        setButtonDisabled(false);
        !isOnChangeEnable && localStorage.setItem('updatedCountyVal', fips);
    };
    const zipCodeChanged = async e => {
        const zip = e.target.value.replace(/\D/g, '');
        setZip(zip);
        if (zip.length === 5) {
            if (isOnChangeEnable) {
                setZipCode(zip);
                if (zip === zipCode) onZipCodeChange();
            } else {
                localStorage.setItem('updatedZipCode', zip);
                if (validateZipCode(zip)) {
                    const countiesList = await getCounties(zip);
                    await onSetCounties(countiesList);
                    if (countiesList && countiesList.length > 1) {
                        await onCountyChange();
                        await onSetCounty('');
                        setButtonDisabled(true);
                    }
                }
            }
        } else {
            error && setError(false);
        }
        isOnChangeEnable && setButtonDisabled(false);
        if (!validateZipCode(zip)) {
            setButtonDisabled(true);
        }
    };

    const handleOnBlur = e => {
        const zip = e.target.value.replace(/\D/g, '');
        if (zipCode.length < 5 || zipCode !== zip) {
            if (zipCode === zip) onZipCodeChange();
            else setZipCode(zip);
        }
    };

    useEffect(() => {
        localStorage.setItem('updatedZipCode', zipCode);
        setZip(zipCode);
    }, [zipCode]);

    useEffect(() => {
        const FirstLoad = async () => {
            if (isFirstLoad.current) {
                let zip;
                if (localStorage.getItem('ZipCodeWithoutError')) {
                    zip = localStorage.getItem('ZipCodeWithoutError');
                } else {
                    localStorage.setItem('updatedZipCode', zipCode);
                    zip = zipCode;
                }
                county && localStorage.setItem('countyVal', county);

                if (validateZipCode(zip)) {
                    const countiesList = await getCounties(zip);
                    onSetCounties(countiesList);

                    if (
                        localStorage.getItem('ZipCodeWithoutError') &&
                        countiesList &&
                        countiesList.length > 1
                    ) {
                        await onCountyChange();
                        await onSetCounty('');
                        !county && setButtonDisabled(true);
                    }
                }
                isFirstLoad.current = false;
                localStorage.removeItem('ZipCodeWithoutError');
                setZip(zip);
            }
        };
        FirstLoad();
    }, [isFirstLoad]);

    return (
        <div className={`${className} ${styles.zipCodeWithCounty}`}>
            <ZipCodeInputWithLabel
                className={styles.zipCodeGridArea}
                error={error}
                id={id}
                labelClass={zipLabelClass}
                onChange={zipCodeChanged}
                onBlur={isOnChangeEnable && handleOnBlur}
                onFocus={e => zip.length < 5 && setError(false)}
                zipCode={zip}
                showError={showError}
            />

            <CountyDropdown
                className={styles.countyDropdown}
                id={id}
                labelClass={countyLabelClass}
                onChange={countySelected}
            />
        </div>
    );
};

export default ZipCodeWithCounty;
