import { memo } from 'react';

import Body2 from 'components/Body2';
import Heading2 from 'components/Heading2';
import Heading3 from 'components/Heading3';
import Icon from 'components/Icon';
import { medicarePlanType } from 'components/PlanTypeModal/constants';

import rightArrow from './images/rightArrow.svg';

import styles from './styles.module.scss';

const PurlBannerFindAgent = ({ selectedAgentType, navigateToFindAgent }) => {
    return (
        <div className={styles.purlAgentNameContainer}>
            <div className={styles.nameWithStatus}>
                <Heading2
                    className={styles.purlAgentName}
                    text={`Find a ${
                        selectedAgentType === medicarePlanType
                            ? 'Medicare'
                            : 'Final Expense'
                    } Agent`}
                ></Heading2>
                <div
                    className={styles.findAgentHeader}
                    onClick={navigateToFindAgent}
                >
                    <Body2
                        text="Contact an Agent"
                        className={styles.heading3}
                    />
                    <Icon
                        className={`${styles.rightArrowIcon}`}
                        image={rightArrow}
                    />
                </div>
            </div>
        </div>
    );
};

export default memo(PurlBannerFindAgent);
