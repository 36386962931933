import styles from './styles.module.scss';

const FooterNavHeader = ({ className = '', text, id = 'footerNavHeader' }) => (
    <div
        className={`${styles.footerListTitleItem} ${styles.footerListItem} ${className}`}
        id={id}
    >
        {text}
    </div>
);

export default FooterNavHeader;
