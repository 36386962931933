const validateZipCode = zipCode => /^[0-9]{5}$/.test(zipCode);

const validateMedicalBeneficiaryId = mbiId => {
    const formattedId = String(mbiId).replace(/-/g, ''); // Convert to string and remove existing hyphens
    const validPattern =
        /^[1-9][AC-HJ-KM-NP-RT-Y][AC-HJ-KM-NP-RT-Y0-9][0-9][AC-HJ-KM-NP-RT-Y][AC-HJ-KM-NP-RT-Y0-9][0-9][AC-HJ-KM-NP-RT-Y][AC-HJ-KM-NP-RT-Y][0-9][0-9]$/;
    return validPattern.test(formattedId);
};

export { validateZipCode, validateMedicalBeneficiaryId };
