import { useState } from 'react';
import { useSelector } from 'react-redux';

import Heading3 from 'components/Heading3';
import Spinner from 'components/Spinner';
import Text from 'components/Text';

import styles from './styles.module.scss';

import PrescriptionListItem from 'temp/components/PrescriptionListItem';

const PrescriptionsList = ({
    selectDrug,
    prescriptions,
    setShowSearch,
    isFromProfile,
    isBackButtonVisible
}) => {
    const selectedPlan = useSelector(
        state => state.prescriptionDetails.selectedPlan
    );
    const isGuided = useSelector(state => state.guidedDetails.isGuidedProcess);
    const [loading, setLoading] = useState(false);
    return (
        <>
            {selectedPlan && (
                <Heading3
                    className={styles.planName}
                    text={selectedPlan.planName}
                />
            )}
            {loading ? (
                <Spinner />
            ) : (
                prescriptions?.length > 0 && (
                    <>
                        <ul className={styles.list}>
                            {prescriptions.map(
                                ({ dosage, dosageDetails, inOutNetwork }) => (
                                    <PrescriptionListItem
                                        selectDrug={value => {
                                            selectDrug(value);
                                            setLoading(true);
                                        }}
                                        key={dosage?.labelName}
                                        dosage={dosage}
                                        dosageDetails={dosageDetails}
                                        inNetworkIcon={Boolean(selectedPlan)}
                                        inOutNetwork={inOutNetwork}
                                    />
                                )
                            )}
                        </ul>
                        {(isGuided ||
                            isFromProfile ||
                            window.location.href.includes('quick-profile')) && (
                            <div className={styles.btnContainer}>
                                <Text
                                    onClick={() => {
                                        setShowSearch(true);
                                        isBackButtonVisible(false);
                                    }}
                                    className={styles.addPrescription}
                                    text="Add Another Prescription +"
                                    id="addAnotherPrescriptionLink"
                                />
                            </div>
                        )}
                    </>
                )
            )}
        </>
    );
};

export default PrescriptionsList;
