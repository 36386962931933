import Text from 'components/Text';

import styles from './styles.module.scss';

const PasswordRuleList = () => (
    <Text className={styles.passwordHelper}>
        Your password must:
        <ul className={styles.passwordHelperList}>
            <li>Be at least 8 characters long</li>
            <li>Include at least one uppercase and lowercase letter</li>
            <li>Include at least one number</li>
            <li>Include at least one non-alphanumeric character</li>
        </ul>
    </Text>
);

export default PasswordRuleList;
