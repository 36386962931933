import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import Icon from 'components/Icon';
import {
    setSelectedQuickProfileItem,
    setTargetPage
} from 'components/QuickProfileHealthModal/slice';

import { medicareEnrollLogoBlueBackroundWAlt } from 'utilities/altText';

import image from './image.svg';

import styles from './styles.module.scss';

const InverseLogo = ({ className = '', linkClassName = '' }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const currentPage = window.location.href.substring(
        window.location.href.indexOf('#') + 1
    );
    const { isAuthenticated } = useAuth0();
    return (
        <Link
            className={`${styles.logo} ${linkClassName}`}
            data-click-type="link"
            onClick={e => {
                e.preventDefault();
                if (
                    window.location.href.includes('quick-profile') &&
                    currentPage !== 'personalInformation' &&
                    currentPage !== 'complete' &&
                    window.location.hash &&
                    !isAuthenticated
                ) {
                    dispatch(setTargetPage('Logo'));
                    dispatch(setSelectedQuickProfileItem('/'));
                } else {
                    history.push('/');
                    window.scrollTo(0, 0);
                }
            }}
            to=""
        >
            <Icon
                alt={medicareEnrollLogoBlueBackroundWAlt}
                className={`${styles.inverseLogo} ${className}`}
                image={image}
                id="logo"
            />
        </Link>
    );
};

export default InverseLogo;
