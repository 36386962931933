import Text from 'components/Text';

import styles from './styles.module.scss';

const TextError = ({ className = '', text = '' }) => (
    <Text
        className={`${className} ${styles.textError}`}
        text={text}
        id="error"
    />
);

export default TextError;
