import * as Sentry from '@sentry/react';

import { API_BASE_URL } from 'utilities/env';
import getJSON from 'utilities/getJSON';
import postJSON from 'utilities/postJSON';

const searchUrl = `${API_BASE_URL}/Search`;

const pharmacySearch = async ({
    perPage = 10,
    radius = 10,
    page = 1,
    searchTerm,
    zipCode = '',
    fips = '',
    authToken = ''
}) => {
    const body = {
        fields: '',
        latLng: '',
        pharmacyIDType: 0,
        pharmacyName: searchTerm,
        planPharmacyType: '',
        radius: radius,
        skip: (page - 1) * perPage,
        take: perPage,
        zip: zipCode,
        fips
    };

    const url = `${searchUrl}/Pharmacies`;
    try {
        return await postJSON({ url, body, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const getDigitalPharmacies = async authToken => {
    const url = `${searchUrl}/DigitalPharmacies`;
    try {
        return await getJSON({ url, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

export { pharmacySearch, getDigitalPharmacies };
