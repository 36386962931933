import { createSlice } from '@reduxjs/toolkit';

export const supplementModalDetails = createSlice({
    name: 'supplementModalDetails',

    initialState: {
        supplementContentModal: false,
        view: 'list',
        isFooterButtonDisabled: false
    },

    reducers: {
        setsupplementContentModal: (state, action) => {
            state.supplementContentModal = action.payload;
        },
        setView: (state, action) => {
            state.view = action.payload;
        },
        setIsFooterButtonDisabled: (state, action) => {
            state.isFooterButtonDisabled = action.payload;
        }
    }
});

export const { setsupplementContentModal, setView, setIsFooterButtonDisabled } =
    supplementModalDetails.actions;

export default supplementModalDetails.reducer;
