export const DoctorIcon = props => (
    <svg
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx="15.5" cy="15" r="15" fill="#052A63" />
        <path
            d="M22.8327 11H8.16602V21H22.8327V11Z"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M18.1654 15.3333C18.1653 15.245 18.134 15.1602 18.0785 15.0978C18.0229 15.0353 17.9476 15.0001 17.8691 15H16.3876V13.3333C16.3875 13.245 16.3562 13.1602 16.3007 13.0978C16.2451 13.0353 16.1698 13.0001 16.0913 13H14.9061C14.8276 13.0001 14.7523 13.0353 14.6967 13.0978C14.6412 13.1602 14.6099 13.245 14.6098 13.3333V15H13.1283C13.0498 15.0001 12.9745 15.0353 12.9189 15.0978C12.8634 15.1602 12.8321 15.245 12.832 15.3333V16.6667C12.8321 16.755 12.8634 16.8398 12.9189 16.9022C12.9745 16.9647 13.0498 16.9999 13.1283 17H14.6098V18.6667C14.6099 18.755 14.6412 18.8398 14.6967 18.9022C14.7523 18.9647 14.8276 18.9999 14.9061 19H16.0913C16.1698 18.9999 16.2451 18.9647 16.3007 18.9022C16.3562 18.8398 16.3875 18.755 16.3876 18.6667V17H17.8691C17.9476 16.9999 18.0229 16.9647 18.0785 16.9022C18.134 16.8398 18.1653 16.755 18.1654 16.6667V15.3333Z"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M13.5 11V10C13.5 9.73478 13.5903 9.48043 13.7511 9.29289C13.9118 9.10536 14.1298 9 14.3571 9H16.6429C16.8702 9 17.0882 9.10536 17.2489 9.29289C17.4097 9.48043 17.5 9.73478 17.5 10V11"
            stroke="white"
            stroke-width="0.738203"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default DoctorIcon;
