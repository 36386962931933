import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Body2 from 'components/Body2';
import Heading2 from 'components/Heading2';
import ProfileImageModal from 'components/ProfileImageModal';
import ProfilePicture from 'components/ProfilePicture';
import { setProfileImage } from 'components/UserProfile/slice';
import useFetch from 'components/hooks/useFetch';

import { PROFILE_IMAGE_SERVICE_URL } from 'utilities/env';
import greet from 'utilities/greet';

import styles from './styles.module.scss';

const UserProfileCard = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const profileImage = useSelector(state => state.profileImage.profileImage);
    const { consumerId } = userProfile;
    const { Get: getProfileImage } = useFetch(
        `${PROFILE_IMAGE_SERVICE_URL}/${consumerId}`
    );

    const openModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const user = userProfile || {};

    useEffect(() => {
        const init = async () => {
            const response = await getProfileImage();
            if (response) {
                dispatch(setProfileImage(response));
            }
        };
        if (!!userProfile?.consumerId) init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile]);

    return (
        <>
            <div className={styles.content}>
                <div className={styles.profileContainer}>
                    <ProfilePicture
                        firstName={user?.firstName}
                        lastName={user?.lastName}
                        profileUrl={profileImage}
                        onClick={openModal}
                        styleProps={{
                            width: '96px',
                            height: '96px',
                            fontSize: '32px'
                        }}
                    />
                    <div className={styles.contentBlock}>
                        <Body2 className={styles.greet} text={greet()} />
                        <Heading2
                            className={styles.name}
                            text={user?.firstName}
                        />
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <ProfileImageModal
                    modalOpen={isModalOpen}
                    openParentModal={openModal}
                />
            )}
        </>
    );
};
export default UserProfileCard;
