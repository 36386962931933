import { v4 as uuid } from 'uuid';

import { LEAD_ID_KEY } from 'utilities/storageKeys';

import { get, set } from 'temp/utilities/storage';

const setLeadId = id => set(LEAD_ID_KEY, id);

const getLeadId = () => get(LEAD_ID_KEY, uuid);

export { getLeadId, setLeadId };
