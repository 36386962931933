import Body2 from 'components/Body2';

import { LAST_UPDATED, MATERIAL_ID } from './constants';

import styles from './styles.module.scss';

const LastUpdated = ({ className = '' }) => (
    <Body2
        className={`${className} ${styles.body2}`}
        text={
            <>
                Last Updated Date: {LAST_UPDATED}
                <span className={styles.pipe}> | </span> {MATERIAL_ID}
            </>
        }
        id="lastUpdatedDate"
    />
);

export default LastUpdated;
