import { createSlice } from '@reduxjs/toolkit';

import { PHARMACY_NAMES_KEY } from 'utilities/storageKeys';

import { get } from 'temp/utilities/storage';

export const locationDetails = createSlice({
    name: 'locationDetails',

    initialState: {
        locationModal: false,
        zipCode: get(PHARMACY_NAMES_KEY, []),
        isApiFailed: false
    },

    reducers: {
        setLocationModal: (state, action) => {
            state.locationModal = action.payload;
        },

        setLocationZipcode: (state, action) => {
            state.zipCode = action.payload;
        },

        setIsApiFailed: (state, action) => {
            state.isApiFailed = action.payload;
        }
    }
});

export const { setLocationModal, setLocationZipcode, setIsApiFailed } =
    locationDetails.actions;

export default locationDetails.reducer;
