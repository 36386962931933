import { useEffect, useState } from 'react';

import Body3 from 'components/Body3';
import Button from 'components/Button';
import Icon from 'components/Icon';

import walletPharmacy from './image.svg';

import styles from './styles.module.scss';

import drugFrequencyOptions from 'temp/utilities/drugFrequencyOptions';

const WalletPharmacyCard = ({
    mouseOver,
    walletID,
    mouseOverOut,
    cardClassName = styles.walletPharmacy,
    openPharmacyModal,
    pharmaciesList
}) => {
    const [pharmacyList, setPharmacyList] = useState([]);

    const showData = () => {
        document
            .getElementById('pharmacyContent')
            ?.setAttribute('style', 'display:flex');
    };

    useEffect(() => {
        setPharmacyList(pharmaciesList);
    }, [pharmaciesList]);

    return (
        <div
            className={cardClassName}
            id="walletPharmacy"
            onLoad={
                walletID !== 'walletLegacyCard' &&
                setTimeout(function () {
                    showData();
                }, 50)
            }
        >
            <Icon
                id={'walletPharmacyIcon'}
                image={walletPharmacy}
                onClick={() => {
                    walletID !== 'walletPharmacy'
                        ? mouseOver('walletPharmacy')
                        : pharmacyList?.length && openPharmacyModal();
                    walletID !== 'walletPharmacy' &&
                        setTimeout(function () {
                            showData();
                        }, 50);
                }}
                className={styles.pharmacyIcon}
            />

            <div
                className={styles.pharmacyContent}
                id="pharmacyContent"
                onClick={e =>
                    pharmacyList?.length &&
                    walletID === 'walletPharmacy' &&
                    openPharmacyModal()
                }
            >
                {!pharmacyList?.length ? (
                    <Button
                        label="Add Pharmacy"
                        className={styles.addPharmacyBtn}
                        onClick={e =>
                            walletID === 'walletPharmacy' && openPharmacyModal()
                        }
                    />
                ) : (
                    <div
                        className={styles.pharmacyList}
                        onClick={e =>
                            pharmacyList?.length &&
                            walletID === 'walletPharmacy' &&
                            openPharmacyModal()
                        }
                    >
                        {pharmacyList.map(pharmacy => {
                            return (
                                <div className={styles.pharmacyListItem}>
                                    <Body3
                                        className={styles.pharmacyName}
                                        text={pharmacy?.name}
                                    />

                                    <Body3
                                        className={styles.dosage}
                                        text={`${pharmacy?.address1}, ${pharmacy?.state} ${pharmacy?.zip}`}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default WalletPharmacyCard;
