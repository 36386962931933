import { createSlice } from '@reduxjs/toolkit';

export const supplementCardDetails = createSlice({
    name: 'supplementCardDetails',

    initialState: {
        supplements: []
    },

    reducers: {
        setSupplements: (state, action) => {
            state.supplements = action.payload;
        }
    }
});

export const { setSupplements } = supplementCardDetails.actions;

export default supplementCardDetails.reducer;
