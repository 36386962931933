import BannerWithBackgroundColor from 'components/BannerWithBackgroundColor';
import LastUpdated from 'components/LastUpdated';

import styles from './styles.module.scss';

const UserProfileTemplate = ({
    children,
    className,
    contentClassName,
    title,
    showLastUpdated = true
}) => (
    <>
        <BannerWithBackgroundColor
            className={styles.bannerWithBackgroundColorHeader}
            headingClassName={styles.headingClassName}
            title={title}
        />

        <div className={`${contentClassName} ${styles.content}`}>
            <div className={`${className} ${styles.userProfileContent}`}>
                {children}
            </div>

            {showLastUpdated && <LastUpdated className={styles.lastUpdated} />}
        </div>
    </>
);

export default UserProfileTemplate;
