import { useState } from 'react';

import { MenuItem, Select } from '@mui/material';

import Text from 'components/Text';

import arrowIcon from './images/arrowIcon.svg';

import styles from './styles.module.scss';

const MuiSelect = ({
    className,
    data,
    disabled,
    onChange,
    selectClassName = '',
    textKey = 'text',
    title,
    value,
    valueKey
}) => {
    const [open, setOpen] = useState(false);
    const onOpen = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    return (
        <div className={className}>
            <Text className={styles.text} id={`label_${title}`}>
                {title}
            </Text>

            <Select
                className={`${selectClassName} ${styles.select}`}
                disabled={disabled || !Array.isArray(data) || data.length <= 1}
                IconComponent={() => (
                    <img
                        alt="arrowIcon"
                        className={`selectIconStyle ${
                            open ? 'selectIconStyleActive' : ''
                        }`}
                        src={arrowIcon}
                    />
                )}
                onChange={onChange}
                onClose={onClose}
                onOpen={onOpen}
                open={open}
                value={value}
                variant="outlined"
                id={`${title}_dropdown`}
            >
                {Array.isArray(data) &&
                    data.map(option => (
                        <MenuItem
                            key={option[textKey]}
                            value={valueKey ? option[valueKey] : option}
                            id={option[textKey]}
                        >
                            {option[textKey]}
                        </MenuItem>
                    ))}
            </Select>
        </div>
    );
};
export default MuiSelect;
